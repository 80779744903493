import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';


const applicationSecurityData = {
    videBgApplicationSecurity:{
        clip:"",
        poster:"",
        title:"Seguridad de aplicaciones",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteApplicationSecurity:{
        headBandshowTitle:true,
        headBandTitle:"Refuerce las <span>vulnerabilidades</span> que se multiplican fácilmente",
        classNameHead:"",
        headBandtext:"Las aplicaciones mueven nuestro mundo. Pero a medida que proliferan las aplicaciones y los usuarios, también lo hacen los riesgos de seguridad. Nuestra familia de soluciones de aplicaciones y API protege su empresa con una solución WAAP galardonada, gestión de bots, seguridad de API y protección contra DDoS, lo que le proporciona la visibilidad de las amenazas y las defensas contra ataques que necesita. Obtenga protecciones que se actualizan automáticamente, se ajustan automáticamente a sus políticas y mantienen un alto rendimiento, incluso al combatir botnets o ataques DDoS récord.",
        
    },
    cardsApplicationSecurity:{
        title:"",
        text:"Proteja las <span>aplicaciones</span> con una seguridad sólida",
        items:[
            {
                img:"",
                title:"API Security",
                label:"Obtenga una visibilidad completa de todo el conjunto de API con la detección y supervisión continuas.",
                url:"/detalle/api-security",
                textUrl:""
            },
            {
                img:"",
                title:"App & API Protector",
                label:"Seguridad integral y sin riesgos para sitios web, aplicaciones y API.",
                url:"/detalle/account-protector",
                textUrl:""
            },
            {
                img:"",
                title:"Prolexic",
                label:"Detenga los ataques DDoS con la protección más rápida y eficaz a escala.",
                url:"/detalle/prolexic",
                textUrl:""
            },
            {
                img:"",
                title:"Page Integrity Manager",
                label:"Proteja su sitio de las amenazas del lado del cliente. Detecte y bloquee la actividad maliciosa.",
                url:"/detalle/page-integrity-manager",
                textUrl:""
            },
            {
                img:"",
                title:"Edge DNS",
                label:"Confíe en un sistema de nombres de dominio (DNS) con un alto nivel de seguridad para garantizar la disponibilidad continua de aplicaciones web y API",
                url:"/detalle/edge-dns",
                textUrl:""
            }
        ]
    },
    urlFormApplicationSecurity:"/hable-con-nosotros",
    texBtntFormApplicationSecurity:"Hable con un experto",
}

export default applicationSecurityData;