const TermsOfUseEn = (props) => {
  return (
    <>
      <section className="global-info">
        <div className="content-text">
          <h3>Legal Documents</h3>

          <p>
            When you use the Site, you accept the Terms of Use; if you do not
            agree to the Terms of Use you may not use the Site. HAAP reserves
            the right to modify content on the Site and these Terms of Use
            periodically without prior notice.
          </p>
        </div>

        <div className="content-text">
          <h3>Disclaimer</h3>

          <p>
            HAAP publishes this Web site for our clients, friends, and other
            interested visitors for information purposes only. The contents of
            the site do not constitute Information Assurance advice and some
            information does not necessarily reflect the opinions of the
            Company, employees or any of its attorneys or clients.
          </p>

          <p>
            The site provides general information, which may or may not be
            correct, complete or current at the time of reading. The content is
            not intended to be used as a substitute for specific Information
            Assurance advice or opinions. No recipients of content from this
            site should act or refrain from acting on the basis of content of
            the site without seeking appropriate Information Assurance or legal
            advice or other professional counselling.
          </p>

          <p>
            HAAP expressly disclaims all liability relating to actions taken or
            not taken based on any or all contents of the site. The transmission
            of information to and from this site, in part or in whole, does not
            create, and receipt does not constitute, a client relationship
            between senders and/or recipients and HAAP. Any communication
            through the Web site or through e-mail does not constitute or create
            a client relationship. In addition, information should not be sent
            to HAAP electronically until you speak with a senior employee and
            receive authorization to send the information. General information
            sent to HAAP via this Web site or through e-mail is not secure and
            is therefore not confidential.
          </p>

          <p>
            HAAP provides clients with a mechanism to communicate information,
            reports, alerts and other relevant information in a secure manner,
            and a client should not send confidential information to HAAP via
            this Web site unless they have reviewed HAAP’s written instructions
            on how to communicate securely. Once information has been securely
            sent and received by the client, HAAP cannot be held responsible for
            its' safeguarding. HAAP is not responsible, and does not necessarily
            endorse, any third-party content that may be accessible through this
            Web site.
          </p>

          <p>
            HAAP expressly disclaims all liability for any actions taken or not
            taken based on any or all the contents of any third-party sites.
          </p>
        </div>

        <div className="content-text">
          <h3>Use of Content on the Site</h3>

          <p>
            You may view, download, and print contents from the Site subject to
            the following conditions:
          </p>

          <ul>
            <li>HAAP's name and copyright notice must appear in all copies</li>

            <li>
              the content may be used solely for information purposes; and
            </li>

            <li>the content may not be modified or altered in any way. </li>
          </ul>

          <p>
            You may not republish, distribute, prepare derivative works, or
            otherwise use the content other than as explicitly permitted herein.
            You may not frame or utilize framing techniques to enclose any
            trademark, logo, or other proprietary information (including images,
            text, page layout, or form) of HAAP without the express written
            consent of HAAP legal counsel.
          </p>

          <p>
            You may not use any meta tags or any other "hidden text" utilizing
            HAAP name or trademarks without the express written consent of HAAP
            legal counsel.
          </p>

          <p>
            You may not use any HAAP logo or other proprietary graphic or
            trademark as part of the link without express written permission.
            You do not acquire any ownership rights to any content in the Site.
            Any unauthorized use terminates the permission or license granted by
            HAAP.
          </p>
        </div>

        <div className="content-text">
          <h3>User Conduct and Obligations</h3>

          <p>
            You agree to follow all applicable laws and regulations when using
            this website. Furthermore, you agree that you shall not:
          </p>

          <ul>
            <li>
              Upload, post or otherwise transmit through or to this website any
              content that: - is unlawful, abusive, threatening, harmful,
              obscene, lewd, offensive, defamatory or otherwise objectionable; -
              might infringe the intellectual property rights, privacy rights,
              rights of publicity, or other proprietary rights of others; -
              contains any viruses, trojan horses, time bombs, or any other
              harmful programs or elements;
            </li>

            <li>
              Disrupt, place unreasonable burdens or excessive loads on,
              interfere with or attempt to gain unauthorized access to any
              portion of our website, its computer systems, servers or networks;
            </li>

            <li>
              Provide false information about yourself to us, impersonate any
              other person, or otherwise attempt to mislead others about your
              identity or the origin of any content, message or other
              communication;
            </li>

            <li>
              Transmit junk mail, chain letters, or other unsolicited bulk
              e-mail or duplicative messages;
            </li>

            <li>
              Collect information about other visitors to our website without
              their consent or otherwise systematically extract data or data
              fields, including without limitation any financial data or e-mail
              addresses;
            </li>

            <li>
              Sell access to or the use of this website, including any content
              contained on, downloaded or accessed from this website;
            </li>

            <li>
              Redistribute any content, including financial data, provided by us
              in any manner whatsoever including by means of printed
              publication, fax broadcast, Web pages, e-mail, Web newsgroups or
              forums, or any other electronic or paper-based service or method;
            </li>

            <li>
              Intentionally alter the format in which financial data is provided
              by us or otherwise circumvent our regular interfaces to such data;
              and
            </li>

            <li>
              Embed or import any financial data provided by us into any
              currency information services (whether or not Web-based), data
              files or application software, including without limitation
              accounting and payroll systems.
            </li>
          </ul>

          <p>
            By uploading, posting or otherwise transmitting through or to our
            website any content, you grant to us, our successors and assigns, a
            non-exclusive, world-wide, royalty free, perpetual, non-revocable
            license to use or distribute such content in any manner otherwise
            than as stated in our Privacy Policy.
          </p>
        </div>

        <div className="content-text">
          <h3>Copyright Information</h3>

          <p>
            The Site and the content within the Site are the property of HAAP or
            its suppliers and are protected by United States copyright laws and
            international treaty provisions.
          </p>

          <p>
            The compilation, organization and display of the content as well as
            all software and inventions used on and in connection with this Site
            are the exclusive property of HAAP. HAAP reserves all rights in the
            Site and its content not specifically granted in any agreements with
            HAAP or in the Terms of Use.
          </p>
        </div>

        <div className="content-text">
          <h3>Trademark Information</h3>

          <p>
            HAAP marks, graphics, logos, page headers, button icons, scripts
            noted on this site are HAAP service marks, trademarks and trade
            dress and are the sole and exclusive property of HAAP.
          </p>

          <p>
            All other trademarks not owned by HAAP or its subsidiaries that
            appear on this site are the property of their respective owners, who
            may or may not be affiliated with, connected to, or sponsored by
            HAAP or its subsidiaries.
          </p>
        </div>

        <div className="content-text">
          <h3>Products and Services offered by HAAP on this Site</h3>

          <p>
            HAAP offers products and services on the Site. When you obtain a
            product or service from HAAP on the Site, you accept the specific
            agreement applicable to that product or service.
          </p>

          <p>
            Your use of any such product or service offered on the Site is
            governed by the terms and conditions in the agreement for that
            product or service. Except as provided in that agreement, HAAP does
            not warrant that any product descriptions or content contained in
            this website is accurate, current, reliable, complete, or
            error-free.
          </p>
        </div>

        <div className="content-text">
          <h3>Disclaimer </h3>

          <p>
            HAAP MAKES NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE
            INFORMATION CONTAINED AND RELATED GRAPHICS PUBLISHED ON THIS WEBSITE
            FOR ANY PURPOSE. ALL SUCH INFORMATION AND RELATED GRAPHICS ARE
            PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND. HAAP HEREBY DISCLAIMS
            ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION,
            INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. IN NO EVENT SHALL
            HAAP BE LIABLE FOR ANY SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES OR
            ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
            WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS
            ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE
            OF INFORMATION AVAILABLE FROM THIS WEBSITE.
          </p>
        </div>

        <div className="content-text">
          <h3>Indemnity</h3>

          <p>
            You agree to defend, indemnify, and hold harmless HAAP, employees,
            attorneys, and agents ("Indemnitees") against all claims, expenses,
            liabilities, losses, costs, and damages, including reasonable
            attorney's fees, that the Indemnitees may incur in connection with
            your use of the Site or any hyperlinked Web site.
          </p>
        </div>
      </section>
    </>
  );
};

export default TermsOfUseEn;
