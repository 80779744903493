import { useIntl } from "react-intl";
import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useGlobalState } from "../store/StoreProvider";
import HeadbandText from "@mzp/commons/dist/dataDisplay/HeadbandText";
import BgVideo from "@mzp/commons/dist/layout/BgVideo";
import Cards from '@mzp/commons/dist/surfaces/Cards';
import FormBasic from '@mzp/commons/dist/forms/FormBasic';
import image from "../assets/img/branding/landing/compute.jpg";


const CloudComputing = (props) => {
  const {resourceSite } = props;
  const intl = useIntl();
  const { locale } = useGlobalState();
  const [scrollItems, setScrollItems] = useState(false);
  const changeTransitionItems = () => {
      const elem = document.querySelector("#itemsoffer");
      var windowheight= window.scrollY;
      var itemTop =  (elem != null && elem !== undefined && elem && elem.offsetTop) ? elem.offsetTop : 0;;
      if(windowheight > (itemTop - 650)){
        setScrollItems(true)
      }else{
        setScrollItems(false)
      }
  }

  window.addEventListener('scroll', changeTransitionItems);

  return (
    <div className="compute-section">
       {resourceSite.videBgCouldComputing.clip ? (
              <BgVideo
              clip={resourceSite.videBgCouldComputing.clip}
              poster={resourceSite.videBgCouldComputing.poster}
              title={resourceSite.videBgCouldComputing.title}
              text={resourceSite.videBgCouldComputing.text}
              iconDirection={resourceSite.videBgCouldComputing.iconDirection}
              locale={locale}
            />
          ) : (
          <div className="bg-image bg-protection" style={{ backgroundImage:`url(${image})` }}>
            <h2>{resourceSite.videBgCouldComputing.title}</h2>
            <div className="overlay"></div>
        </div>
      )}
     <section className='why-haap-tech'>
          <HeadbandText 
            title={""}
            text={resourceSite.infoSiteCouldComputing.headBandtext}
            locale={locale}
            showDivision={false}
            showTitle={false}
      />
      </section>
      <section className="what-we-offer">
            <Container>  
                <div className={`what-we-offer-list-card  ${scrollItems ? 'activeAnimation' : ''}`} id="itemsoffer">                   
                    {resourceSite.cardsCouldComputing.items && resourceSite.cardsCouldComputing.items.map((item, index) => {
                        return   <Link to={`/${locale}${item.url}`} reloadDocument className='card-url'><Cards
                            key={index}
                            image={item.img}
                            classNameCard="card-haap"
                        >
                            <div className='card-content-body'>
                                <h3>{item.title}</h3>
                                <p>{item.label}</p>                                  
                                <div className='line-border'></div>  
                            </div>
                        </Cards></Link> 
                       })}                                      
                </div>               
            </Container>        
        </section>
        <Link className="btn-talk" to={`/${locale}${resourceSite.urlFormcouldComputing}`}>{resourceSite.texBtntFormCouldComputing}</Link>
        {resourceSite.formVideoCouldComputing.show && (
          <section className='contact-video'>
           <Container>
            <Row>
                <Col md={6} className="order-md-1 g-0 ">
                    <div className='min-video'>
                    <video autoPlay loop muted poster={resourceSite.videBgCouldComputing}>
                        <source src={resourceSite.formVideoCouldComputing.urlVideo} type='video/mp4' />
                        <source src={resourceSite.formVideoCouldComputing.urlVideo} type="video/ogg" /> 
                    </video>
                    </div>                    
                </Col>
                <Col md={6} className="order-md-0 rectangle">
                    <div className='form-landing'>
                        <h4>{intl.formatMessage({ id: "general.contactUs" })}</h4>
                        <p>{intl.formatMessage({ id: "general.getcontact" })}</p>
                        <FormBasic 
                             row={resourceSite.formVideoCouldComputing.row}
                             placeholderMessage={resourceSite.formVideoCouldComputing.placeholderMessage}
                             typeEmail={resourceSite.formVideoCouldComputing.typeEmail}
                             size={resourceSite.formVideoCouldComputing.size}
                             placeholderEmail={resourceSite.formVideoCouldComputing.placeholderEmail}
                             idEmail={resourceSite.formVideoCouldComputing.idEmail}
                             requiredEmail={resourceSite.formVideoCouldComputing.requiredEmail}
                             classNameInput={resourceSite.formVideoCouldComputing.classNameInput}
                             typeName={resourceSite.formVideoCouldComputing.typeName}
                             placeholderName={resourceSite.formVideoCouldComputing.placeholderName}
                             idName={resourceSite.formVideoCouldComputing.idName}
                             requiredName={resourceSite.formVideoCouldComputing.requiredName}
                             typeLastName={resourceSite.formVideo.typeLastName}
                             placeholderLastName={resourceSite.formVideo.placeholderLastName}
                             idLastName={resourceSite.formVideo.idLastName}
                             requiredLastName={resourceSite.formVideo.requiredLastName}
                             textButton={resourceSite.formVideo.textButton}
                             typeButton={resourceSite.formVideoCouldComputing.typeButton}
                        />
                    </div>
                </Col>                
            </Row>
           </Container>                         
        </section>
        )}
        
    </div>
  );
};

export default CloudComputing;
