import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import img1 from "../../assets/img/branding/cards/compute/CloudComputing.jpg";
import img3 from "../../assets/img/branding/cards/compute/EdgeApplications-1.jpg";
import img2 from "../../assets/img/branding/cards/compute/EdgeApplications.jpg";

const couldComputingData = {
    videBgCouldComputing:{
        clip:"",
        title:"Cloud computing",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteCouldComputing:{
        headBandshowTitle:true,
        headBandTitle:"",
        classNameHead:"",
        headBandtext:"Aumente el rendimiento empresarial y la velocidad de comercialización con servicios de cloud computing fáciles de usar para los desarrolladores que se ejecutan en <span>Akamai Connected Cloud.</span>",        
    },
    cardsCouldComputing:{
        title:"",
        text:"",
        items:[
            {
                img:img1,
                title:"Baja latencia y alta disponibilidad",
                label:"Acerque las aplicaciones y cargas de trabajo a los usuarios con nuestra plataforma en el borde de Internet y en la nube distribuida a gran escala.",
                url:"/#"
                // url:"/detalle/cloud-computing"
            },
            {
                img:img2,
                title:"Productividad e innovación de los desarrolladores",
                label:"Sus desarrolladores podrán utilizar las herramientas y plataformas que prefieran sin necesidad de certificaciones especiales.",
                url:"/#"
                // url:"/detalle/cloud-computing"
            },
            {
                img:img3,
                title:"Rentable y fiable",
                label:"Optimice los costes de la nube sin dependencia, con un perfil de bajo coste por rendimiento y un servicio de atención al cliente ininterrumpido.",
                url:"/#"
                // url:"/detalle/cloud-computing"
            },
           
        ]
    },
    formVideoCouldComputing:{
        row:3,
        placeholderMessage:"Message",
        typeEmail:"email",
        size:"md",
        placeholderEmail:"Email",
        idEmail:"email",
        requiredEmail:true,
        classNameInput:"form-basic",
        typeName:"text",
        placeholderName:"First Name",
        idName:"name",
        requiredName:true,
        placeholderLastName:"Last Name",
        typeLastName:"text",
        idLastName:"lastname",
        requiredLastName:true,
        typeButton:"submit",
        textButton:"Send",

        urlVideo:"",
        show:false,
    },
    urlFormcouldComputing:"/hable-con-nosotros",
    texBtntFormCouldComputing:"Hable con un experto",
}

export default couldComputingData;