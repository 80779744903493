import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import imgProtection from "../../assets/img/branding/cards/Security.webp";
import imgDelivery from "../../assets/img/branding/cards/Distribucion-de-Contenido.webp";
import imgImplementation from "../../assets/img/branding/cards/Implementation.webp";
import imgCompute from "../../assets/img/branding/cards/CloudComputing.webp";
import partners from '../../dataPartners/partners';
import bgGeneral from "../../assets/img/branding/landing/homeGeneral.jpeg";


const homeData = {
    videBg:{
        clip:"",
        title:"",
        showContentText:false,
        poster: bgGeneral,
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSite:{
        headBandshowTitle:false,
        headBandTitle:"",
        classNameHead:"headband-white",
        headBandtext:"¿Qué pasaría en tu empresa si tuvieras la <span>experiencia de usuario</span> y <span>rendimiento</span> de los <span>e-commerce</span> mas importantes del mundo, con la tecnología de seguridad de un banco global, a un precio adecuado a ti?",
        
    },
    cardsHome:{
        title:"Lo que <span>ofrecemos</span>",
        text:"Un conjunto completo de productos y servicios para desarrollar, alojar, entregar y proteger su presencia en línea y sus contenidos multimedia",
        items:[
            {
                img:imgProtection,
                title:"Seguridad​",
                label:"Gane la partida a las amenazas más sofisticadas. Protéjase sin sacrificar el rendimiento.",
                url:"/seguridad"
            },
            {
                img:imgDelivery,
                title:"Distribución de contenido",
                label:"Haga magia digital. Distribuya su contenido dinámico de forma fluida, incluso en los momentos de mayor demanda.​",
                url:"/distribucion-de-contenido"
            },
            // {
            //     img:imgCompute,
            //     title:"Cloud computing",
            //     label:"Aumente el rendimiento y acelere la innovación. Cree, ejecute y proteja aplicaciones y cargas de trabajo en cualquier lugar en el que su empresa se conecte online.",
            //     url:"/cloud-computing"
            // },
            {
                img:imgImplementation,
                title:"Implementación​",
                label:"Deje que HAAP Tech identifique e implemente las mejores soluciones y estrategias de Akamai para sus necesidades.​",
                url:"/implementacion"
            },
            
        ]
    },
    partners:{
        showComponent:false,
        title:"<span>Principales empresas</span> que confían y trabajan con <span>Akamai</span>",
        list:[
            {
                label:"Medios y entretenimiento",
                img:partners.top1,
                imgResponsive:partners.top1Mobile,
                alt:"Medios y entretenimiento",
    
            },
            {
                label:"Servicios financieros",
                img:partners.top2,
                imgResponsive:partners.top2Mobile,
                alt:"Servicios financieros",
          
            },
            {
                label:"Hoteles y viajes",
                img:partners.top3,
                imgResponsive:partners.top3Mobile,
                alt:"Hoteles y viajes",
             
            },
            {
                label:"Salud y ciencias de la vida",
                img:partners.top4,
                imgResponsive:partners.top4Mobile,
                alt:"Salud y ciencias de la vida",
               
            },
            {
                label:"Tecnología",
                img:partners.top5,
                imgResponsive:partners.top5Mobile,
                alt:"Tecnología",
                
            },
            {
                label:"Gobierno",
                img:partners.top6,
                imgResponsive:partners.top6Mobile,
                alt:"Gobierno",
            },
            {
                label:"Automotriz",
                img:partners.top7,
                imgResponsive:partners.top7Mobile,
                alt:"Automotriz",
            },
            {
                label:"Juegos",
                img:partners.top8,
                imgResponsive:partners.top8Mobile,
                alt:"Juegos",
            },
            {
                label:"Publicidad",
                img:partners.top9,
                imgResponsive:partners.top9Mobile,
                alt:"Publicidad",
            },
            {
                label:"Otros",
                img:partners.top10,
                imgResponsive:partners.top10Mobile,
                alt:"Otros",
            }
        ],
    },
    formVideo:{
        row:3,
        placeholderMessage:"Message",
        typeEmail:"email",
        size:"md",
        placeholderEmail:"Email",
        idEmail:"email",
        requiredEmail:true,
        classNameInput:"form-basic",
        typeName:"text",
        placeholderName:"First Name",
        idName:"name",
        requiredName:true,
        placeholderLastName:"Last Name",
        typeLastName:"text",
        idLastName:"lastname",
        requiredLastName:true,
        typeButton:"submit",
        textButton:"Send",

        urlVideo:"",
        show:false,
    }
}

export default homeData;