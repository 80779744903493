import { useGlobalState } from "../../store/StoreProvider";
import { Link } from "react-router-dom";
const CookiePolicyEn = (props) => {
  const { locale } = useGlobalState();
  return (
    <>
      <section className="global-info">
        <div className="content-text">
          <p>Last Updated: February 17, 2023</p>
          <p>
            This Cookie Policy explains how HAAP Tech Inc. and its affiliates
            (“HAAP”, “we”, “us”, or “our”) and our service providers deploy
            cookies on HAAP’s websites, as well as the options you have to
            control them. For more information on how we collect, use, and
            disclose personal information, please see our  <Link to={`/${locale}/privacy-policy`}>Privacy Policy</Link>.
          </p>
        </div>

        <div className="content-text">
          <h3>(1) WHAT ARE COOKIES? </h3>
          <p>
            Cookies are small pieces of data, stored in text files that are
            stored on your computer or other device. Cookies may be set by the
            site that you are visiting, or by third parties, such as those who
            serve content or provide advertising or social media integration
            services on the website (known as “third party cookies”). The
            website or third party that sets the cookie may access the cookie on
            subsequent visits. This enables websites to “remember” you and your
            preferences, either for a single visit (through a “session cookie”)
            or for multiple repeat visits (through a “persistent cookie”).
            Cookies help ensure a consistent and efficient experience for
            visitors, and perform essential functions such as allowing users to
            register and remain logged in.
          </p>
          <p>
            Both websites and emails may contain other tracking technologies
            such as “web beacons” or “pixels.” These are typically small
            transparent images that allow us to gather information for similar
            purposes as cookies. Web beaconsor pixels are often used in
            conjunction with cookies but are not stored on your computer in the
            same way. As a result, if you disable cookies, web beacons may still
            load, but their functionality will be restricted.
          </p>
        </div>

        <div className="content-text">
          <h3>(2) HOW WE USE COOKIES</h3>
          <p>
            We use cookies for a number of different purposes. Some cookies are
            necessary for technical reasons (e.g., website security or
            optimization); some enable a personalized experience; some enable us
            to distinguish unique visitors for purposes of analyzing the
            effectiveness of our content and marketing; and some allow
            advertising networks to deliver advertisements that may be of
            interest to you.
          </p>
        </div>

        <div className="content-text">
          <h3> (3) TYPES OF COOKIES</h3>
          <p>
            The table below explains the types of cookies we use on our websites
            and why we use them. We also provide examples of the cookies set by
            us or our service providers. Please note that this is not an
            exhaustive list, but rather aims to be representative.
          </p>
        </div>
        <div className="content-text">
          <h3> (4) CONTROLLING COOKIES</h3>
          <p>
            We provide our website visitors with a Privacy Preference Center to
            enable or disable certain categories of cookies.  This Section
            describes additional ways that you can control cookies.
          </p>
          <p>
            Visitors may wish to restrict the use of cookies or completely
            prevent them from being set. Most modern browsers allow you to
            change your cookie settings. Certain browsers may allow you to
            disable categories of cookies (e.g., third party cookies) or
            specific cookies by name. You can usually find these settings in the
            options or preferences menu of your browser. To understand these
            settings, the following links for commonly used browsers may be
            helpful, or you can use the help option in your browser for more
            details:
          </p>
          <ul>
            <li><Link to="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd" target="_blank">Microsoft Edge</Link> </li>

            <li><Link to="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10" target="_blank">Internet Explorer</Link> </li>

            <li><Link to="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectslug=Cookies&redirectlocale=en-US" target="_blank">Firefox</Link> </li>

            <li><Link to="https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666" target="_blank">Chrome</Link> </li>

            <li><Link to="https://support.apple.com/es-mx/guide/safari/sfri11471/mac" target="_blank">Safari web</Link> e <Link to="https://support.apple.com/es-mx/HT201265" target="_blank">iOS</Link>. </li>
          </ul>
          <p>
            If you disable cookies, please be aware that some of the features of
            our service may not function correctly.
          </p>
          <p>
            For more details on your choices regarding use of your web browsing
            activity for interest-based advertising you may visit the following
            sites:
          </p>
          <ul>
            <li> <Link to="https://thenai.org/" target="_blank">Network Advertising</Link> </li>

            <li><Link to="https://youradchoices.com/" target="_blank">Your Ad Choices</Link> </li>

            <li><Link to="https://youronlinechoices.eu/" target="_blank">Your Online Choices</Link>  </li>
          </ul>
        </div>

        <div className="content-text">
          <h3> (5) CONTACT US</h3>

          <p>
            If you have any questions about our use of cookies, you can find the
            most relevant contact details in our <Link to={`/${locale}/privacy-policy`}>Privacy Policy</Link>. 
          </p>
        </div>
      </section>
    </>
  );
};

export default CookiePolicyEn;
