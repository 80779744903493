/* import searchParams from './searchParams'; */
import website from './websiteData';
import homeData from './homeData';
import securityData from "./securityData";
import contentDeliveryData from "./contentDeliveryData";
import implementationData from "./implementationData";
import cloudComputingData from "./cloudComputingData";
import talkWithUsData from './talkWithUsData';
import whyAkamaiData from './whyAkamaiData';
import infrastructureSecurityData from './infrastructureSecurityData';
import applicationSecurityData from './applicationSecurityData';
import securityZeroTrustData from './securityZeroTrustData';
import fraudProtectionData from './fraudProtectionData';
import apiApplicationPerformanceData from './apiApplicationPerformanceData';
import multimediaContentDistribution from './multimediaContentDistribution';
import pdfPageData from './pdfPageData';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
   "es": Object.assign({},
      website,
      homeData,
      securityData,
      contentDeliveryData,
      implementationData,
      cloudComputingData,
      talkWithUsData,
      whyAkamaiData,
      infrastructureSecurityData,
      applicationSecurityData,
      securityZeroTrustData,
      fraudProtectionData,
      apiApplicationPerformanceData,
      multimediaContentDistribution,
      pdfPageData
      /* searchParams */
   )
};
