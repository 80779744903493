import { useIntl } from "react-intl";
import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useGlobalState } from "../store/StoreProvider";
import BgVideo from "@mzp/commons/dist/layout/BgVideo";
import HeadbandText from "@mzp/commons/dist/dataDisplay/HeadbandText";
import Cards from '@mzp/commons/dist/surfaces/Cards';
import FormBasic from '@mzp/commons/dist/forms/FormBasic';
import parse from 'html-react-parser';
import Carousel from 'react-bootstrap/Carousel';
import Slider from "react-slick";
import iconUtils from '../utils/iconUtils';
import iconContants from "../constants/iconContants";
import image from "../assets/img/branding/landing/homeGeneral.jpeg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const HomePage = (props) => {
  const {resourceSite } = props;
  const intl = useIntl();
  const { locale } = useGlobalState();


  useEffect(()=>{
    window.scrollTo(0, 0);
  
  }, [])

  const settings = {
    mobileFirst: true,
    responsive: [
        {
            breakpoint: 9999,
            settings: "unslick"
        },
        {
          breakpoint:992,
          settings: {
                    arrows:false,
                    infinite:false,
                    slidesToScroll: 1,
                    dots: false,            
                    speed: 500,
                    centerMode: false,
                    variableWidth:true,                  
                    slidesToShow: 2,
                    swipe:true,
                    swipeToSlide: true,
          }
        },
        {
            breakpoint: 767,
             settings: {
                    arrows:false,
                    infinite:false,
                    slidesToScroll: 1,
                    dots: false,            
                    speed: 500,
                    centerMode: false,
                    variableWidth:true,                  
                    slidesToShow: 1,
                    swipe:true,
                    swipeToSlide: true,
                }
        }
    ]
  };

  const [scrollItems, setScrollItems] = useState(false);

  const changeTransitionItems = () => {
      const elem = document.querySelector("#itemsoffer");
      var windowheight= window.scrollY;
      var itemTop =  (elem != null && elem !== undefined && elem && elem.offsetTop) ? elem.offsetTop : 0;;
      var itempoint = 150;

      if(windowheight > (itemTop - 740)){
        setScrollItems(true)
      }else{
        setScrollItems(false)
      }
  }

  window.addEventListener('scroll', changeTransitionItems);


  return (
    <div className="home-section">
      {/*    <video autoPlay loop muted key={resourceSite.videBg.clip}>
            <source src={resourceSite.videBg.clip} type='video/mp4' />
            <source src={resourceSite.videBg.clip} type="video/ogg" /> 
        </video>  */}   
      {resourceSite.videBg.clip ? ( <BgVideo 
        clip={resourceSite.videBg.clip}
        poster={resourceSite.videBg.poster}
        title={resourceSite.videBg.title}
        text={resourceSite.videBg.text}
        iconDirection={resourceSite.videBg.iconDirection}
        locale={locale}
      />
    ) : (
       <div
            className="bg-image bg-protection"
            style={{ backgroundImage: `url(${image})` }}
          >
            <h2>{resourceSite.videBg.title}</h2>
            <div className="overlay"></div>
          </div>
         )}
      <section className='why-haap-tech'>
          <HeadbandText 
            text={resourceSite.infoSite.headBandtext}
            title={resourceSite.infoSite.headBandTitle}
            showTitle={resourceSite.infoSite.headBandshowTitle}
            classNameHead={resourceSite.infoSite.classNameHead}
            locale={locale}
      />
      </section>
      <section className="what-we-offer">
            <Container>
                <div className='what-we-offer-content-text'>
                    <h2>{parse(resourceSite.cardsHome.title)}</h2>   
                    <p className='what-we-offer-text'>{resourceSite.cardsHome.text}</p>
                </div>     
                <div className={`what-we-offer-list-card  ${scrollItems ? 'activeAnimation' : ''}`} id="itemsoffer">
                    <Slider {...settings}> 
                    {resourceSite.cardsHome.items && resourceSite.cardsHome.items.map((item, index) => {
                        return <Cards
                            key={index}
                            image={item.img}
                            alt={item.title}
                            classNameCard="card-haap"
                        >
                            <div className='card-content-body'>
                                <h3>{item.title}</h3>
                                <p>{item.label}</p>  
                                <div className='line-border'></div>  
                            </div>
                            <Link to={`/${locale}${item.url === "/" ? "" : item.url}`} reloadDocument className='card-url'></Link>    
                        </Cards>
                       })}
                    </Slider>                     
                </div>               
            </Container>        
        </section>
        {resourceSite.partners.showComponent && (<section className='partners'>
            <Container fluid>
                <h2>{parse(resourceSite.partners.title)} </h2>
                <Carousel
                     nextIcon={<span className='next-icon'>{iconUtils.icon(iconContants.SLARROWRIGHT)}</span>}
                     prevIcon={<span className='prev-icon'>{iconUtils.icon(iconContants.SLARROWLEFT)}</span>}
                     indicators={false}
                >
                  {resourceSite.partners.list && resourceSite.partners.list.map((item, index) => {
                    return <Carousel.Item interval={7000} key={index}>
                        <Container>
                            <div className='partners-slide'>                    
                                <div className='partners-title'>
                                    <h4>{item.label}</h4>
                                    <div className='divisor-part'></div>
                                </div>
                                
                                <div className='partners-logos'>
                                    <img src={item.img} alt={item.alt} className="d-none d-lg-block"/>
                                 
                                    <img src={item.imgResponsive} alt={item.alt} className="d-lg-none"/>
                                   
                                    
                                </div>   
                            </div>
                        </Container>
                    </Carousel.Item>
                    })}
                                              
                </Carousel>
            </Container>                            
        </section>)}
    
        {resourceSite.formVideo.show && (
            <section className='contact-video'>
            <Container>
            <Row>
                <Col md={6} className="order-md-1 g-0 ">
                    <div className='min-video'>
                    <video autoPlay loop muted poster={resourceSite.videBg}>
                        <source src={resourceSite.formVideo.urlVideo} type='video/mp4' />
                        <source src={resourceSite.formVideo.urlVideo} type="video/ogg" /> 
                    </video>
                    </div>                    
                </Col>
                <Col md={6} className="order-md-0 rectangle">
                    <div className='form-landing'>
                        <h4>{intl.formatMessage({ id: "general.contactUs" })}</h4>
                        <p>{intl.formatMessage({ id: "general.getcontact" })}</p>
                        <FormBasic 
                                row={resourceSite.formVideo.row}
                                placeholderMessage={resourceSite.formVideo.placeholderMessage}
                                typeEmail={resourceSite.formVideo.typeEmail}
                                size={resourceSite.formVideo.size}
                                placeholderEmail={resourceSite.formVideo.placeholderEmail}
                                idEmail={resourceSite.formVideo.idEmail}
                                requiredEmail={resourceSite.formVideo.requiredEmail}
                                classNameInput={resourceSite.formVideo.classNameInput}
                                typeName={resourceSite.formVideo.typeName}
                                placeholderName={resourceSite.formVideo.placeholderName}
                                idName={resourceSite.formVideo.idName}
                                requiredName={resourceSite.formVideo.requiredName}
                                typeButton={resourceSite.formVideo.typeButton}

                                typeLastName={resourceSite.formVideo.typeLastName}
                                placeholderLastName={resourceSite.formVideo.placeholderLastName}
                                idLastName={resourceSite.formVideo.idLastName}
                                requiredLastName={resourceSite.formVideo.requiredLastName}
                                textButton={resourceSite.formVideo.textButton}
                        />
                    </div>
                </Col>
                
            </Row>
            </Container>                         
            </section>
        )}
      
    </div>
  );
};

export default HomePage;