import general from "./general";
import website from "./website";


export default {
    "pt": Object.assign({},
       website,
       general,
    
    )
 };