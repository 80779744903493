import { useIntl } from "react-intl";
import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useGlobalState } from "../store/StoreProvider";
import BgVideo from "@mzp/commons/dist/layout/BgVideo";
import HeadbandText from "@mzp/commons/dist/dataDisplay/HeadbandText";
import HeadbandTextChildren from "@mzp/commons/dist/dataDisplay/HeadbandTextChildren";
import Cards from '@mzp/commons/dist/surfaces/Cards';
import image from "../assets/img/branding/landing/delivery.jpg";
import AutoLayoutColumns from "@mzp/commons/dist/layout/AutoLayoutColumns"
import Slider from "react-slick";
import parse from 'html-react-parser';
import breakpointConstants from "../constants/breakpointConstants";
import { useMediaQuery } from "react-responsive";

const FraudProtection = (props) => {
  const { resourceSite } = props;
  const intl = useIntl();
  const { locale } = useGlobalState();
  const [scrollItems, setScrollItems] = useState(false);
  const isTablet = useMediaQuery({ maxWidth: breakpointConstants.XL });
  const isMobile = useMediaQuery({ maxWidth: breakpointConstants.LG });

  const [col, colSet] = useState(
    {
      sm:5,
      md:3
    }
  );


  const settings = {
    mobileFirst: true,
    responsive: [
        {
            breakpoint: 9999,
            settings: "unslick"
        },
        {
          breakpoint:992,
          settings: {
                    arrows:false,
                    infinite:false,
                    slidesToScroll: 1,
                    dots: false,            
                    speed: 500,
                    centerMode: false,
                    variableWidth:true,                  
                    slidesToShow: 2,
                    swipe:true,
                    swipeToSlide: true,
          }
        },
        {
            breakpoint: 767,
             settings: {
                    arrows:false,
                    infinite:false,
                    slidesToScroll: 1,
                    dots: false,            
                    speed: 500,
                    centerMode: false,
                    variableWidth:true,                  
                    slidesToShow: 1,
                    swipe:true,
                    swipeToSlide: true,
                }
        }
    ]
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeTransitionItems = () => {
    const elem = document.querySelector("#itemsoffer");
    var windowheight = window.scrollY;
    var itemTop = (elem != null && elem !== undefined && elem && elem.offsetTop) ? elem.offsetTop : 0;;
    if (windowheight > (itemTop - 650)) {
      setScrollItems(true)
    } else {
      setScrollItems(false)
    }
  }

  window.addEventListener('scroll', changeTransitionItems);

  const CardsTitle = () => {
   return <Container>   
   <div className={`what-we-offer-list-card  ${scrollItems ? 'activeAnimation' : ''}`} id="itemsoffer">
       <Slider {...settings}> 
       {resourceSite.cardsHome.items && resourceSite.cardsHome.items.map((item, index) => {
           return <Cards
               key={index}
               image={item.img}
               alt={item.title}
               classNameCard="card-haap"
           >
               <div className='card-content-body'>
                   <h3>{item.title}</h3>
                   <p>{item.label}</p>  
                   <div className='line-border'></div>  
               </div>
               <Link to={`/${locale}${item.url === "/" ? "" : item.url}`} reloadDocument className='card-url'></Link>    
           </Cards>
          })}
       </Slider>                     
   </div>               
</Container>
  }


  return (
    <div className="fraud-protection-section">
      {resourceSite.videBgFraudProtection.clip ? (
        <BgVideo
          clip={resourceSite.videBgFraudProtection.clip}
          poster={resourceSite.videBgFraudProtection.poster}
          title={resourceSite.videBgFraudProtection.title}
          text={resourceSite.videBgFraudProtection.text}
          iconDirection={resourceSite.videBgFraudProtection.iconDirection}
          locale={locale}
        />
      ) : (
        <div
          className="bg-image bg-protection"
          style={{ backgroundImage: `url(${image})` }}
        >
          <h2>{resourceSite.videBgFraudProtection.title}</h2>
          <div className="overlay"></div>
        </div>
      )}
            <section className="what-we-offer-color">
            <Container>
 
          <HeadbandText 
            title={""}
            text={resourceSite.infoSiteFraudProtection.headBandTitle}
            locale={locale}
            showTitle={false}
            showText={true}
            classNameHead={""}
            showDivision={false}
            classNameContainer={"text-center"}
            fontSizeTitle={""}
            fontSizeText={""}
            colsm={0}
            colmd={0} 
      />
            {/* <Row >
 
              <Col
             
                md={!isTablet ? 3 : 12}
                className="what-we-offer-content-text"
              >
            <h3 className="title-band" >{parse(resourceSite.infoSiteFraudProtection.headBandTitle)}</h3>
                
              </Col>
      

            
              <Col className={``}>
              <div className='what-we-offer-content-text'>
                 
                 <p className='what-we-offer-text'>  {parse(resourceSite.infoSiteFraudProtection.headBandtext)}</p>
             </div>  
             <div className={`what-we-offer-list-card  ${scrollItems ? 'activeAnimation' : ''}`} id="itemsoffer">
              <div className="card-section">

           
                    <Slider {...settings}> 
                    {resourceSite.infoSiteFraudProtection.items && resourceSite.infoSiteFraudProtection.items.map((item, index) => {
                        return <Cards
                            key={index}
                            image={item.img}
                            showImage={false}
                            alt={item.title}
                            classNameCard="card-haap">
                            <div className='card-content-body card-content-body-color'>
                                <h3>{item.title}</h3>
                                <p>{item.label}</p>  
                                <div className='line-border'></div>  
                            </div>
                            <Link to={`/${locale}${item.url === "/" ? "" : item.url}`} reloadDocument className='card-url'></Link>    
                        </Cards>
                       })}
                    </Slider>    
                    </div>                 
                </div>     
              </Col>
         
          </Row> */}
              <div>

       
                {/* <div className='what-we-offer-content-text'>
                 
                    <p className='what-we-offer-text'>  {parse(resourceSite.infoSiteFraudProtection.headBandtext)}</p>
                </div>     
                <div className={`what-we-offer-list-card  ${scrollItems ? 'activeAnimation' : ''}`} id="itemsoffer">
                    <Slider {...settings}> 
                    {resourceSite.infoSiteFraudProtection.items && resourceSite.infoSiteFraudProtection.items.map((item, index) => {
                        return <Cards
                            key={index}
                            image={item.img}
                            showImage={false}
                            alt={item.title}
                            classNameCard="card-haap">
                            <div className='card-content-body'>
                                <h3>{item.title}</h3>
                                <p>{item.label}</p>  
                                <div className='line-border'></div>  
                            </div>
                            <Link to={`/${locale}${item.url === "/" ? "" : item.url}`} reloadDocument className='card-url'></Link>    
                        </Cards>
                       })}
                    </Slider>                     
                </div>    */}
                </div>            
            </Container>        
        </section>

      <section className="what-we-offer">
        <Container>
          <HeadbandText
            locale={locale}
            title={
              resourceSite.infoSiteFraudProtection.headBandtext
            }
            text={""}
            showTitle={true}
            showText={false}
            classNameHead={"headband-white"}
            showDivision={false}
            classNameContainer={"text-center"}
            fontSizeTitle={"16px"}
            fontSizeText={""}
            colsm={0}
            colmd={0}
          />
          <div className={`what-we-offer-list-card  ${scrollItems ? 'activeAnimation' : ''}`} id="itemsoffer">
            <AutoLayoutColumns
              containerLayout={
                resourceSite.cardsFraudProtection.items.length === 4
                  ? "container-layout-four-column"
                  : "container-layout-column"
              }
            >
              {resourceSite.cardsFraudProtection.items && resourceSite.cardsFraudProtection.items.map((item, index) => {
                return <Cards
                  key={index}
                  showImage={false}
                  image={item.img}
                  classNameCard="card-haap"
                >
                  <div className='card-content-body'>
                    <h3>{item.title}</h3>
                    <p>{item.label}</p>
                    <div className='line-border'></div>
                  </div>
                  <Link to={`/${locale}${item.url}`} reloadDocument className='card-url'>{item.textUrl} </Link>
                </Cards>
              })}
            </AutoLayoutColumns>


          </div>
        </Container>
      </section>
      <Link className="btn-talk" to={`/${locale}${resourceSite.urlFormFraudProtection}`}>{resourceSite.texBtntFormFraudProtection}</Link>


    </div>
  );
};

export default FraudProtection;
