import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import aplisecu from "../../assets/img/branding/cards/protection/ApplicationSecurity.jpg";
import infrasecu from "../../assets/img/branding/cards/protection/InfrastructureSecurity.jpg";
import zerosecu from "../../assets/img/branding/cards/protection/ZerotrustSecurity.jpg";
import fraud from "../../assets/img/branding/cards/protection/Proteccion-contra-abuso-2.jpg";


const protectionData = {
  videBgSecurity: {
    clip: "",
    title: "SEGURIDAD",
    text: "",
    showContentText: true,
    iconDirection: iconUtils.icon(iconContants.IOIOSARROWUP),
  },
  infoSiteSecurity: {
    headBandshowTitle: true,
    headBandTitle: "",
    classNameHead: "",
    headBandtext:
      "<span>Manténgase a salvo</span> de las amenazas sin que el rendimiento se vea afectado. <span>Akamai</span> abarca y protege todo su ecosistema: nubes, aplicaciones, API y usuarios.",
  },
  cardsSecurity: {
    title: "",
    text: "",
    items: [
      {
        show: true,
        img: infrasecu,
        title: "Seguridad de infraestructura",
        label:
          "Aumente la resiliencia contra las amenazas más complejas con la defensa multicapa en todo su ecosistema.",
        url: "/infrastructure-security",
        list: [
          {
            label: "Ataques DDoS",
            check: true,
          },
          {
            label: "Agotamiento de recursos",
            check: true,
          },
          {
            label: "Derribo del sitio",
            check: true,
          },
          {
            label: "Ataques DNS",
            check: true,
          },
        ],
      },
      {
        show: true,
        img: aplisecu,
        title: "Seguridad de aplicaciones",
        label:
          "Evite una amplia variedad de ataques multivectoriales. Protección inteligente integral. Detenga los ataques de relleno de credenciales, los bots y el fraude web con la detección y la mitigación más inteligentes.",
        url: "/application-security",
        list: [
          {
            label: "Extracción de información del sitio",
            check: true,
          },
          {
            label: "Corrupción de contenidos?",
            check: true,
          },
          {
            label: "Ataques de bots?",
            check: true,
          },
          {
            label: "Robo de cuentas?",
            check: true,
          },
          {
            label: "Robo de datos de usuario?",
            check: true,
          },
          {
            label: "ecuestro de audiencia?",
            check: true,
          },
        ],
      },
      {
        show: true,
        img: zerosecu,
        title: "Seguridad Zero-trust",
        label:
          "Controle el acceso a aplicaciones corporativas y a Internet desde cualquier lugar sin obstaculizar a sus empleados. La micro-segmentación basado en software simplifica el proceso de reducción de riesgos sin necesidad de un hardware de seguridad costoso",
        url: "/security-zero-trust",
        list: [
          {
            label: " propagación de código malicioso?",
            check: true,
          },
          {
            label: "secuestro de datos (ransomware)",
            check: true,
          },
          {
            label: "suplantación de identidad (phishing) ",
            check: true,
          },
          {
            label: "Robo de datos de la empresa",
            check: true,
          },
        ],
      },
      {
        show: true,
        img: fraud,
        title: "Soluciones de protección contra fraude y abuso",
        label:
          "Mantenga la reputación de su marca y detecte comportamientos sospechosos humanos y de bots en tiempo real.",
        url: "/abuse-and-fraud-protection",
        list: [
          {
            label: "Robo de cuentas",
            check: true,
          },

          {
            label: "Fraude de afiliados",
            check: true,
          },
          {
            label: "Estafadores",
            check: true,
          },
          {
            label: "Suplantaciones de marca antes de que empiecen",
            check: true,
          },
        ],
      },
    ],
  },
  formVideoSecurity: {
    row: 3,
    placeholderMessage: "Message",
    typeEmail: "email",
    size: "md",
    placeholderEmail: "Email",
    idEmail: "email",
    requiredEmail: true,
    classNameInput: "form-basic",
    typeName: "text",
    placeholderName: "First Name",
    idName: "name",
    requiredName: true,
    placeholderLastName: "Last Name",
    typeLastName: "text",
    idLastName: "lastname",
    requiredLastName: true,
    typeButton: "submit",
    textButton: "Send",

    urlVideo:
      "",
    show: false,
  },
  urlFormSecurity: "/hable-con-nosotros",
  texBtntFormSecurity: "Hable con un experto",
};

export default protectionData;