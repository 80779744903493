const talkWithUsData = {
    formContact: {
        name: {
          placeholder:"First Name*",      
          id: "firstName",
          name:"name",
          type: "text",
          required:true, 
        },
        lastName: {
          placeholder: "Last Name*",
          id: "lastName",
          name:"lastName",
          type: "text",
          required:true, 
        },
        email: {
          placeholder: "Email Address*",
          id: "email",
          name:"email",
          type: "email",
          required:true,
        },
        company: {
          placeholder: "Company*",
          id: "company",
          name:"company",
          type: "text",
          required:true, 
        },
        department: {
          id: "department",
          name:"department",
          required:true, 
          items: [
            {
                name:"Select Department*",
                value:"",
            },
            {
              name: "IT: Applications/App Owners",
              value: "IT: Applications/App Owners",
            },
            {
              name: "IT: Architect",
              value: "IT: Architect",
            },
            {
              name: "IT: Data Center",
              value: "IT: Data Center",
            },
            {
              name: "IT: Developer",
              value: "IT: Developer",
            },
            {
              name: "IT: Engineering",
              value: "IT: Engineering",
            },
            {
              name: "IT: General",
              value: "IT: General",
            },
            {
              name: "IT: Network/Architecture/Infrastructure",
              value: "IT: Network/Architecture/Infrastructure",
            },
            {
              name: "IT: Operations",
              value: "IT: Operations",
            },
            {
              name: "IT: Program/Project Manager",
              value: "IT: Program/Project Manager",
            },
            {
              name: "IT: Security",
              value: "IT: Security",
            },
            {
              name: "Analyst",
              value: "Analyst",
            },
            {
              name: "Content Production",
              value: "Content Production",
            },
            {
              name: "Consultant",
              value: "Consultant",
            },
            {
              name: "Digital/Internet Entertainment",
              value: "Digital/Internet Entertainment",
            },
            {
              name: "Engineering",
              value: "Engineering",
            },
            {
              name: "Finance",
              value: "Finance",
            },
            {
              name: "HR",
              value: "HR",
            },
            {
              name: "Legal",
              value: "Legal",
            },
            {
              name: "Marketing",
              value: "Marketing",
            },
            {
              name: "Media",
              value: "Media",
            },
    
            {
              name: "Operations",
              value: "Operations",
            },
            {
              name: "Product Management",
              value: "Product Management",
            },
            {
              name: "Production",
              value: "Production",
            },
    
            {
              name: "Sales",
              value: "Sales",
            },
            {
              name: "Student",
              value: "Student",
            },
            {
              name: "Web",
              value: "Web",
            },
          ],
        },
        title:{
            id:"title",
            name:"title",
            required:true, 
            items:[
                {
                    name:"Select Title*",
                    value:"",
                },           
                {
                    name:"Staff",
                    value:"Staff"
                },
                {
                    name:"Consultant",
                    value:"Consultant"
                },
                {
                    name:"Manager",
                    value:"Manager"
                },
                {
                    name:"Director",
                    value:"Director"
                },
                {
                    name:"VP/Executive",
                    value:"VP/Executive"
                },
                {
                    name:"C-Level",
                    value:"C-Level"
                }
            ],
        },
        country:{
            id:"country",
            name:"country",
            required:true,
            items:[
                {
                    value:"",
                    name:"Select Country"
                },
                {
                  value: "United States of America",
                  name: "United States",
                },
                {
                  value: "Canada",
                  name: "Canada",
                },
                {
                  value: "United Kingdom",
                  name: "United Kingdom",
                },
                {
                  value: "Afghanistan",
                  name: "Afghanistan",
                },
                {
                  value: "Aland Islands",
                  name: "Aland Islands",
                },
                {
                  value: "Albania",
                  name: "Albania",
                },
                {
                  value: "Algeria",
                  name: "Algeria",
                },
                {
                  value: "American Samoa",
                  name: "American Samoa",
                },
                {
                  value: "Andorra",
                  name: "Andorra",
                },
                {
                  value: "Angola",
                  name: "Angola",
                },
                {
                  value: "Anguilla",
                  name: "Anguilla",
                },
                {
                  value: "Antigua and Barbuda",
                  name: "Antigua and Barbuda",
                },
                {
                  value: "Argentina",
                  name: "Argentina",
                },
                {
                  value: "Armenia",
                  name: "Armenia",
                },
                {
                  value: "Aruba",
                  name: "Aruba",
                },
                {
                  value: "Australia",
                  name: "Australia",
                },
                {
                  value: "Austria",
                  name: "Austria",
                },
                {
                  value: "Azerbaijan",
                  name: "Azerbaijan",
                },
                {
                  value: "Bahamas",
                  name: "Bahamas",
                },
                {
                  value: "Bahrain",
                  name: "Bahrain",
                },
                {
                  value: "Bangladesh",
                  name: "Bangladesh",
                },
                {
                  value: "Barbados",
                  name: "Barbados",
                },
                {
                  value: "Belarus",
                  name: "Belarus",
                },
                {
                  value: "Belgium",
                  name: "Belgium",
                },
                {
                  value: "Belize",
                  name: "Belize",
                },
                {
                  value: "Benin",
                  name: "Benin",
                },
                {
                  value: "Bermuda",
                  name: "Bermuda",
                },
                {
                  value: "Bhutan",
                  name: "Bhutan",
                },
                {
                  value: "Bolivia",
                  name: "Bolivia",
                },
                {
                  value: "Bosnia and Herzegovina",
                  name: "Bosnia and Herzegovina",
                },
                {
                  value: "Botswana",
                  name: "Botswana",
                },
                {
                  value: "Brazil",
                  name: "Brazil",
                },
                {
                  value: "British Indian Ocean Territory",
                  name: "British Indian Ocean Territory",
                },
                {
                  value: "British Virgin Islands",
                  name: "British Virgin Islands",
                },
                {
                  value: "Brunei Darussalam",
                  name: "Brunei Darussalam",
                },
                {
                  value: "Bulgaria",
                  name: "Bulgaria",
                },
                {
                  value: "Burkina Faso",
                  name: "Burkina Faso",
                },
                {
                  value: "Burundi",
                  name: "Burundi",
                },
                {
                  value: "Cambodia",
                  name: "Cambodia",
                },
                {
                  value: "Cameroon",
                  name: "Cameroon",
                },
                {
                  value: "Cape Verde",
                  name: "Cape Verde",
                },
                {
                  value: "Cayman Islands",
                  name: "Cayman Islands",
                },
                {
                  value: "Central African Republic",
                  name: "Central African Republic",
                },
                {
                  value: "Chad",
                  name: "Chad",
                },
                {
                  value: "Chile",
                  name: "Chile",
                },
                {
                  value: "China",
                  name: "China",
                },
                {
                  value: "Christmas Island",
                  name: "Christmas Island",
                },
                {
                  value: "Cocos (Keeling) Islands",
                  name: "Cocos (Keeling) Islands",
                },
                {
                  value: "Colombia",
                  name: "Colombia",
                },
                {
                  value: "Comoros",
                  name: "Comoros",
                },
                {
                  value: "Congo",
                  name: "Congo",
                },
                {
                  value: "Congo (the Democratic Republic of the)",
                  name: "Congo (the Democratic Republic of the)",
                },
                {
                  value: "Cook Islands",
                  name: "Cook Islands",
                },
                {
                  value: "Costa Rica",
                  name: "Costa Rica",
                },
                {
                  value: "Croatia",
                  name: "Croatia",
                },
                {
                  value: "Cuba",
                  name: "Cuba",
                },
                {
                  value: "Curacao",
                  name: "Curaçao",
                },
                {
                  value: "Cyprus",
                  name: "Cyprus",
                },
                {
                  value: "Czech Republic",
                  name: "Czech Republic",
                },
                {
                  value: "Denmark",
                  name: "Denmark",
                },
                {
                  value: "Djibouti",
                  name: "Djibouti",
                },
                {
                  value: "Dominica",
                  name: "Dominica",
                },
                {
                  value: "Dominican Republic",
                  name: "Dominican Republic",
                },
                {
                  value: "Ecuador",
                  name: "Ecuador",
                },
                {
                  value: "Egypt",
                  name: "Egypt",
                },
                {
                  value: "El Salvador",
                  name: "El Salvador",
                },
                {
                  value: "Equatorial Guinea",
                  name: "Equatorial Guinea",
                },
                {
                  value: "Eritrea",
                  name: "Eritrea",
                },
                {
                  value: "Estonia",
                  name: "Estonia",
                },
                {
                  value: "Eswatini",
                  name: "Eswatini",
                },
                {
                  value: "Ethiopia",
                  name: "Ethiopia",
                },
                {
                  value: "Falkland Islands (Malvinas)",
                  name: "Falkland Islands (Malvinas)",
                },
                {
                  value: "Faroe Islands",
                  name: "Faroe Islands",
                },
                {
                  value: "Fiji",
                  name: "Fiji",
                },
                {
                  value: "Finland",
                  name: "Finland",
                },
                {
                  value: "France",
                  name: "France",
                },
                {
                  value: "French Guiana",
                  name: "French Guiana",
                },
                {
                  value: "French Polynesia",
                  name: "French Polynesia",
                },
                {
                  value: "Gabon",
                  name: "Gabon",
                },
                {
                  value: "Gambia",
                  name: "Gambia",
                },
                {
                  value: "Georgia",
                  name: "Georgia",
                },
                {
                  value: "Germany",
                  name: "Germany",
                },
                {
                  value: "Ghana",
                  name: "Ghana",
                },
                {
                  value: "Gibraltar",
                  name: "Gibraltar",
                },
                {
                  value: "Greece",
                  name: "Greece",
                },
                {
                  value: "Greenland",
                  name: "Greenland",
                },
                {
                  value: "Grenada",
                  name: "Grenada",
                },
                {
                  value: "Guadeloupe",
                  name: "Guadeloupe",
                },
                {
                  value: "Guam",
                  name: "Guam",
                },
                {
                  value: "Guatemala",
                  name: "Guatemala",
                },
                {
                  value: "Guernsey",
                  name: "Guernsey",
                },
                {
                  value: "Guinea",
                  name: "Guinea",
                },
                {
                  value: "Guinea-bissau",
                  name: "Guinea-Bissau",
                },
                {
                  value: "Guyana",
                  name: "Guyana",
                },
                {
                  value: "Haiti",
                  name: "Haiti",
                },
                {
                  value: "Honduras",
                  name: "Honduras",
                },
                {
                  value: "Hong Kong",
                  name: "Hong Kong",
                },
                {
                  value: "Hungary",
                  name: "Hungary",
                },
                {
                  value: "Iceland",
                  name: "Iceland",
                },
                {
                  value: "India",
                  name: "India",
                },
                {
                  value: "Indonesia",
                  name: "Indonesia",
                },
                {
                  value: "Iran",
                  name: "Iran",
                },
                {
                  value: "Iraq",
                  name: "Iraq",
                },
                {
                  value: "Ireland",
                  name: "Ireland",
                },
                {
                  value: "Isle of Man",
                  name: "Isle of Man",
                },
                {
                  value: "Israel",
                  name: "Israel",
                },
                {
                  value: "Italy",
                  name: "Italy",
                },
                {
                  value: "Ivory Coast",
                  name: "Ivory Coast",
                },
                {
                  value: "Jamaica",
                  name: "Jamaica",
                },
                {
                  value: "Japan",
                  name: "Japan",
                },
                {
                  value: "Jersey",
                  name: "Jersey",
                },
                {
                  value: "Jordan",
                  name: "Jordan",
                },
                {
                  value: "Kazakhstan",
                  name: "Kazakhstan",
                },
                {
                  value: "Kenya",
                  name: "Kenya",
                },
                {
                  value: "Kiribati",
                  name: "Kiribati",
                },
                {
                  value: "Kosovo",
                  name: "Kosovo",
                },
                {
                  value: "Kuwait",
                  name: "Kuwait",
                },
                {
                  value: "Kyrgyzstan",
                  name: "Kyrgyzstan",
                },
                {
                  value: "Lao People's Democratic Republic",
                  name: "Lao People's Democratic Republic",
                },
                {
                  value: "Latvia",
                  name: "Latvia",
                },
                {
                  value: "Lebanon",
                  name: "Lebanon",
                },
                {
                  value: "Lesotho",
                  name: "Lesotho",
                },
                {
                  value: "Liberia",
                  name: "Liberia",
                },
                {
                  value: "Libya",
                  name: "Libya",
                },
                {
                  value: "Liechtenstein",
                  name: "Liechtenstein",
                },
                {
                  value: "Lithuania",
                  name: "Lithuania",
                },
                {
                  value: "Luxembourg",
                  name: "Luxembourg",
                },
                {
                  value: "Macau",
                  name: "Macau",
                },
                {
                  value: "Madagascar",
                  name: "Madagascar",
                },
                {
                  value: "Malawi",
                  name: "Malawi",
                },
                {
                  value: "Malaysia",
                  name: "Malaysia",
                },
                {
                  value: "Maldives",
                  name: "Maldives",
                },
                {
                  value: "Mali",
                  name: "Mali",
                },
                {
                  value: "Malta",
                  name: "Malta",
                },
                {
                  value: "Marshall Islands",
                  name: "Marshall Islands",
                },
                {
                  value: "Martinique",
                  name: "Martinique",
                },
                {
                  value: "Mauritania",
                  name: "Mauritania",
                },
                {
                  value: "Mauritius",
                  name: "Mauritius",
                },
                {
                  value: "Mayotte",
                  name: "Mayotte",
                },
                {
                  value: "Mexico",
                  name: "Mexico",
                },
                {
                  value: "Micronesia",
                  name: "Micronesia",
                },
                {
                  value: "Moldova",
                  name: "Moldova",
                },
                {
                  value: "Monaco",
                  name: "Monaco",
                },
                {
                  value: "Mongolia",
                  name: "Mongolia",
                },
                {
                  value: "Montenegro",
                  name: "Montenegro",
                },
                {
                  value: "Montserrat",
                  name: "Montserrat",
                },
                {
                  value: "Morocco",
                  name: "Morocco",
                },
                {
                  value: "Mozambique",
                  name: "Mozambique",
                },
                {
                  value: "Myanmar",
                  name: "Myanmar",
                },
                {
                  value: "Namibia",
                  name: "Namibia",
                },
                {
                  value: "Nauru",
                  name: "Nauru",
                },
                {
                  value: "Nepal",
                  name: "Nepal",
                },
                {
                  value: "Netherlands",
                  name: "Netherlands",
                },
                {
                  value: "New Caledonia",
                  name: "New Caledonia",
                },
                {
                  value: "New Zealand",
                  name: "New Zealand",
                },
                {
                  value: "Nicaragua",
                  name: "Nicaragua",
                },
                {
                  value: "Niger",
                  name: "Niger",
                },
                {
                  value: "Nigeria",
                  name: "Nigeria",
                },
                {
                  value: "Niue",
                  name: "Niue",
                },
                {
                  value: "Norfolk Island",
                  name: "Norfolk Island",
                },
                {
                  value: "North Korea",
                  name: "North Korea",
                },
                {
                  value: "Northern Mariana Islands",
                  name: "Northern Mariana Islands",
                },
                {
                  value: "Norway",
                  name: "Norway",
                },
                {
                  value: "Oman",
                  name: "Oman",
                },
                {
                  value: "Pakistan",
                  name: "Pakistan",
                },
                {
                  value: "Palau",
                  name: "Palau",
                },
                {
                  value: "Palestine",
                  name: "Palestine",
                },
                {
                  value: "Panama",
                  name: "Panama",
                },
                {
                  value: "Papua New Guinea",
                  name: "Papua New Guinea",
                },
                {
                  value: "Paraguay",
                  name: "Paraguay",
                },
                {
                  value: "Peru",
                  name: "Peru",
                },
                {
                  value: "Philippines",
                  name: "Philippines",
                },
                {
                  value: "Pitcairn",
                  name: "Pitcairn",
                },
                {
                  value: "Poland",
                  name: "Poland",
                },
                {
                  value: "Portugal",
                  name: "Portugal",
                },
                {
                  value: "Puerto Rico",
                  name: "Puerto Rico",
                },
                {
                  value: "Qatar",
                  name: "Qatar",
                },
                {
                  value: "Republic of North Macedonia",
                  name: "Republic of North Macedonia",
                },
                {
                  value: "Reunion",
                  name: "Reunion",
                },
                {
                  value: "Romania",
                  name: "Romania",
                },
                {
                  value: "Russian Federation",
                  name: "Russian Federation",
                },
                {
                  value: "Rwanda",
                  name: "Rwanda",
                },
                {
                  value: "Saint Barthelemy",
                  name: "Saint Barthelemy",
                },
                {
                  value: "Saint Helena",
                  name: "Saint Helena",
                },
                {
                  value: "Saint Kitts and Nevis",
                  name: "Saint Kitts and Nevis",
                },
                {
                  value: "Saint Lucia",
                  name: "Saint Lucia",
                },
                {
                  value: "Saint Martin",
                  name: "Saint Martin",
                },
                {
                  value: "Saint Pierre and Miquelon",
                  name: "Saint Pierre and Miquelon",
                },
                {
                  value: "Saint Vincent and Grenadines",
                  name: "Saint Vincent and Grenadines",
                },
                {
                  value: "Samoa",
                  name: "Samoa",
                },
                {
                  value: "San Marino",
                  name: "San Marino",
                },
                {
                  value: "Sao Tome and Principe",
                  name: "Sao Tome and Principe",
                },
                {
                  value: "Saudi Arabia",
                  name: "Saudi Arabia",
                },
                {
                  value: "Senegal",
                  name: "Senegal",
                },
                {
                  value: "Serbia",
                  name: "Serbia",
                },
                {
                  value: "Seychelles",
                  name: "Seychelles",
                },
                {
                  value: "Sierra Leone",
                  name: "Sierra Leone",
                },
                {
                  value: "Singapore",
                  name: "Singapore",
                },
                {
                  value: "Sint Maarten",
                  name: "Sint Maarten",
                },
                {
                  value: "Slovakia",
                  name: "Slovakia",
                },
                {
                  value: "Slovenia",
                  name: "Slovenia",
                },
                {
                  value: "Solomon Islands",
                  name: "Solomon Islands",
                },
                {
                  value: "Somalia",
                  name: "Somalia",
                },
                {
                  value: "South Africa",
                  name: "South Africa",
                },
                {
                  value: "South Georgia and the South Sandwich Islands",
                  name: "South Georgia and the South Sandwich Islands",
                },
                {
                  value: "South Korea",
                  name: "South Korea",
                },
                {
                  value: "South Sudan",
                  name: "South Sudan",
                },
                {
                  value: "Spain",
                  name: "Spain",
                },
                {
                  value: "Sri Lanka",
                  name: "Sri Lanka",
                },
                {
                  value: "Sudan",
                  name: "Sudan",
                },
                {
                  value: "Suriname",
                  name: "Suriname",
                },
                {
                  value: "Svalbard and Jan Mayen",
                  name: "Svalbard and Jan Mayen",
                },
                {
                  value: "Sweden",
                  name: "Sweden",
                },
                {
                  value: "Switzerland",
                  name: "Switzerland",
                },
                {
                  value: "Syrian Arab Republic",
                  name: "Syrian Arab Republic",
                },
                {
                  value: "Taiwan",
                  name: "Taiwan",
                },
                {
                  value: "Tajikistan",
                  name: "Tajikistan",
                },
                {
                  value: "Tanzania, United Republic of",
                  name: "Tanzania, United Republic of",
                },
                {
                  value: "Thailand",
                  name: "Thailand",
                },
                {
                  value: "Timor-Leste",
                  name: "Timor-Leste",
                },
                {
                  value: "Togo",
                  name: "Togo",
                },
                {
                  value: "Tokelau",
                  name: "Tokelau",
                },
                {
                  value: "Tonga",
                  name: "Tonga",
                },
                {
                  value: "Trinidad and Tobago",
                  name: "Trinidad and Tobago",
                },
                {
                  value: "Tunisia",
                  name: "Tunisia",
                },
                {
                  value: "Turkey",
                  name: "Turkey",
                },
                {
                  value: "Turkmenistan",
                  name: "Turkmenistan",
                },
                {
                  value: "Turks and Caicos Islands",
                  name: "Turks and Caicos Islands",
                },
                {
                  value: "Tuvalu",
                  name: "Tuvalu",
                },
                {
                  value: "Uganda",
                  name: "Uganda",
                },
                {
                  value: "Ukraine",
                  name: "Ukraine",
                },
                {
                  value: "United Arab Emirates",
                  name: "United Arab Emirates",
                },
                {
                  value: "Uruguay",
                  name: "Uruguay",
                },
                {
                  value: "US Minor Outlying Islands",
                  name: "US Minor Outlying Islands",
                },
                {
                  value: "US Virgin Islands",
                  name: "US Virgin Islands",
                },
                {
                  value: "Uzbekistan",
                  name: "Uzbekistan",
                },
                {
                  value: "Vanuatu",
                  name: "Vanuatu",
                },
                {
                  value: "Vatican City",
                  name: "Vatican City",
                },
                {
                  value: "Venezuela",
                  name: "Venezuela",
                },
                {
                  value: "Vietnam",
                  name: "Vietnam",
                },
                {
                  value: "Wallis and Futuna",
                  name: "Wallis and Futuna",
                },
                {
                  value: "Western Sahara",
                  name: "Western Sahara",
                },
                {
                  value: "Yemen",
                  name: "Yemen",
                },
                {
                  value: "Zambia",
                  name: "Zambia",
                },
                {
                  value: "Zimbabwe",
                  name: "Zimbabwe",
                },
              ]
        },
        priority:{
            id:"priority",
            name:"priority",
            required:true, 
            items:[
                {
                    name:"Select Your Highest Priority*",
                    value:"",
                },
                {
                    name:"Performance",
                    value:"Deliver Exceptional Digital Performances"
                  },
                  {
                    name:"Growth",
                    value:"Grow the Business"
                  },
                  {
                    name:"Security",
                    value:"Secure the Business"
                  },
                  {
                    name:"Monetization and Cost",
                    value:"Impact the Bottom Line"
                  },
                  {
                    name:"UX",
                    value:"Improve User Experience"
                  }
            ]
        },
        challenge:{
            id:"challenge",
            name:"challenge",
            required:true,
            items:[
                {
                    name:"Select Time Frame for Addressing Your Challenge*",
                    value:"",
                },
                {
                    name:"Currently evaluating solutions",
                    value:"Currently evaluating solutions"
                  },
                  {
                    name:"1-3 months",
                    value:"1-3 months"
                  },
                  {
                    name:"3-6 months",
                    value:"3-6 months"
                  },
                  {
                    name:"6-12 months",
                    value:"6-12 months"
                  },
                  {
                    name:"12-18 months",
                    value:"12-18 months"
                  },
                  {
                    name:"More than 18 months",
                    value:"More than 18 months"
                  }
            ]
        },
        phoneNumber:{
            placeholder:"Phone Number*",
            id:"phoneNumber",
            name:"phoneNumber",
            type:"text",
            required:true 
        },
        comments:{
            placeholder:"Comments or Questions*",
            id:"comments",
            name:"comments",
            row: 3,
            required:true 
        },
        communicationsConsent:{
          id:"communicationsConsent",
          name:"communicationsConsent",
          label:'I’d like to receive more information from HAAP. By submitting this form, I am providing consent to receive marketing communications and I understand and agree to the usage of my corporate contact information in accordance with HAAP’s',
          url:'/en/privacy-policy',
          showUrl:true,
          textUrl:'Privacy Policy'
        },
        typeButton: "submit",
        labelButton: "Send",
      },
};

export default talkWithUsData;


