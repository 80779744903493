import iconContants from "../constants/iconContants";
import {
  FaHotel,
  FaTicketAlt,
  FaBusAlt,
  FaCalendarAlt,
  FaBed,
  FaConciergeBell,
  FaBriefcase,
  FaClock,
  FaGlassMartini,
  FaBlenderPhone,
  FaSnowflake,
  FaTags,
  FaLongArrowAltRight,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaPinterest
} from "react-icons/fa";
import {
  AiOutlineFire,
  AiOutlineMenu,
  AiOutlineWifi,
  AiFillTag,
  AiOutlineArrowRight,
  AiOutlineGlobal,
  AiOutlineCheck,
  AiOutlineEdit,
  AiOutlineRight,
  AiOutlineClose,
  AiOutlineExclamationCircle,
  AiFillCheckCircle,
  AiOutlineAppstore,
  AiOutlineMenuUnfold,
  AiFillFacebook,
  AiFillYoutube,
  AiFillLinkedin,
  AiOutlineArrowUp
} from "react-icons/ai";
import { ImLocation2 } from "react-icons/im";
import { RiStarSFill, RiFlightTakeoffLine, RiFlightLandLine } from "react-icons/ri";
import {
  BsFillPersonFill,
  BsFillBriefcaseFill,
  BsFillCheckCircleFill,
  BsTrash,
  BsClock,
  BsFillHandbagFill,
  BsFillBagFill,
  BsBagDashFill,
  BsFillQuestionCircleFill,
  BsExclamationTriangleFill,
  BsFillExclamationCircleFill,
  BsFillCartFill,
  BsImages,
  BsArrowLeftRight,
  BsArrowDownUp,
} from "react-icons/bs";
import {
  FiCheck,
} from "react-icons/fi";
import {
  GrAddCircle,
  GrSubtractCircle,
  GrAdd,
} from "react-icons/gr";
import {
  BiSearch,
  BiRestaurant,
  BiListUl,
  BiCart,
} from "react-icons/bi";
import {
  GoArrowRight,
  GoArrowLeft,
  GoPerson,
  GoAlert,
} from "react-icons/go";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  
} from "react-icons/io";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl"
//TODO /ci generan error al compilarlos
import {CiDiscount1} from "react-icons/ci";
import {TbDiscount2, TbLogout} from "react-icons/tb";
import {HiUserCircle} from "react-icons/hi";
import {CgMenuGridR} from "react-icons/cg";
import {MdLanguage} from "react-icons/md";


const icon = (typeIcon) => {
  let icon;

  switch (typeIcon) {
    case iconContants.HOTEL:
      icon = <FaHotel />;
      break;
    case iconContants.ACTIVITY:
      icon = <FaTicketAlt />;
      break;
    case iconContants.SHUTTER:
      icon = <FaBusAlt />;
      break;
    case iconContants.BED:
      icon = <FaBed />;
      break;
    case iconContants.CONCIERGE:
      icon = <FaConciergeBell />;
      break;
    case iconContants.BRIEFCASE:
      icon = <FaBriefcase />;
      break;
    case iconContants.CLOCK:
      icon = <FaClock />;
      break;
    case iconContants.CALENDAR:
      icon = <FaCalendarAlt />;
      break;
    case iconContants.OFFSALE:
      icon = <AiOutlineFire />;
      break;
    case iconContants.OUTLINEMENU:
      icon = <AiOutlineMenu />;
      break;
    case iconContants.LOCATION:
      icon = <ImLocation2 />;
      break;
    case iconContants.STAR:
      icon = <RiStarSFill />;
      break;
    case iconContants.SEARCH:
      icon = <BiSearch />;
      break;
    case iconContants.PERSON:
      icon = <GoPerson />;
      break;
    case iconContants.BSFILLPERSONFILL:
      icon = <BsFillPersonFill />;
      break;
    case iconContants.BSFILLBRIEFCASEFILL:
      icon = <BsFillBriefcaseFill />;
      break;
    case iconContants.MARTINI:
      icon = <FaGlassMartini />;
      break;
    case iconContants.ROOMPHONE:
      icon = <FaBlenderPhone />;
      break;
    case iconContants.WIFI:
      icon = <AiOutlineWifi />;
      break;
    case iconContants.OUTLINEEDIT:
      icon = <AiOutlineEdit />;
      break;
    case iconContants.TRASH:
      icon = <BsTrash />;
      break;
    case iconContants.ADD:
      icon = <GrAdd />;
      break;
    case iconContants.AA:
      icon = <FaSnowflake />;
      break;
    case iconContants.FICHECK:
      icon = <FiCheck />;
      break;
    case iconContants.DISCOUNT:
      icon = <TbDiscount2 />;
      break;
    case iconContants.CIDISCOUNT1:
      icon = <AiFillTag />;
      break;
    case iconContants.AIOUTLINEARROWRIGHT:
      icon = <AiOutlineArrowRight />;
      break;
    case iconContants.CHECKCIRCLE:
      icon = <BsFillCheckCircleFill />;
      break;
    case iconContants.ADDCIRCLE:
      icon = <GrAddCircle />;
      break;
    case iconContants.MINUSCIRCLE:
      icon = <GrSubtractCircle />;
      break;
    case iconContants.BIRESTAURANT:
      icon = <BiRestaurant />;
      break;
    case iconContants.GOARROWRIGHT:
      icon = <GoArrowRight />;
      break;
    case iconContants.GOARROWLEFT:
      icon = <GoArrowLeft />;
      break;
    case iconContants.BSCLOCK:
      icon = <BsClock />;
      break;
    case iconContants.AIOUTLINEGLOBAL:
      icon = <AiOutlineGlobal />;
      break;
    case iconContants.AIOUTLINECHECK:
      icon = <AiOutlineCheck />;
      break;
    case iconContants.IOIOSARROWUP:
      icon = <IoIosArrowDown />;
      break;
    case iconContants.IOIOSARROWDOWN:
      icon = <IoIosArrowUp />;
      break;
    case iconContants.AIOUTLINERIGHT:
      icon = <AiOutlineRight />;
      break;
    case iconContants.FATAGS:
      icon = <FaTags />;
      break;
    case iconContants.AIOUTLINECLOSE:
      icon = <AiOutlineClose />;
      break;
    case iconContants.AIOUTLINEEXCLAMATINCIRCLE:
      icon = <AiOutlineExclamationCircle />;
      break; 
    case iconContants.RIFLIGHTOFF:
      icon = <RiFlightTakeoffLine />;
      break;
    case iconContants.RIFLIGHTLAND:
      icon = <RiFlightLandLine />;
      break;
    case iconContants.BSFILLHANDBAGFILL:
      icon = <BsFillHandbagFill />; 
      break; 
    case iconContants.BSFILLBAGFILL:
      icon = <BsFillBagFill />; 
      break; 
    case iconContants.BSBAGDASFILL:
      icon = <BsBagDashFill />; 
      break; 
    case iconContants.BSFILLQUESTIONCIRCLEFILL:
      icon = <BsFillQuestionCircleFill />; 
      break;
    case iconContants.AIFILLCHECKCIRCLE:
      icon = <AiFillCheckCircle />; 
      break;
    case iconContants.BSEXCLAMATIONTRIANGLEFILL:
      icon = <BsExclamationTriangleFill />; 
      break;
    case iconContants.BSEXCLAMATIONCIRCLEFILL:
      icon = <BsFillExclamationCircleFill />; 
      break;              
    case iconContants.IMGS:
      icon = <BsImages />;
      break;
    case iconContants.AIOUTLINEAPPSTORE:
      icon = <AiOutlineAppstore />;
      break;
    case iconContants.SLARROWRIGHT:
      icon = <SlArrowRight />;
      break;
    case iconContants.SLARROWLEFT:
      icon = <SlArrowLeft />;
      break;  
    case iconContants.BILISTUL:
      icon = <BiListUl />;
      break;
    case iconContants.FALONGARROWALTRIGHT:
      icon = <FaLongArrowAltRight />;
      break;
    case iconContants.GOALERT:
      icon = <GoAlert />; 
      break; 
    case iconContants.BSFILLCARTFILL:
      icon = <BsFillCartFill />; 
      break;
    case iconContants.BSARROWLEFTRIGHT:
      icon = <BsArrowLeftRight />
      break;
    case iconContants.BSARROWDOWNUP:
      icon = <BsArrowDownUp />
      break;
    case iconContants.TBLOGOUT:
      icon = <TbLogout />
      break;
    case iconContants.HIUSERCIRCLE:
      icon = <HiUserCircle />
      break;
    case iconContants.BICART:
      icon = <BiCart />
      break;
    case iconContants.CGMENUGRIDR:
      icon = <CgMenuGridR />
      break;
    case iconContants.FAFACEBOOKF:
      icon = <FaFacebookF />
      break;
    case iconContants.FATWITTER:
        icon = <FaTwitter />
        break;
    case iconContants.FAINSTAGRAM:
        icon = <FaInstagram />
        break; 
    case iconContants.FAYOUTUBE:
        icon = <FaYoutube />
        break;
    case iconContants.FAPINTEREST:
        icon = <FaPinterest />
        break; 
    case iconContants.AIOUTLINEMENUUNFOLD:
        icon = <AiOutlineMenuUnfold />
        break; 
    case iconContants.AIFILLFACEBOOK:
        icon = <AiFillFacebook />
        break;
    case iconContants.AIFILLYOUTUBE:
        icon = <AiFillYoutube />
        break;
    case iconContants.AIFILLLINKEDIN:
        icon = <AiFillLinkedin />
        break; 
    case iconContants.MDLANGUAGE:
          icon = <MdLanguage />
          break;
    case iconContants.AIOUTLINEARROWUP:
          icon = <AiOutlineArrowUp />
          break;                  
    default:
  }
  return icon;
};
const getIconMealPlan = (mealPlanCode) => {
  let icon;

  //TODO is add, remove or mod any case, change getIconMealPlanType
  switch (mealPlanCode.toLowerCase()) {
    case "ro":
      icon = <FaBed />;
      break;
    case "ai":
    case "i":
      icon = <FaGlassMartini />;
      break;
    case "bb":
    case "ab":
    case "db":
    case "l":
      icon = <BiRestaurant />;
      break;
    default:
      icon = <FaBed />;
      break;
  }

  return icon;
}
const getIconMealPlanType = (mealPlanCode) => {
  let icon;

  //TODO is add, remove or mod any case, change getIconMealPlan
  switch (mealPlanCode.toLowerCase()) {
    case "ro":
      icon = iconContants.BED;
      break;
    case "ai":
    case "i":
      icon = iconContants.MARTINI;
      break;
    case "bb":
    case "ab":
    case "db":
    case "l":
      icon = iconContants.BIRESTAURANT;
      break;
    default:
      icon = iconContants.BED;
      break;
  }

  return icon;
}

const iconUtils = {
  icon,
  getIconMealPlan,
  getIconMealPlanType
};

export default iconUtils;
