import top1 from "../assets/img/branding/partners/Top1.svg";
import top2 from "../assets/img/branding/partners/Top2.svg"
import top3 from "../assets/img/branding/partners/Top3.svg"
import top4 from "../assets/img/branding/partners/Top4.svg"
import top5 from "../assets/img/branding/partners/Top5.svg"
import top6 from "../assets/img/branding/partners/Top6.svg"
import top7 from "../assets/img/branding/partners/Top7.svg"
import top8 from "../assets/img/branding/partners/Top8.svg"
import top9 from "../assets/img/branding/partners/Top9.svg"
import top10 from "../assets/img/branding/partners/Top10.svg"

import top1Mobile from "../assets/img/branding/partners/Top1M.svg";
import top2Mobile from "../assets/img/branding/partners/Top2M.svg"
import top3Mobile from "../assets/img/branding/partners/Top3M.svg"
import top4Mobile from "../assets/img/branding/partners/Top4M.svg"
import top5Mobile from "../assets/img/branding/partners/Top5M.svg"
import top6Mobile from "../assets/img/branding/partners/Top6M.svg"
import top7Mobile from "../assets/img/branding/partners/Top7M.svg"
import top8Mobile from "../assets/img/branding/partners/Top8M.svg"
import top9Mobile from "../assets/img/branding/partners/Top9M.svg"
import top10Mobile from "../assets/img/branding/partners/Top10M.svg"


const partners = {
  top1:top1,
  top2:top2,
  top3:top3,
  top4:top4,
  top5:top5,
  top6:top6,
  top7:top7,
  top8:top8,
  top9:top9,
  top10:top10,
  top1Mobile:top1Mobile,
  top2Mobile:top2Mobile,
  top3Mobile:top3Mobile,
  top4Mobile:top4Mobile,
  top5Mobile:top5Mobile,
  top6Mobile:top6Mobile,
  top7Mobile:top7Mobile,
  top8Mobile:top8Mobile,
  top9Mobile:top9Mobile,
  top10Mobile:top10Mobile,
}

export default partners;