import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import appApi from "../../assets/img/branding/cards/delivery/App_APIPerformance.png";
import media from "../../assets/img/branding/cards/delivery/MediaDelivery.png";

const contentDeliveryData = {
    videBgDelivery:{
        clip:"",
        title:"Content Delivery (CDN)",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteDelivery:{
        headBandshowTitle:true,
        headBandTitle:"",
        classNameHead:"",
        headBandtext:"<span>You create extraordinary digital experiences</span>. Trust the agility and scale of a world-class content delivery network to help you flawlessly deliver them.",
        
    },
    cardsDelivery:{
        title:"",
        text:"",
        items:[
            {
                img:appApi,
                title:"App & API Performance",
                label:"Attract customers with fast, engaging app and API performance. Improve user experience with robust data insights and automation.",
                url:"#"
                // url:"/app-and-api-performance"
            },
            {
                img:media,
                title:"Media Delivery",
                label:"Consistently deliver amazing streaming experiences to the largest audiences, on any device. Achieve maximum performance in the biggest events. Anticipate cloud infrastructure costs. Make strategic decisions based on complex information",
                url:"#"
                // url:"/media-delivery"
            },
            
        ]
    },
    formVideoDelivery:{
        row:3,
        placeholderMessage:"Message",
        typeEmail:"email",
        size:"md",
        placeholderEmail:"Email",
        idEmail:"email",
        requiredEmail:true,
        classNameInput:"form-basic",
        typeName:"text",
        placeholderName:"First Name",
        idName:"name",
        requiredName:true,
        placeholderLastName:"Last Name",
        typeLastName:"text",
        idLastName:"lastname",
        requiredLastName:true,
        typeButton:"submit",
        textButton:"Send",

        urlVideo:"",
        show:false,
    },
    urlFormDelivery:"/talk-with-us",
    texBtntFormDelivery:"Talk to an expert",
}

export default contentDeliveryData;