import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';


const apiApplicationPerformanceData = {
    videBgApiApplicationPerformance:{
        clip:"",
        poster:"",
        title:"Soluções de entrega de conteúdo (CDN)",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteApiApplicationPerformance:{
        headBandshowTitle:true,
        headBandTitle:"Você cria <span>experiências</span> digitais extraordinárias.",
        classNameHead:"",
        headBandtext:"Confie na agilidade e no dimensionamento do Akamai Intelligent Edge para ajudar você a entregá-las com perfeição.Atraia clientes com desempenho rápido e envolvente de aplicativos e APIs, aprimorado por dados robustos e automação.",
        
    },
    cardsApiApplicationPerformance:{
        title:"",
        text:"Soluções de <span>desempenho</span> de apps e APIs",
        items:[
            {
                img:"",
                title:"API Acceleration",
                label:"Otimize o desempenho e a disponibilidade das APIs e aprimore as experiências dos usuários.",
                url:"/detalhes/api-acceleration",
                textUrl:""
            },
            {
                img:"",
                title:"CloudTest",
                label:"Teste a resiliência dos seus websites e suas aplicações com testes de carga robustos e em tempo real.",
                url:"/detalhes/cloud-test",
                textUrl:""
            },
            {
                img:"",
                title:"DataStream",
                label:"Entenda a fundo o desempenho da CDN (Rede de Entrega de Conteúdo) com dados de log em escala.",
                url:"/detalhes/datastream",
                textUrl:""
            },
            {
                img:"",
                title:"Global Traffic Management",
                label:"Otimize o desempenho das aplicações e evite paralisações com o balanceamento de carga inteligente.",
                url:"/detalhes/global-traffic-management",
                textUrl:""
            },
            {
                img:"",
                title:"Image & Video Manager",
                label:"Otimize e aprimore automaticamente as mídias visuais para todos os usuários, em qualquer dispositivo.",
                url:"/detalhes/image-video-manager",
                textUrl:""
            }
            ,
            {
                img:"",
                title:"Ion",
                label:"Entregue experiências rápidas, envolventes e confiáveis ao usuário em seus sites e apps.",
                url:"/detalhes/ion",
                textUrl:""
            },
            {
                img:"",
                title:"mPulse",
                label:"Registre dados de experiência do usuário. Otimize o desempenho dos websites. Avalie o ROI.",
                url:"/detalhes/mpulse",
                textUrl:""
            }
        ]
    },
    urlFormApiApplicationPerformance:"/fale-com-a-gente",
    texBtntFormApiApplicationPerformance:"Fale com um especialista",
}

export default apiApplicationPerformanceData;