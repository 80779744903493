import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import location from "../../assets/img/icons/haap/location.svg";
import connection from "../../assets/img/icons/haap/connection.svg";
import global from "../../assets/img/icons/haap/global.svg";
import capacity from "../../assets/img/icons/haap/capacity.svg";
import monitoring from "../../assets/img/icons/haap/verified-monitoring.svg";
import users from "../../assets/img/icons/haap/users.svg";


const whyAkamaiData = {
    videBgAkamai:{
        clip:"",
        title:"WHY AKAMAI",
        text:"",
        showContentText:true,
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteAkamai:{
        headBandshowTitle:true,
        headBandTitle:"Why Akamai?",      
        headBandtext:"Because <span>Akamai</span> is the provider of reference in the world of online content delivery and security. So we became their premier partner and strategic implementor.",
        
    },
    infoAkamai:{
        text:"<p>Every day, billions of people connect with their favorite brands to shop online, play games, share ideas, manage money, and so much more. They may not know it, but Akamai is there, powering and protecting life online.</p><p>Over 20 years ago, Akamai set out to solve the toughest challenge of the early internet: the “World Wide Wait.” And they’ve been solving the internet’s toughest challenges ever since, working toward our vision of a safer and more connected world.</p><p>With the world’s most distributed compute platform — from cloud to edge — Akamai make it easy for businesses to develop and run applications, while we keep experiences closer to users and threats farther away. That’s why innovative companies worldwide choose Akamai to build, deliver, and secure their digital experiences.</p><p>That’s why we work together with Akamai.</p><ul><li>18/20 Top Telecommunications Carriers</li><li>20/20 Top Brokerages</li><li>10/10 global video streaming services</li><li>10/10 Top Global Video Game companies</li><li>30/30 Top media companies</li><li>10/12 Top Security & Software Companies</li><li>9/10 Top Banks in the World</li></ul>",
        listItems:[
            {
                icon:location,
                count:4.100,
                decimal:3,
                complement:"+",
                label:"Locations"
            },
            {
                icon:connection,
                count:1.300,
                decimal:3,
                complement:"+",
                label:"Networks"
            },
            {
                icon:global,
                count:134,
                decimal:"",
                complement:"",
                label:"Countries"
            },
            {
                icon:capacity,
                count:800,
                decimal:"",
                complement:"+ Tbps",
                label:"Capacity"
            },
            {
                icon:monitoring,
                count:24,
                decimal:"",
                complement:"/7",
                label:"Monitoring"
            },
            {
                icon:users,
                count:1.900,
                decimal:3,
                complement:"+",
                label:"Services experts"
            },
        ],
        
    },
    formVideoAkamai:{
        row:3,
        placeholderMessage:"Message",
        typeEmail:"email",
        size:"md",
        placeholderEmail:"Email",
        idEmail:"email",
        requiredEmail:true,
        classNameInput:"form-basic",
        typeName:"text",
        placeholderName:"First Name",
        idName:"name",
        requiredName:true,
        placeholderLastName:"Last Name",
        typeLastName:"text",
        idLastName:"lastname",
        requiredLastName:true,
        typeButton:"submit",
        textButton:"Send",

        urlVideo:"",
        show:false,
    }
}

export default whyAkamaiData;