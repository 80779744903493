import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';


const fraudProtectionData = {
    videBgFraudProtection:{
        clip:"",
        poster:"",
        title:"Proteção contra fraude e violação",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteFraudProtection:{
        headBandshowTitle:true,
        headBandTitle:"<span>Interrompa</span> ataques de preenchimento de credencial, bots e fraudes humanas na Web com os recursos mais inteligentes de <span>detecção e mitigação</span>.",
        classNameHead:"",
        headBandtext:"Identifique comportamentos humanos e de bots suspeitos em tempo real",
        items:[
            {
                img:"",
                title:"Mantenha a confiança no centro das experiências online",
                label:"Proteja transações & relacionamentos online sem restringir os clientes.",
                url:"",
                textUrl:""
            },
            {
                img:"",
                title:"Gaste menos tempo na investigação de acessos à conta.",
                label:"Evite o desperdício de recursos & a rotatividade do gerenciamento de alertas & da mitigação de contas comprometidas.",
                url:"",
                textUrl:""
            },
            {
                img:"",
                title:"Encontre impostores antes de eles roubarem os ativos do cliente.",
                label:"Detecte anomalias sutis na atividade da conta com visibilidade incomparável da Web.",
                url:"",
                textUrl:""
            }
        ]
        
    },
    cardsFraudProtection:{
        title:"",
        text:"Soluções de <span>proteção</span> contra fraude e violação:",
        items:[
            // {
            //     img:"",
            //     title:"Bot Manager",
            //     label:"Detenha os bots mais perigosos e evasivos antes que a confiança dos clientes seja afetada.",
            //     url:"/detalhes/bot-manager",
            //     textUrl:""
            // },
            // {
            //     img:"",
            //     title:"Account Protector",
            //     label:"Afaste os fraudadores e mantenha a confiança intacta com a proteção contra invasão de contas.",
            //     url:"/detalhes/account-protector",
            //     textUrl:""
            // },
            // {
            //     img:"",
            //     title:"Audience Hijacking Protector",
            //     label:"Mantenha os clientes em seu website, maximize as taxas de conversão e minimize a fraude de afiliados.",
            //     url:"/detalhes/audience-hijacking-protector",
            //     textUrl:""
            // },
            // {
            //     img:"",
            //     title:"Identity Cloud",
            //     label:"Crie experiências fluidas e seguras para os clientes.",
            //     url:"/detalhes/identity-cloud",
            //     textUrl:""
            // }
            // ,
            // {
            //     img:"",
            //     title:"Brand Protector",
            //     label:"Impeça apropriações indevidas de identidades de marcas.",
            //     url:"/detalhes/brand-protector",
            //     textUrl:""
            // }
        ]
    },
    urlFormFraudProtection:"/fale-com-a-gente",
    texBtntFormFraudProtection:"Fale com um especialista",
}

export default fraudProtectionData;