import logo from "../../assets/img/branding/haapTech.png";
import logoMini from "../../assets/img/branding/haapMini.png"
import logoSecond from "../../assets/img/branding/akamai.png"
import constants from "../../constants";
import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';

const websiteData = {
    home: {
        alt: "Logo",
        href: "/en",
        imgPath: logo,
        imgPathMini:logoMini
    },
    menu: [
      {
        name: "Home",
        href: "/",
      },
      {
        name: "Security",
        href: "/security",
      },
      {
        name: "Content Delivery (CDN)",
        href: "/content-delivery",
      },
      // {
      //   name: "Cloud Computing",
      //   href: "/cloud-computing",
      // },
      {
        name: "IMPLEMENTATION",
        href: "/implementation",
      },
      {
        name: "TALK WITH US",
        href: "/talk-with-us",
      },
      // {
      //   name: "WHY AKAMAI?",
      //   href: "/why-akamai",
      // },
    ],
    languagesItems: [
     
      {
        name: "English",
        value: constants.language.EN,
        icon: "",
        tag:"EN"
      },
      // {
      //   name: "Español",
      //   value: constants.language.ES,
      //   icon: "",
      //   tag: "ES"
      // },
      //  {
      //   name: "Portugues",
      //   value: constants.language.PT,
      //   icon: "",
      //   tag: "PT"
      // },
      /*{
        name: "Deutsch",
        value: constants.language.DE,
        icon: "imagen.jpg"
      }, */
  ],
    siteLocale: constants.language.EN,
    domain: "http://localhost:3000",
    siteRouter : [
      {
        pathname: "/",
        basename: "/",
        canonical: "/",
        title: "example test title",
        description: "text desc ription",
        keywords: "texts, home, test, example",
        img: "img.jpg",
        homePage: true,
        keypage:"home",
        follow: null //default inddex, follow
      },
      {
        pathname: "/security",
        basename: "/security",
        canonical: "/security",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "security-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/content-delivery",
        basename: "/content-delivery",
        canonical: "/content-delivery",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "content-delivery-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/implementation",
        basename: "/implementation",
        canonical: "/implementation",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "implementation-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/cloud-computing",
        basename: "/cloud-computing",
        canonical: "/cloud-computing",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "cloud-computing-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/talk-with-us",
        basename: "/talk-with-us",
        canonical: "/talk-with-us",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "talk-with-us-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/why-akamai",
        basename: "/why-akamai",
        canonical: "/why-akamai",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "why-akamai-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/cookie-policy",
        basename: "/cookie-policy",
        canonical: "/cookie-policy",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "cookie-policy-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/privacy-policy",
        basename: "/privacy-policy",
        canonical: "/privacy-policy",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "privacy-policy-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/terms-of-use",
        basename: "/terms-of-use",
        canonical: "/terms-of-use",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "terms-of-use-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/infrastructure-security",
        basename: "/infrastructure-security",
        canonical: "/infrastructure-security",
        title: "Seguridad de infraestructura",
        description: "Seguridad de infraestructura",
        keywords: "Seguridad de infraestructura",
        img: "img.jpg",
        keypage: "infrastructure-security",
        follow: null //default inddex, follow
      },
      {
        pathname: "/application-security",
        basename: "/application-security",
        canonical: "/application-security",
        title: "Seguridad de aplicaciones",
        description: "Seguridad de aplicaciones",
        keywords: "Seguridad de aplicaciones",
        img: "img.jpg",
        keypage: "application-security",
        follow: null //default inddex, follow
      },
      {
        pathname: "/security-zero-trust",
        basename: "/security-zero-trust",
        canonical: "/security-zero-trust",
        title: "Seguridad zero trust",
        description: "Seguridad zero trust",
        keywords: "Seguridad zero trust",
        img: "img.jpg",
        keypage: "security-zero-trust",
        follow: null //default inddex, follow
      },
      {
        pathname: "/abuse-and-fraud-protection",
        basename: "/abuse-and-fraud-protection",
        canonical: "/abuse-and-fraud-protection",
        title: "Soluciones de protección contra fraude y abuso",
        description: "Soluciones de protección contra fraude y abuso",
        keywords: "Soluciones de protección contra fraude y abuso",
        img: "img.jpg",
        keypage: "fraud-protection",
        follow: null //default inddex, follow
      },
      {
        pathname: "/app-and-api-performance",
        basename: "/app-and-api-performance",
        canonical: "/app-and-api-performance",
        title: "Rendimiento de aplicaciones api",
        description: "Rendimiento de aplicaciones api",
        keywords: "Rendimiento de aplicaciones api",
        img: "img.jpg",
        keypage: "api-application-performance",
        follow: null //default inddex, follow
      },
      {
        pathname: "/media-delivery",
        basename: "/media-delivery",
        canonical: "/media-delivery",
        title: "Multimedia content distribution",
        description: "Multimedia content distribution",
        keywords: "Multimedia content distribution",
        img: "img.jpg",
        keypage: "multimedia-content-distribution",
        follow: null //default inddex, follow
      },
      {
        pathname: "/detail/",
        basename: "/detail/:id",
        canonical: "/detail",
        title: "Product detail",
        description: "Product detail",
        keywords: "Product detail",
        img: "img.jpg",
        keypage: "product-detail",
        follow: null //default inddex, follow
      },
 
    ],
    footerInformation:{
      alt: "Logo",
      href: "/en",
      imgPath: logo,
      imgPathSecond:"",
      textMini: "",
      textTitle: "Help and information",
      links: [{
        text: "Terms and conditions",
        url: "/terms-of-use",
        hasLink: true
    },  
    {
        text: "Privacy Policy",
        url: "/privacy-policy",
        hasLink: true
    },
    {
      text: "Cookie policy",
      url: "/cookie-policy",
      hasLink: true
    },
     {
        text: "Contact us",
        url: "/talk-with-us",
        hasLink: true
    },
     /*{
        text: "Preguntas frecuentes",
        url: "/",
        hasLink: false
    },    
    {
        text: "Sobre nosotros",
        url: "/",
        hasLink: false
    }, */
    ],
    textSocial:"Visit us on our social networks",
    social:[{
        icon: iconUtils.icon(iconContants.AIFILLFACEBOOK),
        url: "/",
        active: false
    },
    {
        icon: iconUtils.icon(iconContants.AIFILLYOUTUBE),
        url: "/",
        active: false
    },
    {
        icon: iconUtils.icon(iconContants.AIFILLLINKEDIN),
        url: "https://www.linkedin.com/company/haap-tech/",
        active: true
    },
    
    ],
  
  },
}

export default websiteData;