import { useGlobalState } from "../../store/StoreProvider";
import { Link } from "react-router-dom";
const CookiePolicyPt = (props) => {
  const { locale } = useGlobalState();
  return (
    <>
      <section className="global-info">
        <div className="content-text">

          <p>Última atualização: 17 de fevereiro de 2023</p>
          <p>
           Esta Política de Cookies explica como a HAAP Tech Inc. e suas
            afiliadas ("HAAP", "nós", "nos", "nosso" ou "nós") e nossos
            prestadores de serviços utilizam cookies em sites HAAP, bem como as
            escolhas que você tem para controlá-los. Para mais informações sobre
            como coletamos, usamos e divulgamos informações pessoais, consulte
            nossa  <Link to={`/${locale}/politica-de-privacidade`}>Política de Privacidad</Link>.
          </p>
        </div>

        <div className="content-text">
          <h3> (1) O QUE SÃO COOKIES? </h3>

          <p>
            Os cookies são pequenos pedaços de dados, armazenados em arquivos de
            texto que são armazenados em seu computador ou outro dispositivo. Os
            cookies podem ser definidos pelo website que você está visitando ou
            por terceiros, tais como aqueles que servem de conteúdo ou que
            fornecem serviços de publicidade ou de integração de mídia social no
            website (conhecidos como "cookies de terceiros"). O website ou
            terceiro que aplica o cookie pode acessá-lo em visitas subsequentes.
            Isto permite que os websites "lembrem" você e suas preferências,
            seja para uma única visita (através de um "cookie de sessão") ou
            para múltiplas visitas repetidas (através de um "cookie
            persistente"). Os cookies ajudam a garantir uma experiência
            consistente e eficiente para os visitantes e executam funções
            essenciais, tais como permitir que os usuários se registrem e
            permaneçam logados.
          </p>

          <p>
            Tanto websites como e-mails podem conter outras tecnologias de
            rastreamento, tais como "web beacons" ou "pixels". Estas são
            tipicamente imagens pequenas e transparentes que nos permitem
            coletar informações para fins similares aos cookies. Web beacons ou
            pixels são frequentemente usados em conjunto com cookies, mas não
            são armazenados em seu computador da mesma forma. Consequentemente,
            se você desativar os cookies, os web beacons podem ainda carregar,
            mas sua funcionalidade será restrita.
          </p>
        </div>

        <div className="content-text">
          <h3> (2) COMO USAMOS OS COOKIES </h3>

          <p>
            Utilizamos cookies para uma variedade de propósitos. Alguns cookies
            são necessários por razões técnicas (por exemplo, segurança ou
            otimização de websites); alguns permitem uma experiência
            personalizada; alguns nos permitem distinguir visitantes únicos a
            fim de analisar a eficácia de nosso conteúdo e marketing; e alguns
            permitem que as redes de publicidade veiculem anúncios que possam
            ser de seu interesse.
          </p>
        </div>

        <div className="content-text">
          <h3> (3) TIPOS DE COOKIES </h3>

          <p>
            A tabela a seguir explica os tipos de cookies que usamos em nossos
            websites e por que os utilizamos. Também fornecemos exemplos de
            cookies estabelecidos por nós ou por nossos prestadores de serviços.
            Observe que esta não é uma lista exaustiva, mas se destina a ser
            representativa.
          </p>
        </div>
        <div className="content-text">
          <h3> (4) CONTROLE DE COOKIES </h3>

          <p>
            
            Fornecemos aos visitantes de nosso website um Centro de Preferências
            de Privacidade para habilitar ou desabilitar certas categorias de
            cookies. Esta seção descreve outras maneiras de controlar os
            cookies.
          </p>

          <p>
            Os visitantes podem desejar restringir o uso de cookies ou impedir
            que os cookies sejam definidos completamente. A maioria dos
            navegadores modernos permite que você altere suas configurações de
            cookies. Alguns navegadores permitem que você desabilite categorias
            de cookies (por exemplo, cookies de terceiros) ou cookies
            específicos por nome. Geralmente você pode encontrar essas
            configurações no menu de opções ou preferências de seu navegador.
            Para entender estas configurações, os links a seguir para os
            navegadores mais usados podem ser úteis, ou você pode usar a opção
            de ajuda de seu navegador para obter mais detalhes:
          </p>

          <ul>
            <li><Link to="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd" target="_blank">Microsoft Edge</Link> </li>

            <li><Link to="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10" target="_blank">Internet Explorer</Link> </li>

            <li><Link to="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectslug=Cookies&redirectlocale=en-US" target="_blank">Firefox</Link> </li>

            <li><Link to="https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666" target="_blank">Chrome</Link> </li>

            <li><Link to="https://support.apple.com/es-mx/guide/safari/sfri11471/mac" target="_blank">Safari web</Link> e <Link to="https://support.apple.com/es-mx/HT201265" target="_blank">iOS</Link>. </li>
          </ul>

          <p>
            
            Se você desativar os cookies, observe que algumas características de
            nosso serviço podem não funcionar corretamente.
          </p>

          <p>
            Para saber mais sobre suas escolhas em relação ao uso de sua
            atividade de navegação na web para publicidade baseada em
            interesses, você pode visitar os seguintes sites:
          </p>

          <ul>
            <li> <Link to="https://thenai.org/" target="_blank">Network Advertising</Link> </li>

            <li><Link to="https://youradchoices.com/" target="_blank">Your Ad Choices</Link> </li>

            <li><Link to="https://youronlinechoices.eu/" target="_blank">Your Online Choices</Link>  </li>
          </ul>
        </div>

        <div className="content-text">
          <h3> (5) FALE CONOSCO </h3>

          <p>
            
            Se você tiver alguma dúvida sobre nosso uso de cookies, você pode
            encontrar os detalhes de contato mais relevantes em nossa <Link to={`/${locale}/politica-de-privacidade`}>Política de Privacidad</Link>.
          </p>
        </div>
      </section>
    </>
  );
};

export default CookiePolicyPt;
