import APIAcceleration from "../../files/PDF/es/ContentDelivery/APIAcceleration.pdf";
import GlobalTrafficManagement from "../../files/PDF/es/ContentDelivery/GlobalTrafficManagement.pdf";
import ImageVideoManager from "../../files/PDF/es/ContentDelivery/ImageVideoManager.pdf";
import Ion from "../../files/PDF/es/ContentDelivery/Ion.pdf";
//Content Delivery
import AdaptiveMediaDelivery from "../../files/PDF/es/ContentDelivery/AdaptiveMediaDelivery.pdf";
import CloudWrapper from "../../files/PDF/es/ContentDelivery/CloudWrapper.pdf";
import CloudTest from "../../files/PDF/es/ContentDelivery/CloudTest.pdf";
import Datastream from "../../files/PDF/es/ContentDelivery/Datastream.pdf";
import DownloadDelivery from "../../files/PDF/es/ContentDelivery/DownloadDelivery.pdf";
import MediaAnalytics from "../../files/PDF/es/ContentDelivery/MediaAnalytics.pdf";
import MediaServicesLive from "../../files/PDF/es/ContentDelivery/MediaServicesLive.pdf";
import Netstorage from "../../files/PDF/es/ContentDelivery/Netstorage.pdf";
import MPulse from "../../files/PDF/es/ContentDelivery/mPulse.pdf";
import CloudComputing from "../../files/PDF/es/CloudComputing/Cloud-computing.pdf";
import DeliveryDistribution from "../../files/PDF/es/ContentDelivery/Entrega-distribucion-ESP.pdf";

//Segurity

import AppAndApiPerformance from "../../files/PDF/es/Seguridad/App&APIProtector.pdf"
import Prolexic from "../../files/PDF/es/Seguridad/Prolexic.pdf"
import EdgeDNS from "../../files/PDF/es/Seguridad/EdgeDNS.pdf"
import ApiSecurity from "../../files/PDF/es/Seguridad/ApiSecurity.pdf"
import AccountProtector from "../../files/PDF/es/Seguridad/AccountProtector.pdf"
import PageIntegrityManager from "../../files/PDF/es/Seguridad/PageIntegrityManager.pdf"
import GuardicoreSegmentation from "../../files/PDF/es/Seguridad/GuardicoreSegmentation.pdf"
import Hunt from "../../files/PDF/es/Seguridad/Hunt.pdf"
import EnterpriseApplicationAccess from "../../files/PDF/es/Seguridad/EnterpriseApplicationAccess.pdf"
import SecureInternetAccessCloudSecureGateway from "../../files/PDF/es/Seguridad/SecureInternetAccessCloudSecureGateway.pdf"
import MFA from "../../files/PDF/es/Seguridad/MFA.pdf"
import BotManager from "../../files/PDF/es/Seguridad/BotManager.pdf"
import AudienceHijackingProtector from "../../files/PDF/es/Seguridad/AudienceHijackingProtector.pdf"
import IdentityCloud from "../../files/PDF/es/Seguridad/IdentityCloud.pdf"
import BrandProtector from "../../files/PDF/es/Seguridad/BrandProtector.pdf"




const pdfPageData = {
  dataPDF: [
    {
      key: "brand-protector",
      title: "Brand Protector",
      pdffile: BrandProtector,
    },
    {
      key: "identity-cloud",
      title: "Identity Cloud",
      pdffile: IdentityCloud,
    },
    {
      key: "audience-hijacking-protector",
      title: "Audience Hijacking Protector",
      pdffile: AudienceHijackingProtector,
    },
    {
      key: "bot-manager",
      title: "Bot Manager",
      pdffile: BotManager,
    },
    {
      key: "akamai-mfa",
      title: "Akamai MFA",
      pdffile: MFA,
    },
    {
      key: "secure-internet-access-enterprise",
      title: "Secure Internet Access Enterprise",
      pdffile: SecureInternetAccessCloudSecureGateway,
    },
    {
      key: "enterprise-application-access",
      title: "Enterprise Application Access",
      pdffile: EnterpriseApplicationAccess,
    },
    {
      key: "hunt",
      title: "Hunt",
      pdffile: Hunt,
    },
    {
      key: "guardicore-segmentation",
      title: "Guardicore Segmentation de Akamai",
      pdffile: GuardicoreSegmentation,
    },
    {
      key: "page-integrity-manager",
      title: "Page Integrity Manager",
      pdffile: PageIntegrityManager,
    },
    {
      key: "account-protector",
      title: "App & API Protector",
      pdffile: AccountProtector,
    },
    {
      key: "api-security",
      title: "API Security",
      pdffile: ApiSecurity,
    },
    {
      key: "api-performance",
      title: "App & API Performance",
      pdffile: AppAndApiPerformance,
    },
    {
      key: "prolexic",
      title: "Prolexic",
      pdffile: Prolexic,
    },
    {
      key: "edge-dns",
      title: "Edge Dns",
      pdffile: EdgeDNS,
    },
    {
      key: "api-acceleration",
      title: "API Acceleration",
      pdffile: APIAcceleration,
    },
    {
      key: "cloud-test",
      title: "CloudTest",
      pdffile: CloudTest,
    },
    {
      key: "global-traffic-management",
      title: "Global Traffic Management",
      pdffile: GlobalTrafficManagement,
    },
    {
      key: "image-video-manager",
      title: "Image & Video Manager",
      pdffile: ImageVideoManager,
    },
    {
      key: "ion",
      title: "Ion",
      pdffile: Ion,
    },
    {
      key: "mpulse",
      title: "mPulse",
      pdffile: MPulse,
    },
    {
      key: "adaptive-media-delivery",
      title: "Adaptive Media Delivery",
      pdffile: AdaptiveMediaDelivery,
    },
    {
      key: "cloud-wrapper",
      title: "Cloud Wrapper",
      pdffile: CloudWrapper,
    },
    {
      key: "datastream",
      title: "DataStream",
      pdffile: Datastream,
    },
    {
      key: "distribution-dedicate",
      title: "Dedicated Delivery",
      pdffile: "",
    },
    {
      key: "download-delivery",
      title: "Download Delivery",
      pdffile: DownloadDelivery,
    },
    {
      key: "media-analytics",
      title: "Media Analytics",
      pdffile: MediaAnalytics,
    },
    {
      key: "media-services-live",
      title: "Media Services Live",
      pdffile: MediaServicesLive,
    },
    {
      key: "netstorage",
      title: "NetStorage",
      pdffile: Netstorage,
    },
    {
      key: "cloud-computing",
      title: "Cloud Computing",
      pdffile: CloudComputing,
    },
    {
      key: "delivery-distribution",
      title: "Delivery Distribution",
      pdffile: DeliveryDistribution,
    },
  ],
};

export default pdfPageData;
