import { useIntl } from "react-intl";
import { useEffect } from 'react';
import { Container } from "react-bootstrap";
import image from "../assets/img/branding/landing/bgGeneral.jpg";
import CookiePolicyText from "../components/CookiePolicyText";

const CookiePolicy = (props) => {
  const {resourceSite } = props;
  const intl = useIntl();
  useEffect(()=>{
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className="informative-section">
      <div
        className="bg-image bg-protection"
        style={{ backgroundImage: `url(${image})` }}
      >
        <h2>
          
          {intl.formatMessage({ id: "general.titleCookiePolicy" })}
        </h2>
        <div className="overlay"></div>
      </div>
      <Container>
        <CookiePolicyText />
      </Container>
    </div>
  );
};

export default CookiePolicy;
