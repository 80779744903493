import { useGlobalState } from "../../store/StoreProvider";
import { Link } from "react-router-dom";
const PrivacyPolicyEn = (props) => {
  const { locale } = useGlobalState();
  return (
    <>
      <section className="global-info">
        <div className="content-text">
          <p>Effective Date/Last Updated: February 17, 2023</p>
          <p>
             HAAP Tech Inc. and
            its affiliates (collectivelly, <b>“HAAP”, “we”, “us”</b>, or <b>“our”</b>)
            developed this Privacy Policy to explain how we collect, use, share,
            and protect Personal Information (defined below), and your choices
            about the collection and use of Personal Information.
          </p>

          <p>
            This Privacy Policy applies to Personal Information collected or
            processed through our websites and/or electronic systems for HAAP
            virtual or in-person business and events (collectively, the <b>“Site”</b>).
          </p>

          <p>
            To jump to a section of this Privacy Policy, click a link below:
          </p>

          <ul className="listPolicy">
            <li>1. What is Personal Information? </li>

            <li>2.Types of Personal Information We Collect and Use</li>

            <li>3.How We Use Personal Information</li>

            <li>4.Disclosure of Personal Information</li>

            <li>5.How We Protect the Security of Personal Information</li>

            <li>6.Children’s Privacy</li>

            <li>
              7.Correcting, Updating, Accessing, or Removing Personal
              Information
            </li>

            <li>8.Opting Out</li>

            <li>9.Retention of Personal Information</li>

            <li>
              10.Individuals in the EU, UK, Canada and other Relevant
              Jurisdictions
            </li>

            <li>11.Your California Privacy Rights</li>

            <li>12.Other Websites, Including Social Media</li>

            <li>13.Changes to Our Privacy Policy</li>

            <li>14.How to Contact Us</li>
          </ul>
        </div>

        <div className="content-text">
          <h3>1. What is Personal Information? </h3>
          <p>
            <b>“Personal Information”</b> is information that, either alone or in
            combination with other information, identifies you. Examples of
            Personal Information include, among others, name, email address,
            phone number, and mailing address. When we combine Personal
            Information with other information, we treat the combined
            information as Personal Information.
          </p>
          <p>
            Please note that this Privacy Policy may not apply to the extent
            that we process Personal Information in the role of a processor (or
            comparable role such as “service provider” in certain jurisdictions)
            on behalf of our customers, including where we offer to our
            customers various products and services like cybersecurity, cloud
            services, website acceleration, etc. , through which our customers
            make Personal Information of their customers, employees and vendors
            available to HAAP. In the aforementioned cases, the customer, not
            HAAP, controls what information they collect from you and provide to
            HAAP. For information about HAAP’s customers who use your Personal
            Information, please reach out to such customer directly.
          </p>
  
        </div>
        <div className="content-text">
          <h3>2. Types of Personal Information We Collect and Use</h3>
          <p>a. Information that You Provide Us</p>
          <ul>
            <li>
              <b>Contact Information:</b> In various places on the Site, we request
              information, including Personal Information, from you. The exact
              information we need to collect will depend on the purpose of the
              form. For example, when you complete the contact form on the Site,
              we ask for your full name, company email address, work phone
              number, job title, work industry, and country. We will indicate
              when you attempt to submit a form whether a particular field of
              information is mandatory or optional. If you choose not to provide
              certain information, we may not be able to provide requested
              services.
            </li>

            <li>
              <b>Location Data:</b> While navigating our Site, your mobile device or
              browser may share your location data, both through WiFi and GPS.
              We will collect this information based on the settings of your
              phone and browser, or if you decide to use certain features on our
              Site which utilize your location.
            </li>

            <li>
              <b>Usage Information and Websites and Log Information:</b> The collection
              of this information is further described in the “Cookies and
              Tracking Technologies” subsection below.
            </li>

            <li>
              <b>Social Media:</b> Our Site may link to our pages or accounts on third
              party social media networks (“social media”), and may use social
              media plug-ins which, if you click on them, transmit certain
              personal information to the applicable social media site. If you
              choose to interact with us on social media, we may collect the
              information you publish or submit. Note that this information is
              also governed by the privacy policies of the respective companies
              offering the social media service.
            </li>

            <li>
              <b>Virtual Event or Meetings:</b> If you attend an HAAP in-person or
              virtual event or meeting, we may record some or all of that event
              or meeting. We use this information for business and marketing
              purposes to better inform the public about HAAP and its events.
            </li>
          </ul>
      
        </div>
        <div className="content-text">
          <h3>3. How We Use Personal Information</h3>
          <p>We use Personal Information for purposes of: </p>
          <ul>
            <li>Enabling secure use of the Site; </li>

            <li>
              Providing, analyzing, administering, and improving the Site and
              our products, services and operations;
            </li>

            <li>Research and development; </li>

            <li>Sending information at your request; </li>

            <li>
              Sending information about our products and services that we
              believe you and your organization may find useful;
            </li>

            <li>Responding to inquiries; </li>

            <li>
              Conducting surveys to gather feedback on our services or Site;
            </li>

            <li>
              Marketing and advertising our and our partners’ products and
              services;
            </li>

            <li>
              Publishing comments on our blogs (including the name, but not the
              email address, associated with the author);
            </li>

            <li>Publishing testimonials on the Site; </li>

            <li>Recruiting and hiring personnel; </li>

            <li>Protecting our rights or our property; </li>

            <li>Meeting legal obligations; and</li>

            <li>
              For other purposes related to the reasons for which you provide
              Personal Information.
            </li>
          </ul>
          <p>b. Information We Receive from Your Use of the Site</p>
          <p>
            We collect the following types of information automatically through
            your use of the Site:
          </p>
          <p><b>Cookies, Device Identifiers, and Similar Technologies</b></p>
          <p>
            When you use or access the Site, we, our service providers, and our
            advertisers use cookies, device identifiers, and similar
            technologies such as pixels, web beacons, and local storage to
            collect information about how you use the Site. For more
            information, please see our 
            <Link to={`/${locale}/cookie-policy`}>Cookie Policy</Link>.
          </p>
          <p>
            We process the information collected through such technologies to
            help operate certain features of the Site, to enhance your
            experience through personalization, and to help us better understand
            the features of the Site that you and other users are most
            interested in
          </p>
          <p>
            Some of our service providers may use cookies or other methods to
            gather information regarding your use of the Site, and may combine
            the information in these cookies with any Personal Information about
            you that they may have. The use of such tracking information by a
            third party depends on the privacy policy of that third party. We do
            not respond to Do Not Track (“DNT”) signals sent to us by your
            browser at this time. To learn more about how DNT works, please
            visit 
            <Link to="https://allaboutdnt.com/" target="_blank">
              http://allaboutdnt.com/
            </Link>
            .
          </p>
          <p>
            Most browsers provide you with the ability to block, delete, or
            disable cookies, and your mobile device may allow you to disable
            transmission of unique identifiers and location data. If you choose
            to reject cookies or block device identifiers, some features of the
            Site may not be available or some functionality may be limited or
            unavailable. Please review the help pages of your browser or mobile
            device for assistance with changing your settings.
          </p>
          <ul>
            <li>
              <div><b>Log File Information</b></div>  When you use our Site, our servers
              automatically record information, including your Internet Protocol
              address (“<b>IP Address</b>”), browser type, referring URLs (e.g., the
              site you visited before coming to our Site), number of clicks, and
              how you interact with links on the Site, domain names associated
              with your internet service provider, pages viewed, and other such
              information (collectively, “<b>Log File Information</b>”). We may also
              collect similar information from emails sent to you which then
              help us track which emails are opened and which links are clicked
              by recipients. We use Log File Information to help secure the Site
              by identifying potential threats and vulnerabilities, and to
              analyze the effectiveness of our Site to help improve the Site’s
              function and content.
            </li>

            <li>
              <div><b>Information We Receive from Others</b></div> We may receive Personal
              Information about you from your friends or colleagues who, through
              a “forward-to-a-friend” feature on the Site, invite you to visit
              the Site or to view certain content. We use this information to
              send you a one-time message inviting you to visit the Site and to
              track the success of our referral program.
            </li>
          </ul>
       
        </div>
        <div className="content-text">
          <h3>4. Disclosure of Personal Information</h3>
          <p>
            We will not disclose Personal Information except as set forth in
            this Privacy Policy or with your consent. This section describes to
            whom we disclose Personal Information, and for what purposes:
          </p>
          <ul>
            <li>
              <b>Our Service Providers</b>. We engage service providers or business
              partners to perform tasks on our behalf and to assist us in
              providing the Site or our services. Some of these third parties
              may need to access to Personal Information to perform their
              services. For example, we engage third parties to assist us with
              hosting the Site, sponsoring events, analyzing our Site traffic,
              and marketing our services online. These third parties also assist
              with comprehensive data analytics and managing our customer
              contacts.
            </li>

            <li>
              <b>In-Person and Virtual Business and Events</b>. If you participate in
              HAAP’s in-person or virtual events or otherwise do business with
              HAAP personnel, we may share your contract information with our
              business partners, vendors and sponsors, and perform business
              analytics and send you Communications relating to HAAP’s and its
              business partners’ and sponsors’ products and services. Please see
              the Opt-Out Section of this Privacy Policy for information on how
              to stop receiving such Communications. We collect this information
              from you either in person at our marketing booth or other event,
              or online via a registration process.
            </li>

            <li>
              <b>Companies Involved in Mergers and Acquisitions Transactions</b>. If we
              sell or otherwise transfer part or the whole of our business or
              assets to another organization (e.g., in the course of a
              transaction like a merger, acquisition, bankruptcy, dissolution,
              or liquidation), any information collected through the Site,
              including Personal Information, may be among the items sold or
              transferred.
            </li>

            <li>
              <b>Law Enforcement, Government Agencies, and Courts:</b> We may disclose
              Personal Information at the request of law enforcement or
              government agencies or in response to subpoenas, court orders, or
              other legal process to establish, protect, or exercise our rights
              or to defend against a legal claim or as otherwise required or
              allowed by law, or to protect the rights, property, or safety of
              any other person. We may also disclose Personal Information to
              investigate or prevent a violation by you of any contractual or
              other relationship with us or your illegal or harmful activity.
            </li>

            <li>
             <b> Affiliates</b>. We may disclose some or all of your Personal
              Information to our affiliates, in which case we will require our
              affiliates to comply with this Privacy Policy.
            </li>
          </ul>
       
        </div>
        <div className="content-text">
          <h3>5. How We Protect the Security of Personal Information</h3>
          <p>
            We use commercially reasonable safeguards, such as industry-standard
            encryption technology (e.g., Secure Socket Layer (SSL) protocol), to
            help keep the information collected through the Site secure. Despite
            these efforts to store Personal Information in a secure operating
            environment that is not available to the public, we cannot guarantee
            the security of Personal Information during its transmission or its
            storage on our systems. Further, while we attempt to ensure the
            integrity and security of Personal Information, we cannot guarantee
            that our security measures will prevent third parties such as
            hackers from illegally obtaining access to Personal Information. We
            do not represent or warrant that Personal Information about you will
            be protected against, loss, misuse, or alteration by third parties.
          </p>
        
        </div>
        <div className="content-text">
          <h3>6. Children’s Privacy</h3>
          <p>
            We do not knowingly collect or solicit any Personal Information from
            children or minors (under the age of 18). In the event that we learn
            that we have collected Personal Information from a child without
            parental consent, we will promptly take steps to delete that
            information.
          </p>
        
        </div>
        <div className="content-text">
          <h3>
            7. Correcting, Updating, Accessing, or Removing Personal Information
          </h3>
          <p>
            In addition to other rights you may have depending on where you
            reside (as described below), you can request that we correct,
            update, or delete your Personal Information by emailing a request to
            us at 
            <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link>.
          </p>
          <p>
            We will promptly process such requests in accordance with applicable
            law.
          </p>
          <p>
            With your permission, we and our business partners may send you
            Communications. You may choose to stop receiving Communications by
            indicating your preference in your account profile or settings. You
            may also follow the unsubscribe instructions in any Communication
            you receive. You may also send a global unsubscribe request, and/or
            request to opt out of the sale of your Personal Information, by
            emailing such request to 
            <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link>.
            Please note that certain Site-related Communications are necessary
            for the proper functioning and use of the Site and you may not have
            the ability to opt out of those Communications.
          </p>
       
        </div>
        <div className="content-text">
          <h3>8. Retention of Personal Information</h3>
          <p>
            We will retain your Personal Information for the period necessary to
            fulfil the purposes for which your Personal Information has been
            collected as outlined in this Privacy Policy unless a longer
            retention period is required by law.
          </p>
        
        </div>
        <div className="content-text">
          <h3>
            9. Individuals in the EU, UK, Canada and other Relevant
            Jurisdictions
          </h3>
          <p>
            In this section, we provide additional information relating to how
            we process Personal Information of individuals in the EU, the UK,
            Canada and other relevant jurisdictions in accordance with the GDPR,
            the UK GDPR, Quebec’s Bill 64 and other relevant privacy laws. If
            you need more information or would like to exercise your rights
            under the aforementioned laws, you may contact us
            at  <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link>.
          </p>
          <p>a. Legal Basis for Processing</p>
          <p>
            To the extent required by applicable law in the jurisdictions
            mentioned above in this Section 10, our legal basis for processing
            the Personal Information described in this Privacy Policy will
            depend on the Personal Information concerned and the context in
            which we collect it. We collect and use Personal Information from
            you:
          </p>
          <ul>
            <li>where we need it to perform a contract with you; </li>

            <li>
              where the processing is in our legitimate interests (including the
              purposes described, above, in Section 2, How We Use Personal
              Information);
            </li>

            <li>if we need to comply with a legal obligation; or</li>

            <li>if we otherwise have your consent. </li>
          </ul>
          <p>b. Your Rights and Choices</p>
          <p>
            Depending on applicable law and subject to certain exemptions, you
            may have the right to:
          </p>
          <ul>
            <li><b>Request access</b> to Personal Information about you. </li>

            <li>
              <b>Request correction</b> of the Personal Information that we hold about
              you.
            </li>

            <li>
              <b>Request erasure</b> of Personal Information about you. Note, however,
              that we may not always be able to comply with your request of
              erasure for specific legal reasons which will be notified to you,
              if applicable, at the time of your request.
            </li>

            <li>
              <b>Correct (rectify) your Personal Information</b>. Under certain
              circumstances, you may have the right to request that we amend or
              update inaccurate or incomplete Personal Information about you.
            </li>

            <li>
              <b>Object to processing</b> of Personal Information about you where we
              are relying on a legitimate interest (or those of a third party)
              and there is something about your particular situation which makes
              you want to object to processing.
            </li>

            <li>
              <b>Request restriction of processing</b> of Personal Information about
              you. You can ask us to suspend the processing of Personal
              Information in the following scenarios: (a) if you want us to
              establish the Personal Information’s accuracy; (b) where our use
              of Personal Information is unlawful but you do not want us to
              erase it; (c) where you need us to hold the Personal Information
              even if we no longer require it as you need it to establish,
              exercise, or defend legal claims; or (d) you have objected to our
              use of Personal Information but we need to verify whether we have
              overriding legitimate grounds to use it.
            </li>

            <li>
              <b>Right to the Data Portability</b> of your Personal Information. Under
              certain circumstances, you may have the right to request a copy of
              your Personal Information in an electronic format and further
              request that we send such Personal Information to another party.
            </li>

            <li>
              <b>Withdraw consent</b> at any time where we are relying on consent to
              process Personal Information about you.
            </li>

            <li>
              <b>Update your Communication and Opt-Outs Preferences</b>. You may have
              the right to stop receiving marketing communications from us at
              any time by contacting 
              <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link>
              .
            </li>

            <li>
              <b>Right to Lodge a Complaint</b> with the applicable supervisory
              authority.
            </li>
          </ul>
          <p>
            If you wish to exercise any of these applicable rights, please
            contact us at 
            <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link>.
            You have a right to lodge a complaint with a supervisory authority
            if you believe that we have violated any of your rights concerning
            Personal Information. We encourage you to first reach out to us at 
            <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link>,
            so we have an opportunity to address your concerns directly before
            you do so.
          </p>
          <p>
            Individuals accessing the Site who are California residents should
            also review Section 11 (Your California Rights) for specific details
            on their rights.
          </p>
          <p>c. International Data Transfers</p>
          <p>
            If you are using the Sites from outside the United States, please be
            aware that Personal Information will be transferred to, stored, and
            processed in the United States of America, Canada and/or Mexico as
            well as to countries in which HAAP’s Sub-Processors are located.
            Although we take steps to provide adequate safeguards, the data
            protection laws of the United States of America, Canada or Mexico
            might not be as comprehensive as those in your country. Some of the
            countries where your Personal Information may be processed,
            including the United States, are not subject to an adequacy decision
            by the European Commission or your local legislature and/or
            regulator, and may lack data protection laws as comprehensive as or
            may not provide the same level of data protection as your
            jurisdiction.
          </p>
          <p>
            Where Personal Information is transferred to a country outside of
            the EEA that is not considered to provide adequate protections for
            Personal Information, such Personal Information will be transferred
            using the latest version of the European Union and / or United
            Kingdom’s Standard Contractual Clauses.
          </p>
          <p>
            We endeavor to apply suitable safeguards to protect the privacy and
            security of Personal Information transferred to us, and to use it in
            accordance with the practices described in this Privacy Policy.
          </p>
        
        </div>
        <div className="content-text">
          <h3>10. Your California Privacy Rights</h3>
          <p>
            Subject to certain exceptions, this Section applies to our
            collection and use of Personal Information of residents of
            California, as required by the California Consumer Privacy Act and
            its implementing regulations (<b>“CCPA”</b>), where “Personal Information”
            has the meaning set forth in the CCPA. This section describes (1)
            the categories of your Personal Information collected and disclosed
            by us, subject to CCPA, (2) your privacy rights under CCPA, and (3)
            how to exercise such rights.
          </p>
          <p>
            To receive a copy of this section in an alternate format (e.g.,
            printable) or language, please contact us using the information
            provided below. 
          </p>
          <p>
            We Do Not Sell Personal Information. In the last 12 months we do not
            believe that we have sold Personal Information and we do not believe
            we currently sell Personal Information.
          </p>
          <p>
            Use of Sensitive Personal Information. We do not believe we use or
            disclose sensitive Personal Information. 
          </p>
          <p>
            Personal Information Collected. We have collected the following
            categories of Personal Information from consumers within the last 12
            months:
          </p>
          <p>b. Your Privacy Rights Under California Law</p>
          <p>
            Subject to certain exceptions, California residents may have the
            following rights with respect to their Personal Information:
          </p>
          <p>c. How to Exercise Your California Privacy Rights</p>
          <p>
            Only you, or a person properly authorized to act on your behalf, may
            make a request related to your Personal Information.
          </p>
          <p>The request must: </p>
          <ul>
            <li>
              Provide sufficient information that allows us to reasonably verify
              you are the person about whom we collected Personal Information or
              an authorized representative.
            </li>

            <li>
              Be described with sufficient detail to allows us to properly
              understand, evaluate, and respond to it.
            </li>
          </ul>
          <p>
            We may deny certain requests, or only fulfil some in part, as
            permitted or required by law. For example, if you request to delete
            Personal Information, we may retain Personal Information to the
            extent required by applicable law.
          </p>
          <p>
            To exercise your California rights described in this section, please
            contact us at <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link> or call us at +1.512.514.6183
          </p>
          <p>
            Verification. We may be required to verify your identity before
            fulfilling your requests. If we cannot initially verify your
            identity, we may request additional information to complete the
            verification process, and we will only use Personal Information
            provided in a request to verify the requestor’s identity. If you are
            an authorized agent making a request on behalf of a California
            resident, we may also need to verify your identity and
            authorization. We cannot respond to your request or provide you with
            Personal Information if we cannot verify your identity and confirm
            the Personal Information relates to you. Making a verifiable
            consumer request does not require you to create an account with us.
          </p>
          <p>
            Fees. We do not charge a fee to process or respond to your request
            unless it is excessive, repetitive, or manifestly unfounded. If we
            determine that the request warrants a fee, we will tell you why we
            made that decision and provide you with a cost estimate before
            completing your request.
          </p>
          <p>
            Non-Discrimination. HAAP will not discriminate against you in the
            event you exercise any of the aforementioned rights under the CCPA,
            including, but not limited to, by:
          </p>
          <ul>
            <li>denying goods or services to you; </li>

            <li>
              charging different prices or rates for goods or services,
              including through the use of discounts or other benefits or
              imposing penalties;
            </li>

            <li>
              providing a different level or quality of goods or services to
              you; or
            </li>

            <li>
              suggesting that you will receive a different price or rate for
              goods or services or a different level or quality of goods or
              services.
            </li>
          </ul>
          <p>
            Complaints. The CCPA gives California Consumers the right to lodge a
            complaint with the California Attorney General’s office. The
            Attorney General’s office may be contacted
            at <Link to="https://oag.ca.gov/contact/consumer-complaint-against-business-or-company" target="_blank">https://oag.ca.gov/contact/consumer-complaint-against-business-or-company</Link> or
            by telephone at: (916) 210-6276.
          </p>
          <p>
            Accessibility. The Notice is provided using Website Content
            Accessibility Guidelines and therefore is available to consumers
            with disabilities. To request access to this Notice in an
            alternative format, please email <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link>.
          </p>
          <p>
            In your request, please attest to the fact that you are a California
            resident and provide a current California address for our response.
          </p>
          <p><b>California Shine the Light Law:</b> </p>
          <p>
            Under California Civil Code Section 1798.83, individual customers
            who reside in California and who have an existing business
            relationship with us may request information about our disclosure of
            certain categories of Personal Information to third parties for the
            third parties’ direct marketing purposes, if any.
          </p>
          <p>
            To make such a request, please contact us using the information in
            the Contact Us Section. Please be aware that not all information
            sharing is covered by these California privacy rights requirements
            and only information on covered sharing will be included in our
            response. This request may be made no more than once per calendar
            year.
          </p>
        
        </div>
        <div className="content-text">
          <h3>11. Other Websites, Including Social Media</h3>
          <p>
            We are not responsible for the practices employed by any websites or
            services linked to or from our Site, including the information or
            content contained within them. A link to a third party’s website
            should not be construed as an endorsement. We encourage you to
            investigate and ask questions before disclosing Personal Information
            to third parties.
          </p>
         
        </div>

        <div className="content-text">
          <h3>12. Changes to Our Privacy Policy</h3>
          <p>
            This Privacy Policy is subject to change without notice. If at any
            point, we decide to use Personal Information in a manner materially
            different from that stated at the time it was collected, we will
            notify you via email of the changes to our Policy. In such cases,
            you will have a choice as to whether or not we use your Personal
            Information in this different manner.
          </p>
        
        </div>

        <div className="content-text">
          <h3>13. How to Contact Us</h3>

          <p>For further information, please contact us. </p>
          <p>HAAP Tech Inc. </p>
          <p>7000 North Mopac Expressway, Suite 200, Austin, TX, 78731 USA</p>
          <p>+1.512.514.6183</p>
          <p>Email: <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link></p>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicyEn;
