const TermsOfUsePt = (props) => {
  return (
    <>
      <section className="global-info">
        <div className="content-text">
          <h3> Documentos legais </h3>

          <p>
            
            Ao utilizar o site, você concorda com os termos de uso; se não
            concordar com eles, você não poderá utilizar o site. A HAAP se
            reserva o direito de modificar o conteúdo do site e estes termos de
            uso de tempos em tempos e sem aviso prévio.
          </p>
        </div>

        <div className="content-text">
          <h3> Aviso legal </h3>

          <p>
            
            A HAAP publica este website para nossos clientes, amigos e outros
            visitantes interessados apenas para fins informativos. O conteúdo do
            site não constitui aconselhamento de informações de garantia e
            algumas das informações não refletem necessariamente a opinião do
            escritório, funcionários ou qualquer um de seus advogados ou
            clientes.
          </p>

          <p>
            
            O site fornece informações gerais, que podem ou não ser corretas,
            completas ou atuais no momento da leitura. O conteúdo não se destina
            a substituir conselhos ou opiniões específicas para garantir
            informações. Os destinatários do conteúdo deste site não devem agir
            ou abster-se de agir com base no conteúdo do site sem buscar
            aconselhamento jurídico ou de garantia de informação ou outro
            aconselhamento profissional apropriado.
          </p>

          <p>
            
            A HAAP se isenta expressamente de toda responsabilidade relacionada
            a ações tomadas ou não tomadas com base em qualquer ou todo o
            conteúdo do site. A transmissão de informações de e para este site,
            no todo ou em parte, não cria, e o recebimento não constitui, uma
            relação de cliente entre os remetentes e/ou destinatários e a HAAP.
            Qualquer comunicação através do site ou via e-mail não constitui ou
            cria um relacionamento com o cliente. Além disso, as informações não
            devem ser enviadas à HAAP eletronicamente até que você fale com um
            funcionário sênior e receba autorização para enviar as informações.
            As informações gerais enviadas à HAAP através deste website ou por
            e-mail não são seguras e, portanto, não são confidenciais.
          </p>

          <p>
            
            A HAAP fornece a seus clientes um mecanismo para comunicar
            informações, relatórios, alertas e outras informações relevantes de
            forma segura, e um cliente não deve enviar informações confidenciais
            à HAAP através deste website a menos que tenha revisado as
            instruções escritas da HAAP sobre como se comunicar com segurança.
            Uma vez que a informação tenha sido enviada e recebida com segurança
            pelo cliente, a HAAP não é responsável por sua proteção. A HAAP não
            é responsável por, e não necessariamente endossa, qualquer conteúdo
            de terceiros que possa ser acessível através deste website.
          </p>

          <p>
            A HAAP se isenta expressamente de qualquer responsabilidade por
            ações tomadas ou não tomadas com base em qualquer conteúdo de site
            de terceiros.
          </p>
        </div>

        <div className="content-text">
          <h3>Uso do conteudo do sitio </h3>

          <p>
            Você pode visualizar, baixar e imprimir o conteúdo do site, sujeito
            às seguintes condições:
          </p>

          <ul>
            <li>
              
              O nome HAAP e o aviso de direitos autorais devem aparecer em todas
              as cópias.
            </li>

            <li>
              
              O conteúdo pode ser utilizado apenas para fins informativos.
            </li>

            <li>
              
              O conteúdo não pode ser modificado ou alterado de forma alguma.
            </li>
          </ul>

          <p>
            É proibida a republicação, distribuição, obras derivadas ou qualquer
            outro uso do conteúdo não expressamente permitido aqui. Você não
            pode enquadrar ou utilizar técnicas de enquadramento para anexar
            qualquer marca registrada, logotipo ou outras informações de
            propriedade (incluindo imagens, texto, layout de página ou
            formulário) da HAAP sem a permissão expressa por escrito da
            assessoria jurídica da HAAP.
          </p>

          <p>
            
            Você não pode usar rótulos ou qualquer outro "texto oculto"
            utilizando o nome ou marcas registradas da HAAP sem o consentimento
            expresso por escrito do consultor jurídico da HAAP.
          </p>

          <p>
            
            Você não pode usar qualquer logotipo da HAAP ou outro gráfico ou
            marca registrada como parte do link sem o consentimento expresso por
            escrito do consultor jurídico da HAAP. Você não adquire nenhum
            direito de propriedade sobre nenhum conteúdo do site. Qualquer uso
            não autorizado encerra a permissão ou licença concedida pela
            HAAP.<b>Conducta y obligaciones del usuario</b>
          </p>

          <p>
            
            Você concorda em cumprir com todas as leis e regulamentos aplicáveis
            ao usar este website. Além disso, você concorda em não:
          </p>

          <ul>
            <li>
              Carregar, publicar ou transmitir de outra forma através ou para
              este website qualquer conteúdo que - seja ilegal, abusivo,
              ameaçador, prejudicial, obsceno, obsceno, lascivo, ofensivo,
              difamatório ou de outra forma censurável; - possa infringir os
              direitos de propriedade intelectual, direitos de privacidade,
              direitos de publicidade ou outros direitos de propriedade de
              terceiros; - contenha vírus, cavalos de tróia, bombas-relógio ou
              quaisquer outros programas ou características prejudiciais.
            </li>

            <li>
              
              Perturbar, impor encargos excessivos ou irracionais, interferir ou
              tentar obter acesso não autorizado a qualquer parte de nosso
              website, seus sistemas de computador, servidores ou redes.
            </li>

            <li>
              
              Fornecer-nos informações falsas sobre você, fazer-se passar por
              outra pessoa ou tentar enganar outras pessoas quanto à sua
              identidade ou à origem de qualquer conteúdo, mensagem ou outra
              comunicação.
            </li>

            <li>
              
              Transmitir spam, cartas em cadeia ou outras mensagens não
              solicitadas em massa ou em duplicata.
            </li>

            <li>
              
              Coletar informações sobre outros visitantes de nosso website sem
              seu consentimento ou extrair sistematicamente dados ou campos de
              dados, incluindo, mas não se limitando a, dados financeiros ou
              endereços de e-mail.
            </li>

            <li>
              
              Vender acesso ou uso deste website, incluindo qualquer conteúdo
              incluído, baixado ou acessado a partir deste website.
            </li>

            <li>
              
              Redistribuir qualquer conteúdo, incluindo dados financeiros,
              fornecidos por nós de qualquer forma, inclusive por meio de
              publicação impressa, divulgação de fac-símile, páginas da web,
              e-mail, grupos de notícias ou fóruns da web, ou qualquer outro
              serviço ou método eletrônico ou em papel.
            </li>

            <li>
              
              Alterar intencionalmente o formato no qual fornecemos dados
              financeiros ou de outra forma contornar nossas interfaces padrão
              para tais dados.
            </li>

            <li>
              
              Inserir ou importar quaisquer dados financeiros fornecidos por nós
              em qualquer serviço de informações monetárias (seja ou não baseado
              na web), arquivo de dados ou software aplicativo, incluindo mas
              não limitado a sistemas de contabilidade e folha de pagamento.
            </li>
          </ul>

          <p>
            
            Ao carregar, publicar ou transmitir de outra forma através de ou
            para nosso website qualquer conteúdo, você nos concede, nossos
            sucessores e cessionários, uma licença não exclusiva, mundial, livre
            de royalties, perpétua e não revogável para usar ou distribuir tal
            conteúdo de qualquer outra forma que não seja a estabelecida em
            nossa Política de Privacidade.
          </p>
        </div>

        <div className="content-text">
          <h3> Informações sobre direitos autorais </h3>

          <p>
            
            O Site e seu conteúdo são propriedade da HAAP ou de seus
            fornecedores e são protegidos pelas leis de direitos autorais dos
            Estados Unidos e pelas disposições de tratados internacionais.
          </p>

          <p>
            
            A compilação, organização e apresentação do conteúdo, assim como
            todo software e invenções utilizadas neste site e em conexão com
            ele, são de propriedade exclusiva da HAAP. A HAAP se reserva todos
            os direitos no e para o site e seu conteúdo não concedidos
            especificamente em qualquer acordo com a HAAP ou nos termos de uso.
          </p>
        </div>

        <div className="content-text">
          <h3> Informações sobre a marca </h3>

          <p>
            As marcas registradas, gráficos, logotipos, cabeçalhos de página,
            ícones de botões e scripts da HAAP que aparecem neste site são
            marcas de serviço, marcas comerciais e imagem comercial da HAAP e
            são de propriedade única e exclusiva da HAAP.
          </p>

          <p>
            Todas as outras marcas registradas que não pertencem à HAAP ou suas
            afiliadas que aparecem neste site são propriedade de seus
            respectivos proprietários, que podem ou não ser afiliados,
            conectados ou patrocinados pela HAAP ou suas afiliadas.
          </p>
        </div>

        <div className="content-text">
          <h3> Produtos e serviços oferecidos pela HAAP neste site </h3>

          <p>
            
            A HAAP oferece produtos e serviços no Site. Quando você obtém um
            produto ou serviço da HAAP no Site, você aceita o acordo específico
            aplicável a esse produto ou serviço.
          </p>

          <p>
            Seu uso de qualquer um dos produtos ou serviços oferecidos no Site é
            regido pelos termos e condições do acordo aplicável a esse produto
            ou serviço. Exceto conforme previsto em tal acordo, a HAAP não
            garante que as descrições de produtos ou conteúdo deste site sejam
            precisas, atuais, confiáveis, completas ou livres de erros.
          </p>
        </div>

        <div className="content-text">
          <h3> Isenção de responsabilidade </h3>

          <p>
            HAAP NÃO FAZ REPRESENTAÇÕES SOBRE A ADEQUAÇÃO DAS INFORMAÇÕES
            CONTIDAS E GRÁFICOS RELACIONADOS PUBLICADOS NESTE SITE PARA QUALQUER
            FINALIDADE. TODAS ESSAS INFORMAÇÕES E GRÁFICOS RELACIONADOS SÃO
            FORNECIDOS "COMO ESTÃO", SEM GARANTIA DE QUALQUER TIPO. A HAAP SE
            ISENTA DE TODAS AS GARANTIAS E CONDIÇÕES COM RELAÇÃO A ESTAS
            INFORMAÇÕES, INCLUINDO TODAS AS GARANTIAS IMPLÍCITAS DE
            COMERCIABILIDADE, ADEQUAÇÃO A UMA FINALIDADE ESPECÍFICA, TÍTULO E
            NÃO-INFRAÇÃO. EM NENHUMA HIPÓTESE A HAAP SERÁ RESPONSÁVEL POR
            QUAISQUER DANOS ESPECIAIS, INDIRETOS OU CONSEQÜENTES OU QUAISQUER
            DANOS RESULTANTES DE PERDA DE USO, DADOS OU LUCROS, SEJA EM UMA AÇÃO
            DE CONTRATO, NEGLIGÊNCIA OU OUTRA AÇÃO ILÍCITA, DECORRENTE DE OU EM
            CONEXÃO COM O USO OU DESEMPENHO DAS INFORMAÇÕES DISPONÍVEIS NESTE
            WEBSITE.
          </p>
        </div>

        <div className="content-text">
          <h3> Indenicação </h3>

          <p>
            
            Você concorda em defender, indenizar e isentar a HAAP, funcionários,
            advogados e agentes ("Indenizados") de e contra todas as
            reivindicações, despesas, responsabilidades, perdas, custos e danos,
            incluindo honorários razoáveis de advogados, que os Indenizados
            possam incorrer em conexão com seu uso do Site ou de qualquer link
            do Site.
          </p>
        </div>
      </section>
    </>
  );
};

export default TermsOfUsePt;
