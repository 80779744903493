import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import appApi from "../../assets/img/branding/cards/delivery/App_APIPerformance.png";
import media from "../../assets/img/branding/cards/delivery/MediaDelivery.png";

const contentDeliveryData = {
    videBgDelivery:{
        clip:"",
        title:"Distribución de contenido",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteDelivery:{
        headBandshowTitle:true,
        headBandTitle:"",
        classNameHead:"",
        headBandtext:"<span>Cree experiencias digitales extraordinarias</span>. Confíe en la agilidad y escalabilidad de Akamai Intelligent Edge para ayudarle a ofrecerlas a la perfección.",
        
    },
    cardsDelivery:{
        title:"",
        text:"",
        items:[
            {
                img:appApi,
                title:"Rendimiento de aplicaciones & API",
                label:"Atraiga a clientes con un rendimiento rápido y atractivo de API y de las aplicaciones, mejorado gracias a unos datos sólidos y a la automatización.",
                url:"/app-and-api-performance"
            },
            {
                img:media,
                title:"Distribución de contenido multimedia",
                label:"Ofrezca experiencias de streaming increíbles y de forma constante a un gran número de personas, en cualquier dispositivo.",
                url:"/distribucion-de-contenido-multimedia"
            },
            
        ]
    },
    formVideoDelivery:{
        row:3,
        placeholderMessage:"Message",
        typeEmail:"email",
        size:"md",
        placeholderEmail:"Email",
        idEmail:"email",
        requiredEmail:true,
        classNameInput:"form-basic",
        typeName:"text",
        placeholderName:"First Name",
        idName:"name",
        requiredName:true,
        placeholderLastName:"Last Name",
        typeLastName:"text",
        idLastName:"lastname",
        requiredLastName:true,
        typeButton:"submit",
        textButton:"Send",

        urlVideo:"",
        show:false,
    },
    urlFormDelivery:"/hable-con-nosotros",
    texBtntFormDelivery:"Hable con un experto",
}

export default contentDeliveryData;