import { useEffect, useState } from "react";
import { useGlobalState, useDispatch } from "./store/StoreProvider";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import languageUtils from "./utils/languageUtils";
import constants from "./constants";
import data from "./data";
import localeAction from "./actions/localeAction";
import LayoutComponent from "./pages/shared/LayoutComponent";
import HomePage from "./pages/HomePage";
import Security from "./pages/Security";
import ContentDelivery from "./pages/ContentDelivery";
import Implementation from "./pages/Implementation";
import CloudComputing from "./pages/CloudComputing";
import TalkWithUs from "./pages/TalkWithUs";
import WhyAkamai from "./pages/WhyAkamai";
import CookiePolicy from "./pages/CookiePolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import InfrastructureSecurity from "./pages/InfrastructureSecurity";
import FraudProtection from "./pages/FraudProtection"
import ApplicationSecurity from "./pages/ApplicationSecurity"
import SecurityZeroTrust from "./pages/SecurityZeroTrust"
import ApiApplicationPerformance from "./pages/ApiApplicationPerformance"
import MultimediaContentDistribution from "./pages/MultimediaContentDistribution"
import PDFViewerPage from "./pages/PDFViewerPage"

import "./theme/main-boostrap.scss";
import "./theme/theme-main.scss";

function App() {
  const { locale } = useGlobalState();
  const dispatch = useDispatch();
  const getHomePage = (datasSite) => {
    const data = datasSite.siteRouter.find(item => item.homePage);

    return data;
  }
  const [datas, setDatas] = useState(data[locale]);
  const [homePageData, setHomePageData] = useState(getHomePage(data[locale]))

  useEffect(() => {
    const pathLocale = window.location?.pathname?.toLowerCase();
    const auxLocale = languageUtils.getLanguagueToPath(
      pathLocale,
      constants.language.EN
    );
    localeAction.updateLocale(auxLocale, dispatch);
    setDatas(data[locale]);
    setHomePageData(getHomePage(data[locale]));
  }, [locale]);

  const selectPage = (item, position) => {
    switch(item.keypage) {
      case "security-page":
        return <LayoutComponent positionPage={position} metasPage={item} component={Security} />
      case "content-delivery-page":
        return <LayoutComponent positionPage={position} metasPage={item} component={ContentDelivery} />
      case "implementation-page":
        return <LayoutComponent positionPage={position} metasPage={item} component={Implementation} />
      case "cloud-computing-page":
        return <LayoutComponent positionPage={position} metasPage={item} component={CloudComputing} />
      case "talk-with-us-page":
        return <LayoutComponent positionPage={position} metasPage={item} component={TalkWithUs} />
      case "why-akamai-page":
        return <LayoutComponent positionPage={position} metasPage={item} component={WhyAkamai} />
      case "cookie-policy-page":
        return <LayoutComponent positionPage={position} metasPage={item} component={CookiePolicy} />
      case "privacy-policy-page":
        return <LayoutComponent positionPage={position} metasPage={item} component={PrivacyPolicy} />  
      case "terms-of-use-page":
        return <LayoutComponent positionPage={position} metasPage={item} component={TermsOfUse} /> 
      case "infrastructure-security":
        return <LayoutComponent positionPage={position} metasPage={item} component={InfrastructureSecurity} /> 
      case "application-security":
        return <LayoutComponent positionPage={position} metasPage={item} component={ApplicationSecurity} /> 
      case "security-zero-trust":
        return <LayoutComponent positionPage={position} metasPage={item} component={SecurityZeroTrust} /> 
      case "fraud-protection":
        return <LayoutComponent positionPage={position} metasPage={item} component={FraudProtection} /> 
      case "api-application-performance":
        return <LayoutComponent positionPage={position} metasPage={item} component={ApiApplicationPerformance} /> 
      case "multimedia-content-distribution":
        return <LayoutComponent positionPage={position} metasPage={item} component={MultimediaContentDistribution} /> 
      case "product-detail":
        return <LayoutComponent positionPage={position} metasPage={item} component={PDFViewerPage} /> 
      default:
        return <LayoutComponent positionPage={position} metasPage={item} component={HomePage} />
    }
  }
  
  return (
    <>
      <Helmet htmlAttributes={{ lang: locale }} />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<LayoutComponent metasPage={homePageData} positionPage={0} component={HomePage} />}
          />
          {datas &&
            datas.siteRouter.map((item, i) => {
              return (
                <Route
                  key={i}
                  path={`/${locale}${item.basename === "/" ? "" : item.basename}`}
                  element={selectPage(item, i)}
                />
              );
            })}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
