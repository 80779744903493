import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import imgProtection from "../../assets/img/branding/cards/Security.webp";
import imgDelivery from "../../assets/img/branding/cards/Distribucion-de-Contenido.webp";
import imgImplementation from "../../assets/img/branding/cards/Implementation.webp";
import imgCompute from "../../assets/img/branding/cards/CloudComputing.webp";
import partners from '../../dataPartners/partners';
import bgGeneral from "../../assets/img/branding/landing/homeGeneral.jpeg";

const homeData = {
    videBg:{
        clip:"",
        showContentText:false,
        poster: bgGeneral,
        title:"",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSite:{
        headBandshowTitle:false,
        headBandTitle:"",
        classNameHead:"headband-white",
        headBandtext:"What would it do for your business, if you had the <span>customer experience</span>  and <span>performance</span> of the world’s biggest <span>e-commerce</span> platforms, with the security technology of a global bank, at a price tailored to you? ",
        
    },
    cardsHome:{
        title:"What we <span>offer</span>",
        text:"A full suite of products and services to develop, host, deliver and secure your online presence and rich media content",
        items:[
            {
                img:imgProtection,
                title:"Security",
                label:"Outsmart the most sophisticated threats. Stay safe without compromising performance. ",
                url:"/security"
            },
            {
                img:imgDelivery,
                title:"Content Delivery (CDN)",
                label:"Make digital magic. Flawlessly deliver your dynamic content - even at peak demand.",
                url:"/content-delivery"
            },
            // {
            //     img:imgCompute,
            //     title:"Cloud Computing",
            //     label:"Boost performance, speed innovation. Build, run, and secure applications and workloads everywhere your business connects online.",
            //     url:"/cloud-computing"
            // },
            {
                img:imgImplementation,
                title:"Implementation",
                label:"Let HAAP Tech identify and implement the best solutions and strategies for your needs ",
                url:"/implementation"
            },
            
        ]
    },
    partners:{
        showComponent:false,
        title:"<span>Top companies</span> that trust and work with <span>Akamai</span>",
        list:[
            {
                label:"Media and Entertainment",
                img:partners.top1,
                imgResponsive:partners.top1Mobile,
                alt:"Media and Entertainment",

            },
            {
                label:"Financial services",
                img:partners.top2,
                imgResponsive:partners.top2Mobile,
                alt:"Financial services",

            },
            {
                label:"Hotel and Travel",
                img:partners.top3,
                imgResponsive:partners.top3Mobile,
                alt:"Hotel and Travel",

            },
            {
                label:"Health care and life sciences",
                img:partners.top4,
                imgResponsive:partners.top4Mobile,
                alt:"Health care and life sciences",

            },
            {
                label:"Tech",
                img:partners.top5,
                imgResponsive:partners.top5Mobile,
                alt:"Tech",

            },
            {
                label:"Goverment",
                img:partners.top6,
                imgResponsive:partners.top6Mobile,
                alt:"Goverment",
            },
            {
                label:"Automotive",
                img:partners.top7,
                imgResponsive:partners.top7Mobile,
                alt:"Automotive",
            },
            {
                label:"Gaming",
                img:partners.top8,
                imgResponsive:partners.top8Mobile,
                alt:"Gaming",
            },
            {
                label:"Publishing",
                img:partners.top9,
                imgResponsive:partners.top9Mobile,
                alt:"Publishing",
            },
            {
                label:"Other",
                img:partners.top10,
                imgResponsive:partners.top10Mobile,
                alt:"Other",
            }
        ],
    },
    formVideo:{
        row:3,
        placeholderMessage:"Message",
        typeEmail:"email",
        size:"md",
        placeholderEmail:"Email",
        idEmail:"email",
        requiredEmail:true,
        classNameInput:"form-basic",
        typeName:"text",
        placeholderName:"First Name",
        idName:"name",
        requiredName:true,
        placeholderLastName:"Last Name",
        typeLastName:"text",
        idLastName:"lastname",
        requiredLastName:true,
        typeButton:"submit",
        textButton:"Send",

        urlVideo:"",
        show:false,
    }
}

export default homeData;