import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';


const multimediaContentDistribution = {
    videBgMultimediaContentDistribution:{
        clip:"",
        poster:"",
        title:"Solución de distribución de contenido (CDN)",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteMultimediaContentDistribution:{
        headBandshowTitle:true,
        headBandTitle:"<span>Cautive</span> a millones de espectadores. Haga que vuelvan.",
        classNameHead:"",
        headBandtext:"Ofrezca experiencias de streaming increíbles y de forma constante a un gran número de personas, en cualquier dispositivo. Consiga un rendimiento máximo en los mayores eventos. Anticípese a los costes de la infraestructura de nube. Tome decisiones estratégicas basadas en información compleja.",
        
    },
    cardsMultimediaContentDistribution:{
        title:"",
        text:"Soluciones de <span>distribución</span> de contenido multimedia",
        items:[
            {
                img:"",
                title:"Adaptive Media Delivery",
                label:"Ofrezca vídeos online consistentes y de alta calidad de forma segura y a gran escala.",
                url:"/detalle/adaptive-media-delivery",
                textUrl:""
            },
            {
                img:"",
                title:"Cloud Wrapper",
                label:"Ahorre dinero y mejore la descongestión del origen con un espacio de caché privado.",
                url:"/detalle/cloud-wrapper",
                textUrl:""
            },
            {
                img:"",
                title:"CloudTest",
                label:"Ponga a prueba su sitio web y sus aplicaciones realizando pruebas de carga sólidas y en tiempo real.",
                url:"/detalle/cloud-test",
                textUrl:""
            }, 
            {
                img:"",
                title:"DataStream",
                label:"Conozca de primera mano el rendimiento de la red de distribución de contenido (CDN) con datos de registro a escala.",
                url:"/detalle/datastream",
                textUrl:""
            },    
            {
                img:"",
                title:"Entrega/distribución dedicada",
                label:"Ofrezca experiencias en tiempo real de gran calidad con una distribución en el edge de Internet de alto rendimiento.",
                url:"/detalle/delivery-distribution",
                textUrl:""
            },
            {
                img:"",
                title:"Download Delivery",
                label:"Ofrezca descargas de archivos de gran tamaño sin problemas, en todo momento y a escala global.",
                url:"/detalle/download-delivery",
                textUrl:""
            }
            ,
            {
                img:"",
                title:"Media Analytics",
                label:"Analice el rendimiento de la distribución de vídeo, la participación de los usuarios y el comportamiento de la audiencia.",
                url:"/detalle/media-analytics",
                textUrl:""
            },
            {
                img:"",
                title:"Media Services Live",
                label:"Brinde una experiencia de retransmisión de alta calidad a los espectadores de streaming en directo.",
                url:"/detalle/media-services-live",
                textUrl:""
            }
            ,
            {
                img:"",
                title:"NetStorage",
                label:"Guarde y proteja el contenido con el almacenamiento en la nube más distribuido a nivel mundial.",
                url:"/detalle/netstorage",
                textUrl:""
            }
        ]
    },
    urlFormMultimediaContentDistribution:"/hable-con-nosotros",
    texBtntFormMultimediaContentDistribution:"Hable con un experto",
}

export default multimediaContentDistribution;