import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import img1 from "../../assets/img/branding/cards/compute/CloudComputing.jpg";
import img3 from "../../assets/img/branding/cards/compute/EdgeApplications-1.jpg";
import img2 from "../../assets/img/branding/cards/compute/EdgeApplications.jpg";


const buttonDataLink ={
 title:"Talk to an expert",
 url:"/talk-with-us"
}

const couldComputingData = {
    videBgCouldComputing:{
        clip:"",
        title:"Cloud Computing",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteCouldComputing:{
        headBandshowTitle:true,
        headBandTitle:"",
        classNameHead:"",
        headBandtext:"Increase enterprise performance and speed to market with developer-friendly cloud computing services running on <span>Akamai Connected Cloud.</span>",
        
    },
    cardsCouldComputing:{
        title:"",
        text:"",
        items:[
            {
                img:img1,
                title:"Low latency and high availability",
                label:"Bring applications and workloads closer to users on our massively distributed cloud and edge platform.",
                url:"/#"
                // url:"/detail/cloud-computing"
            },
            {
                img:img2,
                title:"Developer productivity and innovation",
                label:"Empower developers to use the tools and platforms of their choice without special certifications.",
                url:"/#"
                // url:"/detail/cloud-computing"
            },
            {
                img:img3,
                title:"Cost-effective and trusted",
                label:"Optimize cloud costs with no lock-in, a low cost-per-performance profile, and proven 24/7/365 support.",
                url:"/#"
                // url:"/detail/cloud-computing"
            },
          
        ]
    },
    formVideoCouldComputing:{
        row:3,
        placeholderMessage:"Message",
        typeEmail:"email",
        size:"md",
        placeholderEmail:"Email",
        idEmail:"email",
        requiredEmail:true,
        classNameInput:"form-basic",
        typeName:"text",
        placeholderName:"First Name",
        idName:"name",
        requiredName:true,
        placeholderLastName:"Last Name",
        typeLastName:"text",
        idLastName:"lastname",
        requiredLastName:true,
        typeButton:"submit",
        textButton:"Send",

        urlVideo:"",
        show:false,
    },
    urlFormcouldComputing:buttonDataLink.url,
    texBtntFormCouldComputing: buttonDataLink.title,
}

export default couldComputingData;