import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import img1 from "../../assets/img/branding/cards/compute/CloudComputing.jpg";
import img3 from "../../assets/img/branding/cards/compute/EdgeApplications-1.jpg";
import img2 from "../../assets/img/branding/cards/compute/EdgeApplications.jpg";

const couldComputingData = {
    videBgCouldComputing:{
        clip:"",
        title:"Computação em nuvem",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteCouldComputing:{
        headBandshowTitle:true,
        headBandTitle:"",
        classNameHead:"",
        headBandtext:"Aumente o desempenho empresarial e a velocidade de entrada no mercado com serviços de computação em nuvem para desenvolvedores em execução na <span>Akamai Connected Cloud.</span>",       
    },
    cardsCouldComputing:{
        title:"",
        text:"",
        items:[
            {
                img:img1,
                title:"Baixa latência e alta disponibilidade",
                label:"Aproxime as aplicações e cargas de trabalho dos usuários em nossa plataforma de nuvem e de edge amplamente distribuída.",
                url:"/#"
                // url:"/detalhes/cloud-computing"
            },
            {
                img:img2,
                title:"Produtividade e inovação para o desenvolvedor",
                label:"Capacite os desenvolvedores a usarem as ferramentas e plataformas que quiserem sem certificações especiais.",
                  url:"/#"
                // url:"/detalhes/cloud-computing"
            },
            {
                img:img3,
                title:"Econômico e confiável",
                label:"Otimize os custos da nuvem sem dependência, com um perfil de baixo custo por desempenho e suporte comprovado 24 horas por dia, 7 dias por semana, durante o ano todo.",
                  url:"/#"
                // url:"/detalhes/cloud-computing"
            },           
        ]
    },
    formVideoCouldComputing:{
        row:3,
        placeholderMessage:"Message",
        typeEmail:"email",
        size:"md",
        placeholderEmail:"Email",
        idEmail:"email",
        requiredEmail:true,
        classNameInput:"form-basic",
        typeName:"text",
        placeholderName:"First Name",
        idName:"name",
        requiredName:true,
        placeholderLastName:"Last Name",
        typeLastName:"text",
        idLastName:"lastname",
        requiredLastName:true,
        typeButton:"submit",
        textButton:"Send",

        urlVideo:"",
        show:false,
    },
    urlFormcouldComputing:"/fale-com-a-gente",
    texBtntFormCouldComputing:"Fale com um especialista",
}

export default couldComputingData;