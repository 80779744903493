import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';

const moreInformation=""
const buttonDataLink ={
 title:"Talk to an expert",
 url:"/talk-with-us"
}

const infrastructureSecurityData = {
    videBgInfrastructureSecurity:{
        clip:"",
        poster:"",
        title:"DDoS Protection",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteInfrastructureSecurity:{
        headBandshowTitle:true,
        headBandTitle:"Defend <span>against</span>  today’s most <span>sophisticated</span>  attacks",
        classNameHead:"",
        headBandtext:""
        // headBandtext:"Modern businesses are constantly under threat from sophisticated and well-coordinated DDoS attacks. Our DDoS protection, built on dedicated infrastructure, safeguards your internet-facing applications and systems while ensuring fast, highly secure, and always-available DNS.<br/> With our advanced solutions, you can prevent DDoS attacks and block malicious traffic in the cloud before it reaches your applications, data centers, and infrastructure, all without the need for multiple firewalls.",
        
    },
    cardsInfrastructureSecurity:{
        title:"",
        text:"Modern businesses are constantly under threat from sophisticated and well-coordinated DDoS attacks. Our DDoS protection, built on dedicated infrastructure, safeguards your internet-facing applications and systems while ensuring fast, highly secure, and always-available DNS."+
        "<br/><br/> With our advanced solutions, you can prevent DDoS attacks and block malicious traffic in the cloud before it reaches your applications, data centers, and infrastructure, all without the need for multiple firewalls.",
        items:[
            // {
            //     img:"",
            //     title:"App & API Performance",
            //     label:"One-stop, zero-compromise security for websites, applications, and APIs.",
            //     url:"/detail/api-performance",
            //     textUrl:moreInformation
            // },
            // {
            //     img:"",
            //     title:"Prolexic",
            //     label:"Stop DDoS at any scale, anywhere. On-demand, always-on, in-cloud, on-prem, or hybrid.",
            //     url:"/detail/prolexic",
            //     textUrl:moreInformation
            // },
            // {
            //     img:"",
            //     title:"Edge DNS",
            //     label:"Rely on highly secure DNS for nonstop availability of web apps and APIs.",
            //     url:"/detail/edge-dns",
            //     textUrl:moreInformation
            // }
        ]
    },
    urlFormInfrastructureSecurity:buttonDataLink.url,
    texBtntFormInfrastructureSecurity:buttonDataLink.title,
}

export default infrastructureSecurityData;