import { useIntl } from "react-intl";
import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useGlobalState } from "../store/StoreProvider";
import BgVideo from "@mzp/commons/dist/layout/BgVideo";
import Cards from '@mzp/commons/dist/surfaces/Cards';
import FormBasic from '@mzp/commons/dist/forms/FormBasic';
import image from "../assets/img/branding/landing/implementation.jpg";

const Implementation = (props) => {
  const {resourceSite } = props;
  const intl = useIntl();
  const { locale } = useGlobalState();
  const [scrollItems, setScrollItems] = useState(false);

  const changeTransitionItems = () => {
      const elem = document.querySelector("#itemsoffer");
      var windowheight= window.scrollY;
      var itemTop =  (elem != null && elem !== undefined && elem && elem.offsetTop) ? elem.offsetTop : 0;;
      if(windowheight > (itemTop - 650)){
        setScrollItems(true)
      }else{
        setScrollItems(false)
      }
  }

  window.addEventListener('scroll', changeTransitionItems);


  return (
    <div className="implementation-section">
        {resourceSite.videBgImplementation.clip ? (
            <BgVideo
            clip={resourceSite.videBgImplementation.clip}
            poster={resourceSite.videBgImplementation.poster}
            title={resourceSite.videBgImplementation.title}
            text={resourceSite.videBgImplementation.text}
            iconDirection={resourceSite.videBgImplementation.iconDirection}
            locale={locale}
          />
        ) : (
          <div className="bg-image bg-protection" style={{ backgroundImage:`url(${image})` }}>
            <h2>{resourceSite.videBgImplementation.title}</h2>
            <div className="overlay"></div>
           </div>
        )}
      <section className="what-we-offer">
            <Container>     
                <div className={`what-we-offer-list-card  ${scrollItems ? 'activeAnimation' : ''}`} id="itemsoffer">                   
                    {resourceSite.cardsImplementation.items && resourceSite.cardsImplementation.items.map((item, index) => {
                        return <Cards
                            key={index}
                            image={item.img}
                            classNameCard="card-haap"
                        >
                            <div className='card-content-body'>
                                <h3>{item.title}</h3>
                                <p>{item.label}</p>  
                                
                                <div className='line-border'></div>  
                            </div>
                            <Link to={item.url} reloadDocument className='card-url'></Link>    
                        </Cards>
                       })}
                                       
                </div>               
            </Container>        
        </section>
        <Link className="btn-talk" to={`/${locale}${resourceSite.urlFormImplementation}`}>{resourceSite.texBtntFormImplementation}</Link>
        {resourceSite.formVideoImplementation.show && (
          <section className='contact-video'>
          <Container>
            <Row>
                <Col md={6} className="order-md-1 g-0 ">
                    <div className='min-video'>
                    <video autoPlay loop muted poster={resourceSite.videBgImplementation}>
                        <source src={resourceSite.formVideoImplementation.urlVideo} type='video/mp4' />
                        <source src={resourceSite.formVideoImplementation.urlVideo} type="video/ogg" /> 
                    </video>
                    </div>                    
                </Col>
                <Col md={6} className="order-md-0 rectangle">
                    <div className='form-landing'>
                        <h4>{intl.formatMessage({ id: "general.contactUs" })}</h4>
                        <p>{intl.formatMessage({ id: "general.getcontact" })}</p>
                        <FormBasic 
                            row={resourceSite.formVideoImplementation.row}
                            placeholderMessage={resourceSite.formVideoImplementation.placeholderMessage}
                            typeEmail={resourceSite.formVideoImplementation.typeEmail}
                            size={resourceSite.formVideoImplementation.size}
                            placeholderEmail={resourceSite.formVideoImplementation.placeholderEmail}
                            idEmail={resourceSite.formVideoImplementation.idEmail}
                            requiredEmail={resourceSite.formVideoImplementation.requiredEmail}
                            classNameInput={resourceSite.formVideoImplementation.classNameInput}
                            typeName={resourceSite.formVideoImplementation.typeName}
                            placeholderName={resourceSite.formVideoImplementation.placeholderName}
                            idName={resourceSite.formVideoImplementation.idName}
                            requiredName={resourceSite.formVideoImplementation.requiredName}
                            typeLastName={resourceSite.formVideo.typeLastName}
                            placeholderLastName={resourceSite.formVideo.placeholderLastName}
                            idLastName={resourceSite.formVideo.idLastName}
                            requiredLastName={resourceSite.formVideo.requiredLastName}
                            textButton={resourceSite.formVideo.textButton}
                            typeButton={resourceSite.formVideoImplementation.typeButton}
                        />
                    </div>
                </Col>                
            </Row>
          </Container>                         
          </section>
        )}
       
    </div>
  );
};

export default Implementation;
