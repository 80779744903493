import { useGlobalState } from "../../store/StoreProvider";
import TermsOfUseEn from "./TermsOfUseEn";
import TermsOfUseEs from "./TermsOfUseEs";
import TermsOfUsePt from "./TermsOfUsePt";

const TermsOfUseText = (props) => {
  const { locale } = useGlobalState();
  return (
    <>
        {locale == 'en' &&(
            <TermsOfUseEn />
        )}
         {locale == 'es' &&(
            <TermsOfUseEs />
        )}
         {locale == 'pt' &&(
            <TermsOfUsePt />
        )}
        
      
    </>
  );
};

export default TermsOfUseText;
