import general from "./general";
import website from "./website";


export default {
    "es": Object.assign({},
       website,
       general,

    )
 };