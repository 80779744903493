import { useIntl } from "react-intl";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import image from "../assets/img/branding/landing/implementation.jpg";
import Pdfviewer from "@mzp/commons/dist/PdfViewer/PdfViewer";
import { useMediaQuery } from "react-responsive";
import breakpointConstants from "../constants/breakpointConstants";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { pdfjs } from "react-pdf";

const PDFViewerPage = (props) => {
  const { resourceSite } = props;
  const isMobile = useMediaQuery({ maxWidth: breakpointConstants.LG });
  const isTablet = useMediaQuery({ maxWidth: breakpointConstants.XL });
  let { id } = useParams();
  const [pageData, setPageData] = useState(null);

  const intl = useIntl();
  useEffect(() => {
    window.scrollTo(0, 0);
    DataContentPage();
  }, []);
  // PDF library URL
  //`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  // //cdnjs.cloudflare.com/ajax/libs/pdf.js/2.4.456/pdf.worker.js dev 
  // //cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js prod 

  let pdfVersion = pdfjs.version;
  let url =  process.env.NODE_ENV === 'production' 
  ?  window.location.origin + "/pdf.worker.2.12.313.js" : window.location.origin + "/pdf.worker.js";

  const pdfworkerPath = url;

  const DataContentPage = () => {

    const pagedataPdf = resourceSite.dataPDF.filter((x) => x.key === id);

    if (pagedataPdf.length > 0) {
      setPageData({
        title: pagedataPdf[0]?.title,
        file: pagedataPdf[0]?.pdffile,
      });
    }
  };

  return (
    <div className="informative-section">
      <div
        className="bg-image bg-protection"
        style={{ backgroundImage: `url(${image})` }}
      >
        <h2>{pageData?.title}</h2>
        <div className="overlay"></div>
      </div>
      <Container>
        <section className="Pdfviewer">
          {!isMobile && !isTablet && (
            <Pdfviewer
              file={pageData?.file}
              showControls={false}
              showAllPages={true}
              pdfscale={2}
              pdfworkerPathFile={pdfworkerPath}
            />
          )}

          {isMobile && (
            <Pdfviewer
              file={pageData?.file}
              showControls={false}
              showAllPages={true}
              pdfscale={0.6}
              pdfworkerPathFile={pdfworkerPath}
            />
          )}

          {isTablet && !isMobile && (
            <Pdfviewer
              file={pageData?.file}
              showControls={false}
              showAllPages={true}
              pdfscale={1.5}
              pdfworkerPathFile={pdfworkerPath}
            />
          )}
        </section>
      </Container>
    </div>
  );
};

export default PDFViewerPage;
