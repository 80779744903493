import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';


const apiApplicationPerformanceData = {
    videBgApiApplicationPerformance:{
        clip:"",
        poster:"",
        title:"Solución de distribución de contenido (CDN)",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteApiApplicationPerformance:{
        headBandshowTitle:true,
        headBandTitle:"Cree <span>experiencias</span> digitales extraordinarias.",
        classNameHead:"",
        headBandtext:"Confíe en la agilidad y escalabilidad de Akamai Intelligent Edge para ayudarle a ofrecerlas a la perfección. Atraiga a clientes con un rendimiento rápido y atractivo de API y de las aplicaciones, mejorado gracias a unos datos sólidos y a la automatización.",
        
    },
    cardsApiApplicationPerformance:{
        title:"",
        text:"Soluciones de <span>rendimiento</span> de aplicaciones y API",
        items:[
            {
                img:"",
                title:"API Acceleration",
                label:"Visualice, proteja y segmente entornos locales, en la nube e híbridos.",
                url:"/detalle/api-acceleration",
                textUrl:""
            },
            {
                img:"",
                title:"CloudTest",
                label:"Ponga a prueba su sitio web y sus aplicaciones realizando pruebas de carga sólidas y en tiempo real.",
                url:"/detalle/cloud-test",
                textUrl:""
            },
            {
                img:"",
                title:"DataStream",
                label:"Conozca de primera mano el rendimiento de la red de distribución de contenido (CDN) con datos de registro a escala.",
                url:"/detalle/datastream",
                textUrl:""
            },
            {
                img:"",
                title:"Global Traffic Management",
                label:"Optimice el rendimiento de las aplicaciones y evite interrupciones con el equilibrio inteligente de cargas.",
                url:"/detalle/global-traffic-management",
                textUrl:""
            },
            {
                img:"",
                title:"Image & Video Manager",
                label:"Optimice y mejore automáticamente el contenido visual para cada usuario, en cualquier dispositivo.",
                url:"/detalle/image-video-manager",
                textUrl:""
            }
            ,
            {
                img:"",
                title:"Ion",
                label:"Brinde experiencias de usuario rápidas, atractivas y fiables en sus sitios web y aplicaciones.",
                url:"/detalle/ion",
                textUrl:""
            },
            {
                img:"",
                title:"mPulse",
                label:"Recoja datos de experiencias de usuario. Optimice el rendimiento de los sitios web. Mida el retorno de la inversión.",
                url:"/detalle/mpulse",
                textUrl:""
            }
        ]
    },
    urlFormApiApplicationPerformance:"/hable-con-nosotros",
    texBtntFormApiApplicationPerformance:"Hable con un experto",
}

export default apiApplicationPerformanceData;