const iconContants = {
    HOTEL: "HOTEL",
    PACKAGE:"PACKAGE",
    ACTIVITY: "ACTIVITY",
    SHUTTER: "SHUTTER",
    OFFSALE: "OFFSALE",
    OUTLINEMENU: "OUTLINEMENU",
    LOCATION:"LOCATION",
    SEARCH: "SEARCH",
    CALENDAR: "CALENDAR",
    BED: "BED",
    CONCIERGE: "CONCIERGE",
    BRIEFCASE: "BRIEFCASE",
    CLOCK: "CLOCK",
    BSFILLPERSONFILL: "BSFILLPERSONFILL",
    BSFILLBRIEFCASEFILL: "BSFILLBRIEFCASEFILL",
    STAR:"STAR",
    MARTINI:"MARTINI",
    CIDISCOUNT1: "CIDISCOUNT1",
    AA:"AA",
    ROOMPHONE:"ROOMPHONE",
    WIFI:"WIFI",
    FICHECK:"FICHECK",
    CHECKCIRCLE:"CHECKCIRCLE",
    AIOUTLINEARROWRIGHT: "AIOUTLINEARROWRIGHT",
    ADDCIRCLE: "ADDCIRCLE",
    MINUSCIRCLE: "MINUSCIRCLE",
    TRASH: "TRASH",
    ADD: "ADD",
    BIRESTAURANT: "BIRESTAURANT",
    GOARROWRIGHT:"GOARROWRIGHT",
    GOARROWLEFT: "GOARROWLEFT",
    BSCLOCK:"BSCLOCK",
    AIOUTLINEGLOBAL:"AIOUTLINEGLOBAL",
    AIOUTLINECHECK:"AIOUTLINECHECK",
    PERSON:"PERSON",
    OUTLINEEDIT: "OUTLINEEDIT",
    IOIOSARROWDOWN: "IOIOSARROWDOWN",
    IOIOSARROWUP: "IOIOSARROWUP",
    AIOUTLINERIGHT: "AIOUTLINERIGHT",
    AIOUTLINECLOSE:"AIOUTLINECLOSE",
    FATAGS:"FATAGS",
    AIOUTLINEEXCLAMATINCIRCLE:"AIOUTLINEEXCLAMATINCIRCLE",
    RIFLIGHTOFF:"RIFLIGHTOFF",
    RIFLIGHTLAND:"RIFLIGHTLAND",
    BSFILLHANDBAGFILL:"BSFILLHANDBAGFILL",
    BSFILLBAGFILL:"BSFILLBAGFILL",
    BSBAGDASFILL:"BSBAGDASFILL",
    AIFILLCHECKCIRCLE:"AIFILLCHECKCIRCLE",
    BSEXCLAMATIONTRIANGLEFILL: "BSEXCLAMATIONTRIANGLEFILL",
    BSEXCLAMATIONCIRCLEFILL:"BSEXCLAMATIONCIRCLEFILL",
    IMGS:"IMGS",
    AIOUTLINEAPPSTORE:"AIOUTLINEAPPSTORE",
    BILISTUL:"BILISTUL",
    DISCOUNT: "DISCOUNT",
    SLARROWRIGHT: "SLARROWRIGHT",
    SLARROWLEFT:"SLARROWLEFT",
    FALONGARROWALTRIGHT: "FALONGARROWALTRIGHT",
    GOALERT: "GOALERT",
    BSFILLCARTFILL:"BSFILLCARTFILL",
    BSARROWLEFTRIGHT: "BSARROWLEFTRIGHT",
    BSARROWDOWNUP: "BSARROWDOWNUP",
    TBLOGOUT:"TBLOGOUT",
    HIUSERCIRCLE:"HIUSERCIRCLE",
    BICART:"BICART",
    CGMENUGRIDR:"CGMENUGRIDR",
    FAFACEBOOKF:"FAFACEBOOKF",
    FATWITTER:"FATWITTER",
    FAINSTAGRAM:"FAINSTAGRAM",
    FAYOUTUBE:"FAYOUTUBE",
    FAPINTEREST:"FAPINTEREST",
    AIOUTLINEMENUUNFOLD:"AIOUTLINEMENUUNFOLD",
    AIFILLFACEBOOK:"AIFILLFACEBOOK",
    AIFILLYOUTUBE:"AIFILLYOUTUBE",
    AIFILLLINKEDIN:"AIFILLLINKEDIN",
    MDLANGUAGE:"MDLANGUAGE",
    AIOUTLINEARROWUP:"AIOUTLINEARROWUP"
}

export default iconContants;