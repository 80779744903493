import { IntlProvider } from "react-intl";
import { useEffect, useState } from "react";
import languages from "../../languages";
import resourceSiteData from "../../data";
import { useGlobalState, useDispatch } from "../../store/StoreProvider";
import { useNavigate, useLocation } from "react-router-dom";
import localeAction from "../../actions/localeAction";
import BackToTopButton from "../../components/Button/BackToTopButton"


import HeaderLayout from "./HeaderLayout";
import FooterLayout from "./FooterLayout";

const LayoutComponent = (props) => {
  const {
    component: ChildrenPage,
    positionPage,
    metasPage,
    hasHeader,
    hasFooter
  } = props;
  const dispatch = useDispatch();
  const { locale } = useGlobalState();
  const [messages, setMessages] = useState(languages[locale]);
  const [resourceSite, setResourceSite] = useState(resourceSiteData[locale]);

  const location = useLocation();
  const navigate = useNavigate();

  const handleChangeLanguage = (e) => {
    const auxSiteRoutes = resourceSiteData[e].siteRouter;

    

    if(positionPage >= 0 && auxSiteRoutes.length > 0) {
       let path = location.pathname.split('/')
       let key =""
        if(path.length >= 2){
          key =path[3]
        }
        key = key ?? "";  
        const auxLocation = {
            search: location.search,
            pathname : `/${e}${auxSiteRoutes[positionPage].pathname}${key}`
        };


        localeAction.updateLocale(e, dispatch);
        //navigate(auxLocation);
        window.location.href =auxLocation.pathname + auxLocation.search
    }
  };

  useEffect(() => {
    setMessages(languages[locale]);
    setResourceSite(resourceSiteData[locale]);
 
    window.scrollTo(0, 0);
  }, [locale])


  
  return locale != null ? (
    <IntlProvider locale={locale} messages={messages}>
      <div className="container-site">
        {!hasHeader && (
     
          <HeaderLayout resourceSite={resourceSite} onClickLenguage={handleChangeLanguage} />
        )}
        <div className="item-flex-grow">
          <ChildrenPage resourceSite={resourceSite} metasPage={metasPage}
            onChangeLanguage={handleChangeLanguage}
          />
        </div>
        {!hasFooter && (
          <FooterLayout resourceSite={resourceSite} locale={locale}/>
        )}
        <BackToTopButton/>
      </div>
    </IntlProvider>
  ) : (
    <>loading...</>
  );
};

LayoutComponent.defaultProps = {
  hasHeader: false,
  hasFooter: false,
}

export default LayoutComponent;
