import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import img1 from "../../assets/img/branding/cards/implementation/AddedValue.jpg";
import img2 from "../../assets/img/branding/cards/implementation/Implementors.jpg";
import img3 from "../../assets/img/branding/cards/implementation/Strategy.jpg";

const implementationData = {
    videBgImplementation:{
        clip:"",
        title:"IMPLEMENTACIÓN",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    cardsImplementation:{
        title:"",
        text:"",
        items:[
            {
                img:img1,
                title:"Valor Agregado",
                label:"Contamos con equipos de ingenieros dedicados que serán su primer puerto de escala para cualquier problema, ya sea seguridad cibernética o velocidad/estabilidad del sitio. Supervisaremos el rendimiento y le daremos comentarios y sugerencias regulares, para que su sistema siempre se mantenga a la vanguardia.",
                url:"/"
            },
            {
                img:img2,
                title:"Implementadores",
                label:"Nos aseguraremos de identificar las soluciones de Akamai adecuadas para sus desafíos... y coordinaremos con los expertos de Akamai para implementar lo que necesite, cuando lo necesite.",
                url:"/"
            },
            {
                img:img3,
                title:"Estratégia",
                label:"Nuestros expertos lo ayudarán a formular las estrategias que requieren sus realidades y siempre las mantendrán actualizadas en el actual panorama de rápida evolución.",
                url:"/"
            },
            
        ]
    },
    formVideoImplementation:{
        row:3,
        placeholderMessage:"Message",
        typeEmail:"email",
        size:"md",
        placeholderEmail:"Email",
        idEmail:"email",
        requiredEmail:true,
        classNameInput:"form-basic",
        typeName:"text",
        placeholderName:"First Name",
        idName:"name",
        requiredName:true,
        placeholderLastName:"Last Name",
        typeLastName:"text",
        idLastName:"lastname",
        requiredLastName:true,
        typeButton:"submit",
        textButton:"Send",

        urlVideo:"",
        show: false
    },
    urlFormImplementation:"/hable-con-nosotros",
    texBtntFormImplementation:"Hable con un experto",
}

export default implementationData;