import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';


const infrastructureSecurityData = {
    videBgInfrastructureSecurity:{
        clip:"",
        poster:"",
        title:"Seguridad de infraestructura",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteInfrastructureSecurity:{
        headBandshowTitle:true,
        headBandTitle:"Siga el ritmo de las <span>amenazas</span> a medida que crece su  <span>entorno</span>",
        classNameHead:"",
        headBandtext:"El aumento de los ataques a la seguridad, ya sea en la nube o en sus instalaciones locales, implica que debe integrar la seguridad en todo lo que cree, en cualquier lugar donde lo cree y lo distribuya. Nuestras soluciones aprovechan nuestra plataforma de seguridad global para proteger su entorno único y todas sus aplicaciones críticas, de modo que pueda innovar y ampliar su negocio con confianza, sin crear nuevas vulnerabilidades.",
        
    },
    cardsInfrastructureSecurity:{
        title:"",
        text:"Proteja sus sitios y la <span>experiencia</span> del cliente. Detenga los ataques DDoS y DNS",
        items:[
            // {
            //     img:"",
            //     title:"App & API Performance",
            //     label:"Seguridad integral y sin riesgos para sitios web, aplicaciones y API.",
            //     url:"/detalle/api-performance",
            //     textUrl:""
            // },
            // {
            //     img:"",
            //     title:"Prolexic",
            //     label:"Detenga los ataques DDoS con la protección más rápida y eficaz a escala.",
            //     url:"/detalle/prolexic",
            //     textUrl:""
            // },
            // {
            //     img:"",
            //     title:"Edge DNS",
            //     label:"Confíe en un sistema de nombres de dominio (DNS) con un alto nivel de seguridad para garantizar la disponibilidad continua de aplicaciones web y API",
            //     url:"/detalle/edge-dns",
            //     textUrl:""
            // }
        ]
    },
    urlFormInfrastructureSecurity:"/hable-con-nosotros",
    texBtntFormInfrastructureSecurity:"Hable con un experto",
}

export default infrastructureSecurityData;