import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import aplisecu from "../../assets/img/branding/cards/protection/ApplicationSecurity.jpg";
import infrasecu from "../../assets/img/branding/cards/protection/InfrastructureSecurity.jpg";
import zerosecu from "../../assets/img/branding/cards/protection/ZerotrustSecurity.jpg";
import fraud from "../../assets/img/branding/cards/protection/Proteccion-contra-abuso-2.jpg";

const securityData = {
    videBgSecurity:{
        clip:"",
        title:"Security",
        showContentText:true,
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteSecurity:{
        headBandshowTitle:true,
        headBandTitle:"",
        classNameHead:"",
        headBandtext:"<span>Stay safe</span> from threats without slowing down. <span>HAAP solutions </span> surround and protect your entire ecosystem — clouds, apps, APIs, and users.​​ ",
        
    },
    cardsSecurity:{
        title:"",
        text:"",
        items:[
            {
                show:true,
                img:infrasecu,
                title:"Infrastructure Security",
                label:"Enhance resilience against the most complex threats with multi-layered defence across your entire ecosystem.",
                url:"/infrastructure-security",
                list:[
                    {
                        label:"DDoS attacks",
                        check:true,
                    },
                    {
                        label:"Site takedown",
                        check:true,
                    },
                    {
                        label:"Resource exhaustion",
                        check:true,
                    },
                    {
                        label:"DNS attacks",
                        check:true,
                    }
                ]
            },
            {
                show:true,
                img:aplisecu,
                title:"Application Security",
                label:"Defend against a wide range of multi-vector attacks. Prevent credential stuffing, bot activity, and web fraud with our advanced detection and mitigation solutions.",
                url:"/application-security",
                list:[
                    {
                        label:"Site scraping",
                        check:true,
                    },
                    {
                        label:"Account takeover",
                        check:true,
                    },
                    {
                        label:"Content corruption",
                        check:true,
                    },
                    {
                        label:"User data theft",
                        check:true,
                    },
                    {
                        label:"Bot attacks",
                        check:true,
                    },
                    {
                        label:"Audience hijacking",
                        check:true,
                    }
                ]
            },
            {
                show:true,
                img:zerosecu,
                title:"Zero-trust Security",
                label:"Control access to corporate apps and the internet from anywhere without disrupting your workforce. Micro-segmentation detects breaches and limits the spread of malware, minimizing damage in the event of a breach.",
                url:"/security-zero-trust",
                list:[
                    {
                        label:"Spread of malware, ransomware",
                        check:true,
                    },
                    {
                        label:"Phishing exploits",
                        check:true,
                    },
                    {
                        label:"Enterprise data theft",
                        check:true,
                    },
                    
                ]
            },
            {   show:true,
                img:fraud,
                title:"Abuse and Fraud Protection",
                label:"Protect your brand reputation by detecting suspicious human and bot behavior in real time.",
                url:"/abuse-and-fraud-protection",
                list:[
                    {
                        label:"Account takeover",
                        check:true,
                    },
                    {
                        label:"Affiliate fraud",
                        check:true,
                    },
                    {
                        label:"Fraudsters",
                        check:true,
                    },
                    {
					label:"Stop brand impersonations before they start",
                        check:true,
				},
                ]
            },
           
        ]
    },
    formVideoSecurity:{
        row:3,
        placeholderMessage:"Message",
        typeEmail:"email",
        size:"md",
        placeholderEmail:"Email",
        idEmail:"email",
        requiredEmail:true,
        classNameInput:"form-basic",
        typeName:"text",
        placeholderName:"First Name",
        idName:"name",
        requiredName:true,
        placeholderLastName:"Last Name",
        typeLastName:"text",
        idLastName:"lastname",
        requiredLastName:true,
        typeButton:"submit",
        textButton:"Send",

        urlVideo:"",
        show:false,
    },
    urlFormSecurity:"/talk-with-us",
    texBtntFormSecurity:"Talk to an expert",
}

export default securityData;