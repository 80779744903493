import React, { useState, useEffect } from "react";
import "./style.scss";
import iconUtils from "../../utils/iconUtils";
import iconContants from "../../constants/iconContants";

const BackToTopButton = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  const isVisible = scrollY >= window.innerHeight / 2;
  return (
    <button
      className="back-to-top"
      style={{ display: !isVisible && "none" }}
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      {iconUtils.icon(iconContants.AIOUTLINEARROWUP)}
    </button>
  );
};

export default BackToTopButton;
