import { useIntl } from "react-intl";
import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useGlobalState } from "../store/StoreProvider";
import BgVideo from "@mzp/commons/dist/layout/BgVideo";
import HeadbandText from "@mzp/commons/dist/dataDisplay/HeadbandText";
import Cards from "@mzp/commons/dist/surfaces/Cards";
import image from "../assets/img/branding/landing/infrastructure.jpeg";
import AutoLayoutColumns from "@mzp/commons/dist/layout/AutoLayoutColumns";

const InfrastructureSecurity = (props) => {
  const { resourceSite } = props;
  const intl = useIntl();
  const { locale } = useGlobalState();
  const [scrollItems, setScrollItems] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeTransitionItems = () => {
    const elem = document.querySelector("#itemsoffer");
    var windowheight = window.scrollY;
    var itemTop =
      elem != null && elem !== undefined && elem && elem.offsetTop
        ? elem.offsetTop
        : 0;
    if (windowheight > itemTop - 650) {
      setScrollItems(true);
    } else {
      setScrollItems(false);
    }
  };

  window.addEventListener("scroll", changeTransitionItems);

  return (
    <div className="infrastructure-security-section">
      {resourceSite.videBgInfrastructureSecurity.clip ? (
        <BgVideo
          clip={resourceSite.videBgInfrastructureSecurity.clip}
          poster={resourceSite.videBgInfrastructureSecurity.poster}
          title={resourceSite.videBgInfrastructureSecurity.title}
          text={resourceSite.videBgInfrastructureSecurity.text}
          iconDirection={
            resourceSite.videBgInfrastructureSecurity.iconDirection
          }
          locale={locale}
        />
      ) : (
        <div
          className="bg-image bg-protection"
          style={{ backgroundImage: `url(${image})` }}
        >
          <h2>{resourceSite.videBgInfrastructureSecurity.title}</h2>
          <div className="overlay"></div>
        </div>
      )}
                  <section className=" what-we-offer-color  ">
        
          <HeadbandText 
            title={""}
            text={resourceSite.infoSiteInfrastructureSecurity.headBandTitle}
            locale={locale}
            showTitle={false}
            showText={true}
            classNameHead={""}
            showDivision={false}
            classNameContainer={"text-center center-element"}
            fontSizeTitle={""}
            fontSizeText={""}
            colsm={0}
            colmd={0} 
      />

              <div>

       
   
                </div>            
                
        </section>
      {/* <section className="why-haap-tech"> */}
        {/* <HeadbandText
          text={resourceSite.infoSiteInfrastructureSecurity.headBandtext}
          title={resourceSite.infoSiteInfrastructureSecurity.headBandTitle}
          showDivision={false}
          locale={locale}
          fontSizeTitle={"23px"}
          fontSizeText={"16px"}
        /> */}
        
        {/* <HeadbandText 
            title={""}
            text={resourceSite.infoSiteInfrastructureSecurity.headBandTitle}
            locale={locale}
            showTitle={false}
            showText={true}
            classNameHead={""}
            showDivision={false}
            classNameContainer={"text-center"}
            fontSizeTitle={""}
            fontSizeText={""}
            colsm={0}
            colmd={0} 
      />
      </section> */}
      <section className="what-we-offer">
        <Container>
          <HeadbandText 
            locale={locale}
            title={resourceSite.cardsInfrastructureSecurity.text}
            text={""}
            showTitle={true}
            showText={false}
            classNameHead={"headband-white"}
            showDivision={false}
            classNameContainer={"text-center"}
            fontSizeTitle={"20px"}
            fontSizeText={""}
            colsm={0}
            colmd={0}
          />
      
          <div
            className={`what-we-offer-list-card  ${
              scrollItems ? "activeAnimation" : ""
            }`}
            id="itemsoffer"
          >
            <AutoLayoutColumns
              containerLayout={
                resourceSite.cardsInfrastructureSecurity.items.length === 4
                  ? "container-layout-four-column"
                  : "container-layout-column"
              }
            >
              {resourceSite.cardsInfrastructureSecurity.items &&
                resourceSite.cardsInfrastructureSecurity.items.map(
                  (item, index) => {
                    return (
                      <Cards
                        key={index}
                        showImage={false}
                        image={item.img}
                        classNameCard="card-haap"
                      >
                        <div className="card-content-body">
                          <h3>{item.title}</h3>
                          <p>{item.label}</p>
                          <div className="line-border"></div>
                        </div>
                        <Link to={`/${locale}${item.url}`} reloadDocument className="card-url">
                          {item.textUrl}{" "}
                        </Link>
                      </Cards>
                    );
                  }
                )}
            </AutoLayoutColumns>
          </div>
        </Container>
      </section>
      <Link
        className="btn-talk"
        to={`/${locale}${resourceSite.urlFormInfrastructureSecurity}`}
      >
        {resourceSite.texBtntFormInfrastructureSecurity}
      </Link>
    </div>
  );
};

export default InfrastructureSecurity;
