const talkWithUsData = {
    formContact: {
        name: {
          placeholder:"Primeiro nome*",      
          id: "firstName",
          name:"name",
          type: "text",
          required:true, 
        },
        lastName: {
          placeholder: "Último nome*",
          id: "lastName",
          name:"lastName",
          type: "text",
          required:true, 
        },
        email: {
          placeholder: "Endereço de e-mail*",
          id: "email",
          name:"email",
          type: "email",
          required:true,
        },
        company: {
          placeholder: "Empresa*",
          id: "company",
          name:"company",
          type: "text",
          required:true, 
        },
        department: {
          id: "department",
          name:"department",
          required:true, 
          items: [
            {
                name:"Selecione Departamento*",
                value:"",
            },
            {
              name: "IT: Applications/App Owners",
              value: "TI: Aplicações/Proprietários das aplicações",
            },
            {
              name: "IT: Architect",
              value: "TI: Arquiteto",
            },
            {
              name: "IT: Data Center",
              value: "TI: Data center",
            },
            {
              name: "IT: Developer",
              value: "TI: Desenvolvedor",
            },
            {
              name: "IT: Engineering",
              value: "TI: Engenharia",
            },
            {
              name: "IT: General",
              value: "TI: Geral",
            },
            {
              name: "IT: Network/Architecture/Infrastructure",
              value: "TI: Rede/Arquitetura/Infraestrutura",
            },
            {
              name: "IT: Operations",
              value: "TI: Operações",
            },
            {
              name: "IT: Program/Project Manager",
              value: "TI: Gerente de programa/projetos",
            },
            {
              name: "IT: Security",
              value: "TI: Segurança",
            },
            {
              name: "Analyst",
              value: "Analista",
            },
            {
              name: "Content Production",
              value: "Produção de conteúdo",
            },
            {
              name: "Consultant",
              value: "Consultor",
            },
            {
              name: "Digital/Internet Entertainment",
              value: "Entretenimento digital/na Internet",
            },
            {
              name: "Engineering",
              value: "Engenharia",
            },
            {
              name: "Finance",
              value: "Finanças",
            },
            {
              name: "HR",
              value: "RH",
            },
            {
              name: "Legal",
              value: "Jurídico",
            },
            {
              name: "Marketing",
              value: "Marketing",
            },
            {
              name: "Media",
              value: "Mídia",
            },
    
            {
              name: "Operations",
              value: "Operações",
            },
            {
              name: "Product Management",
              value: "Gerenciamento de produto",
            },
            {
              name: "Production",
              value: "Produção",
            },
    
            {
              name: "Sales",
              value: "Vendas",
            },
            {
              name: "Student",
              value: "Estudante",
            },
            {
              name: "Web",
              value: "Web",
            },
          ],
        },
        title:{
            id:"title",
            name:"title",
            required:true, 
            items:[
                {
                    name:"Selecione Nível*",
                    value:"",
                },           
                {
                    name:"Staff",
                    value:"Equipe"
                },
                {
                    name:"Consultant",
                    value:"Consultor"
                },
                {
                    name:"Manager",
                    value:"Gerente"
                },
                {
                    name:"Director",
                    value:"Diretor"
                },
                {
                    name:"VP/Executive",
                    value:"Vice-presidente/Executivo"
                },
                {
                    name:"C-Level",
                    value:"Nível C"
                }
            ],
        },
        country:{
            id:"country",
            name:"country",
            required:true,
            items:[
                {
                    value:"",
                    name:"Selecione País/Região"
                },
                {
                  value: "Brazil",
                  name: "Brasil",
                },
                {
                  value: "Portugal",
                  name: "Portugal",
                },
                {
                  value: "Afghanistan",
                  name: "Afghanistan",
                },
                {
                  value: "Albania",
                  name: "Albania",
                },
                {
                  value: "Algeria",
                  name: "Algeria",
                },
                {
                  value: "American Samoa",
                  name: "American Samoa",
                },
                {
                  value: "Andorra",
                  name: "Andorra",
                },
                {
                  value: "Angola",
                  name: "Angola",
                },
                {
                  value: "Anguilla",
                  name: "Anguilla",
                },
                {
                  value: "Antigua and Barbuda",
                  name: "Antigua and Barbuda",
                },
                {
                  value: "Argentina",
                  name: "Argentina",
                },
                {
                  value: "Armenia",
                  name: "Armenia",
                },
                {
                  value: "Aruba",
                  name: "Aruba",
                },
                {
                  value: "Australia",
                  name: "Australia",
                },
                {
                  value: "Austria",
                  name: "Austria",
                },
                {
                  value: "Azerbaijan",
                  name: "Azerbaijan",
                },
                {
                  value: "Bahamas",
                  name: "Bahamas",
                },
                {
                  value: "Bahrain",
                  name: "Bahrain",
                },
                {
                  value: "Bangladesh",
                  name: "Bangladesh",
                },
                {
                  value: "Barbados",
                  name: "Barbados",
                },
                {
                  value: "Belarus",
                  name: "Belarus",
                },
                {
                  value: "Belgium",
                  name: "Belgium",
                },
                {
                  value: "Belize",
                  name: "Belize",
                },
                {
                  value: "Benin",
                  name: "Benin",
                },
                {
                  value: "Bermuda",
                  name: "Bermuda",
                },
                {
                  value: "Bhutan",
                  name: "Bhutan",
                },
                {
                  value: "Bolivia",
                  name: "Bolivia",
                },
                {
                  value: "Bosnia and Herzegovina",
                  name: "Bosnia and Herzegovina",
                },
                {
                  value: "Botswana",
                  name: "Botswana",
                },
                {
                  value: "British Indian Ocean Territory",
                  name: "British Indian Ocean Territory",
                },
                {
                  value: "British Virgin Islands",
                  name: "British Virgin Islands",
                },
                {
                  value: "Brunei Darussalam",
                  name: "Brunei Darussalam",
                },
                {
                  value: "Bulgaria",
                  name: "Bulgaria",
                },
                {
                  value: "Burkina Faso",
                  name: "Burkina Faso",
                },
                {
                  value: "Burundi",
                  name: "Burundi",
                },
                {
                  value: "Cambodia",
                  name: "Cambodia",
                },
                {
                  value: "Cameroon",
                  name: "Cameroon",
                },
                {
                  value: "Canada",
                  name: "Canada",
                },
                {
                  value: "Cape Verde",
                  name: "Cape Verde",
                },
                {
                  value: "Cayman Islands",
                  name: "Cayman Islands",
                },
                {
                  value: "Central African Republic",
                  name: "Central African Republic",
                },
                {
                  value: "Chad",
                  name: "Chad",
                },
                {
                  value: "Chile",
                  name: "Chile",
                },
                {
                  value: "China",
                  name: "China",
                },
                {
                  value: "Christmas Island",
                  name: "Christmas Island",
                },
                {
                  value: "Vatican City",
                  name: "Cidade do Vaticano",
                },
                {
                  value: "Cocos (Keeling) Islands",
                  name: "Cocos (Keeling) Islands",
                },
                {
                  value: "Colombia",
                  name: "Colombia",
                },
                {
                  value: "Comoros",
                  name: "Comoros",
                },
                {
                  value: "Congo",
                  name: "Congo",
                },
                {
                  value: "Congo (the Democratic Republic of the)",
                  name: "Congo (República Democrática do)",
                },
                {
                  value: "Cook Islands",
                  name: "Cook Islands",
                },
                {
                  value: "North Korea",
                  name: "Coreia do Norte",
                },
                {
                  value: "South Korea",
                  name: "Coreia do Sul",
                },
                {
                  value: "Ivory Coast",
                  name: "Costa do Marfim",
                },
                {
                  value: "Costa Rica",
                  name: "Costa Rica",
                },
                {
                  value: "Croatia",
                  name: "Croatia",
                },
                {
                  value: "Cuba",
                  name: "Cuba",
                },
                {
                  value: "Curacao",
                  name: "Curaçao",
                },
                {
                  value: "Cyprus",
                  name: "Cyprus",
                },
                {
                  value: "Czech Republic",
                  name: "Czech Republic",
                },
                {
                  value: "Denmark",
                  name: "Denmark",
                },
                {
                  value: "Djibouti",
                  name: "Djibouti",
                },
                {
                  value: "Dominica",
                  name: "Dominica",
                },
                {
                  value: "Dominican Republic",
                  name: "Dominican Republic",
                },
                {
                  value: "Ecuador",
                  name: "Ecuador",
                },
                {
                  value: "Egypt",
                  name: "Egypt",
                },
                {
                  value: "El Salvador",
                  name: "El Salvador",
                },
                {
                  value: "Equatorial Guinea",
                  name: "Equatorial Guinea",
                },
                {
                  value: "Eritrea",
                  name: "Eritrea",
                },
                {
                  value: "Estonia",
                  name: "Estonia",
                },
                {
                  value: "Ethiopia",
                  name: "Ethiopia",
                },
                {
                  value: "Falkland Islands (Malvinas)",
                  name: "Falkland Islands (Malvinas)",
                },
                {
                  value: "Faroe Islands",
                  name: "Faroe Islands",
                },
                {
                  value: "Fiji",
                  name: "Fiji",
                },
                {
                  value: "Finland",
                  name: "Finland",
                },
                {
                  value: "France",
                  name: "France",
                },
                {
                  value: "French Guiana",
                  name: "French Guiana",
                },
                {
                  value: "French Polynesia",
                  name: "French Polynesia",
                },
                {
                  value: "Gabon",
                  name: "Gabon",
                },
                {
                  value: "Gambia",
                  name: "Gambia",
                },
                {
                  value: "Georgia",
                  name: "Georgia",
                },
                {
                  value: "Germany",
                  name: "Germany",
                },
                {
                  value: "Ghana",
                  name: "Ghana",
                },
                {
                  value: "Gibraltar",
                  name: "Gibraltar",
                },
                {
                  value: "Greece",
                  name: "Greece",
                },
                {
                  value: "Greenland",
                  name: "Greenland",
                },
                {
                  value: "Grenada",
                  name: "Grenada",
                },
                {
                  value: "Guadeloupe",
                  name: "Guadeloupe",
                },
                {
                  value: "Guam",
                  name: "Guam",
                },
                {
                  value: "Guatemala",
                  name: "Guatemala",
                },
                {
                  value: "Guernsey",
                  name: "Guernsey",
                },
                {
                  value: "Guinea",
                  name: "Guinea",
                },
                {
                  value: "Guinea-bissau",
                  name: "Guinea-Bissau",
                },
                {
                  value: "Guyana",
                  name: "Guyana",
                },
                {
                  value: "Haiti",
                  name: "Haiti",
                },
                {
                  value: "Honduras",
                  name: "Honduras",
                },
                {
                  value: "Hong Kong",
                  name: "Hong Kong",
                },
                {
                  value: "Hungary",
                  name: "Hungary",
                },
                {
                  value: "Iceland",
                  name: "Iceland",
                },
                {
                  value: "Isle of Man",
                  name: "Ilha de Man",
                },
                {
                  value: "Aland Islands",
                  name: "Ilhas Aland",
                },
                {
                  value: "US Virgin Islands",
                  name: "Ilhas Virgens Americanas",
                },
                {
                  value: "India",
                  name: "India",
                },
                {
                  value: "Indonesia",
                  name: "Indonesia",
                },
                {
                  value: "Iran",
                  name: "Irã",
                },
                {
                  value: "Iraq",
                  name: "Iraq",
                },
                {
                  value: "Ireland",
                  name: "Ireland",
                },
                {
                  value: "Israel",
                  name: "Israel",
                },
                {
                  value: "Italy",
                  name: "Italy",
                },
                {
                  value: "Jamaica",
                  name: "Jamaica",
                },
                {
                  value: "Japan",
                  name: "Japan",
                },
                {
                  value: "Jersey",
                  name: "Jersey",
                },
                {
                  value: "Jordan",
                  name: "Jordan",
                },
                {
                  value: "Kazakhstan",
                  name: "Kazakhstan",
                },
                {
                  value: "Kenya",
                  name: "Kenya",
                },
                {
                  value: "Kiribati",
                  name: "Kiribati",
                },
                {
                  value: "Kosovo",
                  name: "Kosovo",
                },
                {
                  value: "Kuwait",
                  name: "Kuwait",
                },
                {
                  value: "Kyrgyzstan",
                  name: "Kyrgyzstan",
                },
                {
                  value: "Latvia",
                  name: "Latvia",
                },
                {
                  value: "Lebanon",
                  name: "Lebanon",
                },
                {
                  value: "Lesotho",
                  name: "Lesotho",
                },
                {
                  value: "Liberia",
                  name: "Liberia",
                },
                {
                  value: "Libya",
                  name: "Libya",
                },
                {
                  value: "Liechtenstein",
                  name: "Liechtenstein",
                },
                {
                  value: "Lithuania",
                  name: "Lithuania",
                },
                {
                  value: "Luxembourg",
                  name: "Luxembourg",
                },
                {
                  value: "Macau",
                  name: "Macau",
                },
                {
                  value: "Madagascar",
                  name: "Madagascar",
                },
                {
                  value: "Malawi",
                  name: "Malawi",
                },
                {
                  value: "Malaysia",
                  name: "Malaysia",
                },
                {
                  value: "Maldives",
                  name: "Maldives",
                },
                {
                  value: "Mali",
                  name: "Mali",
                },
                {
                  value: "Malta",
                  name: "Malta",
                },
                {
                  value: "Marshall Islands",
                  name: "Marshall Islands",
                },
                {
                  value: "Martinique",
                  name: "Martinique",
                },
                {
                  value: "Mauritania",
                  name: "Mauritania",
                },
                {
                  value: "Mauritius",
                  name: "Mauritius",
                },
                {
                  value: "Mayotte",
                  name: "Mayotte",
                },
                {
                  value: "Mexico",
                  name: "Mexico",
                },
                {
                  value: "Micronesia",
                  name: "Micronésia",
                },
                {
                  value: "Moldova",
                  name: "Moldova",
                },
                {
                  value: "Monaco",
                  name: "Monaco",
                },
                {
                  value: "Mongolia",
                  name: "Mongolia",
                },
                {
                  value: "Montenegro",
                  name: "Montenegro",
                },
                {
                  value: "Montserrat",
                  name: "Montserrat",
                },
                {
                  value: "Morocco",
                  name: "Morocco",
                },
                {
                  value: "Mozambique",
                  name: "Mozambique",
                },
                {
                  value: "Myanmar",
                  name: "Myanmar",
                },
                {
                  value: "Namibia",
                  name: "Namibia",
                },
                {
                  value: "Nauru",
                  name: "Nauru",
                },
                {
                  value: "Nepal",
                  name: "Nepal",
                },
                {
                  value: "Netherlands",
                  name: "Netherlands",
                },
                {
                  value: "New Caledonia",
                  name: "New Caledonia",
                },
                {
                  value: "New Zealand",
                  name: "New Zealand",
                },
                {
                  value: "Nicaragua",
                  name: "Nicaragua",
                },
                {
                  value: "Niger",
                  name: "Niger",
                },
                {
                  value: "Nigeria",
                  name: "Nigeria",
                },
                {
                  value: "Niue",
                  name: "Niue",
                },
                {
                  value: "Norfolk Island",
                  name: "Norfolk Island",
                },
                {
                  value: "Northern Mariana Islands",
                  name: "Northern Mariana Islands",
                },
                {
                  value: "Norway",
                  name: "Norway",
                },
                {
                  value: "Oman",
                  name: "Oman",
                },
                {
                  value: "Pakistan",
                  name: "Pakistan",
                },
                {
                  value: "Palau",
                  name: "Palau",
                },
                {
                  value: "Palestine",
                  name: "Palestina",
                },
                {
                  value: "Panama",
                  name: "Panama",
                },
                {
                  value: "Papua New Guinea",
                  name: "Papua New Guinea",
                },
                {
                  value: "Paraguay",
                  name: "Paraguay",
                },
                {
                  value: "Peru",
                  name: "Peru",
                },
                {
                  value: "Philippines",
                  name: "Philippines",
                },
                {
                  value: "Pitcairn",
                  name: "Pitcairn",
                },
                {
                  value: "Poland",
                  name: "Poland",
                },
                {
                  value: "Puerto Rico",
                  name: "Puerto Rico",
                },
                {
                  value: "Qatar",
                  name: "Qatar",
                },
                {
                  value: "Syrian Arab Republic",
                  name: "República Árabe da Síria",
                },
                {
                  value: "Republic of North Macedonia",
                  name: "República da Macedônia do Norte",
                },
                {
                  value: "Lao People's Democratic Republic",
                  name: "República Democrática Popular do Laos",
                },
                {
                  value: "Reunion",
                  name: "Reunião",
                },
                {
                  value: "Romania",
                  name: "Romania",
                },
                {
                  value: "Russian Federation",
                  name: "Russian Federation",
                },
                {
                  value: "Rwanda",
                  name: "Rwanda",
                },
                {
                  value: "Saint Helena",
                  name: "Saint Helena",
                },
                {
                  value: "Saint Kitts and Nevis",
                  name: "Saint Kitts and Nevis",
                },
                {
                  value: "Saint Lucia",
                  name: "Saint Lucia",
                },
                {
                  value: "Saint Martin",
                  name: "Saint Martin",
                },
                {
                  value: "Saint Pierre and Miquelon",
                  name: "Saint Pierre and Miquelon",
                },
                {
                  value: "Saint Vincent and Grenadines",
                  name: "Saint Vincent and Grenadines",
                },
                {
                  value: "Samoa",
                  name: "Samoa",
                },
                {
                  value: "San Marino",
                  name: "San Marino",
                },
                {
                  value: "Saint Barthelemy",
                  name: "São Bartolomeu",
                },
                {
                  value: "Sint Maarten",
                  name: "São Martinho",
                },
                {
                  value: "Sao Tome and Principe",
                  name: "Sao Tome and Principe",
                },
                {
                  value: "Saudi Arabia",
                  name: "Saudi Arabia",
                },
                {
                  value: "Senegal",
                  name: "Senegal",
                },
                {
                  value: "Serbia",
                  name: "Serbia",
                },
                {
                  value: "Seychelles",
                  name: "Seychelles",
                },
                {
                  value: "Sierra Leone",
                  name: "Sierra Leone",
                },
                {
                  value: "Singapore",
                  name: "Singapore",
                },
                {
                  value: "Slovakia",
                  name: "Slovakia",
                },
                {
                  value: "Slovenia",
                  name: "Slovenia",
                },
                {
                  value: "Solomon Islands",
                  name: "Solomon Islands",
                },
                {
                  value: "Somalia",
                  name: "Somalia",
                },
                {
                  value: "South Africa",
                  name: "South Africa",
                },
                {
                  value: "South Georgia and the South Sandwich Islands",
                  name: "South Georgia and the South Sandwich Islands",
                },
                {
                  value: "Spain",
                  name: "Spain",
                },
                {
                  value: "Sri Lanka",
                  name: "Sri Lanka",
                },
                {
                  value: "Eswatini",
                  name: "Suazilândia",
                },
                {
                  value: "Sudan",
                  name: "Sudan",
                },
                {
                  value: "South Sudan",
                  name: "Sudão do Sul",
                },
                {
                  value: "Suriname",
                  name: "Suriname",
                },
                {
                  value: "Svalbard and Jan Mayen",
                  name: "Svalbard e Jan Mayen",
                },
                {
                  value: "Sweden",
                  name: "Sweden",
                },
                {
                  value: "Switzerland",
                  name: "Switzerland",
                },
                {
                  value: "Taiwan",
                  name: "Taiwan",
                },
                {
                  value: "Tajikistan",
                  name: "Tajikistan",
                },
                {
                  value: "Tanzania, United Republic of",
                  name: "Tanzania, United Republic of",
                },
                {
                  value: "Thailand",
                  name: "Thailand",
                },
                {
                  value: "Timor-Leste",
                  name: "Timor-Leste",
                },
                {
                  value: "Togo",
                  name: "Togo",
                },
                {
                  value: "Tokelau",
                  name: "Tokelau",
                },
                {
                  value: "Tonga",
                  name: "Tonga",
                },
                {
                  value: "Trinidad and Tobago",
                  name: "Trinidad and Tobago",
                },
                {
                  value: "Tunisia",
                  name: "Tunisia",
                },
                {
                  value: "Turkey",
                  name: "Turkey",
                },
                {
                  value: "Turkmenistan",
                  name: "Turkmenistan",
                },
                {
                  value: "Turks and Caicos Islands",
                  name: "Turks and Caicos Islands",
                },
                {
                  value: "Tuvalu",
                  name: "Tuvalu",
                },
                {
                  value: "Uganda",
                  name: "Uganda",
                },
                {
                  value: "Ukraine",
                  name: "Ukraine",
                },
                {
                  value: "United Arab Emirates",
                  name: "United Arab Emirates",
                },
                {
                  value: "United Kingdom",
                  name: "United Kingdom",
                },
                {
                  value: "United States of America",
                  name: "United States of America",
                },
                {
                  value: "Uruguay",
                  name: "Uruguay",
                },
                {
                  value: "US Minor Outlying Islands",
                  name: "US Minor Outlying Islands",
                },
                {
                  value: "Uzbekistan",
                  name: "Uzbekistan",
                },
                {
                  value: "Vanuatu",
                  name: "Vanuatu",
                },
                {
                  value: "Venezuela",
                  name: "Venezuela",
                },
                {
                  value: "Vietnam",
                  name: "Vietnã",
                },
                {
                  value: "Wallis and Futuna",
                  name: "Wallis e Futuna",
                },
                {
                  value: "Western Sahara",
                  name: "Western Sahara",
                },
                {
                  value: "Yemen",
                  name: "Yemen",
                },
                {
                  value: "Zambia",
                  name: "Zambia",
                },
                {
                  value: "Zimbabwe",
                  name: "Zimbabwe",
                },
              ]
        },
        priority:{
            id:"priority",
            name:"priority",
            required:true, 
            items:[
                {
                    name:"Selecione Sua maior prioridade*",
                    value:"",
                },
                {
                    name:"Performance",
                    value:"Entregar experiências digitais excepcionais"
                  },
                  {
                    name:"Growth",
                    value:"Desenvolver os negócios"
                  },
                  {
                    name:"Security",
                    value:"Proteger os negócios"
                  },
                  {
                    name:"Monetization and Cost",
                    value:"Impactar o resultado"
                  },
                  {
                    name:"UX",
                    value:"Melhorar a experiência do usuário"
                  }
            ]
        },
        challenge:{
            id:"challenge",
            name:"challenge",
            required:true,
            items:[
                {
                    name:"Selecione Prazo para solucionar seu desafio*",
                    value:"",
                },
                {
                    name:"Currently evaluating solutions",
                    value:"Atualmente avaliando as soluções"
                  },
                  {
                    name:"1-3 months",
                    value:"1 a 3 meses"
                  },
                  {
                    name:"3-6 months",
                    value:"3-6 meses"
                  },
                  {
                    name:"6-12 months",
                    value:"6-12 meses"
                  },
                  {
                    name:"12-18 months",
                    value:"12-18 meses"
                  },
                  {
                    name:"More than 18 months",
                    value:"Mais de 18 meses"
                  }
            ]
        },
        phoneNumber:{
            placeholder:"Número de telefone*",
            id:"phoneNombre",
            name:"phoneNombre",
            type:"text",
            required:true 
        },
        comments:{
            placeholder:"Perguntas ou comentários*",
            id:"comments",
            name:"comments",
            row: 3,
            required:true 
        },
        communicationsConsent:{
          id:"communicationsConsent",
          name:"communicationsConsent",
          label:'Gostaria de receber mais informações da HAAP. Ao enviar este formulário, estou dando consentimento para o recebimento de comunicações de marketing e compreendo e concordo com o uso de minhas informações de contato corporativas de acordo com a',
          url:'/pt/politica-de-privacidade',
          showUrl:true,
          textUrl:'Política de Privacidade'
        },
        typeButton: "submit",
        labelButton: "Enviar",
      },
}

export default talkWithUsData;