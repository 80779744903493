import logo from "../../assets/img/branding/haapTech.png";
import logoMini from "../../assets/img/branding/haapMini.png"
import logoSecond from "../../assets/img/branding/akamai.png"
import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import constants from "../../constants";

const websiteData = {
    home: {
        alt: "Logo",
        href: "/es",
        imgPath: logo,
        imgPathMini:logoMini
    },
    menu: [
      {
        name: "Inicio",
        href: "/",
      },
      {
        name: "SEGURIDAD",
        href: "/seguridad",
      },
      {
        name: "Distribución de contenido",
        href: "/distribucion-de-contenido",
      },
      // {
      //   name: "Cloud Computing",
      //   href: "/cloud-computing",
      // },
      {
        name: "IMPLEMENTACION",
        href: "/implementacion",
      },
      
      {
        name: "Hable con nosotros",
        href: "/hable-con-nosotros",
      },
      // {
      //   name: "¿Por qué Akamai?",
      //   href: "/porque-akamai",
      // },
      
    ],
    languagesItems: [
     
      {
        name: "English",
        value: constants.language.EN,
        icon: "",
        tag:"EN"
      },
      // {
      //   name: "Español",
      //   value: constants.language.ES,
      //   icon: "",
      //   tag:"ES"
      // },
      // {
      //   name: "Portugues",
      //   value: constants.language.PT,
      //   icon: "",
      //   tag: "PT"
      // },
      /*{
        name: "Deutsch",
        value: constants.language.DE,
        icon: "imagen.jpg"
      }, */
    ],
    siteLocale: constants.language.ES,
    domain: "",
    siteRouter : [
      {
        pathname: "/",
        basename: "/",
        canonical: "/",
        title: "texto de titulo",
        description: "texto de titulo",
        keywords: "texts, home, pruebas",
        img: "img.jpg",
        homePage: true,
        keypage:"home",
        follow: null //default inddex, follow
      },
      {
        pathname: "/seguridad",
        basename: "/seguridad",
        canonical: "/seguridad",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "security-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/distribucion-de-contenido",
        basename: "/distribucion-de-contenido",
        canonical: "/distribucion-de-contenido",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "content-delivery-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/implementacion",
        basename: "/implementacion",
        canonical: "/implementacion",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "implementation-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/cloud-computing",
        basename: "/cloud-computing",
        canonical: "/cloud-computing",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "cloud-computing-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/hable-con-nosotros",
        basename: "/hable-con-nosotros",
        canonical: "/hable-con-nosotros",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "talk-with-us-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/porque-akamai",
        basename: "/porque-akamai",
        canonical: "/porque-akamai",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "why-akamai-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/politicas-de-cookies",
        basename: "/politicas-de-cookies",
        canonical: "/politicas-de-cookies",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "cookie-policy-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/politica-de-privacidad",
        basename: "/politica-de-privacidad",
        canonical: "/politica-de-privacidad",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "privacy-policy-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/terminos-y-condiciones",
        basename: "/terminos-y-condiciones",
        canonical: "/terminos-y-condiciones",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "terms-of-use-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/infrastructure-security",
        basename: "/infrastructure-security",
        canonical: "/infrastructure-security",
        title: "Seguridad de infraestructura",
        description: "Seguridad de infraestructura",
        keywords: "Seguridad de infraestructura",
        img: "img.jpg",
        keypage: "infrastructure-security",
        follow: null //default inddex, follow
      },
      {
        pathname: "/application-security",
        basename: "/application-security",
        canonical: "/application-security",
        title: "Seguridad de aplicaciones",
        description: "Seguridad de aplicaciones",
        keywords: "Seguridad de aplicaciones",
        img: "img.jpg",
        keypage: "application-security",
        follow: null //default inddex, follow
      },
      {
        pathname: "/security-zero-trust",
        basename: "/security-zero-trust",
        canonical: "/security-zero-trust",
        title: "Seguridad zero trust",
        description: "Seguridad zero trust",
        keywords: "Seguridad zero trust",
        img: "img.jpg",
        keypage: "security-zero-trust",
        follow: null //default inddex, follow
      },
      {
        pathname: "/abuse-and-fraud-protection",
        basename: "/abuse-and-fraud-protection",
        canonical: "/abuse-and-fraud-protection",
        title: "Soluciones de protección contra fraude y abuso",
        description: "Soluciones de protección contra fraude y abuso",
        keywords: "Soluciones de protección contra fraude y abuso",
        img: "img.jpg",
        keypage: "fraud-protection",
        follow: null //default inddex, follow
      },
      {
        pathname: "/app-and-api-performance",
        basename: "/app-and-api-performance",
        canonical: "/app-and-api-performance",
        title: "Rendimiento de aplicaciones api",
        description: "Rendimiento de aplicaciones api",
        keywords: "Rendimiento de aplicaciones api",
        img: "img.jpg",
        keypage: "api-application-performance",
        follow: null //default inddex, follow
      },
      {
        pathname: "/distribucion-de-contenido-multimedia",
        basename: "/distribucion-de-contenido-multimedia",
        canonical: "/distribucion-de-contenido-multimedia",
        title: "Multimedia content distribution",
        description: "Multimedia content distribution",
        keywords: "Multimedia content distribution",
        img: "img.jpg",
        keypage: "multimedia-content-distribution",
        follow: null //default inddex, follow
      },
      {
        pathname: "/detalle/",
        basename: "/detalle/:id",
        canonical: "/detalle",
        title: "Product detail",
        description: "Product detail",
        keywords: "Product detail",
        img: "img.jpg",
        keypage: "product-detail",
        follow: null //default inddex, follow
      },
      
    ],
    footerInformation:{
      alt: "Logo",
      href: "/es",
      imgPath: logo,
      imgPathSecond:"",
      textMini: "",
      textTitle: "AYUDA E INFORMACIÓN",
      links: [{
        text: "Términos y condiciones",
        url: "/terminos-y-condiciones",
        hasLink: true
    },
    {
        text: "Política de privacidad",
        url: "/politica-de-privacidad",
        hasLink: true
    },
    {
      text: "Política de cookies",
      url: "/politicas-de-cookies",
      hasLink: true
    },
 {
      text: "Contáctenos",
      url: "/hable-con-nosotros",
      hasLink: true
  },
    /* {
      text: "Preguntas frecuentes",
      url: "/",
      hasLink: false
  },
  {
      text: "Sobre nosotros",
      url: "/",
      hasLink: false
  }, */
    ],
    textSocial:"Visítanos en nuestras Redes Sociales",
    social:[{
        icon: iconUtils.icon(iconContants.AIFILLFACEBOOK),
        url: "/",
        active: false
    },
    {
        icon: iconUtils.icon(iconContants.AIFILLYOUTUBE),
        url: "/",
        active: false
    },
    {
        icon: iconUtils.icon(iconContants.AIFILLLINKEDIN),
        url: "https://www.linkedin.com/company/haap-tech/",
        active: true
    },
    
    ],
   
  },
}

export default websiteData;