import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';

const moreInformation=""
const buttonDataLink ={
 title:"Talk to an expert",
 url:"/talk-with-us"
}


const fraudProtectionData = {
    videBgFraudProtection:{
        clip:"",
        poster:"",
        title:"Abuse and Fraud Protection",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteFraudProtection:{
        headBandshowTitle:true,
        headBandTitle:"<span>Stop</span> credential stuffing, bots, and human web fraud with the smartest detection and <span>mitigation</span>.",
        classNameHead:"",
        headBandtext:""+
        "<br/>"+
        `
    <ul style="list-style-type: none;">
        <li>
            <p style="font-size: 24px; margin: 0;">Keep trust at the center of online experiences</p>
            <p style="font-size: 16px; margin: 0;">Protect online relationships & transactions without aggravating customers.</p>
        </li>
        <li style="margin-top: 20px;">
            <p style="font-size: 24px; margin: 0;">Spend less time on account access investigation</p>
            <p style="font-size: 16px; margin: 0;">Avoid the churn & resource drain of managing alerts & mitigating compromised accounts.</p>
        </li>
        <li style="margin-top: 20px;">
            <p style="font-size: 24px; margin: 0;">Find impostors before they steal customer assets</p>
            <p style="font-size: 16px; margin: 0;">See subtle anomalies in account activity with unrivaled web visibility.</p>
        </li>
    </ul>
`,
        items:[
            // {
            //     img:"",
            //     title:"Keep trust at the center of online experiences",
            //     label:"Protect online relationships & transactions without aggravating customers.",
            //     url:"",
            //     textUrl:moreInformation
            // },
            // {
            //     img:"",
            //     title:"Spend less time on account access investigation",
            //     label:"Avoid the churn & resource drain of managing alerts & mitigating compromised accounts.",
            //     url:"",
            //     textUrl:moreInformation
            // },
            // {
            //     img:"",
            //     title:"Find impostors before they steal customer assets",
            //     label:"See subtle anomalies in account activity with unrivaled web visibility.",
            //     url:"",
            //     textUrl:moreInformation
            // }
        ]
        
    },
    cardsFraudProtection:{
        title:"",
        text:"Abuse and <span>Fraud</span> Protection Products:",
        items:[
            // {
            //     img:"",
            //     title:"Bot Manager",
            //     label:"Stop the most dangerous, evasive bots before they erode customer trust.",
            //     url:"/detail/bot-manager",
            //     textUrl:moreInformation
            // },
            // {
            //     img:"",
            //     title:"Account Protector",
            //     label:"Keep fraudsters out — and trust intact — with account takeover protection.",
            //     url:"/detail/account-protector",
            //     textUrl:moreInformation
            // },
            // {
            //     img:"",
            //     title:"Audience Hijacking Protector",
            //     label:"Keep customers on your site, maximize conversion rates, and minimize affiliate fraud.",
            //     url:"/detail/audience-hijacking-protector",
            //     textUrl:moreInformation
            // },
            // {
            //     img:"",
            //     title:"Identity Cloud",
            //     label:"Create seamless and secure customer experiences.",
            //     url:"/detail/identity-cloud",
            //     textUrl:moreInformation
            // }
            // ,
            // {
            //     img:"",
            //     title:"Brand Protector",
            //     label:"Stop brand impersonations before they start.",
            //     url:"/detail/brand-protector",
            //     textUrl:moreInformation
            // }
        ]
    },
    urlFormFraudProtection: buttonDataLink.url,
    texBtntFormFraudProtection:buttonDataLink.title,
}

export default fraudProtectionData;