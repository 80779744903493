const constants = {
    breakpoints: {
        xs: 0,
        sm: 768,
        md: 992,
        lg: 1024,
        xl: 1280
    },
    language : {
        ES: "es",
        ESMX: "es-mx",
        EN: "en",
        PT:"pt",
        ENGB: "en-gb", //United Kingdom
        RU: "ru", //
        DE: "de", //Aleman
        FR: "fr",
        
    }
}

export default constants;