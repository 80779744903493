import logo from "../../assets/img/branding/haapTech.png";
import logoMini from "../../assets/img/branding/haapMini.png"
import logoSecond from "../../assets/img/branding/akamai.png"
import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import constants from "../../constants";

const websiteData = {
    home: {
        alt: "Logo",
        href: "/pt",
        imgPath: logo,
        imgPathMini:logoMini
    },
    menu: [
      {
        name: "Inicio",
        href: "/",
      },
      {
        name: "SEGURANÇA",
        href: "/seguranca",
      },
      {
        name: "Entrega de conteúdo",
        href: "/entrega-de-conteudo",
      },
      
      // {
      //   name: "Computação em nuvem",
      //   href: "/computacao-em-nuvem",
      // },
      {
        name: "IMPLEMENTAÇÃO​",
        href: "/implementacao",
      },
      {
        name: "FALE COM A GENTE",
        href: "/fale-com-a-gente",
      },
      // {
      //   name: "¿Por que Akamai?",
      //   href: "/porque-akamai",
      // },
    ],
    languagesItems: [
      
      {
        name: "English",
        value: constants.language.EN,
        icon: "",
        tag:"EN"
      },
      // {
      //   name: "Español",
      //   value: constants.language.ES,
      //   icon: "",
      //   tag:"ES"
      // },
      // {
      //   name: "Portugues",
      //   value: constants.language.PT,
      //   icon: "",
      //   tag: "PT"
      // },
      /*{
        name: "Deutsch",
        value: constants.language.DE,
        icon: "imagen.jpg"
      }, */
    ],
    siteLocale: constants.language.PT,
    domain: "http://localhost:3000",
    siteRouter : [
      {
        pathname: "/",
        basename: "/",
        canonical: "/",
        title: "texto de titulo",
        description: "texto de titulo",
        keywords: "texts, home, pruebas",
        img: "img.jpg",
        homePage: true,
        keypage:"home",
        follow: null //default inddex, follow
      },
      {
        pathname: "/seguranca",
        basename: "/seguranca",
        canonical: "/seguranca",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "security-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/entrega-de-conteudo",
        basename: "/entrega-de-conteudo",
        canonical: "/entrega-de-conteudo",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "content-delivery-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/implementacao",
        basename: "/implementacao",
        canonical: "/implementacao",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "implementation-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/computacao-em-nuvem",
        basename: "/computacao-em-nuvem",
        canonical: "/computacao-em-nuvem",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "cloud-computing-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/fale-com-a-gente",
        basename: "/fale-com-a-gente",
        canonical: "/fale-com-a-gente",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "talk-with-us-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/porque-akamai",
        basename: "/porque-akamai",
        canonical: "/porque-akamai",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "why-akamai-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/politica-de-cookies",
        basename: "/politica-de-cookies",
        canonical: "/politica-de-cookies",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "cookie-policy-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/politica-de-privacidade",
        basename: "/politica-de-privacidade",
        canonical: "/politica-de-privacidade",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "privacy-policy-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/termos-e-condicoes",
        basename: "/termos-e-condicoes",
        canonical: "/termos-e-condicoes",
        title: "texto de titulo ejemplo 2",
        description: "texto de titulo ejemplio descripcion 2",
        keywords: "texts, home, pruebas, ejemplo 2",
        img: "img.jpg",
        keypage: "terms-of-use-page",
        follow: null //default inddex, follow
      },
      {
        pathname: "/infrastructure-security",
        basename: "/infrastructure-security",
        canonical: "/infrastructure-security",
        title: "Seguridad de infraestructura",
        description: "Seguridad de infraestructura",
        keywords: "Seguridad de infraestructura",
        img: "img.jpg",
        keypage: "infrastructure-security",
        follow: null //default inddex, follow
      },
      {
        pathname: "/application-security",
        basename: "/application-security",
        canonical: "/application-security",
        title: "Seguridad de aplicaciones",
        description: "Seguridad de aplicaciones",
        keywords: "Seguridad de aplicaciones",
        img: "img.jpg",
        keypage: "application-security",
        follow: null //default inddex, follow
      },
      {
        pathname: "/security-zero-trust",
        basename: "/security-zero-trust",
        canonical: "/security-zero-trust",
        title: "Seguridad zero trust",
        description: "Seguridad zero trust",
        keywords: "Seguridad zero trust",
        img: "img.jpg",
        keypage: "security-zero-trust",
        follow: null //default inddex, follow
      },
      {
        pathname: "/abuse-and-fraud-protection",
        basename: "/abuse-and-fraud-protection",
        canonical: "/abuse-and-fraud-protection",
        title: "Soluciones de protección contra fraude y abuso",
        description: "Soluciones de protección contra fraude y abuso",
        keywords: "Soluciones de protección contra fraude y abuso",
        img: "img.jpg",
        keypage: "fraud-protection",
        follow: null //default inddex, follow
      },
      {
        pathname: "/app-and-api-performance",
        basename: "/app-and-api-performance",
        canonical: "/app-and-api-performance",
        title: "Rendimiento de aplicaciones api",
        description: "Rendimiento de aplicaciones api",
        keywords: "Rendimiento de aplicaciones api",
        img: "img.jpg",
        keypage: "api-application-performance",
        follow: null //default inddex, follow
      },
      {
        pathname: "/media-delivery",
        basename: "/media-delivery",
        canonical: "/media-delivery",
        title: "Entrega de mídia",
        description: "Entrega de mídia",
        keywords: "Entrega de mídia",
        img: "img.jpg",
        keypage: "multimedia-content-distribution",
        follow: null //default inddex, follow
      },
      {
        pathname: "/detalhes/",
        basename: "/detalhes/:id",
        canonical: "/detalhes ",
        title: "Product detail",
        description: "Product detail",
        keywords: "Product detail",
        img: "img.jpg",
        keypage: "product-detail",
        follow: null //default inddex, follow
      },
    ],
    footerInformation:{
      alt: "Logo",
      href: "/pt",
      imgPath: logo,
      imgPathSecond:"",
      textMini: "",
      textTitle: "Ajuda e informação",
      links: [{
        text: "Termos e condições",
        url: "/termos-e-condicoes",
        hasLink: true
    },
    {
        text: "Politica de privacidade",
        url: "/politica-de-privacidade",
        hasLink: true
    },
    {
      text: "Politica de cookies",
      url: "/politica-de-cookies",
      hasLink: true
    },
  {
      text: "Entre em contato conosco",
      url: "/fale-com-a-gente",
      hasLink: true
  },  
   /* {
      text: "Preguntas frecuentes",
      url: "/",
      hasLink: false
  },
  {
      text: "Sobre nosotros",
      url: "/",
      hasLink: false
  }, */
    ],
    textSocial:"Visite-nos nas nossas redes sociais",
    social:[{
        icon: iconUtils.icon(iconContants.AIFILLFACEBOOK),
        url: "/",
        active: false
    },
    {
        icon: iconUtils.icon(iconContants.AIFILLYOUTUBE),
        url: "/",
        active: false
    },
    {
        icon: iconUtils.icon(iconContants.AIFILLLINKEDIN),
        url: "https://www.linkedin.com/company/haap-tech/",
        active: true
    },
    
    ],
      
  },
}

export default websiteData;