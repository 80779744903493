export default {
    "general.test": "mensaje de prueba general",
    "general.menu": "MENU",
    "general.close": "FECHAR",
    "general.contactUs": "Contate-nos",
    "general.getcontact": "Entre em contato conosco",
    "general.formSuccess": "Mensagem foi enviada, em breve entraremos em contato",
    "general.formErrorMail": "E-mail inválido",
    "general.formError" : "Não foi possível enviar o e-mail, tente novamente.",
    "general.stops" : "Interrompe:",
    "general.titleForm" : "<span>FALE</span> COM A GENTE",
    "general.descForm" : "Deseja conversar sobre as soluções ideais para você? Ou obter uma demonstração? Fale com um dos nossos expertos.",
    "general.titleTermsOfUse" : "Condições de uso",
    "general.titleCookiePolicy" : "POLÍTICA DE COOKIES",
    "general.titlePrivacyPolicy" : "Política de privacidade",
    "general.moreInformation" : "Mais informações >",
}