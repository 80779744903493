import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import appApi from "../../assets/img/branding/cards/delivery/App_APIPerformance.png";
import media from "../../assets/img/branding/cards/delivery/MediaDelivery.png";


const contentDeliveryData = {
    videBgDelivery:{
        clip:"",
        title:"Entrega de conteúdo",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteDelivery:{
        headBandshowTitle:true,
        headBandTitle:"",
        classNameHead:"",
        headBandtext:"<span>Você cria experiências digitais extraordinárias</span>. Confie na agilidade e no dimensionamento do Akamai Intelligent Edge para ajudar você a entregá-las com perfeição.",
        
    },
    cardsDelivery:{
        title:"",
        text:"",
        items:[
            {
                img:appApi,
                title:"Desempenho de Apps & APIs",
                label:"Atraia clientes com desempenho rápido e envolvente de aplicações e APIs. Aprimore a experiença do usuario com analise de dados e automação sólidos.",
                url:"/app-and-api-performance"
            },
            {
                img:media,
                title:"Entrega de mídia",
                label:"Ofereça experiências de streaming incríveis de maneira consistente para qualquer público, em qualquer dispositivo.",
                url:"/media-delivery"
            },
            
        ]
    },
    formVideoDelivery:{
        row:3,
        placeholderMessage:"Message",
        typeEmail:"email",
        size:"md",
        placeholderEmail:"Email",
        idEmail:"email",
        requiredEmail:true,
        classNameInput:"form-basic",
        typeName:"text",
        placeholderName:"First Name",
        idName:"name",
        requiredName:true,
        placeholderLastName:"Last Name",
        typeLastName:"text",
        idLastName:"lastname",
        requiredLastName:true,
        typeButton:"submit",
        textButton:"Send",

        urlVideo:"",
        show:false,
    },
    urlFormDelivery:"/fale-com-a-gente",
    texBtntFormDelivery:"Fale com um especialista",
}

export default contentDeliveryData;