export default {
    "general.test": "general test message",
    "general.menu": "MENU",
    "general.close": "CLOSE",
    "general.contactUs": "Contac Us",
    "general.getcontact": "Get In Touch With US",
    "general.formSuccess": "Message has been sent, we will contact you shortly",
    "general.formErrorMail": "Invalid email",
    "general.formError" : "The email could not be sent, try again.",
    "general.stops" : "Stops:",
    "general.titleForm" : "<span>TALK</span> WITH US",
    "general.descForm" : "Want to discuss the right solutions for you? Or get a demo? Talk with one of our experts one on one.",
    "general.titleTermsOfUse" : "Terms of Use",
    "general.titleCookiePolicy" : "COOKIES POLICY",
    "general.titlePrivacyPolicy" : "Privacy Policy ",
    "general.moreInformation" : "",
}