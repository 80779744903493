import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';

const moreInformation=""
const buttonDataLink ={
 title:"Talk to an expert",
 url:"/talk-with-us"
}


const multimediaContentDistribution = {
    videBgMultimediaContentDistribution:{
        clip:"",
        poster:"",
        title:"Content Delivery Solutions (CDN)",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteMultimediaContentDistribution:{
        headBandshowTitle:true,
        headBandTitle:"<span>Captivate</span> millions of viewers. Keep them coming back.",
        classNameHead:"",
        headBandtext:"Consistently deliver amazing streaming experiences to the largest audiences, on any device.Achieve maximum performance in the biggest events. Anticipate cloud infrastructure costs. Make strategic decisions based on complex information.",
        
    },
    cardsMultimediaContentDistribution:{
        title:"",
        text:"Media <span>Delivery</span> solutions:",
        items:[
            {
                img:"",
                title:"Adaptive Media Delivery",
                label:"Deliver consistent, high-quality online video securely at massive scale.",
                url:"/detail/adaptive-media-delivery",
                textUrl:moreInformation
            },
            {
                img:"",
                title:"Cloud Wrapper",
                label:"Save on costs and improve origin offload with a private cache footprint.",
                url:"/detail/cloud-wrapper",
                textUrl:moreInformation
            },
            {
                img:"",
                title:"CloudTest",
                label:"Stress test your websites and apps with robust, real-time load testing.",
                url:"/detail/cloud-test",
                textUrl:moreInformation
            }, 
            {
                img:"",
                title:"DataStream",
                label:"Get an inside look at CDN performance with log data at scale.",
                url:"/detail/datastream",
                textUrl:moreInformation
            },    
            {
                img:"",
                title:"Entrega/distribución dedicada",
                label:"Offer superior live experiences with high-performance on-net edge delivery.",
                url:"/detail/delivery-distribution",
                textUrl:moreInformation
            },
            {
                img:"",
                title:"Download Delivery",
                label:"Deliver large file downloads flawlessly, every time, at global scale.",
                url:"/detail/download-delivery",
                textUrl:moreInformation
            }
            ,
            {
                img:"",
                title:"Media Analytics",
                label:"Analyze video delivery performance, viewer engagement, and audience behavior.",
                url:"/detail/media-analytics",
                textUrl:moreInformation
            },
            {
                img:"",
                title:"Media Services Live",
                label:"Deliver a broadcast-quality experience to live-streaming viewers.",
                url:"/detail/media-services-live",
                textUrl:moreInformation
            }
            ,
            {
                img:"",
                title:"NetStorage",
                label:"Store and protect content with the most globally distributed cloud storage.",
                url:"/detail/netstorage",
                textUrl:moreInformation
            }
        ]
    },
    urlFormMultimediaContentDistribution:buttonDataLink.url,
    texBtntFormMultimediaContentDistribution:buttonDataLink.title,
}

export default multimediaContentDistribution;