import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import location from "../../assets/img/icons/haap/location.svg";
import connection from "../../assets/img/icons/haap/connection.svg";
import global from "../../assets/img/icons/haap/global.svg";
import capacity from "../../assets/img/icons/haap/capacity.svg";
import monitoring from "../../assets/img/icons/haap/verified-monitoring.svg";
import users from "../../assets/img/icons/haap/users.svg";


const whyAkamaiData = {
    videBgAkamai:{
        clip:"",
        title:"¿POR QUÉ AKAMAI?",
        text:"",
        showContentText:true,
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteAkamai:{
        headBandshowTitle:true,
        headBandTitle:"¿Por qué Akamai? ",      
        headBandtext:"Porque <span>Akamai</span> es el proveedor de referencia en el mundo de la entrega de contenidos online y la seguridad. Así que nos convertimos en Premier Partner socio e implementador estratégico.",
        
    },
    infoAkamai:{
        text:'<p>Todos los días, miles de millones de personas acceden online a sus marcas favoritas para comprar, jugar, compartir ideas o administrar dinero, entre otras. Es posible que no lo sepan, pero Akamai está allí, dando energía y protegiendo la vida online.</p>        <p>Hace más de 20 años que Akamai se propuso resolver el desafío más complicado de los inicios de Internet: el "World Wide Wait". Y, desde entonces, han resuelto los retos más complejos de Internet trabajando por su visión de un mundo más seguro y más conectado.</p><p>Gracias a la plataforma informática más distribuida del mundo, de la nube al Edge, las empresas pueden desarrollar y ejecutar las aplicaciones con facilidad, mientras acercan las experiencias a los usuarios y mantienen las amenazas a raya. Por eso, las empresas innovadoras de todo el mundo eligen Akamai para crear, proteger y ofrecer sus experiencias digitales.</p><p>El conjunto de soluciones de seguridad informática y distribución ayuda a empresas internacionales a mejorar la vida de miles de millones de personas miles de millones de veces al día.</p><p>Es por eso que trabajamos en conjunto con Akamai.</p><ul><li>18/20 de las principales empresas de telecomunicación</li><li>20/20 de las maiores corredurías</li><li>10/10 de los maiores servicios globales de streaming de vídeo</li><li>10/10 de las principales empresas globales de videogame</li><li>30/30 de las principales empresas de comunicación</li><li>10/12 de las principales empresas de seguridad y software</li><li>9/10 de los mejores bancos del mundo</li></ul>',
        listItems:[
            {
                icon:location,
                count:4.100,
                decimal:3,
                complement:"+",
                label:"Ubicaciones"
            },
            {
                icon:connection,
                count:1.300,
                decimal:3,
                complement:"+",
                label:"Redes"
            },
            {
                icon:global,
                count:134,
                decimal:"",
                complement:"",
                label:"Países"
            },
            {
                icon:capacity,
                count:800,
                decimal:"",
                complement:"+ Tbps",
                label:"Capacidad"
            },
            {
                icon:monitoring,
                count:24,
                decimal:"",
                complement:"/7",
                label:"Supervisión"
            },
            {
                icon:users,
                count:1.900,
                decimal:3,
                complement:"+",
                label:"Expertos en servicios"
            },
        ],
        
    },
    formVideoAkamai:{
        row:3,
        placeholderMessage:"Message",
        typeEmail:"email",
        size:"md",
        placeholderEmail:"Email",
        idEmail:"email",
        requiredEmail:true,
        classNameInput:"form-basic",
        typeName:"text",
        placeholderName:"First Name",
        idName:"name",
        requiredName:true,
        placeholderLastName:"Last Name",
        typeLastName:"text",
        idLastName:"lastname",
        requiredLastName:true,
        typeButton:"submit",
        textButton:"Send",

        urlVideo:"",
        show:false,
    }
}

export default whyAkamaiData;