import { useIntl } from "react-intl";
import { useState,useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useGlobalState } from "../store/StoreProvider";
import BgVideo from "@mzp/commons/dist/layout/BgVideo";
import HeadbandText from "@mzp/commons/dist/dataDisplay/HeadbandText";
import Cards from '@mzp/commons/dist/surfaces/Cards';
import image from "../assets/img/branding/landing/SecurityZeroTrust.jpeg";
import AutoLayoutColumns from "@mzp/commons/dist/layout/AutoLayoutColumns"



const SecurityZeroTrust = (props) => {
    const {resourceSite } = props;
    const intl = useIntl();
    const { locale } = useGlobalState();
    const [scrollItems, setScrollItems] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
  
    const changeTransitionItems = () => {
        const elem = document.querySelector("#itemsoffer");
        var windowheight= window.scrollY;
        var itemTop =  (elem != null && elem !== undefined && elem && elem.offsetTop) ? elem.offsetTop : 0;;
        if(windowheight > (itemTop - 650)){
          setScrollItems(true)
        }else{
          setScrollItems(false)
        }
    }
  
    window.addEventListener('scroll', changeTransitionItems);
  
  
    return (
      <div className="infrastructure-security-section">
            {resourceSite.videBgSecurityZeroTrust.clip ? (
          <BgVideo
            clip={resourceSite.videBgSecurityZeroTrust.clip}
            poster={resourceSite.videBgSecurityZeroTrust.poster}
            title={resourceSite.videBgSecurityZeroTrust.title}
            text={resourceSite.videBgSecurityZeroTrust.text}
            iconDirection={resourceSite.videBgSecurityZeroTrust.iconDirection}
            locale={locale}
          />
        ) : (
          <div
            className="bg-image bg-protection"
            style={{ backgroundImage: `url(${image})` }}
          >
            <h2>{resourceSite.videBgSecurityZeroTrust.title}</h2>
            <div className="overlay"></div>
          </div>
        )}
        <section className=''>
            {/* <HeadbandText 
              text={resourceSite.infoSiteSecurityZeroTrust.headBandtext}
              title={resourceSite.infoSiteSecurityZeroTrust.headBandTitle}
              showDivision={false}
              locale={locale}
              fontSizeTitle={"23px"}
              fontSizeText={"16px"}
        /> */}

<HeadbandText 
            title={""}
            text={resourceSite.infoSiteSecurityZeroTrust.headBandTitle}
            locale={locale}
            showTitle={false}
            showText={true}
            classNameHead={""}
            showDivision={false}
            classNameContainer={"text-center center-element"}
            fontSizeTitle={""}
            fontSizeText={""}
            colsm={0}
            colmd={0} 
      />
        </section>
        <section className="what-we-offer">
              <Container>    
              <HeadbandText
            locale={locale}
            title={
              resourceSite.infoSiteSecurityZeroTrust.headBandtext
            }
            text={
              ""
            }
            showTitle={true}
            showText={false}
            classNameHead={"headband-white"}
            showDivision={false}
            classNameContainer={"text-center"}
            fontSizeTitle={"20px"}
            fontSizeText={""}
            colsm={0}
            colmd={0} 
          />
                  <div className={`what-we-offer-list-card  ${scrollItems ? 'activeAnimation' : ''}`} id="itemsoffer">
                  <AutoLayoutColumns
          containerLayout={
            resourceSite.cardsSecurityZeroTrust.items.length === 4
              ? "container-layout-four-column"
              : "container-layout-column"
          }
        >
                           {resourceSite.cardsSecurityZeroTrust.items && resourceSite.cardsSecurityZeroTrust.items.map((item, index) => {
                          return <Cards
                              key={index}
                              showImage={false}
                              image={item.img}
                              classNameCard="card-haap"
                          >
                              <div className='card-content-body'>
                                  <h3>{item.title}</h3>
                                  <p>{item.label}</p>  
                                  <div className='line-border'></div>  
                              </div>
                              <Link to={`/${locale}${item.url}`} reloadDocument className='card-url'>{item.textUrl} </Link>    
                          </Cards>
                         })}
            </AutoLayoutColumns>                  
       
                                      
                  </div>               
              </Container>        
          </section>
          <Link className="btn-talk" to={`/${locale}${resourceSite.urlFormSecurityZeroTrust}`}>{resourceSite.texBtntFormSecurityZeroTrust}</Link>
       
          
      </div>
    );
  };
  
  export default SecurityZeroTrust;
    