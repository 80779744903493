import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import img1 from "../../assets/img/branding/cards/implementation/AddedValue.jpg";
import img2 from "../../assets/img/branding/cards/implementation/Implementors.jpg";
import img3 from "../../assets/img/branding/cards/implementation/Strategy.jpg";


const implementationData = {
    videBgImplementation:{
        clip:"",
        title:"IMPLEMENTATION",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    cardsImplementation:{
        title:"",
        text:"",
        items:[
            {
                img:img1,
                title:"Added-Value",
                label:"We have teams of dedicated engineers who will be your first port of call for any for any issue, whether it’s cybersecurity or site speed/stability. We will monitor performance and give regular feedback and suggestions, so your system always remains at the cutting edge",
                url:"/"
            },
            {
                img:img2,
                title:"Implementors",
                label:"We will make sure to identify the right solutions for your challenges… and liaise with the most suitable experts to implement what you need, when you need it.",
                url:"/"
            },
            {
                img:img3,
                title:"Strategy",
                label:"Our experts will help you to formulate the strategies that your realities require and always keep them up to date in the rapidly evolving landscape.",
                url:"/"
            },
            
        ]
    },
    formVideoImplementation:{
        row:3,
        placeholderMessage:"Message",
        typeEmail:"email",
        size:"md",
        placeholderEmail:"Email",
        idEmail:"email",
        requiredEmail:true,
        classNameInput:"form-basic",
        typeName:"text",
        placeholderName:"First Name",
        idName:"name",
        requiredName:true,
        placeholderLastName:"Last Name",
        typeLastName:"text",
        idLastName:"lastname",
        requiredLastName:true,
        typeButton:"submit",
        textButton:"Send",

        urlVideo:"",
        show: false
    },
    urlFormImplementation:"/talk-with-us",
    texBtntFormImplementation:"Talk to an expert",
}

export default implementationData;