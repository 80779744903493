import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';


const applicationSecurityData = {
    videBgApplicationSecurity:{
        clip:"",
        poster:"",
        title:"Segurança de aplicações e APIs",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteApplicationSecurity:{
        headBandshowTitle:true,
        headBandTitle:"Proteja-se contra <span>vulnerabilidades</span> que se multiplicam facilmente",
        classNameHead:"",
        headBandtext:"As aplicações controlam nosso mundo. Contudo, com o aumento do número de apps e usuários, também crescem os riscos à segurança. Nosso conjunto de soluções para apps e APIs protege sua empresa com WAAP, gerenciamento de bots, segurança de APIs e proteção contra DDoS de renome que oferecem a visibilidade de ameaças e as defesas contra ataques de que você precisa.Conte com defesas que se atualizam automaticamente, ajustam-se às suas políticas e mantêm o alto desempenho mesmo enquanto combatem botnets ou ataques de DDoS em grande escala.",
        
    },
    cardsApplicationSecurity:{
        title:"",
        text:"Proteja sua marca com <span>soluções</span> de segurança de apps e APIs contra ameaças persistentes:",
        items:[
            {
                img:"",
                title:"API Security",
                label:"Obtenha visibilidade total de todo o seu patrimônio de APIs com detecção e monitoramento contínuos.",
                url:"/detalhes/api-security",
                textUrl:""
            },
            {
                img:"",
                title:"App & API Protector",
                label:"Segurança completa e sem comprometimento para websites, aplicações e APIs.",
                url:"/detalhes/account-protector",
                textUrl:""
            },
            {
                img:"",
                title:"Prolexic",
                label:"Impeça ataques de DDoS com a defesa mais rápida, eficaz e em escala.",
                url:"/detalhes/prolexic",
                textUrl:""
            },
            {
                img:"",
                title:"Page Integrity Manager",
                label:"Defenda seu website contra ameaças vindas dos clientes. Identifique e bloqueie atividades mal-intencionadas.",
                url:"/detalhes/page-integrity-manager",
                textUrl:""
            },
            {
                img:"",
                title:"Edge DNS",
                label:"Conte com um DNS (Sistema de Nomes de Domínio) altamente seguro para ter disponibilidade ininterrupta das APIs e aplicações Web.",
                url:"/detalhes/edge-dns",
                textUrl:""
            }
        ]
    },
    urlFormApplicationSecurity:"/fale-com-a-gente",
    texBtntFormApplicationSecurity:"Fale com um especialista",
}

export default applicationSecurityData;