import FooterSlim from "@mzp/commons/dist/footers/FooterSlim";


const FooterLayout = (props) => {
    const { locale, resourceSite } = props;

    return <FooterSlim 
            locale={locale}
            footer={resourceSite.footerInformation}
            showSocial={true}
            border={true}
    />;
}

export default FooterLayout;