import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';


const multimediaContentDistribution = {
    videBgMultimediaContentDistribution:{
        clip:"",
        poster:"",
        title:"Soluções de entrega de conteúdo (CDN)",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteMultimediaContentDistribution:{
        headBandshowTitle:true,
        headBandTitle:"<span>Cative</span> milhões de espectadores. Faça com que eles voltem sempre..",
        classNameHead:"",
        headBandtext:"Ofereça experiências de streaming incríveis de maneira consistente para o público, em qualquer dispositivo.Obtenha o máximo de desempenho nos maiores eventos. Antecipe os custos da infraestrutura de nuvem. Tome decisões estratégicas com base em informações complexas.",
        
    },
    cardsMultimediaContentDistribution:{
        title:"",
        text:"<span>Entrega</span> de mídia:",
        items:[
            {
                img:"",
                title:"Adaptive Media Delivery",
                label:"Entregue vídeos online consistentes, de alta qualidade e com segurança em grande escala.",
                url:"/detalhes/adaptive-media-delivery",
                textUrl:""
            },
            {
                img:"",
                title:"Cloud Wrapper",
                label:"Economize nos custos e melhore o descarregamento de origem com um espaço de cache privado.",
                url:"/detalhes/cloud-wrapper",
                textUrl:""
            },
            {
                img:"",
                title:"CloudTest",
                label:"Teste a resiliência dos seus websites e suas aplicações com testes de carga robustos e em tempo real.",
                url:"/detalhes/cloud-test",
                textUrl:""
            }, 
            {
                img:"",
                title:"DataStream",
                label:"Entenda a fundo o desempenho da CDN (Rede de Entrega de Conteúdo) com dados de log em escala.",
                url:"/detalhes/datastream",
                textUrl:""
            },    
            {
                img:"",
                title:"Entrega/distribución dedicada",
                label:"Ofereça excelentes experiências ao vivo com entrega de alto desempenho na edge.",
                url:"/detalhes/delivery-distribution",
                textUrl:""
            },
            {
                img:"",
                title:"Download Delivery",
                label:"Entregue downloads de grandes arquivos sem falhas, todas as vezes e em escala global.",
                url:"/detalhes/download-delivery",
                textUrl:""
            }
            ,
            {
                img:"",
                title:"Media Analytics",
                label:"Analise o desempenho da entrega de vídeo, o engajamento dos espectadores e o comportamento do público.",
                url:"/detalhes/media-analytics",
                textUrl:""
            },
            {
                img:"",
                title:"Media Services Live",
                label:"Proporcione uma experiência com qualidade de transmissão para os espectadores de streaming ao vivo.",
                url:"/detalhes/media-services-live",
                textUrl:""
            }
            ,
            {
                img:"",
                title:"NetStorage",
                label:"Armazene e proteja conteúdo com o armazenamento em nuvem mais globalmente distribuído.",
                url:"/detalhes/netstorage",
                textUrl:""
            }
        ]
    },
    urlFormMultimediaContentDistribution:"/fale-com-a-gente",
    texBtntFormMultimediaContentDistribution:"Fale com um especialista",
}

export default multimediaContentDistribution;