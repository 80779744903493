import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import img1 from "../../assets/img/branding/cards/implementation/AddedValue.jpg";
import img2 from "../../assets/img/branding/cards/implementation/Implementors.jpg";
import img3 from "../../assets/img/branding/cards/implementation/Strategy.jpg";

const implementationData = {
    videBgImplementation:{
        clip:"",
        title:"IMPLEMENTAÇÃO​",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    cardsImplementation:{
        title:"",
        text:"",
        items:[
            {
                img:img1,
                title:"Valor agregado",
                label:"Temos equipes de engenheiros dedicados que serão seu primeiro porto de escala para qualquer problema, seja segurança cibernética ou velocidade/estabilidade do seu site. Monitoraremos o desempenho e daremos feedback e sugestões regulares, para que seu sistema permaneça sempre na vanguarda.",
                url:"/"
            },
            {
                img:img2,
                title:"Implementadores",
                label:"Garantiremos a identificação das soluções Akamai certas para seus desafios... e coordenaremos com os especialistas da Akamai para implementar o que você precisa, quando você precisa.",
                url:"/"
            },
            {
                img:img3,
                title:"Estratégia",
                label:"Nossos especialistas irão ajudá-lo a formular as estratégias que suas realidades exigem e sempre mantê-los atualizados neste cenário global em tão rápida evolução.",
                url:"/"
            },
            
        ]
    },
    formVideoImplementation:{
        row:3,
        placeholderMessage:"Message",
        typeEmail:"email",
        size:"md",
        placeholderEmail:"Email",
        idEmail:"email",
        requiredEmail:true,
        classNameInput:"form-basic",
        typeName:"text",
        placeholderName:"First Name",
        idName:"name",
        requiredName:true,
        placeholderLastName:"Last Name",
        typeLastName:"text",
        idLastName:"lastname",
        requiredLastName:true,
        typeButton:"submit",
        textButton:"Send",

        urlVideo:"",
        show: false
    },
    urlFormImplementation:"/fale-com-a-gente",
    texBtntFormImplementation:"Fale com um especialista",
}

export default implementationData;