import { useIntl } from "react-intl";
import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BgVideo from "@mzp/commons/dist/layout/BgVideo";
import { useGlobalState } from "../store/StoreProvider";
import HeadbandText from "@mzp/commons/dist/dataDisplay/HeadbandText";
import FormBasic from '@mzp/commons/dist/forms/FormBasic';
import ItemIconLabel from '@mzp/commons/dist/dataDisplay/ItemIconLabel';
import parse from 'html-react-parser';
import image from "../assets/img/branding/landing/bgGeneral.jpg";

const WhyAkamai = (props) => {
  const {resourceSite } = props;
  const intl = useIntl();
  const { locale } = useGlobalState();


  return (
    <div className="why-akamai-section">
        {resourceSite.videBgAkamai.clip ? (
        <BgVideo 
            clip={resourceSite.videBgAkamai.clip}
            poster={resourceSite.videBgAkamai.poster}
            title={resourceSite.videBgAkamai.title}
            text={resourceSite.videBgAkamai.text}
            iconDirection={resourceSite.videBgAkamai.iconDirection}
            locale={locale}
            showContentText={resourceSite.videBgAkamai.showContentText}
        />
      ) : (
        <div className="bg-image bg-protection" style={{ backgroundImage:`url(${image})` }}>
            <h2>Application and API <span>security</span></h2>
            <p>We are expanding our services as well as our global reach and are updating our website to reflect this.</p>
            <div className="overlay"></div>
        </div>
          )}    
      <section className='why-haap-tech'>
          <HeadbandText 
            text={resourceSite.infoSiteAkamai.headBandtext}
            title={resourceSite.infoSiteAkamai.headBandTitle}
            showTitle={resourceSite.infoSiteAkamai.headBandshowTitle}
            locale={locale}
      />
      </section>
         <section className='haap-why'>
            <Container>
                <Row>
                    <Col md={6}>
                        <div className='why-text'>
                            {parse(resourceSite.infoAkamai.text)}
                        </div>
                        
                    </Col>
                    <Col md={6}>
                        <div className='why-list'>
                        {resourceSite.infoAkamai.listItems && resourceSite.infoAkamai.listItems.map((item, index) => {
                            return <div className='why-list-item'  key={index} >
                                <ItemIconLabel                                   
                                    icon={item.icon}
                                    number={item.count}
                                    decimal={item.decimal}
                                    complement={item.complement}
                                    text={item.label}
                                />
                            </div>
                         })}                            
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {resourceSite.formVideoAkamai.show && (
        <section className='contact-video'>
            <Container>
            <Row>
                <Col md={6} className="order-md-1 g-0 ">
                    <div className='min-video'>
                    <video autoPlay loop muted poster={resourceSite.videBgAkamai}>
                        <source src={resourceSite.formVideoAkamai.urlVideo} type='video/mp4' />
                        <source src={resourceSite.formVideoAkamai.urlVideo} type="video/ogg" /> 
                    </video>
                    </div>                    
                </Col>
                <Col md={6} className="order-md-0 rectangle">
                    <div className='form-landing'>
                        <h4>{intl.formatMessage({ id: "general.contactUs" })}</h4>
                        <p>{intl.formatMessage({ id: "general.getcontact" })}</p>
                        <FormBasic 
                                row={resourceSite.formVideoAkamai.row}
                                placeholderMessage={resourceSite.formVideoAkamai.placeholderMessage}
                                typeEmail={resourceSite.formVideoAkamai.typeEmail}
                                size={resourceSite.formVideoAkamai.size}
                                placeholderEmail={resourceSite.formVideoAkamai.placeholderEmail}
                                idEmail={resourceSite.formVideoAkamai.idEmail}
                                requiredEmail={resourceSite.formVideoAkamai.requiredEmail}
                                classNameInput={resourceSite.formVideoAkamai.classNameInput}
                                typeName={resourceSite.formVideoAkamai.typeName}
                                placeholderName={resourceSite.formVideoAkamai.placeholderName}
                                idName={resourceSite.formVideoAkamai.idName}
                                requiredName={resourceSite.formVideoAkamai.requiredName}
                                typeLastName={resourceSite.formVideo.typeLastName}
                                placeholderLastName={resourceSite.formVideo.placeholderLastName}
                                idLastName={resourceSite.formVideo.idLastName}
                                requiredLastName={resourceSite.formVideo.requiredLastName}
                                textButton={resourceSite.formVideo.textButton}
                                typeButton={resourceSite.formVideoAkamai.typeButton}
                        />
                    </div>
                </Col>                
            </Row>
            </Container>                         
        </section>
        )}                 
      
    </div>
  );
};

export default WhyAkamai;
