const talkWithUsData = {
    formContact: {
        name: {
          placeholder:"Nombre*",      
          id: "firstName",
          name:"name",
          type: "text",
          required:true, 
        },
        lastName: {
          placeholder: "Apellidos*",
          id: "lastName",
          name:"lastName",
          type: "text",
          required:true, 
        },
        email: {
          placeholder: "Correo electrónico*",
          id: "email",
          name:"email",
          type: "email",
          required:true,
        },
        company: {
          placeholder: "Empresa*",
          id: "company",
          name:"company",
          type: "text",
          required:true, 
        },
        department: {
          id: "department",
          name:"department",
          required:true, 
          items: [
            {
                name:"Selecciona Departamento*",
                value:"",
            },
            {
                name:"IT: Applications/App Owners",
                value:"TI: Aplicaciones/Propietarios de aplicaciones"
            },
            {
                name:"IT: Architect",
                value:"TI: Arquitecto"
            },
            {
              
                name:"IT: Data Center",
                value:"TI: Centro de datos"
            },
            {
                name:"IT: Developer",
                value:"TI: Desarrollador"
            },
            {
                name:"IT: Engineering",
                value:"TI: Ingeniería"
            },
            {
                name:"IT: General",
                value:"TI: General"
            },
            {
                name:"IT: Network/Architecture/Infrastructure",
                value:"TI: Red/Arquitectura/Infraestructura"
            },
            {
                name:"IT: Operations",
                value:"TI: Operaciones"
            },
            {
                name:"IT: Program/Project Manager",
                value:"TI: Gestor de proyectos/Director de programa"
            },
            {
                name:"IT: Security",
                value:"TI: Seguridad"
            },
            {
                name:"Analyst",
                value:"Analista"
            },
            {
                name:"Content Production",
                value:"Producción de contenido"
            },
            {
                name:"Consultant",
                value:"Asesor"
            },
            {
                name:"Digital/Internet Entertainment",
                value:"Entretenimiento digital/por Internet"
            },
            {
                name:"Engineering",
                value:"Ingeniería"
            },
            {
                name:"Finance",
                value:"Finanzas"
            },
            {
                name:"HR",
                value:"RR.&nbsp;HH."
            },
            {
                name:"Legal",
                value:"Departamento legal"
            },
            {
                name:"Marketing",
                value:"Marketing"
            },
            {
                name:"Media",
                value:"Multimedia"
            },
    
            {
                name:"Operations",
                value:"Operaciones"
            },
            {
                name:"Product Management",
                value:"Gestión de productos"
            },
            {
                name:"Production",
                value:"Producción"
            },
    
            {
                name:"Sales",
                value:"Ventas"
            },
            {
                name:"Student",
                value:"Estudiante"
            },
            {
                name:"Web",
                value:"Web"
            },
          ],
        },
        title:{
            id:"title",
            name:"title",
            required:true, 
            items:[
                {
                    name:"Selecciona Nivel de puesto*",
                    value:"",
                },           
                {
                    name:"Staff",
                    value:"Personal"
                },
                {
                    name:"Consultant",
                    value:"Asesor"
                },
                {
                    name:"Manager",
                    value:"Responsable"
                },
                {
                    name:"Director",
                    value:"Director"
                },
                {
                    name:"VP/Executive",
                    value:"Vicepresidente/Directivo"
                },
                {
                    name:"C-Level",
                    value:"Nivel directivo"
                }
            ],
        },
        country:{
            id:"country",
            name:"country",
            required:true,
            items:[
                {
                    value:"",
                    name:"Selcciona País"
                },
                {
                  value: "Afghanistan",
                  name: "Afganistán",
                },
                {
                  value: "Albania",
                  name: "Albania",
                },
                {
                  value: "Germany",
                  name: "Alemania",
                },
                {
                  value: "Andorra",
                  name: "Andorra",
                },
                {
                  value: "Angola",
                  name: "Angola",
                },
                {
                  value: "Anguilla",
                  name: "Anguila",
                },
                {
                  value: "Antigua and Barbuda",
                  name: "Antigua y Barbuda",
                },
                {
                  value: "Saudi Arabia",
                  name: "Arabia Saudí",
                },
                {
                  value: "Algeria",
                  name: "Argelia",
                },
                {
                  value: "Argentina",
                  name: "Argentina",
                },
                {
                  value: "Armenia",
                  name: "Armenia",
                },
                {
                  value: "Aruba",
                  name: "Aruba",
                },
                {
                  value: "Australia",
                  name: "Australia",
                },
                {
                  value: "Austria",
                  name: "Austria",
                },
                {
                  value: "Azerbaijan",
                  name: "Azerbaiyán",
                },
                {
                  value: "Bahamas",
                  name: "Bahamas",
                },
                {
                  value: "Bangladesh",
                  name: "Bangladés",
                },
                {
                  value: "Barbados",
                  name: "Barbados",
                },
                {
                  value: "Bahrain",
                  name: "Baréin",
                },
                {
                  value: "Belgium",
                  name: "Bélgica",
                },
                {
                  value: "Belize",
                  name: "Belice",
                },
                {
                  value: "Benin",
                  name: "Benín",
                },
                {
                  value: "Bermuda",
                  name: "Bermudas",
                },
                {
                  value: "Belarus",
                  name: "Bielorrusia",
                },
                {
                  value: "Bolivia",
                  name: "Bolivia",
                },
                {
                  value: "Bosnia and Herzegovina",
                  name: "Bosnia y Herzegovina",
                },
                {
                  value: "Botswana",
                  name: "Botsuana",
                },
                {
                  value: "Brazil",
                  name: "Brasil",
                },
                {
                  value: "Brunei Darussalam",
                  name: "Brunéi Darusalam",
                },
                {
                  value: "Bulgaria",
                  name: "Bulgaria",
                },
                {
                  value: "Burkina Faso",
                  name: "Burkina Faso",
                },
                {
                  value: "Burundi",
                  name: "Burundi",
                },
                {
                  value: "Bhutan",
                  name: "Bután",
                },
                {
                  value: "Capeverde",
                  name: "Caboverde",
                },
                {
                  value: "Cambodia",
                  name: "Camboya",
                },
                {
                  value: "Cameroon",
                  name: "Camerún",
                },
                {
                  value: "Canada",
                  name: "Canadá",
                },
                {
                  value: "Chad",
                  name: "Chad",
                },
                {
                  value: "Chile",
                  name: "Chile",
                },
                {
                  value: "China",
                  name: "China",
                },
                {
                  value: "Cyprus",
                  name: "Chipre",
                },
                {
                  value: "Vatican City",
                  name: "Ciudad delvaticano",
                },
                {
                  value: "Colombia",
                  name: "Colombia",
                },
                {
                  value: "Comoros",
                  name: "Comoras",
                },
                {
                  value: "Congo (the Democratic Republic of the)",
                  name: "Congo (República Democrática del)",
                },
                {
                  value: "Congo",
                  name: "Congo",
                },
                {
                  value: "North Korea",
                  name: "Corea del Norte",
                },
                {
                  value: "South Korea",
                  name: "Corea del Sur",
                },
                {
                  value: "Ivory Coast",
                  name: "Costa de Marfil",
                },
                {
                  value: "Costa Rica",
                  name: "Costa Rica",
                },
                {
                  value: "Croatia",
                  name: "Croacia",
                },
                {
                  value: "Cuba",
                  name: "Cuba",
                },
                {
                  value: "Curacao",
                  name: "Curazao",
                },
                {
                  value: "Denmark",
                  name: "Dinamarca",
                },
                {
                  value: "Dominica",
                  name: "Dominica",
                },
                {
                  value: "Ecuador",
                  name: "Ecuador",
                },
                {
                  value: "Egypt",
                  name: "Egipto",
                },
                {
                  value: "El Salvador",
                  name: "El Salvador",
                },
                {
                  value: "United Arab Emirates",
                  name: "Emiratos Árabes Unidos",
                },
                {
                  value: "Eritrea",
                  name: "Eritrea",
                },
                {
                  value: "Slovakia",
                  name: "Eslovaquia",
                },
                {
                  value: "Slovenia",
                  name: "Eslovenia",
                },
                {
                  value: "Spain",
                  name: "España",
                },
                {
                  value: "United States of America",
                  name: "Estados Unidos de América",
                },
                {
                  value: "Estonia",
                  name: "Estonia",
                },
                {
                  value: "Eswatini",
                  name: "Eswatini",
                },
                {
                  value: "Ethiopia",
                  name: "Etiopía",
                },
                {
                  value: "Russian Federation",
                  name: "Federación de Rusia",
                },
                {
                  value: "Philippines",
                  name: "Filipinas",
                },
                {
                  value: "Finland",
                  name: "Finlandia",
                },
                {
                  value: "Fiji",
                  name: "Fiyi",
                },
                {
                  value: "France",
                  name: "Francia",
                },
                {
                  value: "Gabon",
                  name: "Gabón",
                },
                {
                  value: "Gambia",
                  name: "Gambia",
                },
                {
                  value: "Georgia",
                  name: "Georgia",
                },
                {
                  value: "Ghana",
                  name: "Ghana",
                },
                {
                  value: "Gibraltar",
                  name: "Gibraltar",
                },
                {
                  value: "Grenada",
                  name: "Granada",
                },
                {
                  value: "Greece",
                  name: "Grecia",
                },
                {
                  value: "Greenland",
                  name: "Groenlandia",
                },
                {
                  value: "Guadeloupe",
                  name: "Guadalupe",
                },
                {
                  value: "Guam",
                  name: "Guam",
                },
                {
                  value: "Guatemala",
                  name: "Guatemala",
                },
                {
                  value: "French Guiana",
                  name: "Guayana Francesa",
                },
                {
                  value: "Guyana",
                  name: "Guayana",
                },
                {
                  value: "Guernsey",
                  name: "Guernsey",
                },
                {
                  value: "Equatorial Guinea",
                  name: "Guinea Ecuatorial",
                },
                {
                  value: "Guinea",
                  name: "Guinea",
                },
                {
                  value: "Guinea-bissau",
                  name: "Guinea-Bisáu",
                },
                {
                  value: "Haiti",
                  name: "Haití",
                },
                {
                  value: "Honduras",
                  name: "Honduras",
                },
                {
                  value: "Hong Kong",
                  name: "Hong Kong",
                },
                {
                  value: "Hungary",
                  name: "Hungría",
                },
                {
                  value: "India",
                  name: "India",
                },
                {
                  value: "Indonesia",
                  name: "Indonesia",
                },
                {
                  value: "Iraq",
                  name: "Irak",
                },
                {
                  value: "Iran",
                  name: "Irán",
                },
                {
                  value: "Ireland",
                  name: "Irlanda",
                },
                {
                  value: "Isle of Man",
                  name: "Isla de Man",
                },
                {
                  value: "Christmas Island",
                  name: "Isla de Navidad",
                },
                {
                  value: "Norfolk Island",
                  name: "Isla Norfolk",
                },
                {
                  value: "Iceland",
                  name: "Islandia",
                },
                {
                  value: "Aland Islands",
                  name: "Islas Åland",
                },
                {
                  value: "Cayman Islands",
                  name: "Islas Caimán",
                },
                {
                  value: "Cocos (Keeling) Islands",
                  name: "Islas Cocos (Keeling)",
                },
                {
                  value: "Cook Islands",
                  name: "Islas Cook",
                },
                {
                  value: "Faroe Islands",
                  name: "Islas Feroe",
                },
                {
                  value: "South Georgia and the South Sandwich Islands",
                  name: "Islas Georgias del Sur y Sandwich del Sur",
                },
                {
                  value: "Falkland Islands (Malvinas)",
                  name: "Islas Malvinas (Falkland Islands)",
                },
                {
                  value: "Northern Mariana Islands",
                  name: "Islas Marianas del Norte",
                },
                {
                  value: "Marshall Islands",
                  name: "Islas Marshall",
                },
                {
                  value: "US Minor Outlying Islands",
                  name: "Islas menores alejadas de los Estados Unidos",
                },
                {
                  value: "Solomon Islands",
                  name: "Islas Salomón",
                },
                {
                  value: "Turks and Caicos Islands",
                  name: "Islas Turcas y Caicos",
                },
                {
                  value: "British virgin Islands",
                  name: "Islas vírgenes Británicas",
                },
                {
                  value: "US virgin Islands",
                  name: "Islas vírgenes de los Estados Unidos",
                },
                {
                  value: "Israel",
                  name: "Israel",
                },
                {
                  value: "Italy",
                  name: "Italia",
                },
                {
                  value: "Jamaica",
                  name: "Jamaica",
                },
                {
                  value: "Japan",
                  name: "Japón",
                },
                {
                  value: "Jersey",
                  name: "Jersey",
                },
                {
                  value: "Jordan",
                  name: "Jordania",
                },
                {
                  value: "Kazakhstan",
                  name: "Kazajistán",
                },
                {
                  value: "Kenya",
                  name: "Kenia",
                },
                {
                  value: "Kyrgyzstan",
                  name: "Kirguistán",
                },
                {
                  value: "Kiribati",
                  name: "Kiribati",
                },
                {
                  value: "Kosovo",
                  name: "Kosovo",
                },
                {
                  value: "Kuwait",
                  name: "Kuwait",
                },
                {
                  value: "Lesotho",
                  name: "Lesoto",
                },
                {
                  value: "Latvia",
                  name: "Letonia",
                },
                {
                  value: "Lebanon",
                  name: "Líbano",
                },
                {
                  value: "Liberia",
                  name: "Liberia",
                },
                {
                  value: "Libya",
                  name: "Libia",
                },
                {
                  value: "Liechtenstein",
                  name: "Liechtenstein",
                },
                {
                  value: "Lithuania",
                  name: "Lituania",
                },
                {
                  value: "Luxembourg",
                  name: "Luxemburgo",
                },
                {
                  value: "Macau",
                  name: "Macao",
                },
                {
                  value: "Madagascar",
                  name: "Madagascar",
                },
                {
                  value: "Malaysia",
                  name: "Malasia",
                },
                {
                  value: "Malawi",
                  name: "Malaui",
                },
                {
                  value: "Maldives",
                  name: "Maldivas",
                },
                {
                  value: "Mali",
                  name: "Mali",
                },
                {
                  value: "Malta",
                  name: "Malta",
                },
                {
                  value: "Morocco",
                  name: "Marruecos",
                },
                {
                  value: "Martinique",
                  name: "Martinica",
                },
                {
                  value: "Mauritius",
                  name: "Mauricio",
                },
                {
                  value: "Mauritania",
                  name: "Mauritania",
                },
                {
                  value: "Mayotte",
                  name: "Mayotte",
                },
                {
                  value: "Mexico",
                  name: "México",
                },
                {
                  value: "Micronesia",
                  name: "Micronesia",
                },
                {
                  value: "Moldova",
                  name: "Moldavia",
                },
                {
                  value: "Monaco",
                  name: "Mónaco",
                },
                {
                  value: "Mongolia",
                  name: "Mongolia",
                },
                {
                  value: "Montenegro",
                  name: "Montenegro",
                },
                {
                  value: "Montserrat",
                  name: "Montserrat",
                },
                {
                  value: "Mozambique",
                  name: "Mozambique",
                },
                {
                  value: "Myanmar",
                  name: "Myanmar/Birmania",
                },
                {
                  value: "Namibia",
                  name: "Namibia",
                },
                {
                  value: "Nauru",
                  name: "Nauru",
                },
                {
                  value: "Nepal",
                  name: "Nepal",
                },
                {
                  value: "Nicaragua",
                  name: "Nicaragua",
                },
                {
                  value: "Niger",
                  name: "Níger",
                },
                {
                  value: "Nigeria",
                  name: "Nigeria",
                },
                {
                  value: "Niue",
                  name: "Niue",
                },
                {
                  value: "Norway",
                  name: "Noruega",
                },
                {
                  value: "New Caledonia",
                  name: "Nueva Caledonia",
                },
                {
                  value: "New Zealand",
                  name: "Nueva Zelanda",
                },
                {
                  value: "Oman",
                  name: "Omán",
                },
                {
                  value: "Netherlands",
                  name: "Países Bajos",
                },
                {
                  value: "Pakistan",
                  name: "Pakistán",
                },
                {
                  value: "Palau",
                  name: "Palaos",
                },
                {
                  value: "Palestine",
                  name: "Palestina",
                },
                {
                  value: "Panama",
                  name: "Panamá",
                },
                {
                  value: "Papua New Guinea",
                  name: "Papúa Nueva Guinea",
                },
                {
                  value: "Paraguay",
                  name: "Paraguay",
                },
                {
                  value: "Peru",
                  name: "Perú",
                },
                {
                  value: "Pitcairn",
                  name: "Pitcairn",
                },
                {
                  value: "French Polynesia",
                  name: "Polinesia Francesa",
                },
                {
                  value: "Poland",
                  name: "Polonia",
                },
                {
                  value: "Portugal",
                  name: "Portugal",
                },
                {
                  value: "Puerto Rico",
                  name: "Puerto Rico",
                },
                {
                  value: "Qatar",
                  name: "Qatar",
                },
                {
                  value: "United Kingdom",
                  name: "Reino Unido",
                },
                {
                  value: "Syrian Arab Republic",
                  name: "República Árabe Siria",
                },
                {
                  value: "Central African Republic",
                  name: "República Centroafricana",
                },
                {
                  value: "Czech Republic",
                  name: "República Checa",
                },
                {
                  value: "Republic of North Macedonia",
                  name: "República de Macedonia del Norte",
                },
                {
                  value: "Lao People's Democratic Republic",
                  name: "República Democrática Popular de Laos",
                },
                {
                  value: "Dominican Republic",
                  name: "República Dominicana",
                },
                {
                  value: "Reunion",
                  name: "Reunión",
                },
                {
                  value: "Rwanda",
                  name: "Ruanda",
                },
                {
                  value: "Romania",
                  name: "Rumania",
                },
                {
                  value: "Western Sahara",
                  name: "Sáhara Occidental",
                },
                {
                  value: "American Samoa",
                  name: "Samoa Americana",
                },
                {
                  value: "Samoa",
                  name: "Samoa",
                },
                {
                  value: "Saint Barthelemy",
                  name: "San Bartolomé",
                },
                {
                  value: "Saint Kitts and Nevis",
                  name: "San Cristóbal y Nieves",
                },
                {
                  value: "San Marino",
                  name: "San Marino",
                },
                {
                  value: "Saint Martin",
                  name: "San Martín",
                },
                {
                  value: "Sint Maarten",
                  name: "San Martín",
                },
                {
                  value: "Saint Pierre and Miquelon",
                  name: "San Pedro y Miquelón",
                },
                {
                  value: "Saint vincent and Grenadines",
                  name: "San vicente y las Granadinas",
                },
                {
                  value: "Saint Helena",
                  name: "Santa Elena",
                },
                {
                  value: "Saint Lucia",
                  name: "Santa Lucía",
                },
                {
                  value: "Sao Tome and Principe",
                  name: "Santo Tomé y Príncipe",
                },
                {
                  value: "Senegal",
                  name: "Senegal",
                },
                {
                  value: "Serbia",
                  name: "Serbia",
                },
                {
                  value: "Seychelles",
                  name: "Seychelles",
                },
                {
                  value: "Sierra Leone",
                  name: "Sierra Leona",
                },
                {
                  value: "Singapore",
                  name: "Singapur",
                },
                {
                  value: "Somalia",
                  name: "Somalia",
                },
                {
                  value: "Sri Lanka",
                  name: "Sri Lanka",
                },
                {
                  value: "South Africa",
                  name: "Sudáfrica",
                },
                {
                  value: "South Sudan",
                  name: "Sudán del Sur",
                },
                {
                  value: "Sudan",
                  name: "Sudán",
                },
                {
                  value: "Sweden",
                  name: "Suecia",
                },
                {
                  value: "Switzerland",
                  name: "Suiza",
                },
                {
                  value: "Suriname",
                  name: "Surinam",
                },
                {
                  value: "Svalbard and Jan Mayen",
                  name: "Svalbard y Jan Mayen",
                },
                {
                  value: "Thailand",
                  name: "Tailandia",
                },
                {
                  value: "Taiwan",
                  name: "Taiwán",
                },
                {
                  value: "Tanzania, United Republic of",
                  name: "Tanzania, República Unida de",
                },
                {
                  value: "Tajikistan",
                  name: "Tayikistán",
                },
                {
                  value: "British Indian Ocean Territory",
                  name: "Territorio Británico del Océano Índico",
                },
                {
                  value: "Timor-Leste",
                  name: "Timor Oriental",
                },
                {
                  value: "Togo",
                  name: "Togo",
                },
                {
                  value: "Tokelau",
                  name: "Tokelau",
                },
                {
                  value: "Tonga",
                  name: "Tonga",
                },
                {
                  value: "Trinidad and Tobago",
                  name: "Trinidad y Tobago",
                },
                {
                  value: "Tunisia",
                  name: "Túnez",
                },
                {
                  value: "Turkmenistan",
                  name: "Turkmenistán",
                },
                {
                  value: "Turkey",
                  name: "Turquía",
                },
                {
                  value: "Tuvalu",
                  name: "Tuvalu",
                },
                {
                  value: "Ukraine",
                  name: "Ucrania",
                },
                {
                  value: "Uganda",
                  name: "Uganda",
                },
                {
                  value: "Uruguay",
                  name: "Uruguay",
                },
                {
                  value: "Uzbekistan",
                  name: "Uzbekistán",
                },
                {
                  value: "Vanuatu",
                  name: "Vanuatu",
                },
                {
                  value: "Venezuela",
                  name: "Venezuela",
                },
                {
                  value: "Vietnam",
                  name: "Vietnam",
                },
                {
                  value: "Wallis and Futuna",
                  name: "Wallis y Futuna",
                },
                {
                  value: "Yemen",
                  name: "Yemen",
                },
                {
                  value: "Djibouti",
                  name: "Yibuti",
                },
                {
                  value: "Zambia",
                  name: "Zambia",
                },
                {
                  value: "Zimbabwe",
                  name: "Zimbabue",
                },
              ]
        },
        priority:{
            id:"priority",
            name:"priority",
            required:true, 
            items:[
                {
                    name:"Selecciona Su máxima prioridad*",
                    value:"",
                },
                {
                    name:"Performance",
                    value:"Ofrecer experiencias digitales excepcionales"
                  },
                  {
                    name:"Growth",
                    value:"Impulsar el crecimiento de la empresa"
                  },
                  {
                    name:"Security",
                    value:"Proteger la empresa"
                  },
                  {
                    name:"Monetization and Cost",
                    value:"Mejorar los resultados"
                  },
                  {
                    name:"UX",
                    value:"Optimizar la experiencia del usuario"
                  }
            ]
        },
        challenge:{
            id:"challenge",
            name:"challenge",
            required:true,
            items:[
                {
                    name:"Selecciona Período para abordar el desafío*",
                    value:"",
                },
                {
                    name:"Currently evaluating solutions",
                    value:"CEvaluando soluciones actualmente"
                  },
                  {
                    name:"1-3 months",
                    value:"De 1 a 3 meses"
                  },
                  {
                    name:"3-6 months",
                    value:"De 3 a 6 meses"
                  },
                  {
                    name:"6-12 months",
                    value:"De 6 a 12 meses"
                  },
                  {
                    name:"12-18 months",
                    value:"De 12 a 18 meses"
                  },
                  {
                    name:"More than 18 months",
                    value:"Más de 18 meses"
                  }
            ]
        },
        phoneNumber:{
            placeholder:"Teléfono*",
            id:"phoneNumber",
            name:"phoneNumber",
            type:"text",
            required:true 
        },
        comments:{
            placeholder:"Comentarios o preguntas*",
            id:"comments",
            name:"comments",
            row: 3,
            required:true 
        },
        communicationsConsent:{
          id:"communicationsConsent",
          name:"communicationsConsent",
          label:'Me gustaría seguir recibiendo información de HAAP. Al enviar este formulario, doy mi consentimiento para recibir comunicaciones de marketing, y entiendo y acepto el uso de mi información de contacto corporativa de acuerdo con la',
          url:'/es/politica-de-privacidad',
          showUrl:true,
          textUrl:'Política de Privacidad'
        },
        typeButton: "submit",
        labelButton: "Enviar",
      },
  
}

export default talkWithUsData;