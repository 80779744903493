import { useGlobalState } from "../../store/StoreProvider";
import { Link } from "react-router-dom";
const PrivacyPolicyEn = (props) => {
  const { locale } = useGlobalState();
  return (
    <>
      <section className="global-info">
        <div className="content-text">
         
            <p>Data de entrada em vigor/Última atualização: 17 de fevereiro de 2023</p>
          <p>
           A HAAP Tech Inc. e suas afiliadas (coletivamente, "HAAP", "nós" ou
            "nos") desenvolveram esta Política de Privacidade para explicar como
            coletamos, usamos, compartilhamos e protegemos Informações Pessoais
            (definidas abaixo), e suas escolhas sobre a coleta e uso de
            Informações Pessoais.
          </p>

          <p>
            Esta Política de Privacidade se aplica às Informações Pessoais
            coletadas ou processadas através de nossos websites e/ou sistemas
            eletrônicos para negócios e eventos HAAP virtuais ou presenciais
            (coletivamente, o "Site").
          </p>

          <p>
            Para ir a uma seção desta política de privacidade, clique em um dos
            links abaixo:
          </p>

          <ul className="listPolicy">
            <li> 1.O que são informações pessoais? </li>

            <li> 2.Tipos de informações pessoais que coletamos e usamos </li>

            <li> 3.Como usamos as informações pessoais </li>

            <li> 4.Divulgação de informações pessoais </li>

            <li> 5.Como protegemos a segurança das informações pessoais </li>

            <li> 6.Privacidade das crianças </li>

            <li>
              7.Correção, atualização, acesso ou exclusão de informações
              pessoais
            </li>

            <li> 8.Opt-out </li>

            <li> 9.Retenção de dados pessoais </li>

            <li>
              10.Particularidades na UE, Reino Unido, Canadá e outras
              jurisdições relevantes
            </li>

            <li> 11.Seus direitos de privacidade na Califórnia </li>

            <li> 12.Outros sites, incluindo sites de redes sociais </li>

            <li> 13.Mudanças em nossa política de privacidade </li>

            <li> 14.Como entrar em contato conosco </li>
          </ul>
        </div>

        <div className="content-text">
          <h3> 1. O que são informações pessoais? </h3>

          <p>
            <b>" Informações pessoais "</b> são informações que, sozinhas ou em
            combinação com outras informações, o identificam. Exemplos de
            informações pessoais incluem, mas não estão limitadas a, nome,
            endereço de e-mail, número de telefone e endereço postal. Quando
            combinamos dados pessoais com outras informações, tratamos as
            informações combinadas como dados pessoais.
          </p>

          <p>
            Observe que esta Política de Privacidade pode não se aplicar na
            medida em que processamos informações pessoais no papel de um
            processador (ou papel comparável como "provedor de serviços" em
            certas jurisdições) em nome de nossos clientes, incluindo onde
            oferecemos a nossos clientes vários produtos e serviços, tais como
            ciber-segurança, serviços em nuvem, aceleração de websites, etc.,
            através dos quais nossos clientes disponibilizam dados pessoais de
            seus clientes, funcionários e fornecedores para a HAAP. Nesses
            casos, o cliente, não a HAAP, controla quais informações ele coleta
            de você e fornece à HAAP. Para informações sobre quais clientes da
            HAAP usam seus dados pessoais, favor entrar em contato diretamente
            com esse cliente.
          </p>
        </div>

        <div className="content-text">
          <h3>2. Tipos de informações pessoais que coletamos e utilizamos.</h3>

          <p> a. Informações que você nos fornece </p>

          <ul>
            <li>
              <b>Informações de contato:</b> Em vários lugares do site, pedimos
              informações, inclusive informações pessoais. As informações exatas
              que precisamos coletar dependerão da finalidade do formulário. Por
              exemplo, ao preencher o formulário de contato no site, solicitamos
              seu nome completo, endereço de e-mail da empresa, número de
              telefone do trabalho, cargo, setor de trabalho e país. Quando você
              tentar enviar um formulário, nós lhe diremos se um determinado
              campo de informação é necessário ou opcional. Se você optar por
              não fornecer certas informações, talvez não possamos fornecer-lhe
              os serviços solicitados.
            </li>

            <li>
             <b> Dados de localização:</b> Ao navegar em nosso site, seu dispositivo
              móvel ou navegador pode compartilhar seus dados de localização,
              tanto via WiFi como por GPS. Coletaremos estas informações com
              base em suas configurações de telefone e navegador, ou se você
              optar por usar certos recursos de nosso site que utilizam sua
              localização.
            </li>

            <li>
              <b>- Informações de uso e websites e informações de registro:</b> A
              coleta destas informações é descrita com mais detalhes na subseção
              "cookies e tecnologias de rastreamento" abaixo.
            </li>

            <li>
             <b> Redes sociais:</b> Nosso site pode ter um link para nossas páginas ou
              contas de mídia social de terceiros ("mídia social"), e pode usar
              plug-ins de mídia social que, se você clicar neles, transmitirá
              certas informações pessoais para o site de mídia social aplicável.
              Se você optar por interagir conosco nas mídias sociais, poderemos
              coletar as informações que você publicar ou enviar. Observe que
              estas informações também são regidas pelas políticas de
              privacidade das respectivas empresas que fornecem o serviço de
              mídia social.
            </li>

            <li>
              <b>Eventos ou reuniões virtuais:</b> Se você participar de um evento ou
              reunião HAAP presencial ou virtual, poderemos registrar parte ou a
              totalidade desse evento ou reunião. Utilizamos essas informações
              para fins de marketing e negócios para melhor informar o público
              sobre o HAAP e seus eventos.
            </li>
          </ul>
        </div>

        <div className="content-text">
          <h3> 3. Como usamos as informações pessoais </h3>

          <ul>
            <li> Utilizamos dados pessoais para: </li>

            <li> Permitir o uso seguro do Site. </li>

            <li>
              Fornecer, analisar, administrar e melhorar o Site e nossos
              produtos, serviços e operações.
            </li>

            <li> Pesquisa e desenvolvimento </li>

            <li> Enviando-lhe informações a seu pedido </li>

            <li>
              Enviando-lhe informações sobre nossos produtos e serviços que
              achamos que você e sua organização podem achar úteis
            </li>

            <li> Respondendo a consultas </li>

            <li>
              Realização de pesquisas para obter seu feedback sobre nossos
              serviços ou sobre o Site
            </li>

            <li>
              Marketing e publicidade de nossos produtos e serviços e os de
              nossos parceiros
            </li>

            <li>
              Postar comentários em nossos blogs (incluindo o nome, mas não o
              endereço de e-mail, associado ao autor)
            </li>

            <li> Publicação de depoimentos no site </li>

            <li> Recrutamento e contratação de pessoal </li>

            <li> Proteger nossos direitos ou propriedade </li>

            <li> Para cumprir com as obrigações legais </li>

            <li>
              Para outros propósitos relacionados às razões pelas quais você
              fornece informações pessoais.
            </li>
          </ul>

          <p> b. Informações que recebemos de seu uso do Site </p>

          <p>
            Coletamos os seguintes tipos de informações automaticamente através
            de seu uso do Site:
          </p>

          <p>
           <b> Cookies, identificadores de dispositivos e tecnologias similares</b>
          </p>

          <p>
            Quando você usa ou acessa o Site, nós, nossos provedores de serviços
            e nossos anunciantes usamos cookies, identificadores de dispositivos
            e tecnologias similares, tais como pixels, web beacons e
            armazenamento local para coletar informações sobre como você usa o
            Site. Para maiores informações, por favor consulte nossa  <Link to={`/${locale}/cookie-policy`}>Política de
            cookies</Link>.
          </p>

          <p>
            Processamos informações coletadas através dessas tecnologias para
            facilitar a operação de certas características do Site, para
            melhorar sua experiência através da personalização e para nos ajudar
            a entender melhor as características do Site que são de maior
            interesse para você e outros usuários.
          </p>

          <p>
            Alguns de nossos prestadores de serviços podem usar cookies ou
            outros métodos para coletar informações sobre seu uso do Site, e
            podem combinar as informações desses cookies com quaisquer
            Informações Pessoais que possam ter sobre você. O uso de tais
            informações de rastreamento por terceiros depende da política de
            privacidade de tais terceiros. Neste momento, não respondemos aos
            sinais de Não Rastrear ("DNT") enviados a nós por seu navegador.
            Para maiores informações sobre como o DNT funciona, por favor,
            visite   <Link to="https://allaboutdnt.com/" target="_blank">
              http://allaboutdnt.com/
            </Link>.
          </p>

          <p>
            A maioria dos navegadores lhe dá a capacidade de bloquear, excluir
            ou desativar cookies, e seu dispositivo móvel pode permitir que você
            desabilite a transmissão de identificadores exclusivos e dados de
            localização. Se você optar por rejeitar cookies ou bloquear
            identificadores de dispositivos, algumas características do Site
            podem não estar disponíveis ou algumas funcionalidades podem ser
            limitadas ou indisponíveis. Favor consultar as páginas de ajuda de
            seu navegador ou dispositivo móvel para obter assistência na
            alteração de suas configurações.
          </p>

          <ul>
            <li>
            <div><b>Log File Information</b></div> Quando você usa nosso Site, nossos servidores
              automaticamente registram informações, incluindo seu endereço de
              Protocolo de Internet <b>("Endereço IP")</b>, tipo de navegador, URL de
              referência (por exemplo, o site que você visitou antes de vir ao
              nosso Site), número de cliques e como você interage com links no
              Site, nomes de domínio associados a seu provedor de serviços de
              Internet, páginas visitadas e outras informações similares
              (<b>coletivamente, "Informações de arquivo de log"</b>). Também podemos
              coletar informações similares a partir de mensagens de e-mail que
              lhe enviamos, o que nos ajuda a saber quais mensagens são abertas
              e quais links são clicados pelos destinatários. Usamos as
              Informações do Arquivo de Registro para ajudar a proteger o Site
              identificando ameaças e vulnerabilidades potenciais, e para
              analisar a eficácia de nosso Site a fim de melhorar seu
              funcionamento e conteúdo.
            </li>

            <li>
            <div> <b> Informações que recebemos de terceiros</b></div> Podemos receber informações
              pessoais sobre você de seus amigos ou colegas que, através do
              recurso "encaminhar a um amigo" do Site, o convidam a visitar o
              Site ou visualizar determinados conteúdos. Usamos estas
              informações para enviar-lhe uma mensagem única convidando-o a
              visitar o Site e para acompanhar o sucesso de nosso programa de
              indicação.
            </li>
          </ul>
        </div>

        <div className="content-text">
          <h3> 4. Divulgação de informação pessoal </h3>

          <p>
            Não divulgaremos informações pessoais, exceto conforme estabelecido
            nesta Política de Privacidade ou com seu consentimento. Esta seção
            descreve a quem revelamos Informações Pessoais e para quais
            propósitos:
          </p>

          <ul>
            <li>
             <b> Nossos prestadores de serviços.</b> Contratamos prestadores de
              serviços ou parceiros comerciais para realizar tarefas em nosso
              nome para nos ajudar a fornecer o Site ou nossos serviços. Alguns
              desses terceiros podem precisar de acesso às Informações Pessoais
              para realizar seus serviços. Por exemplo, contratamos terceiros
              para nos ajudar a hospedar o Site, patrocinar eventos, analisar o
              tráfego para o nosso Site e comercializar nossos serviços on-line.
              Esses terceiros também nos ajudam na análise extensiva de dados e
              no gerenciamento de nossos contatos com clientes.
            </li>

            <li>
              <b>Negócios e eventos presenciais e virtuais.</b> Se você participar dos
              eventos presenciais ou virtuais da HAAP ou se de outra forma
              realizar negócios com a equipe da HAAP, poderemos compartilhar
              suas informações contratuais com nossos parceiros comerciais,
              fornecedores e patrocinadores, e realizar análises comerciais e
              enviar-lhe comunicações relativas à HAAP e aos produtos e serviços
              de seus parceiros comerciais e patrocinadores. Consulte a seção
              Opt-Out desta Política de Privacidade para obter informações sobre
              como optar por não receber tais Comunicações. Nós coletamos estas
              informações de você pessoalmente em nosso estande de marketing ou
              em outro evento, ou on-line através de um processo de registro.
            </li>

            <li>
              <b>Empresas envolvidas em transações de fusões e aquisições.</b> Se
              vendermos ou transferirmos parte ou todos os nossos negócios ou
              ativos para outra organização (por exemplo, no curso de uma
              transação como fusão, aquisição, falência, dissolução ou
              liquidação), qualquer informação coletada através do Site,
              incluindo Informações Pessoais, poderá estar entre os itens
              vendidos ou transferidos.
            </li>

            <li>
             <b> Aplicação da lei, agências governamentais e tribunais:</b> Podemos
              divulgar Informações Pessoais a pedido da aplicação da lei ou
              agências governamentais ou em resposta a intimações, ordens
              judiciais ou outro processo legal para estabelecer, proteger ou
              exercer nossos direitos ou defender-nos contra uma reivindicação
              legal ou conforme exigido ou permitido por lei, ou para proteger
              os direitos, propriedade ou segurança de qualquer outra pessoa.
              Também podemos divulgar Informações Pessoais para investigar ou
              impedir uma violação por você de qualquer relação contratual ou de
              outra natureza conosco ou de sua atividade ilegal ou prejudicial.
            </li>

            <li>
             <b> Afiliados.</b> Podemos divulgar algumas ou todas as suas Informações
              Pessoais a nossas afiliadas, caso em que exigiremos de nossas
              afiliadas que cumpram com esta Política de Privacidade.
            </li>
          </ul>
        </div>

        <div className="content-text">
          <h3> 5. Como protegemos a segurança das informações pessoais </h3>

          <p>
            Utilizamos salvaguardas comercialmente razoáveis, como a tecnologia
            de criptografia padrão da indústria (por exemplo, protocolo Secure
            Socket Layer (SSL)), para ajudar a manter as informações coletadas
            através do Site seguras. Apesar destes esforços para armazenar
            informações pessoais em um ambiente operacional seguro que não está
            disponível ao público, não podemos garantir a segurança das
            informações pessoais durante a transmissão ou armazenamento em
            nossos sistemas. Além disso, enquanto tentamos garantir a
            integridade e segurança das Informações Pessoais, não podemos
            garantir que nossas medidas de segurança impedirão que terceiros,
            tais como hackers, obtenham acesso ilegal às Informações Pessoais.
            Não representamos ou garantimos que as Informações Pessoais a seu
            respeito sejam protegidas contra perda, mau uso ou alteração por
            terceiros.
          </p>
        </div>

        <div className="content-text">
          <h3> 6. Privacidade infantil </h3>

          <p>
            Não coletamos ou solicitamos intencionalmente qualquer Informação
            Pessoal de crianças ou menores (menores de 18 anos). Caso saibamos
            que coletamos informações pessoais de uma criança sem o
            consentimento dos pais, tomaremos medidas imediatas para apagar tais
            informações.
          </p>
        </div>

        <div className="content-text">
          <h3>
            7. Correção, atualização, acesso ou exclusão de dados pessoais
          </h3>

          <p>
            Além de quaisquer outros direitos que você possa ter, dependendo de
            onde você mora (como descrito abaixo), você pode solicitar que nós
            corrijamos, atualizemos ou excluamos seus dados pessoais,
            enviando-nos uma solicitação por e-mail para <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link>.
          </p>

          <p>
            Processaremos prontamente tais solicitações de acordo com a
            legislação aplicável.
          </p>

          <p>
            Com sua permissão, nós e nossos parceiros comerciais poderemos
            enviar-lhe Comunicações. Você pode optar por não recebê-las,
            indicando suas preferências no perfil ou configurações de sua conta.
            Você também pode seguir as instruções para cancelar sua inscrição em
            qualquer comunicação que receber. Você também pode enviar uma
            solicitação global de cancelamento de assinatura, e/ou solicitação
            para cancelar a venda de suas Informações Pessoais, enviando tal
            solicitação por e-mail a <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link> . Observe que certas
            Comunicações relacionadas ao Site são necessárias para o
            funcionamento e uso adequados do Site e você pode não poder optar
            por não recebê-las.
          </p>
        </div>

        <div className="content-text">
          <h3> 8. Retenção de dados pessoais </h3>

          <p>
            Conservaremos seus Dados Pessoais pelo período necessário para
            cumprir as finalidades para as quais foram coletados, conforme
            estabelecido nesta Política de Privacidade, a menos que um período
            de retenção mais longo seja exigido por lei.
          </p>
        </div>

        <div className="content-text">
          <h3>
            9. Indivíduos na UE, Reino Unido, Canadá e outras jurisdições
            relevantes
          </h3>

          <p>
            Nesta seção, fornecemos informações adicionais sobre como
            processamos informações pessoais sobre indivíduos na UE, Reino
            Unido, Canadá e outras jurisdições relevantes de acordo com a GDPR,
            a GDPR do Reino Unido, a Lei 64 do Quebec e outras leis de
            privacidade relevantes. Se você precisar de mais informações ou
            desejar exercer seus direitos sob as leis acima, você pode nos
            contatar em <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link>.
          </p>

          <p> a. Base legal para o processamento </p>

          <p>
            Na medida exigida pela legislação aplicável nas jurisdições listadas
            acima nesta Seção 10, nossa base legal para processar as Informações
            Pessoais descritas nesta Política de Privacidade dependerá das
            Informações Pessoais em questão e do contexto no qual as coletamos.
            Nós coletamos e usamos suas Informações Pessoais:
          </p>

          <ul>
            <li> Onde precisamos dele para realizar um contrato com você </li>

            <li>
              Onde o processamento é de nosso legítimo interesse (incluindo os
              propósitos descritos acima na Seção 2, Como usamos as informações
              pessoais);
            </li>

            <li> Se tivermos que cumprir uma obrigação legal </li>

            <li> Se tivermos seu consentimento. </li>
          </ul>

          <p> b. Seus direitos e opções </p>

          <p>
            Dependendo da lei aplicável e sujeito a certas isenções, você pode
            ter direito a:
          </p>

          <ul>
            <li> <b>Solicite acesso</b> às suas informações pessoais. </li>

            <li>
              <b>Solicite a correção</b> das Informações Pessoais que mantemos sobre
              você.
            </li>

            <li>
              <b>Solicite a exclusão</b> de suas Informações Pessoais. Entretanto,
              observe que nem sempre podemos atender a sua solicitação de
              exclusão por razões legais específicas que serão notificadas a
              você, se aplicável, no momento de sua solicitação.
            </li>

            <li>
              <b>Corrigir seus Dados Pessoais.</b> Em determinadas circunstâncias, você
              pode ter o direito de solicitar que alteremos ou atualizemos
              informações pessoais imprecisas ou incompletas a seu respeito.
            </li>

            <li>
              <b>Protestar contra</b> o processamento de seus Dados Pessoais quando
              confiamos em um interesse legítimo (ou de terceiros) e há algo em
              sua situação particular que o leva a se opor ao processamento.
            </li>

            <li>
              <b>Solicitar restrição</b> ao processamento de seus Dados Pessoais. Você
              pode nos pedir que paremos de processar suas Informações Pessoais
              nos seguintes casos (a) se você quiser que estabeleçamos a
              precisão das Informações Pessoais; (b) quando nosso uso das
              Informações Pessoais for ilegal, mas você não quiser que as
              apaguemos; (c) quando você precisar que mantenhamos as Informações
              Pessoais mesmo que não precisemos mais delas porque você precisa
              delas para estabelecer, exercer ou defender reivindicações legais;
              ou (d) quando você tiver se oposto ao nosso uso das Informações
              Pessoais, mas precisarmos verificar se temos motivos legítimos
              para usá-las.
            </li>

            <li>
              <b>Direito à portabilidade</b> de suas informações pessoais. Em
              determinadas circunstâncias, você pode ter o direito de solicitar
              uma cópia de suas Informações Pessoais em formato eletrônico e
              solicitar ainda que enviemos tais Informações Pessoais a outra
              parte.
            </li>

            <li>
              <b>Retirar o consentimento</b> a qualquer momento se confiarmos nele para
              processar suas Informações Pessoais.
            </li>

            <li>
              <b>Atualizar sua comunicação e preferências de exclusão.</b> Você pode
              exercer seu direito de parar de receber nossas comunicações de
              marketing a qualquer momento, entrando em contato com
              <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link>
            </li>

            <li>
              <b>Direito de apresentar uma reclamação</b> junto à autoridade
              supervisora aplicável
            </li>
          </ul>

          <p>
            Se você deseja exercer algum destes direitos aplicáveis, favor
            entrar em contato conosco através de <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link>. Você tem
            o direito de apresentar uma reclamação a uma autoridade de
            supervisão se acreditar que violamos qualquer um de seus direitos
            relativos às Informações Pessoais. Encorajamos você a entrar em
            contato conosco pelo e-mail <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link>, para que tenhamos
            a oportunidade de abordar suas preocupações diretamente antes que
            você o faça. Os residentes da Califórnia que acessam o site também
            devem consultar a Seção 11 (Seus Direitos na Califórnia) para obter
            informações específicas sobre seus direitos.
          </p>

          <p> c. Transferências internacionais de dados </p>

          <p>
            Se você usar os Sites de fora dos Estados Unidos, observe que os
            Dados Pessoais serão transferidos, armazenados e processados nos
            Estados Unidos da América, Canadá e/ou México, bem como nos países
            onde os Sub-processadores HAAP estão localizados. Embora tomemos
            medidas para fornecer salvaguardas adequadas, as leis de proteção de
            dados nos Estados Unidos da América, Canadá ou México podem não ser
            tão abrangentes como as do seu país. Alguns dos países nos quais
            seus dados pessoais podem ser processados, incluindo os Estados
            Unidos, não estão sujeitos a uma decisão adequada da Comissão
            Européia ou de seu legislador e/ou regulador local, e podem não ter
            leis de proteção de dados tão abrangentes ou fornecer o mesmo nível
            de proteção de dados que sua jurisdição.
          </p>

          <p>
            Quando Dados Pessoais são transferidos para um país não pertencente
            ao EEE que não é considerado como fornecendo proteção adequada para
            Dados Pessoais, tais Dados Pessoais serão transferidos usando a
            última versão das Cláusulas Contratuais Padrão da UE e/ou do Reino
            Unido.
          </p>

          <p>
            Nós nos esforçamos para implementar salvaguardas apropriadas para
            proteger a privacidade e segurança das Informações Pessoais
            transferidas para nós e para utilizá-las de acordo com as práticas
            descritas nesta Política de Privacidade.
          </p>
        </div>
        <div className="content-text">
          <h3> 10. Seus direitos de privacidade na Califórnia </h3>
          <p>
            
            Sujeito a certas exceções, esta seção se aplica à nossa coleta e uso
            de Informações Pessoais de residentes da Califórnia, de acordo com a
            Lei de Proteção de Dados do Consumidor da Califórnia (California
            Consumer Data Protection Act) e seus regulamentos de implementação
            ("CCPA"), onde "Informações Pessoais" tem o significado estabelecido
            no CCPA. Esta seção descreve (1) as categorias de suas Informações
            Pessoais coletadas e divulgadas por nós sujeitas à CCPA, (2) seus
            direitos de privacidade sob a CCPA, e (3) como exercer esses
            direitos.
          </p>
          <p>
            
            Para receber uma cópia desta seção em um formato alternativo (por
            exemplo, imprimível) ou em outro idioma, favor entrar em contato
            conosco usando as informações fornecidas abaixo.
          </p>
          <p>
            Nós não vendemos informações pessoais. Não acreditamos que tenhamos
            vendido informações pessoais nos últimos 12 meses e não acreditamos
            que vendemos atualmente informações pessoais.
          </p>
          <p>
            Uso de Informações Pessoais Sensíveis. Não acreditamos que usamos ou
            divulgamos Informações Pessoais Sensíveis.
          </p>
          <p>
            Informações Pessoais Coletadas. Nos últimos 12 meses, coletamos as
            seguintes categorias de Informações Pessoais dos consumidores:
          </p>
          <p>
            
            b. Seus direitos de privacidade de acordo com a lei da Califórnia
            Sujeitos a certas exceções, os residentes da Califórnia podem ter os
            seguintes direitos com respeito a suas informações pessoais:
          </p>
          <p> c. Como exercer seus direitos de privacidade na Califórnia </p>
          <p>
            Somente você, ou uma pessoa devidamente autorizada a agir em seu
            nome, pode fazer um pedido relacionado aos seus Dados Pessoais.
          </p>
          <p> O pedido deve: </p>
          <ul>
            <li>
              
              Fornecer informações suficientes que nos permitam verificar
              razoavelmente se você é a pessoa sobre a qual estamos coletando
              dados pessoais ou um representante autorizado.
            </li>

            <li>
              
              Descreva-o com detalhes suficientes para que possamos
              compreendê-lo, avaliá-lo e responder-lhe adequadamente.
            </li>
          </ul>
          <p>
            
            Podemos negar certos pedidos, ou conceder alguns pedidos apenas em
            parte, como permitido ou exigido por lei. Por exemplo, se você
            solicitar a exclusão de Dados Pessoais, poderemos reter Dados
            Pessoais na medida exigida pela lei aplicável.
          </p>
          <p>
            
            Para exercer seus direitos na Califórnia descritos nesta seção,
            favor entrar em contato conosco pelo e-mail <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link> ou
            ligue para +1.512.514.6183.
          </p>
          <p>
            
            Verificação. Talvez precisemos verificar sua identidade antes de
            atender seus pedidos. Se não pudermos verificar inicialmente sua
            identidade, poderemos solicitar informações adicionais para
            completar o processo de verificação, e só utilizaremos as
            informações pessoais fornecidas em uma solicitação para verificar a
            identidade do solicitante. Se você for um agente autorizado fazendo
            uma solicitação em nome de um residente da Califórnia, podemos
            também precisar verificar sua identidade e autorização. Não
            poderemos responder à sua solicitação ou fornecer-lhe Dados Pessoais
            se não pudermos verificar sua identidade e confirmar que os Dados
            Pessoais dizem respeito a você. Você não precisará criar uma conta
            conosco para fazer um pedido verificável como consumidor.
          </p>
          <p>
            
            Taxas. Não cobramos uma taxa pelo processamento ou resposta ao seu
            pedido, a menos que seja excessiva, repetitiva ou manifestamente
            infundada. Se determinarmos que o pedido justifica uma taxa, o
            informaremos sobre as razões de nossa decisão e lhe forneceremos uma
            cotação antes de processar seu pedido.
          </p>
          <p>
            
            Não-discriminação. A HAAP não o discriminará no caso de você exercer
            qualquer dos direitos acima mencionados sob o CCPA, incluindo, mas
            não limitado a:
          </p>
          <ul>
            <li> negando-lhe serviços </li>

            <li>
              
              cobrar preços ou taxas diferentes por bens ou serviços, inclusive
              através do uso de descontos ou outros benefícios ou da imposição
              de penalidades
            </li>

            <li>
              
              fornecer a você um nível ou qualidade diferente de bens ou
              serviços
            </li>

            <li>
              
              sugerem que você receberá um preço ou taxa diferente para bens ou
              serviços ou um nível ou qualidade diferente de bens ou serviços.
            </li>
          </ul>
          <p>
            
            Reclamações. A CCPA dá aos consumidores da Califórnia o direito de
            apresentar uma reclamação junto à Procuradoria Geral da Califórnia.
            Você pode entrar em contato com a Procuradoria Geral da Califórnia
            em
            <Link to="https://oag.ca.gov/contact/consumer-complaint-against-business-or-company" target="_blank">https://oag.ca.gov/contact/consumer-complaint-against-business-or-company</Link>
            ou por telefone: (916) 210-6276.
          </p>
          <p>
            
            Acessibilidade. O Aviso é fornecido de acordo com as Diretrizes de
            Acessibilidade de Conteúdo do Website e, portanto, está disponível
            para consumidores com deficiências. Para solicitar acesso a este
            Aviso em um formato alternativo, favor enviar um e-mail para
            <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link>.
          </p>
          <p>
            
            Em sua solicitação, certifique que você é um residente da Califórnia
            e forneça um endereço atual na Califórnia para que possamos
            responder a você.
          </p>
          <p> <b>Lei "Shine the Light" da California:</b> </p>
          <p>
            
            De acordo com a Seção 1798.83 do Código Civil da Califórnia,
            clientes individuais residentes na Califórnia que tenham uma relação
            comercial conosco podem solicitar informações sobre nossa divulgação
            de certas categorias de Informações Pessoais a terceiros para fins
            de marketing direto de tais terceiros, se houver.
          </p>
          <p>
            Para fazer tal solicitação, favor entrar em contato conosco
            utilizando as informações da seção Fale conosco. Favor observar que
            nem todo compartilhamento de informações é coberto por estes
            requisitos de direitos de privacidade da Califórnia e que somente
            informações sobre compartilhamento coberto serão incluídas em nossa
            resposta. Esta solicitação não pode ser feita mais de uma vez por
            ano.
          </p>
        </div>

        <div className="content-text">
          <h3> 11. Outros sites, incluindo redes sociais </h3>

          <p>
            Não somos responsáveis pelas práticas empregadas pelos websites ou
            serviços vinculados ao nosso Site ou a partir dele, incluindo as
            informações ou o conteúdo nele contido. Um link para um site de
            terceiros não deve ser interpretado como um endosso. Encorajamos
            você a investigar e fazer perguntas antes de divulgar informações
            pessoais a terceiros.
          </p>
        </div>

        <div className="content-text">
          <h3>12. Mudanças em nossa política de privacidade </h3>

          <p>
            
            Esta Política de Privacidade está sujeita a alterações sem aviso
            prévio. Se a qualquer momento decidirmos usar os Dados Pessoais de
            uma maneira materialmente diferente daquela declarada no momento em
            que foram coletados, notificaremos você por e-mail sobre as mudanças
            em nossa Política. Nesses casos, você terá a escolha de usar ou não
            seus Dados Pessoais desta maneira diferente.
          </p>
        </div>

        <div className="content-text"></div>

        <div>
          <h3> 13. Como entrar em contato conosco </h3>

          <p>
            
            Para maiores informações, por favor, entre em contato conosco.
          </p>

          <p> HAAP Tech Inc. </p>
          <p>
            7000 North Mopac Expressway, Suite 200, Austin, TX, 78731 USA
            +1.512.514.6183
          </p>
          <p> Correo: <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link> </p>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicyEn;
