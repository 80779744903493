import { useIntl } from "react-intl";
import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useGlobalState } from "../store/StoreProvider";
import BgVideo from "@mzp/commons/dist/layout/BgVideo";
import HeadbandText from "@mzp/commons/dist/dataDisplay/HeadbandText";
import Cards from '@mzp/commons/dist/surfaces/Cards';
import FormBasic from '@mzp/commons/dist/forms/FormBasic';
import image from "../assets/img/branding/landing/delivery.jpg";

const ContentDelivery = (props) => {
  const {resourceSite } = props;
  const intl = useIntl();
  const { locale } = useGlobalState();
  const [scrollItems, setScrollItems] = useState(false);

  const changeTransitionItems = () => {
      const elem = document.querySelector("#itemsoffer");
      var windowheight= window.scrollY;
      var itemTop =  (elem != null && elem !== undefined && elem && elem.offsetTop) ? elem.offsetTop : 0;;
      if(windowheight > (itemTop - 650)){
        setScrollItems(true)
      }else{
        setScrollItems(false)
      }
  }

  window.addEventListener('scroll', changeTransitionItems);


  return (
    <div className="delivery-section">
          {resourceSite.videBgDelivery.clip ? (
        <BgVideo
          clip={resourceSite.videBgDelivery.clip}
          poster={resourceSite.videBgDelivery.poster}
          title={resourceSite.videBgDelivery.title}
          text={resourceSite.videBgDelivery.text}
          iconDirection={resourceSite.videBgDelivery.iconDirection}
          locale={locale}
        />
      ) : (
        <div
          className="bg-image bg-protection"
          style={{ backgroundImage: `url(${image})` }}
        >
          <h2>{resourceSite.videBgDelivery.title}</h2>
          <div className="overlay"></div>
        </div>
      )}
      <section className='why-haap-tech'>
          <HeadbandText 
            text={resourceSite.infoSiteDelivery.headBandtext}
            title={resourceSite.infoSiteDelivery.headBandTitle}
            locale={locale}
            showDivision={false}
            showTitle={false}
      />
      </section>
      <section className="what-we-offer">
            <Container>    
                <div className={`what-we-offer-list-card  ${scrollItems ? 'activeAnimation' : ''}`} id="itemsoffer">                  
                    {resourceSite.cardsDelivery.items && resourceSite.cardsDelivery.items.map((item, index) => {
                        // return <Link key={index} to={"/"+locale+item.url} style={{textDecoration:"none"}} reloadDocument className='card-url'>  <Cards
                        return   <Cards
                            key={index}
                            image={item.img}
                            classNameCard="card-haap"
                        >
                            <div className='card-content-body'>
                                <h3>{item.title}</h3>
                                <p>{item.label}</p>  
                                <div className='line-border'></div>  
                            </div>
                      
                        </Cards>
                     
                       })}
                                    
                </div>               
            </Container>        
        </section>
        <Link className="btn-talk" to={`/${locale}${resourceSite.urlFormDelivery}`}>{resourceSite.texBtntFormDelivery}</Link>
        {resourceSite.formVideoDelivery.show && (
          <section className='contact-video'>
           <Container>
            <Row>
                <Col md={6} className="order-md-1 g-0 ">
                    <div className='min-video'>
                    <video autoPlay loop muted poster={resourceSite.videBgDelivery}>
                        <source src={resourceSite.formVideoDelivery.urlVideo} type='video/mp4' />
                        <source src={resourceSite.formVideoDelivery.urlVideo} type="video/ogg" /> 
                    </video>
                    </div>                    
                </Col>
                <Col md={6} className="order-md-0 rectangle">
                    <div className='form-landing'>
                        <h4>{intl.formatMessage({ id: "general.contactUs" })}</h4>
                        <p>{intl.formatMessage({ id: "general.getcontact" })}</p>
                        <FormBasic 
                             row={resourceSite.formVideoDelivery.row}
                             placeholderMessage={resourceSite.formVideoDelivery.placeholderMessage}
                             typeEmail={resourceSite.formVideoDelivery.typeEmail}
                             size={resourceSite.formVideoDelivery.size}
                             placeholderEmail={resourceSite.formVideoDelivery.placeholderEmail}
                             idEmail={resourceSite.formVideoDelivery.idEmail}
                             requiredEmail={resourceSite.formVideoDelivery.requiredEmail}
                             classNameInput={resourceSite.formVideoDelivery.classNameInput}
                             typeName={resourceSite.formVideoDelivery.typeName}
                             placeholderName={resourceSite.formVideoDelivery.placeholderName}
                             idName={resourceSite.formVideoDelivery.idName}
                             requiredName={resourceSite.formVideoDelivery.requiredName}
                             typeLastName={resourceSite.formVideo.typeLastName}
                             placeholderLastName={resourceSite.formVideo.placeholderLastName}
                             idLastName={resourceSite.formVideo.idLastName}
                             requiredLastName={resourceSite.formVideo.requiredLastName}
                             textButton={resourceSite.formVideo.textButton}
                             typeButton={resourceSite.formVideoDelivery.typeButton}
                        />
                    </div>
                </Col>                
            </Row>
           </Container>                         
        </section>
        )}
        
    </div>
  );
};

export default ContentDelivery;
