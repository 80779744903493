import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import aplisecu from "../../assets/img/branding/cards/protection/ApplicationSecurity.jpg";
import infrasecu from "../../assets/img/branding/cards/protection/InfrastructureSecurity.jpg";
import zerosecu from "../../assets/img/branding/cards/protection/ZerotrustSecurity.jpg";
import fraud from "../../assets/img/branding/cards/protection/Proteccion-contra-abuso-2.jpg";

const protectionData = {
    videBgSecurity:{
        clip:"",
        title:"SEGURANÇA",
        text:"",
        showContentText:true,
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteSecurity:{
        headBandshowTitle:true,
        headBandTitle:"",
        classNameHead:"",
        headBandtext:"<span>Proteja-se</span> contra ameaças sem diminuir o ritmo. <span> A Akamai</span> envolve e protege todo o seu ecossistema: nuvens, apps, APIs e usuários.",
        
    },
    cardsSecurity:{
        title:"",
        text:"",
        items:[
            {
                show:true,
                img:infrasecu,
                title:"Segurança de infraestrutura",
                label:"Aprimore a resiliência contra as ameaças mais complexas, com uma defesa de várias camadas para todo o ecossistema.",
                url:"/infrastructure-security",
                list:[
                    {
                        label:"Ataques DDoS",
                        check:true,
                    },
                    {
                        label:"Esgotamento de recursos",
                        check:true,
                    },
                    {
                        label:"Remoção do website",
                        check:true,
                    },
                    {
                        label:"Ataques DNS",
                        check:true,
                    }
                ]
            },
            {
                show:true,
                img:aplisecu,
                title:"Segurança de aplicações",
                label:"Defenda-se contra uma gama abrangente de ataques multivetor. Proteção inteligente de ponta a ponta. Lute contra o preenchimento de credencial, bots e fraudes na Web com os recursos mais inteligentes de detecção e mitigação.",
                url:"/application-security",
                list:[
                    {
                        label:"Coleta de dados website",
                        check:true,
                    },
                    {
                        label:"Corrupção de conteúdo",
                        check:true,
                    },
                    {
                        label:"Ataques de bots",
                        check:true,
                    },
                    {
                        label:"Controle da conta",
                        check:true,
                    },
                    {
                        label:"Roubo de dados do usuário",
                        check:true,
                    },
                    {
                        label:"Sequestro de público",
                        check:true,
                    }
                ]
            },
            {
                show:true,
                img:zerosecu,
                title:"Segurança Zero-trust",
                label:"Controle o acesso a aplicações corporativas e à Internet de qualquer lugar, sem prejudicar sua força de trabalho. A microssegmentação detecta infrações e minimiza movimentos laterais e o tempo de permanência em toda a cadeia de destruição de ataques cibernéticos.",
                url:"/security-zero-trust",
                list:[
                    {
                        label:"Disseminação de malware e ransomware ",
                        check:true,
                    },
                    {
                        label:"Explorações de phishing ",
                        check:true,
                    },
                    {
                        label:"Roubo de dados corporativos",
                        check:true,
                    },
                    
                ]
            },
            {   show:true,
                img:fraud,
                title:"Proteção contra fraude e violação",
                label:"Detenga el Credential Stuffing, los bots y el fraude web con la detección y la mitigación más inteligentes",
                url:"/abuse-and-fraud-protection",
                list:[
                    {
                        label:"Propagação de código malicioso",
                        check:true,
                    },
                    {
                        label:"Ransomware de dados",
                        check:true,
                    },
                    {
                        label:"Roubo de identidade (phishing) ",
                        check:true,
                    },
                    {
					label:"Roubo de dados da empresa",
                        check:true,
				},
                ]
            },
           
        ]
    },
    formVideoSecurity:{
        row:3,
        placeholderMessage:"Message",
        typeEmail:"email",
        size:"md",
        placeholderEmail:"Email",
        idEmail:"email",
        requiredEmail:true,
        classNameInput:"form-basic",
        typeName:"text",
        placeholderName:"First Name",
        idName:"name",
        requiredName:true,
        placeholderLastName:"Last Name",
        typeLastName:"text",
        idLastName:"lastname",
        requiredLastName:true,
        typeButton:"submit",
        textButton:"Send",

        urlVideo:"",
        show:false,
    },
    urlFormSecurity:"/fale-com-a-gente",
    texBtntFormSecurity:"Fale com um especialista",
}

export default protectionData;