import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';


const securityZeroTrustData = {
    videBgSecurityZeroTrust:{
        clip:"",
        poster:"",
        title:"Seguridad zero trust",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteSecurityZeroTrust:{
        headBandshowTitle:true,
        headBandTitle:"¿Por qué deberíamos adoptar la <span>seguridad Zero Trust</span>?",
        classNameHead:"",
        headBandtext:"Ponga fin a su dependencia de los firewalls y de las VPN a la hora de proteger la red. Las soluciones Zero Trust de Akamai protegen todo su entorno de TI, ya se trate de entornos locales o en la nube; sus aplicaciones, ya sean heredadas o SaaS y sus empleados, ya trabajen in situ o teletrabajen. La visibilidad que ofrece Akamai de sus activos, accesos y flujos de red proporciona la base idónea para su estrategia de seguridad Zero Trust. Además, nuestra experiencia global puede ayudarle a detectar las amenazas más evasivas y a limitar el movimiento lateral en caso de filtración.",
        
    },
    cardsSecurityZeroTrust:{
        title:"",
        text:"Descubra la <span>seguridad</span> Zero Trust",
        items:[
            {
                img:"",
                title:"Guardicore Segmentation de Akamai",
                label:"Visualice, proteja y segmente entornos locales, en la nube e híbridos.",
                url:"/detalle/guardicore-segmentation",
                textUrl:""
            },
            {
                img:"",
                title:"Hunt",
                label:"Detección de amenazas gestionada para encontrar y neutralizar los riesgos de seguridad más evasivos.",
                url:"/detalle/hunt",
                textUrl:""
            },
            {
                img:"",
                title:"Enterprise Application Access",
                label:"Proteja a su plantilla híbrida al mismo tiempo que mejora el acceso.",
                url:"/detalle/enterprise-application-access",
                textUrl:""
            },
            {
                img:"",
                title:"Akamai MFA",
                label:"Evite el robo de cuentas de empleados y las filtraciones de datos con la autenticación multifactorial (MFA) a prueba de phishing",
                url:"/detalle/akamai-mfa",
                textUrl:""
            },
            {
                img:"",
                title:"Secure Internet Access Enterprise",
                label:"Conecte de forma segura a los usuarios y los dispositivos a Internet con una puerta de enlace web segura.",
                url:"/detalle/secure-internet-access-enterprise",
                textUrl:""
            }
        ]
    },
    urlFormSecurityZeroTrust:"/hable-con-nosotros",
    texBtntFormSecurityZeroTrust:"Hable con un experto",
}

export default securityZeroTrustData;