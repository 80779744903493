import { useIntl } from "react-intl";
import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useGlobalState } from "../store/StoreProvider";
import BgVideo from "@mzp/commons/dist/layout/BgVideo";
import HeadbandText from "@mzp/commons/dist/dataDisplay/HeadbandText";
import Cards from '@mzp/commons/dist/surfaces/Cards';
import FormBasic from '@mzp/commons/dist/forms/FormBasic';
import iconUtils from '../utils/iconUtils';
import iconContants from "../constants/iconContants";
import image from "../assets/img/branding/landing/protection.jpg";

const Security = (props) => {
  const {resourceSite } = props;
  const intl = useIntl();
  const { locale } = useGlobalState();
  const [scrollItems, setScrollItems] = useState(false);

  const changeTransitionItems = () => {
      const elem = document.querySelector("#itemsoffer");
      var windowheight= window.scrollY;
      var itemTop =  (elem != null && elem !== undefined && elem && elem.offsetTop) ? elem.offsetTop : 0;
      if(windowheight > (itemTop - 650)){
        setScrollItems(true)
      }else{
        setScrollItems(false)
      }
  }

  window.addEventListener('scroll', changeTransitionItems);


  return (
    <div className="protection-section">
        {resourceSite.videBgSecurity.clip ? (
      <BgVideo 
        clip={resourceSite.videBgSecurity.clip}
        poster={resourceSite.videBgSecurity.poster}
        title={resourceSite.videBgSecurity.title}
        text={resourceSite.videBgSecurity.text}
        iconDirection={resourceSite.videBgSecurity.iconDirection}
        locale={locale}
        showContentText={resourceSite.videBgSecurity.showContentText}
      />
      ) : (
        <div
          className="bg-image bg-protection"
          style={{ backgroundImage: `url(${image})` }}
        >
          <h2>{resourceSite.videBgSecurity.title}</h2>
          <div className="overlay"></div>
        </div>
      )}
      <section className='why-haap-tech'>
          <HeadbandText 
            title={""}
            text={resourceSite.infoSiteSecurity.headBandtext}
            locale={locale}
            showTitle={false}
            showText={true}
            classNameHead={""}
            showDivision={false}
            classNameContainer={"text-center"}
            fontSizeTitle={""}
            fontSizeText={""}
            colsm={0}
            colmd={0} 
      />
      </section>
      <section className="what-we-offer">
            <Container>
                <div className={`what-we-offer-list-card  ${scrollItems ? 'activeAnimation' : ''}`} id="itemsoffer">
                    {resourceSite.cardsSecurity.items && resourceSite.cardsSecurity.items.map((item, index) => {

                      if(item.show){
                        return  <Link to={"/"+locale+item.url} style={{textDecoration:"none"}} reloadDocument  className='card-url'>              
                        <Cards
                            key={index}
                            image={item.img}
                            classNameCard="card-haap"
                        >
                            <div className='card-content-body'>
                                <h3>{item.title}</h3>
                                <p>{item.label}</p>  
                                <div className="list-data">
                                  <span>{intl.formatMessage({ id: "general.stops" })}</span>
                                  <ul>
                                    {item.list && item.list.map((li, key) =>{
                                        return  <li key={key}>
                                                  <small>{li.check ? iconUtils.icon(iconContants.FICHECK) : ""}</small>
                                                  <span>{li.label}</span>
                                                </li>
                                    })}
                                  </ul>
                                </div>
                                <div className='line-border'></div>  
                            </div>
                           
                        </Cards>
                        </Link>   

                      }
                  
                       })}                                      
                </div>               
            </Container>        
        </section>

        <Link className="btn-talk" to={`/${locale}${resourceSite.urlFormSecurity}`}>{resourceSite.texBtntFormSecurity}</Link>
        {resourceSite.formVideoSecurity.show && (
          <section className='contact-video'>
           <Container>
            <Row>
                <Col md={6} className="order-md-1 g-0 ">
                    <div className='min-video'>
                    <video autoPlay loop muted poster={resourceSite.videBgSecurity}>
                        <source src={resourceSite.formVideoSecurity.urlVideo} type='video/mp4' />
                        <source src={resourceSite.formVideoSecurity.urlVideo} type="video/ogg" /> 
                    </video>
                    </div>                    
                </Col>
                <Col md={6} className="order-md-0 rectangle">
                    <div className='form-landing'>
                        <h4>{intl.formatMessage({ id: "general.contactUs" })}</h4>
                        <p>{intl.formatMessage({ id: "general.getcontact" })}</p>
                        <FormBasic 
                             row={resourceSite.formVideoSecurity.row}
                             placeholderMessage={resourceSite.formVideoSecurity.placeholderMessage}
                             typeEmail={resourceSite.formVideoSecurity.typeEmail}
                             size={resourceSite.formVideoSecurity.size}
                             placeholderEmail={resourceSite.formVideoSecurity.placeholderEmail}
                             idEmail={resourceSite.formVideoSecurity.idEmail}
                             requiredEmail={resourceSite.formVideoSecurity.requiredEmail}
                             classNameInput={resourceSite.formVideoSecurity.classNameInput}
                             typeName={resourceSite.formVideoSecurity.typeName}
                             placeholderName={resourceSite.formVideoSecurity.placeholderName}
                             idName={resourceSite.formVideoSecurity.idName}
                             requiredName={resourceSite.formVideoSecurity.requiredName}
                             typeLastName={resourceSite.formVideo.typeLastName}
                             placeholderLastName={resourceSite.formVideo.placeholderLastName}
                             idLastName={resourceSite.formVideo.idLastName}
                             requiredLastName={resourceSite.formVideo.requiredLastName}
                             textButton={resourceSite.formVideo.textButton}
                             typeButton={resourceSite.formVideoSecurity.typeButton}
                        />
                    </div>
                </Col>                
            </Row>
           </Container>                         
        </section>
        )}               
    </div>
  );
};

export default Security;
