import { useGlobalState } from "../../store/StoreProvider";
import PrivacyPolicyEn from "./PrivacyPolicyEn";
import PrivacyPolicyEs from "./PrivacyPolicyEs";
import PrivacyPolicyPt from "./PrivacyPolicyPt";

const PrivacyPolicyText = (props) => {
  const { locale } = useGlobalState();
  return (
    <>
        {locale == 'en' &&(
            <PrivacyPolicyEn />
        )}
         {locale == 'es' &&(
            <PrivacyPolicyEs />
        )}
         {locale == 'pt' &&(
            <PrivacyPolicyPt />
        )}              
    </>
  );
};

export default PrivacyPolicyText;
