import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import location from "../../assets/img/icons/haap/location.svg";
import connection from "../../assets/img/icons/haap/connection.svg";
import global from "../../assets/img/icons/haap/global.svg";
import capacity from "../../assets/img/icons/haap/capacity.svg";
import monitoring from "../../assets/img/icons/haap/verified-monitoring.svg";
import users from "../../assets/img/icons/haap/users.svg";


const whyAkamaiData = {
    videBgAkamai:{
        clip:"",
        title:"¿POR QUE AKAMAI?",
        text:"",
        showContentText:true,
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteAkamai:{
        headBandshowTitle:true,
        headBandTitle:"¿Por que Akamai?",      
        headBandtext:"Porque a <span>Akamai</span> é o fornecedor de referência no mundo da entrega e segurança de conteúdo online. Assim, nos tornamos Permier Partner e implementador estratégico.",
        
    },
    infoAkamai:{
        text:"<p>Todos os dias, bilhões de pessoas se conectam com suas marcas favoritas para fazer compras online, jogar, compartilhar ideias, gerenciar finanças e muito mais. Eles podem não saber, mas a Akamai está lá, potencializando e protegendo a vida online. </p><p>Há mais de 20 anos, a Akamai foi estabelecida para resolver o desafio mais difícil do início da Internet: a lentidão na rede mundial. E, desde então, Akamai está solucionando os desafios mais difíceis da Internet, trabalhando em direção à nossa visão de um mundo mais seguro e conectado. </p><p>Com a plataforma de computação mais distribuída do mundo, da nuvem à edge, Akamai facilita o desenvolvimento e a execução de aplicações para as empresas, enquanto mantem as experiências mais próximas dos usuários e as ameaças mais distantes. É por isso que empresas inovadoras em todo o mundo escolhem a Akamai para criar, entregar e proteger suas experiências digitais.</p><p>O conjunto de soluções líderes em segurança, computação e entrega estão ajudando empresas globais a melhorar a vida de bilhões de pessoas, bilhões de vezes por dia. </p><p>É por isso que trabalhamos junto com a Akamai!</p><ul><li>18/20 das maiores empresas de telecomunicação</li><li>20/20 das maiores corretoras</li><li>10/10 dos maiores serviços globais de streaming de vídeo</li><li>10/10 das principais empresas globais de videogame</li><li>30/30 das principias empresas de mídia</li><li>10/12 das principais empresas de segurança e software</li><li>9/10 dos melhores bancos do mundo</li></ul>",
        listItems:[
            {
                icon:location,
                count:4.100,
                decimal:3,
                complement:"+",
                label:"Localizações"
            },
            {
                icon:connection,
                count:1.300,
                decimal:3,
                complement:"+",
                label:"Redes"
            },
            {
                icon:global,
                count:134,
                decimal:"",
                complement:"",
                label:"Países"
            },
            {
                icon:capacity,
                count:800,
                decimal:"",
                complement:"+ Tbps",
                label:"Capacidade"
            },
            {
                icon:monitoring,
                count:24,
                decimal:"",
                complement:"/7",
                label:"Monitoramento"
            },
            {
                icon:users,
                count:1.900,
                decimal:3,
                complement:"+",
                label:"Especialistas em serviços"
            },
        ],
        
    },
    formVideoAkamai:{
        row:3,
        placeholderMessage:"Message",
        typeEmail:"email",
        size:"md",
        placeholderEmail:"Email",
        idEmail:"email",
        requiredEmail:true,
        classNameInput:"form-basic",
        typeName:"text",
        placeholderName:"First Name",
        idName:"name",
        requiredName:true,
        placeholderLastName:"Last Name",
        typeLastName:"text",
        idLastName:"lastname",
        requiredLastName:true,
        typeButton:"submit",
        textButton:"Send",

        urlVideo:"",
        show:false,
    }
}

export default whyAkamaiData;