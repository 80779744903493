import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';


const infrastructureSecurityData = {
    videBgInfrastructureSecurity:{
        clip:"",
        poster:"",
        title:"Proteção contra DDoS",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteInfrastructureSecurity:{
        headBandshowTitle:true,
        headBandTitle:"<span>Defenda-se</span> contra os ataques modernos mais <span>sofisticados</span>",
        classNameHead:"",
        headBandtext:"As empresas atuais enfrentam a ameaça constante de ataques de DDoS sofisticados e bem orquestrados. A proteção da Akamai contra DDoS, criada a partir de uma infraestrutura dedicada, protege suas aplicações e sistemas voltados para a Internet enquanto mantém um DNS rápido, altamente seguro e sempre disponível.Com a Akamai, você pode interromper ataques de DDoS e tráfego mal-intencionado na nuvem antes que eles atinjam aplicações, data centers e infraestruturas, sem a necessidade de vários firewalls.",   
    },
    cardsInfrastructureSecurity:{
        title:"",
        text:"As <span>defesas</span> ativas contra ataques de DDoS garantem o <span>acesso</span> a qualquer momento, em qualquer lugar.",
        items:[
            // {
            //     img:"",
            //     title:"App & API Performance",
            //     label:"Segurança completa e sem comprometimento para websites, aplicações e APIs.",
            //     url:"/detalhes/api-performance",
            //     textUrl:""
            // },
            // {
            //     img:"",
            //     title:"Prolexic",
            //     label:"Interrompa ataques de DDoS com a defesa mais rápida e eficaz, não importa a escala.",
            //     url:"/detalhes/prolexic",
            //     textUrl:""
            // },
            // {
            //     img:"",
            //     title:"Edge DNS",
            //     label:"Conte com um DNS (Sistema de Nomes de Domínio) altamente seguro para ter disponibilidade ininterrupta das APIs e aplicações Web.",
            //     url:"/detalhes/edge-dns",
            //     textUrl:""
            // }
        ]
    },
    urlFormInfrastructureSecurity:"/fale-com-a-gente",
    texBtntFormInfrastructureSecurity:"Fale com um especialista",
}

export default infrastructureSecurityData;