import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';

const moreInformation=""
const buttonDataLink ={
 title:"Talk to an expert",
 url:"/talk-with-us"
}

const applicationSecurityData = {
    videBgApplicationSecurity:{
        clip:"",
        poster:"",
        title:"Application and API Security",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteApplicationSecurity:{
        headBandshowTitle:true,
        headBandTitle:"Shore up <span>vulnerabilities</span> that easily multiply",
        classNameHead:"",
        headBandtext:"Applications are the backbone of modern life. However, as the number of applications and users increases, so do the security threats.<br/> <br/>Our comprehensive App and API solution suite safeguards your enterprise with an award-winning Web Application and API Protection (WAAP), advanced bot management, robust API security, and effective DDoS protection. These tools provide critical visibility into threats and the defensive capabilities required to counteract them.<br/><br/>Benefit from protections that automatically update, adapt to your policies in real-time, and maintain high performance, even during extensive botnet assaults or massive DDoS attacks.",
        
    },
    cardsApplicationSecurity:{
        title:"",
        text:"Protect your brand by <span>securing</span> apps and APIs from persistent threats:",
        items:[
            // {
            //     img:"",
            //     title:"API Security",
            //     label:"Gain full visibility into your entire API estate with continuous detection and monitoring.",
            //     url:"/detail/api-security",
            //     textUrl:moreInformation
            // },
            // {
            //     img:"",
            //     title:"App & API Protector",
            //     label:"One-stop, zero-compromise security for websites, applications, and APIs.",
            //     url:"/detail/account-protector",
            //     textUrl:moreInformation
            // },
            // {
            //     img:"",
            //     title:"Prolexic",
            //     label:"Stop DDoS attacks with the fastest, most effective defense — at scale.",
            //     url:"/detail/prolexic",
            //     textUrl:moreInformation
            // },
            // {
            //     img:"",
            //     title:"Page Integrity Manager",
            //     label:"Defend your site from client-side threats. Spot and block malicious activity.",
            //     url:"/detail/page-integrity-manager",
            //     textUrl:moreInformation
            // },
            // {
            //     img:"",
            //     title:"Edge DNS",
            //     label:"Rely on highly secure DNS for nonstop availability of web apps and APIs.",
            //     url:"/detail/edge-dns",
            //     textUrl:moreInformation
            // }
        ]
    },
    urlFormApplicationSecurity:buttonDataLink.url,
    texBtntFormApplicationSecurity:buttonDataLink.title,
}

export default applicationSecurityData;