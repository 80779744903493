import { useGlobalState } from "../../store/StoreProvider";
import { Link } from "react-router-dom";
const CookiePolicyEs = (props) => {
  const { locale } = useGlobalState();
  return (
    <>
      <section className="global-info">
        <div className="content-text">
      
          <p>Última actualización: 17 de febrero de 2023</p>
          <p>
           Esta Política de Cookies explica cómo HAAP Tech Inc. y sus filiales
            ("HAAP", "nosotros" o "nuestro") y nuestros proveedores de servicios
            despliegan cookies en los sitios web de HAAP, así como las opciones
            que tiene para controlarlas. Para más información sobre cómo
            recopilamos, utilizamos y divulgamos información personal, consulte
            nuestra <Link to={`/${locale}/politica-de-privacidad`}>Política de Privacidad</Link>.
          </p>
        </div>
        <div className="content-text">
          <h3>(1) ¿QUÉ SON LAS COOKIES? </h3>

          <p>
            Las cookies son pequeños fragmentos de datos, almacenados en
            archivos de texto que se guardan en su ordenador u otro dispositivo.
            Las cookies pueden ser aplicadas por el sitio web que está visitando
            o por terceros, como los que sirven contenidos o prestan servicios
            de publicidad o integración en redes sociales en el sitio web
            (conocidas como "cookies de terceros"). El sitio web o el tercero
            que aplica la cookie puede acceder a ella en visitas posteriores.
            Esto permite a los sitios web "recordarle" a usted y sus
            preferencias, ya sea para una sola visita (a través de una "cookie
            de sesión") o para múltiples visitas repetidas (a través de una
            "cookie persistente"). Las cookies ayudan a garantizar una
            experiencia consistente y eficiente para los visitantes, y realizan
            funciones esenciales como permitir que los usuarios se registren y
            permanezcan conectados.
          </p>

          <p>
           
            Tanto los sitios web como los correos electrónicos pueden contener
            otras tecnologías de seguimiento como "contadores de visitantes" o
            "píxeles". Se trata normalmente de pequeñas imágenes transparentes
            que nos permiten recopilar información con fines similares a los de
            las cookies. Los contadores de visitantes o píxeles se utilizan a
            menudo junto con las cookies, pero no se almacenan en su ordenador
            de la misma manera. En consecuencia, si desactiva las cookies, los
            contadores de visitantes pueden seguir cargándose, pero su
            funcionalidad se verá restringida.
          </p>
        </div>
        <div className="content-text">
          <h3>(2) CÓMO UTILIZAMOS LAS COOKIES</h3>

          <p>
            Utilizamos cookies con distintos fines. Algunas cookies son
            necesarias por razones técnicas (por ejemplo, seguridad u
            optimización del sitio web); otras permiten una experiencia
            personalizada; otras nos permiten distinguir visitantes únicos con
            el fin de analizar la eficacia de nuestro contenido y marketing; y
            otras permiten a las redes publicitarias ofrecer anuncios que pueden
            ser de su interés.
          </p>
        </div>
        <div className="content-text">
          <h3>(3) TIPOS DE COOKIES</h3>

          <p>
            La siguiente tabla explica los tipos de cookies que utilizamos en
            nuestros sitios web y por qué las utilizamos. También proporcionamos
            ejemplos de las cookies instaladas por nosotros o por nuestros
            proveedores de servicios. Tenga en cuenta que no se trata de una
            lista exhaustiva, sino que pretende ser representativa.
          </p>
        </div>
        <div className="content-text">
          <h3>(4) CONTROL DE LAS COOKIES</h3>

          <p>
            Proporcionamos a los visitantes de nuestro sitio web un Centro de
            preferencias de privacidad para activar o desactivar determinadas
            categorías de cookies. Esta sección describe otras formas de
            controlar las cookies.
          </p>

          <p>
           
            Los visitantes pueden desear restringir el uso de cookies o impedir
            completamente su instalación. La mayoría de los navegadores modernos
            permiten cambiar la configuración de las cookies. Algunos
            navegadores permiten desactivar categorías de cookies (por ejemplo,
            cookies de terceros) o cookies específicas por su nombre.
            Normalmente puede encontrar estos ajustes en el menú de opciones o
            preferencias de su navegador. Para entender estos ajustes, los
            siguientes enlaces para los navegadores más utilizados pueden ser
            útiles, o puede utilizar la opción de ayuda de su navegador para
            obtener más detalles:
          </p>

          <ul>
            <li><Link to="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd" target="_blank">Microsoft Edge</Link> </li>

            <li><Link to="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10" target="_blank">Internet Explorer</Link> </li>

            <li><Link to="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectslug=Cookies&redirectlocale=en-US" target="_blank">Firefox</Link> </li>

            <li><Link to="https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666" target="_blank">Chrome</Link> </li>

            <li><Link to="https://support.apple.com/es-mx/guide/safari/sfri11471/mac" target="_blank">Safari web</Link> e <Link to="https://support.apple.com/es-mx/HT201265" target="_blank">iOS</Link>. </li>
          </ul>

          <p>
            Si desactiva las cookies, tenga en cuenta que algunas de las
            características de nuestro servicio pueden no funcionar
            correctamente.
          </p>

          <p>
            Para obtener más información sobre sus opciones en relación con el
            uso de su actividad de navegación web para la publicidad basada en
            intereses, puede visitar los siguientes sitios:
          </p>

          <ul>
            <li> <Link to="https://thenai.org/" target="_blank">Network Advertising</Link> </li>

            <li><Link to="https://youradchoices.com/" target="_blank">Your Ad Choices</Link> </li>

            <li><Link to="https://youronlinechoices.eu/" target="_blank">Your Online Choices</Link>  </li>
          </ul>
        </div>
        <div className="content-text">
          <h3> (5) CONTÁCTENOS </h3>

          <p>
           
            Si tiene alguna pregunta sobre nuestro uso de cookies, puede
            encontrar los datos de contacto más relevantes en nuestra <Link to={`/${locale}/politica-de-privacidad`}>Política de Privacidad</Link>.
          </p>
        </div>
      </section>
    </>
  );
};

export default CookiePolicyEs;
