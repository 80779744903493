import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';
import imgProtection from "../../assets/img/branding/cards/Security.webp";
import imgDelivery from "../../assets/img/branding/cards/Distribucion-de-Contenido.webp";
import imgImplementation from "../../assets/img/branding/cards/Implementation.webp";
import imgCompute from "../../assets/img/branding/cards/CloudComputing.webp";
import partners from '../../dataPartners/partners';
import bgGeneral from "../../assets/img/branding/landing/compute.jpg";

const homeData = {
    videBg:{
        clip:"",
        showContentText:false,
        title:"",
        poster: bgGeneral,
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSite:{
        headBandshowTitle:false,
        headBandTitle:" ",
        classNameHead:"headband-white",
        headBandtext:"O que aconteceria com seu negócio se você tivesse a <span>experiência do usuário</span> e <span>o desempenho</span> das <span>e-commerce</span> mais importantes do mundo, com a tecnologia de segurança de um banco global, a um preço adecuado a você?",
        
    },
    cardsHome:{
        title:"O que <span>oferecemos</span>",
        text:"Um conjunto completo de produtos e serviços para desenvolver, hospedar, entregar e garantir sua presença on-line e seus conteúdos multimídia",
        items:[
            {
                img:imgProtection,
                title:"Segurança",
                label:"Supere as ameaças mais sofisticadas. Mantenha-se seguro sem comprometer o desempenho.",
                url:"/seguranca"
            },
            {
                img:imgDelivery,
                title:"Entrega de conteúdo",
                label:"Faça mágica digital. Entregue seu conteúdo dinâmico com perfeição, mesmo durante picos de demanda.",
                url:"/entrega-de-conteudo"
            },
            // {
            //     img:imgCompute,
            //     title:"Computação em nuvem",
            //     label:"Impulsione seu desempenho e acelere a inovação. Crie, execute e proteja aplicações e cargas de trabalho em todos os lugares onde sua empresa se conecta online.",
            //     url:"/computacao-em-nuvem"
            // },
            {
                img:imgImplementation,
                title:"Implementação",
                label:"Deixe que HAAP Tech identifique y implemente as melhores soluções e estrategias de Akamai para as suas necessidades.​",
                url:"/implementacao"
            },
           
        ]
    },
    partners:{
        showComponent:false,
        title:"<span>Principais empresas</span> que confiam e trabalham com a <span>Akamai</span>",
        list:[
            {
                label:"Mídia e Entretenimento",
                img:partners.top1,
                imgResponsive:partners.top1Mobile,
                alt:"Mídia e Entretenimento",
               
            },
            {
                label:"Serviços financeiros",
                img:partners.top2,
                imgResponsive:partners.top2Mobile,
                alt:"Serviços financeiros",
           
            },
            {
                label:"Hotéis e viagens",
                img:partners.top3,
                imgResponsive:partners.top3Mobile,
                alt:"Hotéis e viagens",
                
            },
            {
                label:"Ciências da saúde e da vida",
                img:partners.top4,
                imgResponsive:partners.top4Mobile,
                alt:"Ciências da saúde e da vida",
                
            },
            {
                label:"Tecnologia",
                img:partners.top5,
                imgResponsive:partners.top5Mobile,
                alt:"Tecnologia",
                
            },
            {
                label:"governo",
                img:partners.top6,
                imgResponsive:partners.top6Mobile,
                alt:"governo",
            },
            {
                label:"Automotivo",
                img:partners.top7,
                imgResponsive:partners.top7Mobile,
                alt:"Automotivo",
            },
            {
                label:"jogos",
                img:partners.top8,
                imgResponsive:partners.top8Mobile,
                alt:"jogos",
            },
            {
                label:"Publicação",
                img:partners.top9,
                imgResponsive:partners.top9Mobile,
                alt:"Publicação",
            },
            {
                label:"outra",
                img:partners.top10,
                imgResponsive:partners.top10Mobile,
                alt:"outra",
            }
        ],
    },
    formVideo:{
        row:3,
        placeholderMessage:"Message",
        typeEmail:"email",
        size:"md",
        placeholderEmail:"Email",
        idEmail:"email",
        requiredEmail:true,
        classNameInput:"form-basic",
        typeName:"text",
        placeholderName:"First Name",
        idName:"name",
        requiredName:true,
        placeholderLastName:"Last Name",
        typeLastName:"text",
        idLastName:"lastname",
        requiredLastName:true,
        typeButton:"submit",
        textButton:"Send",

        urlVideo:"",
        show:false,
    }
}

export default homeData;