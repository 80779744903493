const TermsOfUseEs = (props) => {
  return (
    <>
      <section className="global-info">
        <div className="content-text">
          <h3>Documentos legales</h3>

          <p>
            Al utilizar el sitio, usted acepta las condiciones de uso; si no las
            acepta, no podrá utilizar el sitio. HAAP se reserva el derecho de
            modificar periódicamente y sin previo aviso el contenido del sitio y
            las presentes condiciones de uso.
          </p>
        </div>

        <div className="content-text">
          <h3>Aviso legal</h3>

          <p>
            HAAP publica este sitio web para nuestros clientes, amigos y otros
            visitantes interesados únicamente con fines informativos. El
            contenido del sitio no constituye asesoramiento de garantizar la
            información y parte de la información no refleja necesariamente las
            opiniones de la empresa, los empleados o cualquiera de sus abogados
            o clientes.
          </p>

          <p>
            
            El sitio proporciona información general, que puede o no ser
            correcta, completa o actual en el momento de su lectura. El
            contenido no pretende sustituir a consejos u opiniones específicos
            de garantizar la información. Los destinatarios del contenido de
            este sitio no deben actuar o abstenerse de actuar sobre la base del
            contenido del sitio sin buscar el asesoramiento jurídico o de la
            garantía de información adecuado u otro asesoramiento profesional.
          </p>

          <p>
            
            HAAP declina expresamente toda responsabilidad relativa a las
            acciones emprendidas o no emprendidas sobre la base de cualquiera o
            de todos los contenidos del sitio. La transmisión de información
            hacia y desde este sitio, en parte o en su totalidad, no crea, y la
            recepción no constituye, una relación de cliente entre los
            remitentes y/o destinatarios y HAAP. Cualquier comunicación a través
            del sitio web o a través del correo electrónico no constituye ni
            crea una relación de cliente. Además, la información no debe ser
            enviada a HAAP electrónicamente hasta que usted hable con un
            empleado de alto nivel y reciba autorización para enviar la
            información. La información general enviada a HAAP a través de este
            sitio web o por correo electrónico no es segura y, por lo tanto, no
            es confidencial.
          </p>

          <p>
            
            HAAP proporciona a sus clientes un mecanismo para comunicar
            información, reportes, alertas y otra información relevante de
            manera segura, y un cliente no debe enviar información confidencial
            a HAAP a través de este sitio web a menos que haya revisado las
            instrucciones escritas de HAAP sobre cómo comunicarse de manera
            segura. Una vez que la información ha sido enviada y recibida de
            forma segura por el cliente, HAAP no se hace responsable de su
            protección. HAAP no es responsable, y no necesariamente aprueba,
            cualquier contenido de terceros que pueda ser accesible a través de
            este sitio Web.
          </p>

          <p>
            
            HAAP declina expresamente toda responsabilidad por las medidas
            adoptadas o no adoptadas sobre la base de cualquier contenido de
            sitios de terceros.
          </p>
        </div>

        <div className="content-text">
          <h3>Uso del contenido del sitio</h3>

          <p>
            Usted puede ver, descargar e imprimir contenidos del sitio sujeto a
            las siguientes condiciones:
          </p>

          <ul>
            <li>
              El nombre de HAAP y el aviso de copyright deben aparecer en todas
              las copias.
            </li>

            <li>
              El contenido puede utilizarse únicamente con fines informativos.
            </li>

            <li>
              El contenido no podrá modificarse ni alterarse en ningun modo.
            </li>
          </ul>

          <p>
            Queda prohibida la reedición, distribución, elaboración de obras
            derivadas o cualquier otro uso de los contenidos que no esté
            explícitamente permitido en el presente documento. Queda prohibido
            enmarcar o utilizar técnicas de enmarcado para incluir cualquier
            marca comercial, logotipo u otra información de propiedad
            (incluyendo imágenes, texto, diseño de página o forma) de HAAP sin
            el consentimiento expreso por escrito del asesor jurídico de HAAP.
          </p>

          <p>
            
            No podrá utilizar etiquetas ni ningún otro "texto oculto" que
            utilice el nombre o las marcas comerciales de HAAP sin el
            consentimiento expreso y por escrito del asesor jurídico de HAAP.
          </p>

          <p>
            
            No podrá utilizar ningún logotipo de HAAP ni ningún otro gráfico o
            marca comercial de su propiedad como parte del enlace sin el
            consentimiento expreso por escrito de la asesoría jurídica de HAAP.
            Usted no adquiere ningún derecho de propiedad sobre ningún contenido
            del sitio. Cualquier uso no autorizado pone fin al permiso o
            licencia concedidos por HAAP.
          </p>
        </div>

        <div className="content-text">
          <h3>Conducta y obligaciones del usuario</h3>

          <p>
            Usted se compromete a respetar todas las leyes y reglamentos
            aplicables cuando utilice este sitio web. Además, se compromete a
            no:
          </p>
          <ul>
            <li>
              Cargar, publicar o transmitir de cualquier otro modo a través o
              hacia este sitio web cualquier contenido que - sea ilícito,
              abusivo, amenazador, perjudicial, obsceno, lascivo, ofensivo,
              difamatorio o censurable por cualquier otro motivo; - pueda
              infringir los derechos de propiedad intelectual, los derechos de
              privacidad, los derechos de publicidad u otros derechos de
              propiedad de terceros; - contenga virus, troyanos, bombas de
              tiempo o cualquier otro programa o elemento prejudicial.
            </li>

            <li>
              Interrumpir, imponer cargas irrazonables o excesivas, interferir o
              intentar obtener acceso no autorizado a cualquier parte de nuestro
              sitio web, sus sistemas informáticos, servidores o redes.
            </li>

            <li>
              Proporcionarnos información falsa sobre usted, suplantar la
              identidad de otra persona o intentar engañar a otros sobre su
              identidad o el origen de cualquier contenido, mensaje u otra
              comunicación.
            </li>

            <li>
              Transmitir correo basura, cartas en cadena u otros mensajes
              masivos no solicitados o mensajes duplicados.
            </li>

            <li>
              Recopilar información sobre otros visitantes de nuestro sitio web
              sin su consentimiento o extraer sistemáticamente datos o campos de
              datos, incluidos, entre otros, datos financieros o direcciones de
              correo electrónico.
            </li>

            <li>
              Vender el acceso o el uso de este sitio web, incluido cualquier
              contenido incluido, descargado o al que se acceda desde este sitio
              web.
            </li>

            <li>
              Redistribuir cualquier contenido, incluidos los datos financieros,
              proporcionado por nosotros de cualquier manera, incluyendo por
              medio de publicación impresa, difusión por fax, páginas web,
              correo electrónico, grupos de noticias o foros web, o cualquier
              otro servicio o método electrónico o en papel.
            </li>

            <li>
              Alterar intencionadamente el formato en el que facilitamos los
              datos financieros o eludir de cualquier otro modo nuestras
              interfaces habituales para dichos datos.
            </li>

            <li>
              Insertar o importar cualquier dato financiero proporcionado por
              nosotros en cualquier servicio de información sobre divisas
              (basado o no en la web), archivos de datos o software de
              aplicación, incluidos, entre otros, los sistemas de contabilidad y
              nóminas.
            </li>
          </ul>

          <p>
            Al cargar, publicar o transmitir de cualquier otro modo a través o
            hacia nuestro sitio web cualquier contenido, usted nos concede a
            nosotros, a nuestros sucesores y cesionarios, una licencia no
            exclusiva, mundial, libre de regalías, perpetua y no revocable para
            utilizar o distribuir dicho contenido de cualquier otro modo que no
            sea el indicado en nuestra Política de Privacidad.
          </p>
        </div>

        <div className="content-text">
          <h3>Información sobre derechos de autor </h3>

          <p>
            El Sitio y su contenido son propiedad del HAAP o de sus proveedores
            y están protegidos por las leyes de derechos de autor de los Estados
            Unidos y por las disposiciones de los tratados internacionales.
          </p>

          <p>
            
            La compilación, organización y presentación del contenido, así como
            todos los programas informáticos e invenciones utilizados en este
            sitio y en relación con el mismo, son propiedad exclusiva de HAAP.
            HAAP se reserva todos los derechos sobre el sitio y su contenido que
            no estén específicamente concedidos en cualquier acuerdo con HAAP o
            en las condiciones de uso.
          </p>
        </div>

        <div className="content-text">
          <h3>Información sobre la marca</h3>

          <p>
            Las marcas, gráficos, logotipos, encabezados de página, iconos de
            botones y guiones de HAAP que aparecen en este sitio son marcas de
            servicio, marcas comerciales e imágenes comerciales de HAAP y son
            propiedad única y exclusiva de HAAP.
          </p>

          <p>
            
            Todas las demás marcas comerciales que no sean propiedad de HAAP o
            de sus filiales y que aparezcan en este sitio son propiedad de sus
            respectivos propietarios, que pueden o no estar afiliados,
            conectados o patrocinados por HAAP o sus filiales.
          </p>
        </div>

        <div className="content-text">
          <h3>Productos y servicios ofrecidos por HAAP en este sitio</h3>

          <p>
            HAAP ofrece productos y servicios en el sitio. Cuando usted obtiene
            un producto o servicio de HAAP en el sitio, acepta el acuerdo
            específico aplicable a dicho producto o servicio.
          </p>

          <p>
            
            El uso que usted haga de cualquiera de los productos o servicios
            ofrecidos en el sitio se regirá por los términos y condiciones del
            acuerdo correspondiente a dicho producto o servicio. Salvo lo
            dispuesto en dicho acuerdo, HAAP no garantiza que las descripciones
            de los productos o el contenido de este sitio web sean precisos,
            actuales, fiables, completos o estén exentos de errores.
          </p>
        </div>

        <div className="content-text">
          <h3>Renuncia de responsabilidad </h3>

          <p>
            HAAP NO SE PRONUNCIA SOBRE LA IDONEIDAD DE LA INFORMACIÓN CONTENIDA
            Y LOS GRÁFICOS RELACIONADOS PUBLICADOS EN ESTE SITIO WEB PARA NINGÚN
            FIN. TODA ESTA INFORMACIÓN Y GRÁFICOS RELACIONADOS SE PROPORCIONAN
            "TAL CUAL", SIN GARANTÍA DE NINGÚN TIPO. POR LA PRESENTE, HAAP
            RENUNCIA A TODAS LAS GARANTÍAS Y CONDICIONES CON RESPECTO A ESTA
            INFORMACIÓN, INCLUIDAS TODAS LAS GARANTÍAS IMPLÍCITAS DE
            COMERCIABILIDAD, IDONEIDAD PARA UN FIN DETERMINADO, TITULARIDAD Y NO
            INFRACCIÓN. EN NINGÚN CASO HAAP SERÁ RESPONSABLE DE NINGÚN DAÑO
            ESPECIAL, INDIRECTO O CONSECUENTE NI DE NINGÚN DAÑO DERIVADO DE LA
            PÉRDIDA DE USO, DATOS O BENEFICIOS, YA SEA EN UNA ACCIÓN
            CONTRACTUAL, NEGLIGENCIA U OTRA ACCIÓN TORTICERA, QUE SURJA DE O EN
            RELACIÓN CON EL USO O RENDIMIENTO DE LA INFORMACIÓN DISPONIBLE EN
            ESTE SITIO WEB.
          </p>
        </div>

        <div className="content-text">
          <h3>Indemnidad</h3>

          <p>
            Usted acepta defender, indemnizar y mantener indemne a HAAP,
            empleados, abogados y agentes ("Indemnizados") frente a todas las
            reclamaciones, gastos, responsabilidades, pérdidas, costes y daños,
            incluidos los honorarios razonables de abogados, en que los
            indemnizados puedan incurrir en relación con su uso del sitio o de
            cualquier enlace del sitio web.
          </p>
        </div>
      </section>
    </>
  );
};

export default TermsOfUseEs;
