import { useIntl } from "react-intl";
import { useEffect } from 'react';
import { Container } from "react-bootstrap";
import image from "../assets/img/branding/landing/bgGeneral.jpg";
import PrivacyPolicyText from "../components/PrivacyPolicyText";

const PrivacyPolicy = (props) => {
  const intl = useIntl();

  useEffect(()=>{
    window.scrollTo(0, 0);
  }, [])
  return (
    <div className="informative-section">
      <div
        className="bg-image bg-protection"
        style={{ backgroundImage: `url(${image})` }}
      >
        <h2>{intl.formatMessage({ id: "general.titlePrivacyPolicy" })}</h2>
        <div className="overlay"></div>
      </div>
      <Container>
        <PrivacyPolicyText />
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
