import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';


const fraudProtectionData = {
    videBgFraudProtection:{
        clip:"",
        poster:"",
        title:"soluciones de protección contra fraude y abuso",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteFraudProtection:{
        headBandshowTitle:true,
        headBandTitle:"<span>Detenga</span> el Credential Stuffing, los bots y el fraude web con la <span>detección</span> y la <span>mitigación</span> más inteligentes",
        classNameHead:"",
        headBandtext:"Detecte comportamientos sospechosos humanos y de bots en tiempo real.",
        items:[
            {
                img:"",
                title:"Mantenga la confianza en el centro de las experiencias online.",
                label:"Proteja las relaciones y las transacciones online sin perjudicar a sus clientes.",
                url:"",
                textUrl:""
            },
            {
                img:"",
                title:"Dedique menos tiempo a la investigación de accesos a cuentas.",
                label:"Evite los abandonos y las pérdidas de recursos que suponen la gestión de alertas y la mitigación de las cuentas comprometidas.",
                url:"",
                textUrl:""
            },
            {
                img:"",
                title:"Encuentre a los impostores antes de que roben los activos del cliente.",
                label:"Detecte anomalías sutiles en la actividad de cuentas con una visibilidad web inigualable.",
                url:"",
                textUrl:""
            }
        ]
        
    },
    cardsFraudProtection:{
        title:"",
        text:"Evite el uso <span>indebido</span> y el fraude",
        items:[
            // {
            //     img:"",
            //     title:"Bot Manager",
            //     label:"Detenga los bots más peligrosos y evasivos antes de que puedan afectar a la confianza de sus clientes.",
            //     url:"/detalle/bot-manager",
            //     textUrl:""
            // },
            // {
            //     img:"",
            //     title:"Account Protector",
            //     label:"Mantenga alejados a los estafadores (y la reputación de su empresa intacta) con la protección contra el robo de cuentas.",
            //     url:"/detalle/account-protector",
            //     textUrl:""
            // },
            // {
            //     img:"",
            //     title:"Audience Hijacking Protector",
            //     label:"Mantenga a los clientes en su sitio, maximice las tasas de conversión y minimice el fraude de afiliados.",
            //     url:"/detalle/audience-hijacking-protector",
            //     textUrl:""
            // },
            // {
            //     img:"",
            //     title:"Identity Cloud",
            //     label:"Cree experiencias de usuario fluidas y seguras.",
            //     url:"/detalle/identity-cloud",
            //     textUrl:""
            // }
            // ,
            // {
            //     img:"",
            //     title:"Brand Protector",
            //     label:"Detenga las suplantaciones de marca antes de que empiecen.",
            //     url:"/detalle/brand-protector",
            //     textUrl:""
            // }
        ]
    },
    urlFormFraudProtection:"/hable-con-nosotros",
    texBtntFormFraudProtection:"Hable con un experto",
}

export default fraudProtectionData;