import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';

const moreInformation=""
const buttonDataLink ={
 title:"Talk to an expert",
 url:"/talk-with-us"
}


const securityZeroTrustData = {
    videBgSecurityZeroTrust:{
        clip:"",
        poster:"",
        title:"Zero Trust Security",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteSecurityZeroTrust:{
        headBandshowTitle:true,
        headBandTitle:"Why Zero Trust <span>security</span>?",
        classNameHead:"",
        headBandtext:"Eliminate reliance on firewalls and VPNs for network security. Our Zero Trust solutions secure your entire IT environment, whether on-premises, containerized or in the cloud; your applications, whether legacy or SaaS; and your workforce, whether on-site or remote. <br/><br/>Our solutions provide visibility into your assets, access, and network flows (without inspecting your actual data or slowing you down), laying a strong foundation for your Zero Trust security strategy. Leveraging our global expertise, we help you hunt down elusive threats and limit lateral movement in the event of a breach.",
        
    },
    cardsSecurityZeroTrust:{
        title:"",
        text:"Get started with Zero Trust <span>security</span>",
        items:[
            // {
            //     img:"",
            //     title:"Akamai Guardicore Segmentation",
            //     label:"Visualize, protect, and segment on-premises, cloud, and hybrid environments.",
            //     url:"/detail/guardicore-segmentation",
            //     textUrl:moreInformation
            // },
            // {
            //     img:"",
            //     title:"Hunt",
            //     label:"Managed threat hunting that finds and remediates the most evasive security risks.",
            //     url:"/detail/hunt",
            //     textUrl:moreInformation
            // },
            // {
            //     img:"",
            //     title:"Enterprise Application Access",
            //     label:"Secure your hybrid workforce while improving access.",
            //     url:"/detail/enterprise-application-access",
            //     textUrl:moreInformation
            // },
            // {
            //     img:"",
            //     title:"Akamai MFA",
            //     label:"Prevent employee account takeovers and data breaches with phish-proof MFA.",
            //     url:"/detail/akamai-mfa",
            //     textUrl:moreInformation
            // },
            // {
            //     img:"",
            //     title:"Secure Internet Access Enterprise",
            //     label:"Safely connect users and devices to the internet with a secure web gateway.",
            //     url:"/detail/secure-internet-access-enterprise",
            //     textUrl:moreInformation
            // }
        ]
    },
    urlFormSecurityZeroTrust:buttonDataLink.url,
    texBtntFormSecurityZeroTrust:buttonDataLink.title,
}

export default securityZeroTrustData;