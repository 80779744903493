export default {
    "general.test": "mensaje de prueba general",
    "general.menu": "MENÚ",
    "general.close": "CERRAR",
    "general.contactUs": "Contactanos",
    "general.getcontact": "Póngase en contacto con nosotros",
    "general.formSuccess": "Mensaje se ha enviado, en breve nos comunicaremos contigo",
    "general.formErrorMail": "Correo no valido",
    "general.formError" : "Nose pudo enviar el correo intentelo de nuevo",
    "general.stops" : "Interrumpe:",
    "general.titleForm" : "<span>HABLE</span> CON NOSOTROS",
    "general.descForm" : "Desea saber cuáles son las soluciones adecuadas para usted? Necesita una demostración? Hable con uno de nuestros expertos.",
    "general.titleTermsOfUse" : "Condiciones de uso ",
    "general.titleCookiePolicy" : "POLÍTICA DE COOKIES",
    "general.titlePrivacyPolicy" : "Política de privacidad  ",
    "general.moreInformation" : "Más información >",
}