import { useIntl } from "react-intl";
import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import FormHaapTech from '@mzp/commons/dist/forms/FormHaapTech';
import Swal from 'sweetalert2';
import { useGlobalState } from "../store/StoreProvider";
import parse from 'html-react-parser';
import image from "../assets/img/branding/landing/bgGeneral.jpg";
import { Link } from "react-router-dom";

const TalkWithUs = (props) => {
  const {resourceSite } = props;
  const intl = useIntl();
  const { locale } = useGlobalState();
  const [env, setEnv] = useState(false);

  useEffect(()=>{
    window.scrollTo(0, 0);
  }, [])



  const showAlertSuccess = () => {
    Swal.fire({
      icon: 'success',
      html: `<p>${intl.formatMessage({ id: "general.formSuccess" })}</p>`,
      showConfirmButton: false,
      timer: 4000
    });
  }

  const showAlertErrorMail = () => {
    Swal.fire({
      icon: 'warning',
      html: `<p>${intl.formatMessage({ id: "general.formErrorMail" })}</p>`,
      showConfirmButton: false,
      timer: 4000
    });
  }

  const showAlertError = () => {
    Swal.fire({
      icon: 'error',
      html: `<p>${intl.formatMessage({ id: "general.formError" })}</p>`,
      showConfirmButton: false,
      timer: 4000
    });
  }

  async function onSubmit(info) {


    const cookies = document.cookie;
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Cookie", cookies + " SameSite=None; Secure");

    var raw = JSON.stringify(info);

    var requestOptions = {
      method: 'POST',
      headers: headers,
      credentials: 'include',
      body: raw
    };
    setEnv(true)
    fetch(`${process.env.REACT_APP_MAIL_API}SendMail/Lead`, requestOptions)
      .then((response) => {

        if (response.status === 400) {
          showAlertErrorMail()
        }
        if (response.status == 500) {
          showAlertError()
        }
        if (response.ok)
          return response.json();
        throw new Error(response.status);
      })
      .then((data) => {
        setEnv(false)
        showAlertSuccess()
      })
      .catch((error) => {
        setEnv(false)
      });

  }

  return (
    <div className="talkwithus-section">
      <div className="bg-image bg-protection" style={{ backgroundImage:`url(${image})` }}>
      <section className='contact'>   
           <Container>
            <div className='form-contact'>
                    <h2 className="mb-4">{parse(intl.formatMessage({ id: "general.titleForm" }))}</h2>
                    <p className="mb-4">{intl.formatMessage({ id: "general.descForm" })}</p>
    
                    <div className="form-us">
                    <FormHaapTech 
                        onSubmit={onSubmit}
                        classNameInput={resourceSite.formContact.classNameInput}
                        formData={resourceSite.formContact}
                        disabledButton={env}
                    />
                    </div>
                </div>
           </Container>                         
        </section>
        <div className="overlay"></div>
      </div>    
    
    </div>
  );
};

export default TalkWithUs;
