import constants from "../constants";

const getLanguagueToPath = (path,defaultLocale) => {
    //['','']
    //['','es-mx']
    //['','es-mx', '']
    let locale = defaultLocale; //default

    const pathSplit = path.split('/');
    const language = constants.language;
    if(pathSplit.length > 0) {
        switch(pathSplit[1]) {
            case language.ES:
                locale = language.ES;
            break;
            case language.ESMX:
                locale = language.ESMX;
            break;
            case language.EN:
                locale = language.EN;
            break;
            case language.PT:
                locale = language.PT;
            break;
            case language.ENGB:
                locale = language.ENGB;
            break;
            case language.RU:
                locale = language.RU;
            break;
            case language.DE:
                locale = language.DE;
            break;
            case language.FR:
                locale = language.FR;
            break;
            default:
                locale = defaultLocale;
        }
    }

    return locale;
}

const languageUtils = {
    getLanguagueToPath
}

export default languageUtils;