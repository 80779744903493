import { useIntl } from "react-intl";
import iconContants from '../../constants/iconContants';
import iconUtils from '../../utils/iconUtils';
import HeaderMenuSlide from "@mzp/commons/dist/headers/HeaderMenuSlide";

import { useGlobalState } from "../../store/StoreProvider";

const HeaderLayout = (props) => {
    const {resourceSite, positionMenu} = props;
    const intl = useIntl();
    const { locale } = useGlobalState();
    
     const onClickLenguage = (value) => {
       
        const {onClickLenguage} = props;
        onClickLenguage && onClickLenguage(value);
    } 

  
     const getValueName = (lang) => {
        const auxLocale =resourceSite.languagesItems.find(a => a.value === locale)
        
        return auxLocale.tag
    } 

    const getValueIcon = (lang) => {
        const auxLocale =resourceSite.languagesItems.find(a => a.value === locale)
       
        return auxLocale.icon
    }

    return <HeaderMenuSlide
    locale={locale}
    languagesItems={resourceSite.languagesItems}
    showLanguage={true}
    classNameHeader="header-transparent"
    home={resourceSite.home}
    menuItems={resourceSite.menu}
    iconLanguage={iconUtils.icon(iconContants.MDLANGUAGE)} 
    textLanguage={getValueName()}
    orderLogo="1"
    orderMenu="0"
    orderSettings="2"
    centerLogo={true}
    textClose={intl.formatMessage({ id: "general.close" })}
    textMenu={intl.formatMessage({ id: "general.menu" })}
    iconClose={iconUtils.icon(iconContants.AIOUTLINECLOSE)}
    iconMenu={iconUtils.icon(iconContants.AIOUTLINEMENUUNFOLD)}
    currenciesItems={resourceSite.currenciesItems}
    showMenu={true}
    onClickLenguage={onClickLenguage} 
    valueLanguage={locale} 
    hasSession={false}
    positionMenu={positionMenu}
/>;
}

export default HeaderLayout;