import { useGlobalState } from "../../store/StoreProvider";
import CookiePolicyEn from "./CookiePolicyEn";
import CookiePolicyEs from "./CookiePolicyEs";
import CookiePolicyPt from "./CookiePolicyPt";

const CookiePolicyText = (props) => {
  const { locale } = useGlobalState();
  return (
    <>
        {locale == 'en' &&(
            <CookiePolicyEn />
        )}
         {locale == 'es' &&(
            <CookiePolicyEs />
        )}
         {locale == 'pt' &&(
            <CookiePolicyPt />
        )}              
    </>
  );
};

export default CookiePolicyText;
