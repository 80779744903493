import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';

const moreInformation=""
const buttonDataLink ={
 title:"Talk to an expert",
 url:"/talk-with-us"
}

const apiApplicationPerformanceData = {
    videBgApiApplicationPerformance:{
        clip:"",
        poster:"",
        title:"Content Delivery Solutions (CDN)",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteApiApplicationPerformance:{
        headBandshowTitle:true,
        headBandTitle:"You <span>create</span> extraordinary digital experiences.",
        classNameHead:"",
        headBandtext:"Trust the agility and scale of Akamai Connected Cloud to help you flawlessly deliver them.Attract customers with fast, engaging app & API performance, enhanced by robust data and automation.",
        
    },
    cardsApiApplicationPerformance:{
        title:"",
        text:"App and API <span>Performance</span> solutions:",
        items:[
            {
                img:"",
                title:"API Acceleration",
                label:"Optimize API performance and availability while enhancing user experiences.",
                url:"/detail/api-acceleration",
                textUrl:moreInformation
            },
            {
                img:"",
                title:"CloudTest",
                label:"Stress test your websites and apps with robust, real-time load testing.",
                url:"/detail/cloud-test",
                textUrl:moreInformation
            },
            {
                img:"",
                title:"DataStream",
                label:"Get an inside look at CDN performance with log data at scale.",
                url:"/detail/datastream",
                textUrl:moreInformation
            },
            {
                img:"",
                title:"Global Traffic Management",
                label:"Optimize app performance and avoid outages with intelligent load balancing.",
                url:"/detail/global-traffic-management",
                textUrl:moreInformation
            },
            {
                img:"",
                title:"Image & Video Manager",
                label:"Automatically optimize and enhance visual media for every user, on any device.",
                url:"/detail/image-video-manager",
                textUrl:moreInformation
            }
            ,
            {
                img:"",
                title:"Ion",
                label:"Deliver fast, engaging, and reliable user experiences on your sites and apps.",
                url:"/detail/ion",
                textUrl:moreInformation
            },
            {
                img:"",
                title:"mPulse",
                label:"Capture user experience data. Optimize website performance. Measure ROI.",
                url:"/detail/mpulse",
                textUrl:moreInformation
            }
        ]
    },
    urlFormApiApplicationPerformance: buttonDataLink.url,
    texBtntFormApiApplicationPerformance:buttonDataLink.title,
}

export default apiApplicationPerformanceData;