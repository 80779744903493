import iconUtils from '../../utils/iconUtils';
import iconContants from '../../constants/iconContants';


const securityZeroTrustData = {
    videBgSecurityZeroTrust:{
        clip:"",
        poster:"",
        title:"Segurança Zero Trust",
        text:"",
        iconDirection:iconUtils.icon(iconContants.IOIOSARROWUP),
    },
    infoSiteSecurityZeroTrust:{
        headBandshowTitle:true,
        headBandTitle:"Por que a <span>segurança</span> Zero Trust?",
        classNameHead:"",
        headBandtext:"Acabe com a sua dependência de firewalls e de VPNs para a segurança da rede. As soluções Zero Trust da Akamai protegem todo o seu ambiente de TI, seja no local ou na nuvem, suas aplicações, sejam elas legadas ou SaaS, e seus funcionários, tanto os que trabalham no local quanto aqueles que trabalham remotamente.A visibilidade da Akamai de seus ativos, acesso e fluxos de rede fornece uma base para a sua estratégia de segurança Zero Trust. E nossa experiência global pode ajudá-lo a descobrir as ameaças mais evasivas e limitar o movimentação lateral em caso de violação.",
    },
    cardsSecurityZeroTrust:{
        title:"",
        text:"Primeiros passos para a estrutura de <span>segurança</span> Zero Trust:",
        items:[
            {
                img:"",
                title:"Guardicore Segmentation de Akamai",
                label:"Visualize, proteja e segmente ambientes locais, de nuvem e híbridos.",
                url:"/detalhes/guardicore-segmentation",
                textUrl:""
            },
            {
                img:"",
                title:"Hunt",
                label:"Busca gerenciada de ameaças que localiza e remedia os riscos mais evasivos à segurança.",
                url:"/detalhes/hunt",
                textUrl:""
            },
            {
                img:"",
                title:"Enterprise Application Access",
                label:"Proteja sua força de trabalho híbrida e melhore o acesso.",
                url:"/detalhes/enterprise-application-access",
                textUrl:""
            },
            {
                img:"",
                title:"Akamai MFA",
                label:"Evite violações de dados e apropriações indevidas de contas dos funcionários com a MFA à prova de phishing.",
                url:"/detalhes/akamai-mfa",
                textUrl:""
            },
            {
                img:"",
                title:"Secure Internet Access Enterprise",
                label:"Conecte usuários e dispositivos à Internet com segurança usando um gateway Web seguro.",
                url:"/detalhes/secure-internet-access-enterprise",
                textUrl:""
            }
        ]
    },
    urlFormSecurityZeroTrust:"/fale-com-a-gente",
    texBtntFormSecurityZeroTrust:"Fale com um especialista",
}

export default securityZeroTrustData;