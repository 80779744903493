import { useGlobalState } from "../../store/StoreProvider";
import { Link } from "react-router-dom";
const PrivacyPolicyEn = (props) => {
  const { locale } = useGlobalState();
  return (
    <>
      <section className="global-info">
        <div className="content-text">
          <p>Fecha de entrada en vigor/última actualización: 17 de febrero de 2023</p>

          <p>
           HAAP Tech Inc. y sus filiales (colectivamente, "HAAP", "nosotros")
            han desarrollado esta Política de Privacidad para explicar cómo
            recopilamos, utilizamos, compartimos y protegemos la Información
            personal (definida a continuación), y sus opciones sobre la
            recopilación y el uso de la Información personal.
          </p>

          <p>
            Esta política de privacidad se aplica a la información personal
            recopilada o procesada a través de nuestros sitios web y/o sistemas
            electrónicos para negocios y eventos virtuales o presenciales del
            HAAP (colectivamente, el <b>"sitio"</b>).
          </p>

          <p>
            Para ir a una sección de esta política de privacidad, haga clic en
            uno de los enlaces siguientes:
          </p>

          <ul className="listPolicy">
            <li>1.¿Qué es la información personal? </li>

            <li>
              2.Tipos de información personal que recopilamos y utilizamos
            </li>

            <li> 3.Cómo utilizamos la información personal </li>

            <li>4.Divulgación de información personal </li>

            <li> 5.Cómo protegemos la seguridad de la información personal </li>

            <li> 6.Privacidad de los niños </li>

            <li>
              7.Corrección, actualización, acceso o eliminación de información
              personal
            </li>

            <li> 8.Exclusión voluntaria </li>

            <li> 9.Conservación de datos personales </li>

            <li>
              10.Particulares en la EU, Reino Unido, Canadá y otras
              jurisdicciones pertinentes
            </li>

            <li> 11.Sus derechos de privacidad en California </li>

            <li> 12.Otros sitios web, incluidas las redes sociales </li>

            <li> 13.Cambios en nuestra política de privacidad </li>

            <li> 14.Cómo ponerse en contacto con nosotros </li>
          </ul>
        </div>

        <div className="content-text">
          <h3> 1. ¿Qué es la información personal? </h3>

          <p>
            <b>"Información personal"</b> es información que, por sí sola o en
            combinación con otra información, le identifica. Ejemplos de
            información personal son, entre otros, el nombre, la dirección de
            correo electrónico, el número de teléfono y la dirección postal.
            Cuando combinamos datos personales con otra información, tratamos la
            información combinada como datos personales.
          </p>

          <p>
            Tenga en cuenta que esta Política de Privacidad puede no aplicarse
            en la medida en que procesemos información personal en el papel de
            procesador (o papel comparable como "proveedor de servicios" en
            ciertas jurisdicciones) en nombre de nuestros clientes, incluyendo
            cuando ofrezcamos a nuestros clientes diversos productos y servicios
            como ciberseguridad, servicios en la nube, aceleración de sitios
            web, etc. a través de los cuales nuestros clientes ponen a
            disposición de HAAP datos personales de sus clientes, empleados y
            proveedores. En los casos mencionados, el cliente, y no HAAP,
            controla qué información recopila de usted y proporciona a HAAP.
            Para obtener información sobre los clientes de HAAP que utilizan sus
            datos personales, póngase en contacto directamente con dicho
            cliente.
          </p>
        </div>

        <div className="content-text">
          <h3>
            2. Tipos de información personal que recopilamos y utilizamos.
          </h3>

          <p> a. Información que usted nos facilita </p>

          <ul>
            <li>
              <b>Información de contacto:</b> En varios lugares del sitio, le
              solicitamos información, incluida información personal. La
              información exacta que necesitamos recopilar dependerá de la
              finalidad del formulario. Por ejemplo, cuando rellena el
              formulario de contacto del sitio, le pedimos su nombre completo,
              dirección de correo electrónico de la empresa, número de teléfono
              del trabajo, cargo, sector de trabajo y país. Cuando intente
              enviar un formulario, le indicaremos si un determinado campo de
              información es obligatorio u opcional. Si decide no facilitar
              determinada información, es posible que no podamos prestarle los
              servicios solicitados.
            </li>

            <li>
              <b>Datos de localización:</b> Mientras navega por nuestro sitio, su
              dispositivo móvil o navegador puede compartir sus datos de
              ubicación, tanto a través de WiFi como de GPS. Recopilaremos esta
              información en función de la configuración de su teléfono y
              navegador, o si decide utilizar determinadas funciones de nuestro
              sitio que utilizan su ubicación.
            </li>

            <li>
              <b>Información de uso y sitios web e información de registro:</b> La
              recopilación de esta información se describe con más detalle en la
              subsección "cookies y tecnologías de seguimiento" a continuación.
            </li>

            <li>
             <b> Redes sociales:</b> Nuestro sitio puede enlazar con nuestras páginas o
              cuentas en redes sociales de terceros ("medios sociales"), y puede
              utilizar plug-ins de medios sociales que, si hace clic en ellos,
              transmiten cierta información personal al sitio de medios sociales
              aplicable. Si decide interactuar con nosotros en las redes
              sociales, podemos recopilar la información que publique o envíe.
              Tenga en cuenta que esta información también se rige por las
              políticas de privacidad de las respectivas empresas que ofrecen el
              servicio de medios sociales.
            </li>

            <li>
              <b>Eventos o reuniones virtuales:</b> Si asiste a un evento o reunión
              presencial o virtual del HAAP, podemos grabar parte o la totalidad
              de dicho evento o reunión. Utilizamos esta información con fines
              comerciales y de marketing para informar mejor al público sobre el
              HAAP y sus eventos.
            </li>
          </ul>
        </div>

        <div className="content-text">
          <h3>3. Cómo utilizamos la información personal</h3>

          <p>Utilizamos los datos personales para:</p>

          <ul>
            <li>Permitir el uso seguro del sitio. </li>

            <li>
              Proporcionar, analizar, administrar y mejorar el Sitio y nuestros
              productos, servicios y operaciones
            </li>

            <li> Investigación y desarrollo </li>

            <li> Envío de información a petición suya </li>

            <li>
              Envío de información sobre nuestros productos y servicios que
              creemos que usted y su organización pueden encontrar útil
            </li>

            <li> Responder a consultas </li>

            <li>
              Realización de encuestas para recabar su opinión sobre nuestros
              servicios o el Sitio
            </li>

            <li>
              Marketing y publicidad de nuestros productos y servicios y los de
              nuestros socios
            </li>

            <li>
              Publicación de comentarios en nuestros blogs (incluido el nombre,
              pero no la dirección de correo electrónico, asociada al autor)
            </li>

            <li>Publicación de testimonios en el Sitio </li>

            <li>Reclutamiento y contratación de personal </li>

            <li>Proteger nuestros derechos o nuestra propiedad </li>

            <li>Cumplir obligaciones legales </li>

            <li>
              Para otros fines relacionados con las razones por las que usted
              proporciona Información Personal.
            </li>
          </ul>

          <p> b. Información que recibimos de su uso del Sitio </p>

          <p>
            Recogemos los siguientes tipos de información automáticamente a
            través de su uso del Sitio:
          </p>

          <p>
            <b>Cookies, identificadores de dispositivos y tecnologías similares</b>
          </p>

          <p>
            Cuando usted utiliza o accede al Sitio, nosotros, nuestros
            proveedores de servicios y nuestros anunciantes utilizamos cookies,
            identificadores de dispositivos y tecnologías similares como
            píxeles, balizas web y almacenamiento local para recopilar
            información sobre cómo utiliza el Sitio. Para obtener más
            información, consulte nuestra  <Link to={`/${locale}/cookie-policy`}>Política de cookies</Link>.
          </p>

          <p>
            Procesamos la información recopilada a través de dichas tecnologías
            para facilitar el funcionamiento de determinadas funciones del
            Sitio, mejorar su experiencia a través de la personalización y
            ayudarnos a comprender mejor las funciones del Sitio que más le
            interesan a usted y a otros usuarios.
          </p>

          <p>
            Algunos de nuestros proveedores de servicios pueden utilizar cookies
            u otros métodos para recopilar información sobre su uso del Sitio, y
            pueden combinar la información de estas cookies con cualquier
            Información Personal sobre usted que puedan tener. El uso de dicha
            información de seguimiento por parte de un tercero depende de la
            política de privacidad de dicho tercero. Por el momento, no
            respondemos a las señales Do Not Track ("DNT") que nos envía su
            navegador. Para obtener más información sobre el funcionamiento de
            DNT, visite <Link to="https://allaboutdnt.com/" target="_blank">
              http://allaboutdnt.com/
            </Link>.
          </p>

          <p>
            La mayoría de los navegadores le ofrecen la posibilidad de bloquear,
            eliminar o desactivar las cookies, y su dispositivo móvil puede
            permitirle desactivar la transmisión de identificadores únicos y
            datos de localización. Si decide rechazar las cookies o bloquear los
            identificadores del dispositivo, es posible que algunas
            características del Sitio no estén disponibles o que algunas
            funciones estén limitadas o no disponibles. Consulte las páginas de
            ayuda de su navegador o dispositivo móvil para obtener ayuda sobre
            cómo cambiar su configuración.
          </p>

          <ul>
            <li>
              <div><b>Log File Information</b></div> Cuando usted utiliza nuestro Sitio, nuestros
              servidores registran automáticamente información, incluida su
              dirección de Protocolo de Internet (<b>"Dirección IP"</b>), tipo de
              navegador, URL de referencia (por ejemplo, el sitio que visitó
              antes de llegar a nuestro Sitio), número de clics y cómo
              interactúa con los enlaces del Sitio, nombres de dominio asociados
              a su proveedor de servicios de Internet, páginas visitadas y otra
              información similar (colectivamente, <b>"Información de archivo de
              registro"</b>). También podemos recopilar información similar de los
              mensajes de correo electrónico que le enviamos, lo que nos ayuda a
              saber qué mensajes se abren y en qué enlaces hacen clic los
              destinatarios. Utilizamos la información de los archivos de
              registro para ayudar a proteger el sitio identificando posibles
              amenazas y vulnerabilidades, y para analizar la eficacia de
              nuestro sitio con el fin de mejorar su funcionamiento y contenido.
            </li>

            <li>
              <div><b>Información que recibimos de terceros</b></div> Podemos recibir Información
              Personal sobre usted de sus amigos o colegas que, a través de la
              función "reenviar a un amigo" del Sitio, le inviten a visitar el
              Sitio o a ver determinados contenidos. Utilizamos esta información
              para enviarle un único mensaje invitándole a visitar el Sitio y
              para realizar un seguimiento del éxito de nuestro programa de
              recomendación.
            </li>
          </ul>
        </div>

        <div className="content-text">
          <h3>4. Divulgación de información personal</h3>

          <p>
            No revelaremos Información Personal excepto en los casos
            establecidos en esta Política de Privacidad o con su consentimiento.
            Esta sección describe a quién revelamos la Información Personal y
            con qué fines:
          </p>

          <ul>
            <li>
              <b>Nuestros proveedores de servicios.</b> Contratamos a proveedores de
              servicios o socios comerciales para que realicen tareas en nuestro
              nombre y nos ayuden a proporcionar el Sitio o nuestros servicios.
              Algunos de estos terceros pueden necesitar acceder a Datos
              Personales para prestar sus servicios. Por ejemplo, contratamos a
              terceros para que nos ayuden a alojar el Sitio, patrocinar
              eventos, analizar el tráfico de nuestro Sitio y comercializar
              nuestros servicios en línea. Estos terceros también nos ayudan en
              el análisis exhaustivo de datos y en la gestión de nuestros
              contactos con los clientes.
            </li>

            <li>
              <b>Eventos y negocios presenciales y virtuales.</b> Si usted participa en
              eventos presenciales o virtuales del HAAP o realiza cualquier otra
              actividad comercial con el personal del HAAP, podremos compartir
              la información de su contrato con nuestros socios comerciales,
              proveedores y patrocinadores, y realizar análisis comerciales y
              enviarle Comunicaciones relacionadas con los productos y servicios
              del HAAP y de sus socios comerciales y patrocinadores. Por favor,
              consulte la Sección de Exclusión de esta Política de Privacidad
              para obtener información sobre cómo dejar de recibir dichas
              Comunicaciones. Recopilamos esta información de usted, ya sea en
              persona en nuestro stand de marketing u otro evento, o en línea a
              través de un proceso de registro.
            </li>

            <li>
              <b>Empresas implicadas en transacciones de fusiones y adquisiciones</b>.
              Si vendemos o transferimos parte o la totalidad de nuestro negocio
              o activos a otra organización (por ejemplo, en el curso de una
              transacción como una fusión, adquisición, quiebra, disolución o
              liquidación), cualquier información recogida a través del Sitio,
              incluida la Información Personal, puede estar entre los elementos
              vendidos o transferidos.
            </li>

            <li>
              <b>Cumplimiento de la ley, agencias gubernamentales y tribunales:</b>
              Podemos revelar Información Personal a petición de las fuerzas de
              seguridad o agencias gubernamentales o en respuesta a citaciones,
              órdenes judiciales u otros procesos legales para establecer,
              proteger o ejercer nuestros derechos o para defendernos contra una
              reclamación legal o según lo requiera o permita la ley, o para
              proteger los derechos, la propiedad o la seguridad de cualquier
              otra persona. También podemos revelar Información Personal para
              investigar o prevenir una violación por su parte de cualquier
              relación contractual o de otro tipo con nosotros o su actividad
              ilegal o perjudicial.
            </li>

            <li>
              <b>Filiales.</b> Podemos revelar algunos o todos sus Datos Personales a
              nuestras filiales, en cuyo caso exigiremos a nuestras filiales que
              cumplan esta Política de Privacidad.
            </li>
          </ul>
        </div>

        <div className="content-text">
          <h3>5. Cómo protegemos la seguridad de la información personal </h3>

          <p>
            Utilizamos salvaguardas comercialmente razonables, como la
            tecnología de encriptación estándar del sector (por ejemplo, el
            protocolo Secure Socket Layer (SSL)), para ayudar a mantener segura
            la información recopilada a través del Sitio. A pesar de estos
            esfuerzos por almacenar la información personal en un entorno
            operativo seguro que no está a disposición del público, no podemos
            garantizar la seguridad de la información personal durante su
            transmisión o almacenamiento en nuestros sistemas. Además, aunque
            intentamos garantizar la integridad y seguridad de la Información
            Personal, no podemos garantizar que nuestras medidas de seguridad
            impidan que terceros, como piratas informáticos, obtengan acceso
            ilegal a la Información Personal. No declaramos ni garantizamos que
            la Información Personal sobre usted esté protegida contra la
            pérdida, el uso indebido o la alteración por parte de terceros.
          </p>
        </div>

        <div className="content-text">
          <h3>6. Privacidad infantil</h3>

          <p>
            No recopilamos ni solicitamos conscientemente ningún tipo de
            Información Personal de niños o menores (de menos de 18 años). En
            caso de que nos enteremos de que hemos recopilado Información
            Personal de un niño sin el consentimiento de sus padres, tomaremos
            medidas inmediatas para eliminar dicha información.
          </p>
        </div>

        <div className="content-text">
          <h3>
            7. Corrección, actualización, acceso o eliminación de datos
            personales
          </h3>

          <p>
            Además de otros derechos que pueda tener en función de su lugar de
            residencia (como se describe a continuación), puede solicitar que
            corrijamos, actualicemos o eliminemos sus Datos Personales
            enviándonos una solicitud por correo electrónico a <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link>.
          </p>

          <p>
            Tramitaremos sin demora dichas solicitudes de conformidad con la
            legislación aplicable.
          </p>

          <p>
            Con su permiso, nosotros y nuestros socios comerciales podemos
            enviarle Comunicaciones. Puede optar por dejar de recibirlas
            indicando sus preferencias en el perfil de su cuenta o en la
            configuración. También puede seguir las instrucciones para darse de
            baja en cualquier comunicación que reciba. También puede enviar una
            solicitud global de cancelación de la suscripción, y/o solicitar la
            exclusión voluntaria de la venta de su Información Personal,
            enviando dicha solicitud por correo electrónico a <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com </Link> Tenga en cuenta que determinadas Comunicaciones
            relacionadas con el Sitio son necesarias para el correcto
            funcionamiento y uso del Sitio y es posible que no pueda optar por
            no recibirlas.
          </p>
        </div>

        <div className="content-text">
          <h3>8. Conservación de datos personales </h3>

          <p>
            Conservaremos sus Datos Personales durante el período necesario para
            cumplir los fines para los que se han recogido, tal como se indica
            en esta Política de Privacidad, a menos que la ley exija un período
            de conservación más largo.
          </p>
        </div>

        <div className="content-text">
          <h3>
            9. Particulares en la EU, Reino Unido, Canadá y otras jurisdicciones
            pertinentes
          </h3>

          <p>
            En esta sección, proporcionamos información adicional sobre cómo
            procesamos la información personal de las personas en la UE, el
            Reino Unido, Canadá y otras jurisdicciones pertinentes de
            conformidad con el GDPR, el GDPR del Reino Unido, el proyecto de ley
            64 de Quebec y otras leyes de privacidad pertinentes. Si necesita
            más información o desea ejercer sus derechos en virtud de las leyes
            mencionadas, puede ponerse en contacto con nosotros en
            <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link>.
          </p>

          <p> a. Base jurídica del tratamiento </p>

          <p>
            En la medida en que lo exija la legislación aplicable en las
            jurisdicciones mencionadas anteriormente en esta Sección 10, nuestra
            base jurídica para procesar la Información Personal descrita en esta
            Política de Privacidad dependerá de la Información Personal en
            cuestión y del contexto en el que la recopilemos. Recogemos y
            utilizamos sus Datos Personales:
          </p>

          <ul>
            <li> Cuando la necesitemos para cumplir un contrato con usted </li>

            <li>
              Cuando el tratamiento responda a nuestros intereses legítimos
              (incluidos los fines descritos anteriormente en la Sección 2,
            </li>

            <li>Cómo utilizamos la información personal); </li>

            <li> Si tenemos que cumplir una obligación legal</li>

            <li> Si contamos con su consentimiento. </li>
          </ul>

          <p>b. Sus derechos y opciones </p>

          <p>
            En función de la legislación aplicable y sujeto a determinadas
            exenciones, puede tener derecho a:
          </p>

          <ul>
            <li> <b>Solicitar acceso</b> a su Información Personal. </li>

            <li>
              <b>Solicitar la corrección</b> de la Información Personal que tenemos
              sobre usted.
            </li>

            <li>
              <b>Solicitar la eliminación</b> de sus Datos Personales. No obstante,
              tenga en cuenta que no siempre podremos atender su solicitud de
              supresión por motivos legales específicos que se le notificarán,
              si procede, en el momento de su solicitud.
            </li>

            <li>
              <b>Corregir (rectificar) sus Datos Personales.</b> En determinadas
              circunstancias, puede tener derecho a solicitar que modifiquemos o
              actualicemos la Información Personal inexacta o incompleta sobre
              usted.
            </li>

            <li>
              <b>Oponerse al tratamiento</b> de sus Datos Personales cuando nos basemos
              en un interés legítimo (o en los de un tercero) y haya algo en su
              situación particular que le lleve a oponerse al tratamiento.
            </li>

            <li>
              <b>Solicitar la restricción</b> del tratamiento de sus datos personales.
              Puede solicitarnos que suspendamos el tratamiento de sus Datos
              Personales en los siguientes supuestos (a) si quiere que
              establezcamos la exactitud de la Información Personal; (b) cuando
              nuestro uso de la Información Personal es ilegal pero no quiere
              que la borremos; (c) cuando necesita que conservemos la
              Información Personal aunque ya no la necesitemos ya que la
              necesita para establecer, ejercer o defender reclamaciones
              legales; o (d) se ha opuesto a nuestro uso de la Información
              Personal pero necesitamos verificar si tenemos motivos legítimos
              imperiosos para usarla.
            </li>

            <li>
              <b>Derecho a la portabilidad</b> de sus datos personales. En determinadas
              circunstancias, usted puede tener derecho a solicitar una copia de
              su Información Personal en formato electrónico y solicitar además
              que enviemos dicha Información Personal a otra parte.
            </li>

            <li>
              <b>Retirar el consentimiento</b> en cualquier momento si nos basamos en
              él para procesar su información personal.
            </li>

            <li>
              <b>Actualizar sus preferencias de comunicación y exclusión
              voluntaria.</b> Puede ejercer su derecho a dejar de recibir nuestras
              comunicaciones comerciales en cualquier momento poniéndose en
              contacto con <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link>.
            </li>

            <li>
              <b>Derecho a presentar una reclamación</b> ante la autoridad de control
              aplicable
            </li>
          </ul>

          <p>
            Si desea ejercer alguno de estos derechos aplicables, póngase en
            contacto con nosotros en <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link>. Tiene derecho a
            presentar una reclamación ante una autoridad de control si considera
            que hemos vulnerado alguno de sus derechos relativos a la
            Información Personal. Le animamos a que primero se ponga en contacto
            con nosotros en <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link>, para que tengamos la
            oportunidad de abordar directamente sus preocupaciones antes de que
            usted lo haga.
          </p>

          <p>
            Las personas residentes en California que accedan al sitio web
            también deben consultar la sección 11 (Sus derechos en California)
            para obtener información específica sobre sus derechos.
          </p>

          <p> c. Transferencias internacionales de datos </p>

          <p>
            Si utiliza los Sitios desde fuera de los Estados Unidos, tenga en
            cuenta que los Datos Personales se transferirán, almacenarán y
            procesarán en los Estados Unidos de América, Canadá y/o México, así
            como en los países en los que se encuentren los Subprocesadores de
            HAAP. A pesar de que tomamos medidas para proporcionar garantías
            adecuadas, las leyes de protección de datos de los Estados Unidos de
            América, Canadá o México podrían no ser tan completas como las de su
            país. Algunos de los países en los que se pueden procesar sus Datos
            Personales, incluidos los Estados Unidos, no están sujetos a una
            decisión de adecuación por parte de la Comisión Europea o de su
            legislador y/o regulador local, y pueden carecer de leyes de
            protección de datos tan completas o no proporcionar el mismo nivel
            de protección de datos que su jurisdicción.
          </p>

          <p>
            Cuando se transfieran Datos Personales a un país no perteneciente al
            EEE que no se considere que ofrece una protección adecuada de los
            Datos Personales, dichos Datos Personales se transferirán utilizando
            la última versión de las Cláusulas Contractuales Tipo de la Unión
            Europea y/o del Reino Unido.
          </p>

          <p>
            Nos esforzamos por aplicar las salvaguardas adecuadas para proteger
            la privacidad y seguridad de la Información Personal que se nos
            transfiere, y por utilizarla de acuerdo con las prácticas descritas
            en esta Política de Privacidad.
          </p>
        </div>
        <div className="content-text">
          <h3>10. Sus derechos de privacidad en California </h3>
          <p>
            Salvo determinadas excepciones, la presente sección se aplica a la
            recopilación y el uso por nuestra parte de datos personales de
            residentes en California, de conformidad con la Ley de Protección de
            Datos de los Consumidores de California (Ley de Privacidad del
            Consumidor de California) y su normativa de desarrollo ("CCPA"),
            donde "datos personales" tiene el significado establecido en la
            CCPA. Esta sección describe (1) las categorías de su Información
            Personal recopilada y divulgada por nosotros, sujeta a la CCPA, (2)
            sus derechos de privacidad bajo la CCPA, y (3) cómo ejercer tales
            derechos.
          </p>
          <p>
            
            Para recibir una copia de esta sección en un formato alternativo
            (por ejemplo, imprimible) o en otro idioma, póngase en contacto con
            nosotros utilizando la información que se facilita a continuación.
          </p>
          <p>
            
            No vendemos información personal. En los últimos 12 meses no creemos
            que hayamos vendido Información Personal y no creemos que
            actualmente vendamos Información Personal.
          </p>
          <p>
            
            Uso de información personal confidencial. No creemos que utilicemos
            o revelemos información personal confidencial.
          </p>
          <p>
            Información personal recopilada. En los últimos 12 meses hemos
            recopilado las siguientes categorías de Datos Personales de
            consumidores:
          </p>
          <p>
            
            b. Sus derechos de privacidad según la legislación de California
          </p>
          <p>
            
            Salvo determinadas excepciones, los residentes en California pueden
            tener los siguientes derechos con respecto a sus Datos Personales:
          </p>
          <p>c. Cómo ejercer sus derechos de privacidad en California </p>
          <p>
            
            Sólo usted, o una persona debidamente autorizada para actuar en su
            nombre, puede realizar una solicitud relacionada con sus Datos
            Personales.
          </p>
          <p> La solicitud debe: </p>
          <ul>
            <li>
              Proporcionar información suficiente que nos permita verificar
              razonablemente que usted es la persona sobre la que recopilamos
              Datos Personales o un representante autorizado.
            </li>

            <li>
              Describirse con suficiente detalle para que podamos comprenderlo,
              evaluarlo y responder a él adecuadamente.
            </li>
          </ul>
          <p>
            Podemos denegar determinadas solicitudes, o satisfacer algunas sólo
            en parte, según lo permita o exija la ley. Por ejemplo, si solicita
            la eliminación de Datos Personales, podremos conservarlos en la
            medida en que lo exija la legislación aplicable.
          </p>
          <p>
            
            Para ejercer sus derechos en California descritos en esta sección,
            póngase en contacto con nosotros en <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link> o llámenos
            al +1.512.514.6183.
          </p>
          <p>
            
            Verificación. Es posible que debamos verificar su identidad antes de
            atender sus solicitudes. Si no podemos verificar inicialmente su
            identidad, podemos solicitar información adicional para completar el
            proceso de verificación, y sólo utilizaremos la información personal
            proporcionada en una solicitud para verificar la identidad del
            solicitante. Si usted es un agente autorizado que realiza una
            solicitud en nombre de un residente en California, es posible que
            también tengamos que verificar su identidad y autorización. No
            podremos responder a su solicitud ni facilitarle Datos Personales si
            no podemos verificar su identidad y confirmar que los Datos
            Personales se refieren a usted. Para realizar una solicitud
            verificable como consumidor no es necesario que cree una cuenta con
            nosotros.
          </p>
          <p>
            
            Tasas. No cobramos honorarios por procesar o responder a su
            solicitud a menos que sea excesiva, repetitiva o manifiestamente
            infundada. Si determinamos que la solicitud justifica el pago de una
            tasa, le informaremos de los motivos de nuestra decisión y le
            facilitaremos un presupuesto antes de tramitar su solicitud.
          </p>
          <p>
            
            No discriminación. HAAP no discriminará contra usted en caso de que
            ejerza cualquiera de los derechos antes mencionados en virtud de la
            CCPA, incluyendo, pero no limitado a, por:
          </p>
          <ul>
            <li> negarle servicios </li>

            <li>
              
              cobrar precios o tarifas diferentes por bienes o servicios,
              incluso mediante el uso de descuentos u otros beneficios o la
              imposición de sanciones
            </li>

            <li>
              
              proporcionarle un nivel o calidad diferentes de bienes o servicios
            </li>

            <li>
              
              sugerirle que recibirá un precio o tarifa diferentes por los
              bienes o servicios o un nivel o calidad diferentes de los bienes o
              servicios.
            </li>
          </ul>
          <p>
            Quejas. La CCPA otorga a los consumidores de California el derecho a
            presentar una denuncia ante la oficina del Fiscal General de
            California. Puede ponerse en contacto con la oficina del Fiscal
            General en
            <Link to="https://oag.ca.gov/contact/consumer-complaint-against-business-or-company" target="_blank">https://oag.ca.gov/contact/consumer-complaint-against-business-or-company</Link>
            o por teléfono en: (916) 210-6276.
          </p>
          <p>
            
            Accesibilidad. El Aviso se proporciona siguiendo las Directrices de
            Accesibilidad al Contenido del Sitio Web y, por tanto, está a
            disposición de los consumidores con discapacidad. Para solicitar
            acceso a este Aviso en un formato alternativo, envíe un correo
            electrónico a <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link>.
          </p>
          <p>
            
            En su solicitud, certifique que es residente en California y
            facilite una dirección actual en California para que podamos
            responderle.
          </p>
          <p> <b>Ley "Shine the Light" de California:</b> </p>
          <p>
            
            En virtud del artículo 1798.83 del Código Civil de California, los
            clientes particulares que residan en California y que mantengan una
            relación comercial con nosotros pueden solicitar información sobre
            la divulgación por nuestra parte de determinadas categorías de Datos
            Personales a terceros para fines de marketing directo de dichos
            terceros, en su caso.
          </p>
          <p>
            
            Para realizar dicha solicitud, póngase en contacto con nosotros a
            través de la información que figura en la sección Contacto. Tenga en
            cuenta que no todo el intercambio de información está cubierto por
            estos requisitos de derechos de privacidad de California y que en
            nuestra respuesta sólo se incluirá información sobre el intercambio
            cubierto. Esta solicitud no podrá realizarse más de una vez al año.
          </p>
        </div>

        <div className="content-text">
          <h3>11. Otros sitios web, incluidas las redes sociales </h3>

          <p>
            No nos hacemos responsables de las prácticas empleadas por los
            sitios web o servicios enlazados a nuestro Sitio o desde él,
            incluida la información o el contenido que contengan. Un enlace al
            sitio web de un tercero no debe interpretarse como una aprobación.
            Le recomendamos que investigue y pregunte antes de revelar
            información personal a terceros.
          </p>
        </div>

        <div className="content-text">
          <h3> 12. Cambios en nuestra política de privacidad </h3>

          <p>
            
            Esta Política de Privacidad está sujeta a cambios sin previo aviso.
            Si en algún momento decidimos utilizar los Datos Personales de un
            modo sustancialmente distinto al establecido en el momento de su
            recogida, le notificaremos por correo electrónico los cambios
            introducidos en nuestra Política. En tales casos, usted tendrá la
            opción de decidir si utilizamos o no sus Datos Personales de esta
            manera diferente.
          </p>
        </div>

        <div className="content-text">
          <h3>13. Cómo ponerse en contacto con nosotros </h3>

          <p>Si desea más información, póngase en contacto con nosotros. </p>
          <p> HAAP Tech Inc. </p>
          <p> 7000 North Mopac Expressway, Suite 200, Austin, TX, 78731 USA </p>
          <p> +1.512.514.6183 </p>

          <p> Correo: <Link to="mailto:privacy@haaptech.com">privacy@haaptech.com</Link> </p>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicyEn;
